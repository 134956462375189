/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Image } from 'antd';
import { Carousel } from 'react-responsive-carousel';
import { getImagePath } from '../../../utils/getImagePath';
import fallback from '../../../img/fallback.png';
import { $basicGrey, $basicWhite } from '../../../styles/styled/colors';

const StyledSlider = styled.div`
  overflow: hidden;
  width: 304px;
  height: 215px;
  position: relative;
  background: #D2E9FF;

  .ant-image {
    img {
      object-fit: cover;
    }
  }

  .styled-slider {
    &__slide {
      padding: 0 62px;
      display: flex;
      align-items: center;
      min-height: 215px;

      > div {
        display: flex;
        align-items: center;
        height: 60px;
      }
    }

    &__arrows {
      display: flex;
      width: 100px;
      justify-content: space-between;
      margin: 15px auto;
    }

    &__arrow {
      color: ${$basicWhite};
      transition: .25s ease-in;

      &:hover {
        color: ${$basicGrey};
      }
    }
  }
`;

const StyledThumbs = styled.div`
  position: absolute;
  z-index: 999;
  bottom: 16px;
  display: flex;
  height: 40px;
  padding: 0 0 0 15px;
`;

const StyledImage = styled.div`
  > div {
    margin: 0 12px 0 0;
    border-radius: 2px;
    height: 100%;
    border: 1px solid transparent;
    cursor: pointer;

    ${({ isActive }) => isActive && css`
      border: 1px solid #FFFFFF;
    `}
    ${({ remains }) => remains > 0 && css`
      border: 1px solid #FFFFFF;
      position: relative;

      &:after {
        content: "+${remains}";
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        color: #fff;
        transform: translate(-50%, -50%);
      }
    `}
    img {
      object-fit: cover;
      height: 100%;
    }
  }`;

const AuctionsCardSlider = ({ pictures }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [slides, setSlides] = useState([]);
    const thumbHandler = (idx) => {
        setCurrentSlide(idx);
    };
    useEffect(() => {
        const gallery = pictures.map((picture) => getImagePath(picture));
        setSlides(gallery);
    }, [pictures]);

    return (
        <StyledSlider className="w-25">
            {
                !!slides.length
                    ? (
                        <Carousel
                            autoPlay
                            infiniteLoop
                            showThumbs={false}
                            showStatus={false}
                            showIndicators={false}
                            selectedItem={currentSlide}
                            onChange={(index) => setCurrentSlide(index)}
                        >
                            {slides.slice(0, 4).map((slide, idx) => (
                                <Image
                                    key={idx}
                                    preview={false}
                                    alt={slide}
                                    src={slide}
                                    height={215}
                                />
                            ))}
                        </Carousel>
                    )
                    : <Image src={fallback} width="100%" height="100%" preview={false} />
            }

            <StyledThumbs>
                {slides.map((slide, idx) => {
                    if (idx > 4) {
                        return null;
                    }
                    return (
                        <StyledImage
                            key={idx}
                            isActive={idx === currentSlide}
                            remains={idx === 4 ? slides.length - 4 : 0}
                            onClick={() => thumbHandler(idx)}
                        >
                            <Image
                                preview={false}
                                alt={slide}
                                src={slide}
                                width={40}
                            />
                        </StyledImage>
                    );
                })}
            </StyledThumbs>

        </StyledSlider>
    );
};

export default AuctionsCardSlider;
