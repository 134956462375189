import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import FormLabel from './FormLabel';
import { Checkbox } from '../../ui';

const StyledSelect = styled.div`
  padding: 0;
  flex: auto;
`;

const FormCheckbox = ({
                          label, link = null,
                          onChange, items = [],
                      }) => {
    const { locale } = useIntl();
    const checkboxHandler = (evt) => {
        const { id } = evt.target;
        onChange(id);
    };
    return (
        <StyledSelect>
            {
                label && <FormLabel label={label} link={link} />
            }
            <div className="d-flex align-items-center flex-wrap">
                {
                    items.map(({
                                   checked, disabled, id, title,
                               }) => (
                        <Checkbox
                            key={id}
                            id={id}
                            checked={checked}
                            label={title[locale]}
                            onChange={checkboxHandler}
                            disabled={disabled}
                            className="me-3"
                        />
                    ))
                }
            </div>
        </StyledSelect>
    );
};

export default FormCheckbox;
