const linkClick = (href) => {
    const el = document.createElement('a');
    el.href = href;
    el.target = '_blank';
    document.querySelector('body').append(el);

    el.dispatchEvent(new MouseEvent('click', { bubbles: true }));
    el.remove();
};

export default linkClick;
