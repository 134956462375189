import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { categoriesFetch } from '../../store/actions/productActions';
import { productState, setActiveCategory } from '../../store/reducers/productReducer';
import { $basicBlue, $basicGreen } from '../../styles/styled/colors';
import { localeState } from '../../store/reducers/localesReducer';
import { mdQueryDown, smQueryDown, xlQueryDown } from '../../styles/styled/sizes';

const StyledNav = styled.nav`
  position: relative;
  background: ${$basicBlue};
  z-index: 2;

  @media ${xlQueryDown} {
    margin-top: 75px;
    margin-bottom: -30px;
  }
  
  @media ${mdQueryDown} {
    margin-top: 55px;
  }

  .styled-nav {
    &-list {
      position: relative;
      display: flex;
      align-items: center;
      margin: 0 -25px;

      @media ${mdQueryDown} {
        margin: 0;
        position: absolute;
        left: 11px;
        right: 0;
      }

      li {
        margin: 0 7px;

        a {
          position: relative;
          letter-spacing: .04em;
          text-decoration: none;
          text-transform: uppercase;
          padding: 13px 18px;
          display: block;
          transition: none;
          width: max-content;

          @media ${smQueryDown} {
            font-size: 12px;
            padding: 8px 10px;
          }

          &:hover {
            color: initial;
          }

          &.active {
            color: #fff;
            background: ${$basicGreen};
          }

          &.active:after,
          &.active:before {
            content: '';
            display: block;
            position: absolute;
            width: 0;
            height: 0;
            top: 0;
          }

          &.active:before {
            left: -18px;
            border-bottom: 47px solid ${$basicGreen};
            border-left: 18px solid transparent;

            @media ${smQueryDown} {
              border-bottom: 34px solid ${$basicGreen};
            }
          }

          &.active:after {
            right: -18px;
            border-top: 47px solid ${$basicGreen};
            border-right: 18px solid transparent;

            @media ${smQueryDown} {
              border-top: 34px solid ${$basicGreen};
            }
          }
        }
      }
    }
  }
`;

const StyledContainer = styled.div`
  @media ${mdQueryDown} {
    max-width: 9999px;
    height: 47px;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media ${smQueryDown} {
    height: 34px;
  }
`;

const CategoryNav = (props) => {
    const dispatch = useDispatch();
    const containerRef = useRef();

    const { categories, activeCategory } = useSelector(productState);
    const { currentLocale } = useSelector(localeState);

    const changeHandler = (id) => {
        dispatch(setActiveCategory(id));
    };

    const linkHandler = async (e, id) => {
        e.preventDefault();

        changeHandler(id);
    };

    useEffect(() => {
        dispatch(categoriesFetch());
    }, [dispatch]);

    if (!categories.length) return null;

    return (
        <StyledNav {...props}>
            <StyledContainer className="container position-relative" ref={containerRef}>
                <ul className="styled-nav-list">
                    {
                        categories.map(({ id, name }) => (
                            <li key={`navCategory${id}`}>
                                <NavLink
                                    to="#"
                                    isActive={() => id === activeCategory}
                                    onClick={(e) => {
                                        linkHandler(e, id);
                                    }}
                                >
                                    {name[currentLocale]}
                                </NavLink>
                            </li>
                        ))
                    }
                </ul>
            </StyledContainer>
        </StyledNav>
    );
};

export default CategoryNav;
