import React, { Fragment } from 'react';
import styled from 'styled-components';
import { $basicGrey } from '../../../styles/styled/colors';
import { xsQueryDown } from '../../../styles/styled/sizes';

const StyledCardInfoTop = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px dashed ${$basicGrey};
  padding-bottom: 10px;

  > span {
    width: 2px;
    height: 2px;
    margin: 0 4px;
    background: ${$basicGrey};

    &:last-child {
      display: none;
    }
  }

  > p {
    font-size: 12px;
    font-weight: 500;

    @media ${xsQueryDown} {
      font-weight: 400;
    }
  }
`;

const CardInfoTop = ({ items = [] }) => (
    <StyledCardInfoTop>
        {items.filter((item) => !!item).map((item) => (
            <Fragment key={item}>
                <p>{item}</p>
                <span />
            </Fragment>
        ))}
    </StyledCardInfoTop>
);

export default CardInfoTop;
