import React, { useState } from 'react';
import Slider from 'react-slick';
import { Image } from 'antd';
import styled from 'styled-components';
import { ReactComponent as ArrowRight } from '../../svg/arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../../svg/arrow-left.svg';
import { getImagePath } from '../../utils/getImagePath';
import {
    // lg,
    lgQueryDown, mdQueryDown, sm, smQueryDown, xl,
} from '../../styles/styled/sizes';

const StyledSlider = styled(Slider)`
  margin-top: -41px;
  transform: translateY(41px);

  @media ${smQueryDown} {
    margin-top: -20px;
    margin-left: -60px;
    margin-right: -60px;
    transform: translateY(20px);
  }

  .slick-list {
    overflow: visible;
    margin-left: -7.5px;
    margin-right: -7.5px;
  }

  .slick-slide {
    opacity: .5;
    transition: .3s;
    padding: 0 7.5px;

    @media ${lgQueryDown} {
      opacity: 1;
    }
  }

  .slick-active {
    opacity: 1;
  }

  .ant-image {
    width: 327px;
    height: 278px;
    border-radius: 4px;
    overflow: hidden;

    @media ${lgQueryDown} {
      width: 100%;
    }

    @media ${mdQueryDown} {
      height: 191px;
    }
  }

  .image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: opacity .3s;

    &.prev {
      opacity: 0;

      @media ${lgQueryDown} {
        opacity: 1;
      }
    }
  }

  &.centered {
    .slick-track {
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: center;
    }
  }

  .detail__slider {
    &-next,
    &-prev {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;

      @media ${lgQueryDown} {
        display: none;
      }
    }

    &-next {
      right: 100px;
    }

    &-prev {
      left: 10px;
    }
  }

  @media (max-width: 320px) {
    max-width: 35%;
  }
`;

const NextArrow = ({ onClick }) => (
    <button className="detail__slider-next" onClick={onClick} type="button">
        <ArrowRight />
    </button>
);
const PrevArrow = ({ onClick }) => (
    <button className="detail__slider-prev" onClick={onClick} type="button"><ArrowLeft />
    </button>
);

const sliderSettings = {
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    infinite: false,
    draggable: true,
    variableWidth: true,
    responsive: [
        {
            breakpoint: xl,
            settings: {
                slidesToShow: 2,
            },
        },
        // {
        //     breakpoint: lg,
        //     settings: {
        //         slidesToShow: 2,
        //         centerMode: true,
        //         variableWidth: false,
        //         infinite: true,
        //     },
        // },
        {
            breakpoint: sm,
            settings: {
                slidesToShow: 1,
                centerMode: true,
                variableWidth: false,
                infinite: true,
            },
        },
    ],
};

const DetailSlider = ({ items }) => {
    const [prevSliderImages, setPrevSliderImages] = useState([]);

    const handleBeforeChange = (current, next) => {
        const dir = current - next;

        if (dir < 0) {
            setPrevSliderImages([...prevSliderImages, current]);
        } else {
            setPrevSliderImages([...prevSliderImages.filter((index) => index !== next)]);
        }
    };

    if (!items?.length) return null;

    const content = items.map(({ path, uid, extension }, index) => {
        const src = getImagePath({ path, uid, extension });

        return (
            <Image
                key={uid}
                src={src}
                preview={src}
                className={`image ${prevSliderImages.includes(index) ? 'prev' : ''}`}
            />
        );
    });

    return (
        <Image.PreviewGroup>
            <StyledSlider
                {...sliderSettings}
                className={`${items.length < 4 ? 'centered' : ''}`}
                beforeChange={handleBeforeChange}
            >
                {content}
            </StyledSlider>
        </Image.PreviewGroup>
    );
};

export default DetailSlider;
