import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { FormattedMessage } from 'react-intl';
import { Pagination } from 'antd';
import { ReactComponent as ArrowLeft } from '../../svg/pagination-arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../svg/pagination-arrow-right.svg';
import { StyledPagination } from './styles';
import { mdQueryDown } from '../../styles/styled/sizes';

const PaginationUi = ({
                          total, pageSize, currentPage, items, onChange, pageType = 'products',
                      }) => {
    const isMobile = useMediaQuery({ query: mdQueryDown });

    const itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
            return <ArrowLeft />;
        }
        if (type === 'next') {
            return <ArrowRight />;
        }
        if (type === 'page') {
            return <button className="pagination__jumper-btn" type="button">{current}</button>;
        }
        return originalElement;
    };

    return (
        <StyledPagination className="pagination" key={items.length}>
            <div className="container">
                {
                    !isMobile && (
                        <p className="pagination__counter">
                            <FormattedMessage
                                id={pageType === 'auctions' ? 'paginationTotals' : 'paginationProducts'}
                                values={{
                                    current: items.length,
                                    total,
                                }}
                            />
                        </p>
                    )
                }
                <div className="pagination__jumper">
                    <Pagination
                        current={currentPage}
                        itemRender={itemRender}
                        onChange={onChange}
                        defaultCurrent={currentPage}
                        total={total}
                        showSizeChanger={false}
                        showLessItems
                        pageSize={pageSize}
                    />
                </div>
            </div>
        </StyledPagination>
    );
};

export default PaginationUi;
