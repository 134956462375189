import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import Checkbox from '../../ui/Checkbox';
import FormControlsWrapper from '../../components/Form/FormControlsWrapper';
import FormInput from '../../components/Form/FormInput';
import FormSectionTitle from '../../components/Form/FormSectionTitle';
import FormSelect from '../../components/Form/FormSelect';
import { productState } from '../../store/reducers/productReducer';
import { userState } from '../../store/reducers/userReducer';
import { $basicWhite } from '../../styles/styled/colors';
import {
    BANK_NAME, INN, PERSON_EMAIL, PERSON_NAME, PERSON_PASSPORT, PERSON_PHONE,
} from '../../utils/consts';
import { localeState } from '../../store/reducers/localesReducer';
import deliveryPorts from '../../utils/deliveryPorts';

const StyledCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${$basicWhite};
  border-radius: 3px;
  height: 42px;
`;

const PersonalCustomerCompany = ({
                                     company, onChange, errors, resetErrors,
                                 }) => {
    const { currentLocale } = useSelector(localeState);
    const { categories } = useSelector(productState);
    const [persons, setPersons] = useState([]);
    const [requisites, setRequisites] = useState({});
    const [isPrimary, setPrimary] = useState(false);
    const { mainProductCategory, companies, activeIndex } = useSelector(userState);
    const { formatMessage } = useIntl();

    const categoriesOptions = categories.map(({
                                                  id,
                                                  name,
                                                  nameEn,
                                                  nameRu,
                                              }) => ({ id, name: name[currentLocale] || nameEn || nameRu }));

    useEffect(() => {
        if (!!company) {
            const requisitesCompany = company.requisites || {};
            let isPrimaryCompany = company.primary;
            if (companies.length === 1) {
                isPrimaryCompany = true;
            }
            const responsiblePersons = company.responsiblePersons.length === 1
                ? [
                    ...company.responsiblePersons,
                    {
                        name: '',
                        phone: '',
                        email: '',
                        passport: '',
                        wechatId: '',
                    },
                ]
                : [...company.responsiblePersons];
            setPersons(responsiblePersons);
            setRequisites(requisitesCompany);
            setPrimary(isPrimaryCompany);
        }
    }, [company]);
    return (
        <div key={company.id}>
            <StyledCheckboxContainer className="row px-0 mt-4">
                <div className="d-flex align-items-center justify-content-center">
                    <Checkbox
                        checked={isPrimary}
                        onChange={onChange('primary', true, null, activeIndex, true)}
                        label={formatMessage({ id: 'customerMainProfile' })}
                    />
                </div>
            </StyledCheckboxContainer>
            <div className="row px-0">
                <div className="d-flex flex-column px-0">
                    <FormControlsWrapper>
                        <div className="flex-grow-1 col-12 col-sm-6 pe-0 ps-0 pt-4 pt-sm-4">
                            <FormSelect
                                label={<FormattedMessage id="portTitle" />}
                                value={company.deliveryPort}
                                options={deliveryPorts}
                                onChange={onChange('deliveryPort', true, null, activeIndex, false, undefined, true)}
                            />
                        </div>
                    </FormControlsWrapper>
                </div>
            </div>
            <div className="row px-0">
                <FormSectionTitle title={<FormattedMessage id="companyTitle" />} />
                <div className="d-flex flex-column px-0">
                    <FormControlsWrapper>
                        <div className="flex-grow-1 col-12 col-sm-6 pe-0 ps-0 pe-sm-3 pt-4 pt-sm-4">
                            <FormInput
                                label={<FormattedMessage id="companyNameLabel" />}
                                value={company.name}
                                onChange={onChange('name', true, null, activeIndex)}
                                error={errors.name}
                                onFocus={resetErrors}
                            />
                        </div>
                        <div className="flex-grow-1 col-12 col-sm-6 pe-0 ps-0 ps-sm-3 pt-4 pt-sm-4">
                            <FormInput
                                label={<FormattedMessage id="companyAddressLabel" />}
                                value={company.address}
                                onChange={onChange('address', true, null, activeIndex)}
                                error={errors.address}
                                onFocus={resetErrors}
                            />
                        </div>
                        <div className="flex-grow-1 col-12 col-sm-6 pe-0 ps-0 pe-sm-3 pt-4 pt-sm-4">
                            <FormInput
                                label={<FormattedMessage id="innLabel" />}
                                value={requisites.inn}
                                onChange={onChange('inn', true, 'requisites', activeIndex)}
                                error={errors[INN]}
                                onFocus={resetErrors}
                            />
                        </div>
                        {
                            mainProductCategory.id && !!categories.length && (
                                <div className="flex-grow-1 col-12 col-sm-6 pe-0 ps-0 ps-sm-3 pt-4 pt-sm-4">
                                    <FormSelect
                                        label={<FormattedMessage id="categoryLabel" />}
                                        value={mainProductCategory.id}
                                        options={categoriesOptions}
                                        disabled
                                    />
                                </div>
                            )
                        }
                    </FormControlsWrapper>
                </div>
            </div>
            {
                company && persons.map((person, idx) => (
                    <div key={idx} className="row px-0">
                        <FormSectionTitle title={<FormattedMessage id={`responsiblePersonsTitle${idx + 1}`} />} />
                        <div className="d-flex flex-column px-0">
                            <FormControlsWrapper>
                                <div className="flex-grow-1 col-12 col-lg-6 pe-0 ps-0 pe-lg-3">
                                    <div className="d-flex flex-wrap pt-4">
                                        <div className="col-12 pe-0 ps-0 col-lg-6 pe-lg-3">
                                            <FormInput
                                                label={<FormattedMessage id="personNameLabel" />}
                                                value={person.name}
                                                onChange={onChange('name', true, 'responsiblePersons', activeIndex, false, idx)}
                                                error={idx === 0 ? errors[PERSON_NAME] : null}
                                                onFocus={resetErrors}
                                            />
                                        </div>
                                        <div
                                            className="d-none d-lg-block col-12 pe-0 ps-0 col-lg-6 ps-lg-3 pt-4 pt-sm-0"
                                        >
                                            <FormInput
                                                label={<FormattedMessage id="personPassportLabel" />}
                                                value={person.passport}
                                                onChange={onChange('passport', true, 'responsiblePersons', activeIndex, false, idx)}
                                                error={idx === 0 ? errors[PERSON_PASSPORT] : null}
                                                onFocus={resetErrors}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-grow-1 col-12 col-lg-6 pe-0 ps-0 ps-lg-3">
                                    <div className="d-flex flex-wrap pt-4">
                                        <div
                                            className="col-12 pe-0 ps-0 ps-sm-2 ps-lg-0 pt-4 pt-sm-0 col-sm-6 col-lg-4 styled-form-controls__triple
                                                styled-form-controls__triple-left"
                                        >
                                            <FormInput
                                                label={<FormattedMessage id="personWechatLabel" />}
                                                value={person.wechatId}
                                                onChange={onChange('wechatId', true, 'responsiblePersons', activeIndex, false, idx)}
                                                onFocus={resetErrors}
                                            />
                                        </div>
                                        <div
                                            className="col-12 pe-0 ps-0 pe-sm-2 pe-lg-0 col-sm-6 col-lg-4 pt-4 pt-lg-0 styled-form-controls__triple styled-form-controls__triple-center"
                                        >
                                            <FormInput
                                                label={<FormattedMessage id="personPhoneLabel" />}
                                                value={person.phone}
                                                onChange={onChange('phone', true, 'responsiblePersons', activeIndex, false, idx)}
                                                error={idx === 0 ? errors[PERSON_PHONE] : null}
                                                onFocus={resetErrors}
                                            />
                                        </div>
                                        <div
                                            className="col-12 pe-0 ps-0 ps-sm-2 ps-lg-0 col-sm-6 col-lg-4 pt-4 pt-lg-0  styled-form-controls__triple styled-form-controls__triple-right"
                                        >
                                            <FormInput
                                                label={<FormattedMessage id="personEmailLabel" />}
                                                value={person.email}
                                                onChange={onChange('email', true, 'responsiblePersons', activeIndex, false, idx)}
                                                error={idx === 0 ? errors[PERSON_EMAIL] : null}
                                                onFocus={resetErrors}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </FormControlsWrapper>
                        </div>
                    </div>
                ))
            }
            <div className="row px-0">
                <FormSectionTitle title={<FormattedMessage id="requisitesTitle" />} />
                <div className="d-flex flex-column px-0">
                    <FormControlsWrapper>
                        <div className="flex-grow-1 col-12 col-sm-6 pe-0 ps-0 pe-sm-3 pt-4 pt-sm-4">
                            <FormInput
                                label={<FormattedMessage id="bankNameLabel" />}
                                value={requisites.bankName}
                                onChange={onChange('bankName', true, 'requisites', activeIndex)}
                                error={errors[BANK_NAME]}
                                onFocus={resetErrors}
                            />
                        </div>
                        <div className="flex-grow-1 col-12 col-sm-6 pe-0 ps-0 ps-sm-3 pt-4 pt-sm-4">
                            <FormInput
                                label={<FormattedMessage id="accountLabel" />}
                                value={requisites.account}
                                onChange={onChange('account', true, 'requisites', activeIndex)}
                                onFocus={resetErrors}
                            />
                        </div>
                    </FormControlsWrapper>
                </div>
            </div>
        </div>
    );
};

export default PersonalCustomerCompany;
