import {
    APPROVED, INACTIVE, ON_MODERATION,
} from './consts';
import {
    $basicGreen, $basicGrey, $basicRed, $basicYellow,
} from '../styles/styled/colors';

export const statusColor = (status) => {
    switch (true) {
        case status === ON_MODERATION:
            return $basicYellow;
        case status === INACTIVE:
            return $basicGrey;
        case status === APPROVED:
            return $basicGreen;
        default:
            return $basicRed;
    }
};
