import styled from 'styled-components';
import { Collapse } from 'antd';
import { $basicBlack, $basicWhite } from '../../../../styles/styled/colors';
import Text from '../../../../ui/Text';
import { smQueryDown } from '../../../../styles/styled/sizes';

export const StyledCollapse = styled(Collapse)`
  background: transparent;
  border: 0;

  > .ant-collapse-item {
    border: 0;

    > .ant-collapse-header {
      padding: 16px 27px;

      @media ${smQueryDown} {
        padding: 16px 15px;
      }

      .ant-collapse-arrow {
        right: 27px;
        transition: .3s;

        @media ${smQueryDown} {
          right: 15px;
        }
      }
    }

    &.ant-collapse-item-active {
      > .ant-collapse-header {
        .ant-collapse-arrow {
          transform: rotate(-180deg);
        }
      }
    }
  }
`;

export const StyledCollapsePanel = styled(Collapse.Panel)`
  margin-bottom: 10px;
  background: ${$basicWhite};
  border-radius: 4px;
  box-shadow: none;
  border: 0;

  .ant-collapse-header {
    font-size: 16px;
    color: ${$basicBlack};

    @media ${smQueryDown} {
      font-size: 14px;
    }
  }

  .ant-collapse-content {
    border-radius: 0 0 4px 4px;
    border: 0;
  }

  .ant-collapse-content-box {
    padding: 5px 39px 14px 27px;

    @media ${smQueryDown} {
      padding-left: 21px;
      padding-right: 32px;
    }
  }
`;

export const StyledText = styled(Text)`
  line-height: 22px;

  p {
    margin-bottom: 15px;
  }
`;
