import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, message } from 'antd';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import AuthModal from '../Auth/AuthModal';
import { setSupportFormOpen } from '../../store/reducers/notificationReducer';
import { Button, Text } from '../../ui';
import { $basicGrey, $basicRed } from '../../styles/styled/colors';
import useAuth from '../../utils/hooks/useAuth';
import { supportSend } from '../../store/actions/notificationActions';

const StyledInput = styled.div`
  padding: 0;
  flex: 1 0 auto;
  color: ${({ error }) => (error ? $basicRed : 'currentColor')} !important;

  input, textarea {
    border: 1px solid ${$basicGrey};
    box-sizing: border-box;
    border-radius: 4px;
  }

  input {
    height: 42px;
  }

  .ant-input-affix-wrapper, .styled-form-input {
    border: 1px solid ${({ error }) => (error ? $basicRed : $basicGrey)} !important;
  }

  .ant-input-suffix {
    color: ${({ error }) => (error ? $basicRed : $basicGrey)} !important;
  }
`;

const SupportForm = () => {
    const dispatch = useDispatch();
    const { isAuth } = useAuth();
    const { formatMessage } = useIntl();
    const onCancelHandler = () => {
        dispatch(setSupportFormOpen(false));
    };
    const submitHandler = async (values) => {
        const { payload } = await dispatch(supportSend({ ...values, isAuth }));
        if (payload && payload.data) {
            message.success(formatMessage({ id: 'questionSent' }));
        }
    };

    return (
        <AuthModal
            onCancel={onCancelHandler}
            title={formatMessage({ id: 'writeSupport' })}
            body={(
                <Form
                    onFinish={submitHandler}
                >
                    {
                        !isAuth && (
                            <>
                                <StyledInput>
                                    <Form.Item
                                        name="authorName"
                                        rules={[
                                            {
                                                required: true,
                                                message: formatMessage({ id: 'enterCorrectEmail' }),
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={formatMessage({ id: 'yourName' })}
                                        />
                                    </Form.Item>
                                </StyledInput>
                                <StyledInput>
                                    <Form.Item
                                        name="authorWechatId"
                                    >
                                        <Input
                                            placeholder="WeChat ID"
                                        />
                                    </Form.Item>
                                </StyledInput>
                                <StyledInput>
                                    <Form.Item
                                        name="authorPhone"
                                        rules={[
                                            {
                                                required: true,
                                                message: formatMessage({ id: 'enterCorrectPhone' }),
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={formatMessage({ id: 'personPhonePlaceholder' })}
                                        />
                                    </Form.Item>
                                </StyledInput>
                                <StyledInput>
                                    <Form.Item
                                        name="authorEmail"
                                        rules={[
                                            {
                                                type: 'email',
                                                message: formatMessage({ id: 'enterCorrectEmail' }),
                                            },
                                            {
                                                required: true,
                                                message: formatMessage({ id: 'enterCorrectEmail' }),
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={formatMessage({ id: 'personEmailPlaceholder' })}
                                        />
                                    </Form.Item>
                                </StyledInput>
                            </>
                        )
                    }
                    <StyledInput>
                        <Form.Item
                            name="question"
                            rules={[
                                {
                                    required: true,
                                    message: formatMessage({ id: 'fieldRequired' }),
                                },
                            ]}
                        >
                            <Input.TextArea
                                placeholder={formatMessage({ id: 'question' })}
                                autoSize={{ minRows: 6, maxRows: 6 }}
                            />
                        </Form.Item>
                    </StyledInput>

                    <Form.Item>
                        <Button type="submit" width="100%">
                            {formatMessage({ id: 'send' })}
                        </Button>
                    </Form.Item>
                    <Text color={$basicGrey} className="text-center">
                        {formatMessage({ id: 'registerAgreement' })}
                    </Text>
                </Form>
            )}

        />
    );
};

export default SupportForm;
