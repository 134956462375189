import React from 'react';
import styled, { css } from 'styled-components';
import {
    $basicGreen,
    $basicGrey,
    $basicWhite,
    $basicYellow,
} from '../styles/styled/colors';

const StyledSteps = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -15px;
    
    ${({ vertical }) => vertical && css`
        flex-direction: column;
    `}
`;

const StyledStep = styled.div`
    position: relative;
    font-size: 12px;
    color: ${$basicGrey};
    flex: auto;
    border-color: ${$basicGrey};
    
    ${({ vertical }) => (!vertical ? css`
        border-bottom: 2px solid;
        padding: 0 8px 8px;
        text-align: center;
        margin-bottom: 15px;
    ` : css`
        border-left: 2px solid;
        padding: 15px 0 15px 13px;
    `)}
    
    ${({ color }) => color && css`
        color: ${color};
        border-color: ${color};
    `}
    
    ${({ current, vertical }) => current && vertical && css`
        background: ${$basicYellow};
        color: ${$basicWhite};
        border-radius: 0 10px 10px 0;
    `}
    
    &:before,
    &:after {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        position: absolute;
        background: ${$basicGrey};
        
        ${({ color }) => color && css`
            background: ${color};
        `}
    }
    
    &:before {
        ${({ vertical }) => (!vertical ? css`
            left: -4px;
            bottom: -5px; 
        ` : css`
            left: -5px;
            top: -4px;
        `)}
    }
    
    &:after {
        right: -4px;
        z-index: 1;
        
        ${({ vertical }) => (!vertical ? css`
            right: -4px;
            bottom: -5px;
        ` : css`
            left: -5px;
            bottom: -4px;
        `)}
    }
`;

const Steps = ({ items = [], current = 0, vertical = 0 }) => {
    const getColor = (i) => {
        if (current === i) return $basicYellow;

        if (i < current) return $basicGreen;

        return null;
    };

    return (
        <StyledSteps vertical={vertical}>
            {items.map(({ label }, i) => (
                <StyledStep
                    color={getColor(i)}
                    vertical={vertical}
                    current={i === current}
                >
                    {label}
                </StyledStep>
            ))}
        </StyledSteps>
    );
};

export default Steps;
