import React from 'react';
import styled from 'styled-components';
import useAuction from '../hooks/useAuction';
import DetailSlider from '../../../components/Detail/DetailSlider';

const StyledDetailSlider = styled.div`
  //margin-bottom: -30px;
  //margin-top: 30px;
`;

const AuctionsDetailSlider = ({ auction }) => {
    const { media } = useAuction(auction);
    return (
        !!media.length && (
            <StyledDetailSlider>
                <DetailSlider items={media} />
            </StyledDetailSlider>
        )
    );
};

export default AuctionsDetailSlider;
