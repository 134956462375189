import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { mdQueryDown } from '../../../styles/styled/sizes';
import { $basicDarkGreen, $basicGrey, $basicWhite } from '../../../styles/styled/colors';
import FormSelect from '../../../components/Form/FormSelect';
import { Button } from '../../../ui';
import { productState } from '../../../store/reducers/productReducer';
import FormDatePicker from '../../../components/Form/FormDatePicker';
import { auctionsState, setAuctionsProperty } from '../../../store/reducers/auctionsReducer';
import useAuctionFilter from '../hooks/useAuctionFilter';
import AuctionDetailCustomerDeliveryFilter from './AuctionDetailCustomerDeliveryFilter';
import { localeState } from '../../../store/reducers/localesReducer';

const StyledFilters = styled.div`
  margin: 22px 0 -22px 0;

  @media ${mdQueryDown} {
    margin: 0;
  }

  .filters {
    display: flex;
    justify-content: space-between;

    @media ${mdQueryDown} {
      padding-top: 20px;
    }

    &__col {
      @media ${mdQueryDown} {
        margin: 0 0 30px;
      }
    }

    &__label {
      font-weight: 500;
      margin-bottom: 18px;
      display: none;

      @media ${mdQueryDown} {
        display: block;
        margin-bottom: 15px;
      }
    }

    &__button {
      width: 100%;
      height: 42px;
      text-transform: uppercase;
    }

    @media (max-width: 320px) {
      flex-direction: column;
      &__button {
        width: 100%;
      }
    }

    .ant-select {
      width: 100%;
    }

    .ant-select-selector {
      border-color: ${$basicGrey} !important;
      border-radius: 4px;
      height: 42px;
      display: flex;
      align-items: center;

      .ant-select-selection-item {
        display: flex;
        align-items: center;
      }

      .ant-select-selection-overflow {
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;

        .ant-select-selection-item-content,
        .ant-select-selection-item-remove {
          display: flex;
          align-items: center;
        }

        .option__box {
          display: none;
        }
      }

      &:last-child {
        min-height: 42px;
        height: 100%;
      }
    }
  }

  .reset-btn {
    margin-top: 10px;
    text-decoration: underline;
    color: ${$basicWhite};
    font-weight: 500;

    @media ${mdQueryDown} {
      font-weight: 400;
      color: ${$basicDarkGreen};
      text-align: center;
      width: 100%;
    }
  }
`;

const AuctionsCustomerFilter = ({ onSearch }) => {
    const dispatch = useDispatch();
    const isTablet = useMediaQuery({ query: mdQueryDown });
    const { formatMessage } = useIntl();
    const { currentLocale } = useSelector(localeState);
    const { customerFilters, status, delivery_status_id } = useSelector(auctionsState);
    const { applyFilter } = useAuctionFilter();
    const {
        factories,
        subcategories,
        countries,
    } = useSelector(productState);
    // времмено
    // const countries = [{ id: '1', name: formatMessage({ id: 'russia' }) }];

    const handleReset = useCallback(async () => {
        await dispatch(setAuctionsProperty({
            property: 'customerFilters',
            value: {},
        }));
        applyFilter();
    }, []);

    const setFilter = (id) => (value) => {
        dispatch(setAuctionsProperty({
            property: 'customerFilters',
            value: { ...customerFilters, [id]: value || null },
        }));
    };

    const onSearchHandler = () => {
        applyFilter();

        if (onSearch) onSearch();
    };

    useEffect(() => {
        applyFilter();
    }, [delivery_status_id]);

    const countryPlaceholder = isTablet
        ? formatMessage({ id: 'selectFromList' })
        : formatMessage({ id: 'selectCountry' });
    const factoryPlaceholder = isTablet
        ? formatMessage({ id: 'selectFromList' })
        : formatMessage({ id: 'meatFactory' });
    const productPlaceholder = isTablet
        ? formatMessage({ id: 'selectFromList' })
        : formatMessage({ id: 'productType' });
    const periodPlaceholder = [formatMessage({ id: 'periodStart' }), formatMessage({ id: 'periodEnd' })];

    const factoriesOptions = factories.map(({ id, name }) => ({ id, name }));
    const subcategoriesOptions = subcategories.map(({
                                                        id,
                                                        name,
                                                        nameEn,
                                                        nameRu,
                                                    }) => ({ id, name: name[currentLocale] || nameEn || nameRu }));

    return (
        <StyledFilters>
            <div className="row filters">
                <div className="col-md-3 filters__col">
                    <p className="filters__label"><FormattedMessage id="country" /></p>
                    <FormSelect
                        value={customerFilters.country_id}
                        placeholder={countryPlaceholder}
                        onChange={setFilter('country_id')}
                        options={countries}
                    />
                </div>
                <div className="col-md-3 filters__col">
                    <p className="filters__label">
                        <FormattedMessage id="factoryNumber" />
                    </p>
                    <FormSelect
                        placeholder={factoryPlaceholder}
                        value={customerFilters.factory_id}
                        onChange={setFilter('factory_id')}
                        options={factoriesOptions}
                    />
                </div>
                <div className="col-md-3 filters__col">
                    <p className="filters__label">
                        <FormattedMessage id="productTypeAndCategory" />
                    </p>
                    <FormSelect
                        multiple
                        placeholder={productPlaceholder}
                        value={customerFilters.subcategory_id || []}
                        onChange={setFilter('subcategory_id')}
                        options={subcategoriesOptions}
                    />
                </div>
                <div className="col-md-3 filters__col">
                    <p className="filters__label">
                        {formatMessage({ id: 'periodPlaceholder' })}
                    </p>
                    <FormDatePicker
                        value={customerFilters.period}
                        placeholder={periodPlaceholder}
                        range
                        onChange={setFilter('period')}
                    />
                </div>
            </div>
            <div className="row justify-content-end mt-md-4">
                {
                    status === 'is_winner' && (
                        <div className="col-12 col-md-7 align-items-center d-flex mb-md-0 mb-4">
                            <AuctionDetailCustomerDeliveryFilter />
                        </div>
                    )
                }
                <div className="col-md-2 d-flex justify-content-end align-items-center mb-md-0 mb-4">
                    <button className="reset-btn mt-0" type="button" onClick={handleReset}>
                        <FormattedMessage id="resetFilter" />
                    </button>
                </div>
                <div className="col-md-3">
                    <Button
                        title={isTablet ? formatMessage({ id: 'apply' }) : formatMessage({ id: 'find' })}
                        className="filters__button"
                        variant="filled"
                        bordered
                        onClick={onSearchHandler}
                    />
                </div>
            </div>
        </StyledFilters>
    );
};

export default AuctionsCustomerFilter;
