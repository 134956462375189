import React from 'react';
import { useSelector } from 'react-redux';
import { Radio } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import is from 'is_js';
import { $basicDarkGreen } from '../../styles/styled/colors';
import FormSectionTitle from '../../components/Form/FormSectionTitle';
import { productState } from '../../store/reducers/productReducer';
import FormControlsWrapper from '../../components/Form/FormControlsWrapper';
import FormInput from '../../components/Form/FormInput';
import FormSelect from '../../components/Form/FormSelect';
import { userState } from '../../store/reducers/userReducer';
import {
    BANK_NAME, INN, PERSON_EMAIL, PERSON_NAME, PERSON_PASSPORT, PERSON_PHONE,
} from '../../utils/consts';
import { localeState } from '../../store/reducers/localesReducer';
import deliveryPorts from '../../utils/deliveryPorts';

const StyledCustomerTypeSwitcher = styled.div`
  border: 1px solid ${$basicDarkGreen};
  box-sizing: border-box;
  border-radius: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center !important;

  ${({ isMobile }) => isMobile && css`
    border: none;
  `}
  .styled-customer-type-switcher__label {
    position: relative;
    top: -2px;
  }
`;

const isMobile = is.mobile() || is.tablet();

const PersonalCustomerCreate = ({
                                    company, onChange, errors, resetErrors,
                                }) => {
    const { currentLocale } = useSelector(localeState);
    const { categories } = useSelector(productState);
    const { mainProductCategory, activeIndex } = useSelector(userState);

    const categoriesOptions = categories.map(({
                                                  id,
                                                  name,
                                                  nameEn,
                                                  nameRu,
                                              }) => ({ id, name: name[currentLocale] || nameEn || nameRu }));

    return (
        <div>
            <StyledCustomerTypeSwitcher
                isMobile={isMobile}
                className="row mt-5 px-0"
            >
                <Radio.Group
                    onChange={onChange('isIndividual', true, null, activeIndex)}
                    value={company.isIndividual}
                    className="d-flex justify-content-center"
                    size="large"
                >
                    <Radio className="me-3" value={false}>
                                    <span className="styled-customer-type-switcher__label">
                                        <FormattedMessage id="company" />
                                    </span>
                    </Radio>
                    <Radio className="ms-3" value>
                                    <span
                                        className="styled-customer-type-switcher__label"
                                    >
                                         <FormattedMessage id="ip" />
                                    </span>
                    </Radio>
                </Radio.Group>
            </StyledCustomerTypeSwitcher>
            <div className="row px-0">
                <div className="d-flex flex-column px-0">
                    <FormControlsWrapper>
                        <div className="flex-grow-1 col-12 col-sm-6 pe-0 ps-0 pt-4 pt-sm-4">
                            <FormSelect
                                label={<FormattedMessage id="portTitle" />}
                                value={company.deliveryPort}
                                options={deliveryPorts}
                                onChange={onChange('deliveryPort', true, null, activeIndex, false, undefined, true)}
                            />
                        </div>
                    </FormControlsWrapper>
                </div>
            </div>
            <div className="row px-0">
                <FormSectionTitle title={<FormattedMessage id="companyTitle" />} />
                <div className="d-flex flex-column px-0">
                    <FormControlsWrapper>
                        <div className="flex-grow-1 col-12 col-sm-6 pe-0 ps-0 pe-sm-3 pt-4 pt-sm-4">
                            <FormInput
                                label={<FormattedMessage id="companyNameLabel" />}
                                value={company.name || ''}
                                onChange={onChange('name', true, null, activeIndex)}
                                error={errors.name}
                                onFocus={resetErrors}
                            />
                        </div>
                        <div className="flex-grow-1 col-12 col-sm-6 pe-0 ps-0 ps-sm-3 pt-4 pt-sm-4">
                            <FormInput
                                label={<FormattedMessage id="companyAddressLabel" />}
                                value={company.address || ''}
                                onChange={onChange('address', true, null, activeIndex)}
                                error={errors.address}
                                onFocus={resetErrors}
                            />
                        </div>
                        <div className="flex-grow-1 col-12 col-sm-6 pe-0 ps-0 pe-sm-3 pt-4 pt-sm-4">
                            <FormInput
                                label={<FormattedMessage id="innLabel" />}
                                value={company?.requisites?.inn || ''}
                                onChange={onChange('inn', true, 'requisites', activeIndex)}
                                error={errors[INN]}
                                onFocus={resetErrors}
                            />
                        </div>
                        {
                            mainProductCategory.id && !!categories.length && (
                                <div className="flex-grow-1 col-12 col-sm-6 pe-0 ps-0 ps-sm-3 pt-4 pt-sm-4">
                                    <FormSelect
                                        label={<FormattedMessage id="categoryLabel" />}
                                        value={mainProductCategory.id}
                                        options={categoriesOptions}
                                        disabled
                                    />
                                </div>
                            )
                        }
                    </FormControlsWrapper>
                </div>
            </div>
            {
                !!company.responsiblePersons && company.responsiblePersons.map((person, idx) => (
                    <div key={idx} className="row px-0">
                        <FormSectionTitle title={<FormattedMessage id={`responsiblePersonsTitle${idx + 1}`} />} />
                        <div className="d-flex flex-column px-0">
                            <FormControlsWrapper>
                                <div className="flex-grow-1 col-12 col-lg-6 pe-0 ps-0 pe-lg-3">
                                    <div className="d-flex flex-wrap pt-4">
                                        <div className="col-12 pe-0 ps-0 col-lg-6 pe-lg-3">
                                            <FormInput
                                                label={<FormattedMessage id="personNameLabel" />}
                                                value={person.name || ''}
                                                onChange={onChange('name', true, 'responsiblePersons', activeIndex, false, idx)}
                                                error={idx === 0 ? errors[PERSON_NAME] : null}
                                                onFocus={resetErrors}
                                            />
                                        </div>
                                        <div
                                            className="d-none d-lg-block col-12 pe-0 ps-0 col-lg-6 ps-lg-3 pt-4 pt-sm-0"
                                        >
                                            <FormInput
                                                label={<FormattedMessage id="personPassportLabel" />}
                                                value={person.passport || ''}
                                                onChange={onChange('passport', true, 'responsiblePersons', activeIndex, false, idx)}
                                                error={idx === 0 ? errors[PERSON_PASSPORT] : null}
                                                onFocus={resetErrors}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-grow-1 col-12 col-lg-6 pe-0 ps-0 ps-lg-3">
                                    <div className="d-flex flex-wrap pt-4">
                                        <div
                                            className="col-12 pe-0 ps-0 ps-sm-2 ps-lg-0 pt-4 pt-sm-0 col-sm-6 col-lg-4 styled-form-controls__triple
                                                styled-form-controls__triple-left"
                                        >
                                            <FormInput
                                                label={<FormattedMessage id="personWechatLabel" />}
                                                value={person.wechatId || ''}
                                                onChange={onChange('wechatId', true, 'responsiblePersons', activeIndex, false, idx)}
                                                onFocus={resetErrors}
                                            />
                                        </div>
                                        <div
                                            className="col-12 pe-0 ps-0 pe-sm-2 pe-lg-0 col-sm-6 col-lg-4 pt-4 pt-lg-0 styled-form-controls__triple styled-form-controls__triple-center"
                                        >
                                            <FormInput
                                                label={<FormattedMessage id="personPhoneLabel" />}
                                                value={person.phone || ''}
                                                onChange={onChange('phone', true, 'responsiblePersons', activeIndex, false, idx)}
                                                error={idx === 0 ? errors[PERSON_PHONE] : null}
                                                onFocus={resetErrors}
                                            />
                                        </div>
                                        <div
                                            className="col-12 pe-0 ps-0 ps-sm-2 ps-lg-0 col-sm-6 col-lg-4 pt-4 pt-lg-0  styled-form-controls__triple styled-form-controls__triple-right"
                                        >
                                            <FormInput
                                                label={<FormattedMessage id="personEmailLabel" />}
                                                value={person.email || ''}
                                                onChange={onChange('email', true, 'responsiblePersons', activeIndex, false, idx)}
                                                error={idx === 0 ? errors[PERSON_EMAIL] : null}
                                                onFocus={resetErrors}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </FormControlsWrapper>
                        </div>
                    </div>
                ))
            }
            <div className="row px-0">
                <FormSectionTitle title={<FormattedMessage id="requisitesTitle" />} />
                <div className="d-flex flex-column px-0">
                    <FormControlsWrapper>
                        <div className="flex-grow-1 col-12 col-sm-6 pe-0 ps-0 pe-sm-3 pt-4 pt-sm-4">
                            <FormInput
                                label={<FormattedMessage id="bankNameLabel" />}
                                value={company?.requisites?.bankName || ''}
                                onChange={onChange('bankName', true, 'requisites', activeIndex)}
                                error={errors[BANK_NAME]}
                                onFocus={resetErrors}
                            />
                        </div>
                        <div className="flex-grow-1 col-12 col-sm-6 pe-0 ps-0 ps-sm-3 pt-4 pt-sm-4">
                            <FormInput
                                label={<FormattedMessage id="accountLabel" />}
                                value={company?.requisites?.account || ''}
                                onChange={onChange('account', true, 'requisites', activeIndex)}
                                onFocus={resetErrors}
                            />
                        </div>
                    </FormControlsWrapper>
                </div>
            </div>
        </div>
    );
};

export default PersonalCustomerCreate;
