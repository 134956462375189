import { createAsyncThunk } from "@reduxjs/toolkit";
import $api from "../../api/api";
import { CUSTOMER } from "../../utils/consts";

export const auctionsFetch = createAsyncThunk(
	"auctions",
	async (
		{ role, ordering = "", filters = {} },
		{ rejectWithValue, getState },
	) => {
		const { currentPage, pageSize, status, delivery_status_id } =
			getState().auctions;

		try {
			const params = {
				page: currentPage,
				page_size: pageSize,
				ordering,
				...filters,
			};
			if (delivery_status_id && status === "is_winner") {
				filters.delivery_status_id = delivery_status_id;
			}
			const statuses = ["completed", "active", "planned"];
			if (statuses.includes(status)) {
				params.status = status;
			} else {
				params[status] = true;
			}

			if (!role) {
				return false;
			}

			const url = `/${role}/auctions/`;

			const { data } = await $api.get(url, { params });

			return { data };
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const auctionDetailFetch = createAsyncThunk(
	"auction",
	async ({ role, id }, { rejectWithValue }) => {
		try {
			const url = `/${role}/auctions/${id}/`;
			const { data } = await $api.get(url);

			return { data };
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const productsForAuctionList = createAsyncThunk(
	"products/fetch",
	async ({ pageSize }, { rejectWithValue }) => {
		try {
			const params = {
				pageSize,
				moderation_status: "approved",
			};
			return await $api.get("products/", { params });
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const auctionCreate = createAsyncThunk(
	"auctions/create",
	async ({ data }, { rejectWithValue, getState }) => {
		try {
			const response = await $api.post("/seller/auctions/", data);
			return { data: response.data };
		} catch (e) {
			return rejectWithValue({
				...e.response.data,
				locale: getState().locales.currentLocale,
			});
		}
	},
);

export const auctionBid = createAsyncThunk(
	"auctions/bid",
	async ({ amount, currencyCode, id }, { rejectWithValue, dispatch }) => {
		try {
			const data = {
				currencyCode,
				amount,
			};
			const response = await $api.post(`/customer/auctions/${id}/bid/`, data);
			dispatch(auctionDetailFetch({ role: CUSTOMER, id }));
			return { data: response.data };
		} catch (e) {
			return rejectWithValue(e.response);
		}
	},
);

export const addAuctionToFavourite = createAsyncThunk(
	"auctions/favourite/add",
	async ({ id }, { rejectWithValue, dispatch }) => {
		try {
			const { data } = await $api.post(`/customer/auctions/${id}/favorites/`);
			dispatch(auctionDetailFetch({ role: CUSTOMER, id }));
			return { data };
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const removeAuctionFromFavourite = createAsyncThunk(
	"auctions/favourite/remove",
	async ({ id }, { rejectWithValue, dispatch }) => {
		try {
			const { data } = await $api.delete(`/customer/auctions/${id}/favorites/`);
			dispatch(auctionDetailFetch({ role: CUSTOMER, id }));
			return { data };
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const attachFile = createAsyncThunk(
	"auctions/account",
	async ({ id, file }, { rejectWithValue }) => {
		try {
			const formData = new FormData();

			formData.append("file", file, file.name);

			const { data } = await $api.post(
				`/seller/auctions/${id}/account/`,
				formData,
			);

			return { data };
		} catch (e) {
			return rejectWithValue(e.response);
		}
	},
);

export const deleteAuction = createAsyncThunk(
	"auctions/deleteAuction",
	async ({ id }, { rejectWithValue }) => {
		try {
			const { data } = await $api.delete(`/seller/auctions/${id}/`);
			return { data };
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);
