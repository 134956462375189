import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.div`

  display: flex;
  justify-content: space-between;
  align-items: center;

  > div:first-child {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  > div {
    margin: 0 0 18px;
  }

  @media screen and (max-width: 1200px) {
    > div {
      margin: 0 0 10px;
    }
  }
`;

const FormLabel = ({ label, link, required }) => (
    <StyledLabel>
        <div>
            {label}
            {required ? '*' : ''}
        </div>
        {
            link && <>{link}</>
        }
    </StyledLabel>
);

export default FormLabel;
