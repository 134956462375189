const deliveryPorts = [
    {
        id: 'Tianjin',
        name: 'Tianjin',
    },
    {
        id: 'Yantian',
        name: 'Yantian',
    },
    {
        id: 'Qingdao',
        name: 'Qingdao',
    },
    {
        id: 'Shanghai',
        name: 'Shanghai',
    }, {
        id: 'Xiamen',
        name: 'Xiamen',
    },
    {
        id: 'Ningbo',
        name: 'Ningbo',
    },
    {
        id: 'Yantai',
        name: 'Yantai',
    },
    {
        id: 'Dalian',
        name: 'Dalian',
    },
];

export default deliveryPorts;
