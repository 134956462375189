import React from 'react';
import { useSelector } from 'react-redux';
import { auctionsState } from '../../../store/reducers/auctionsReducer';
import AuctionsCard from './AuctionsCard';
import AuctionsCardWinner from './AuctionsCardWinner';

const AuctionsList = () => {
    const { auctions, status } = useSelector(auctionsState);
    return (
        <div className="row mt-5">
            {
                status === 'is_winner'
                    ? (
                        auctions.map((auction) => (
                            <div key={auction.id} className="col-12 col-lg-6 px-3 mb-4">
                                <AuctionsCardWinner auction={auction} key={auction.id} />
                            </div>
                        ))
                    )
                    : (
                        auctions.map((auction) => (
                            <div key={auction.id} className="col-xl-12 col-md-6">
                                <AuctionsCard auction={auction} />
                            </div>
                        ))
                    )
            }
        </div>
    );
};

export default AuctionsList;
