import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Popover } from 'antd';
import { StyledNotificationsControls, StyledSwitch, StyledSwitchLabel } from './styles';
import { userState } from '../../../../../store/reducers/userReducer';
import { notificationOptionsEdit, userFetch } from '../../../../../store/actions/userActions';
import WeChatConnectInfo from '../WeChatConnectInfo';
import { wechatAuth, wechatSubscribe } from '../../../../../store/actions/notificationActions';
import { CUSTOMER } from '../../../../../utils/consts';

const NotificationsControls = ({ setStepOneOpen }) => {
    const dispatch = useDispatch();
    const { role } = useSelector(userState);
    const {
        companies,
        phone,
        notificationOptions,
    } = useSelector(userState);
    const {
        notifyByWechat,
        notifyByEmail,
        notifyByPhone,
    } = notificationOptions;

    const [profile, setProfile] = useState({});

    const handleChange = (property) => {
        dispatch(notificationOptionsEdit({
            [property]: !notificationOptions[property],
        }));
        dispatch(userFetch());
    };

    const stepHandler = () => {
        dispatch(wechatSubscribe());
        dispatch(wechatAuth());
        setStepOneOpen(true);
    };

    useEffect(() => {
        let primaryCompany = null;
        if (companies.length === 1) {
            // eslint-disable-next-line prefer-destructuring
            primaryCompany = companies[0];
        } else {
            primaryCompany = companies.find(({ primary }) => primary);
        }
        if (primaryCompany) {
            const { wechatId, email } = primaryCompany.responsiblePersons.find(({ primary }) => primary);

            setProfile({ wechatId, email });
        }
    }, [companies]);

    return (
        <StyledNotificationsControls>
            {
                role === CUSTOMER && (
                    <Popover
                        placement="bottomRight"
                        content={(
                            <WeChatConnectInfo
                                stepHandler={stepHandler}
                            />
                        )}
                        trigger="hover"
                    >
                        <div>
                            <StyledSwitch
                                checked={notifyByWechat}
                                label={<StyledSwitchLabel>WeChat ID<br /> {profile.wechatId}</StyledSwitchLabel>}
                                onChange={() => handleChange('notifyByWechat')}
                            />
                        </div>
                    </Popover>
                )
            }
            {!!phone && (
                <StyledSwitch
                    checked={notifyByPhone}
                    label={(
                        <StyledSwitchLabel>
                            <FormattedMessage id="notificationsSmsLabel" />
                            <br /> +{phone}
                        </StyledSwitchLabel>
                    )}
                    onChange={() => handleChange('notifyByPhone')}
                />
            )}
            {!!profile.email && (
                <StyledSwitch
                    checked={notifyByEmail}
                    label={(
                        <StyledSwitchLabel>
                            <FormattedMessage id="notificationsEmailLabel" />
                            <br /> {profile.email}
                        </StyledSwitchLabel>
                    )}
                    onChange={() => handleChange('notifyByEmail')}
                />
            )}
        </StyledNotificationsControls>
    );
};

export default NotificationsControls;
