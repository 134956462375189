import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { StyledModalButton } from '../../../ui/Modal/styles';
import { productSuggest } from '../../../store/actions/productActions';
import FormInput from '../../../components/Form/FormInput';
import { StyledModalContent, StyledModalFooter } from '../styles';

const StyledRow = styled.div`
  margin-left: -28px;
  margin-right: -28px;

  > * {
    padding-left: 28px;
    padding-right: 28px;
  }
`;

const ProductSuggestForm = () => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const [name, setName] = useState();
    const [subcategories, setSubcategories] = useState();
    const [boxWeight, setBoxWeight] = useState();
    const [isValid, setIsValid] = useState(false);

    const submitHandler = () => {
        dispatch(productSuggest({ name, subcategories, boxWeight }));
    };

    useEffect(() => {
        if (name && subcategories && boxWeight) {
            setIsValid(true);
            return;
        }

        setIsValid(false);
    }, [name, subcategories, boxWeight]);

    return (
        <StyledModalContent>
            <StyledRow className="row">
                <div className="col-md-4 mb-3 mb-md-0">
                    <p className="modal__title">
                        <FormattedMessage id="productName" />
                    </p>
                    <FormInput
                        placeholder={formatMessage({ id: 'productNamePlaceholder2' })}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className="col-md-4 mb-3 mb-md-0">
                    <p className="modal__title">
                        <FormattedMessage id="productSubcategory" />
                    </p>
                    <FormInput
                        placeholder={formatMessage({ id: 'productSubcategoryPlaceholder' })}
                        value={subcategories}
                        onChange={(e) => setSubcategories(e.target.value)}
                    />
                </div>
                <div className="col-md-4">
                    <p className="modal__title">
                        <FormattedMessage id="boxWeight" />
                    </p>
                    <FormInput
                        placeholder={formatMessage({ id: 'kgValue' }, { value: 15 })}
                        value={boxWeight}
                        onChange={(e) => setBoxWeight(e.target.value)}
                    />
                </div>
            </StyledRow>
            <StyledModalFooter>
                <p className="footer__text">
                    <FormattedMessage id="productNote" />
                </p>
                <StyledModalButton
                    title={formatMessage({ id: 'suggest' })}
                    width="26%"
                    variant="filled"
                    disabled={!isValid}
                    onClick={submitHandler}
                />
            </StyledModalFooter>
        </StyledModalContent>
    );
};

export default ProductSuggestForm;
