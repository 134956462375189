import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Button, Text } from "../../../ui";
import { $basicGrey2 } from "../../../styles/styled/colors";
import { AUCTIONS_ROUTE, SELLER } from "../../../utils/consts";
import useAuction from "../hooks/useAuction";
import { userState } from "../../../store/reducers/userReducer";
import {
	setAddModalVisibility,
	setCurrency,
	setDeliveryTerms,
} from "../../../store/reducers/auctionsReducer";
import { deleteAuction } from "../../../store/actions/auctionsActions";

const AuctionDetailInfoFooter = ({ auction }) => {
	const dispatch = useDispatch();
	const { push } = useHistory();
	const { role } = useSelector(userState);
	const { formatMessage, locale } = useIntl();
	const {
		etdDate,
		isPlanned,
		// deliveryCondition,
		deliveryConditions,
		paymentConditions,
		isCompleted,
		currencyCode,
		id,
		logisticsType,
	} = useAuction(auction);
	const repeatActionHandler = () => {
		dispatch(setAddModalVisibility(true));
		dispatch(setDeliveryTerms({ id: deliveryConditions }));
		dispatch(setCurrency(currencyCode));
	};

	// let deliveryConditionTitle = "";
	// if (role === SELLER) {
	// 	deliveryConditionTitle = `${formatMessage({
	// 		id: "deliveryConditionSeller",
	// 	})},${deliveryCondition.title[locale]}`;
	// } else {
	// 	deliveryConditionTitle = formatMessage({ id: "deliveryConditionCustomer" });
	// }

	const deleteAuctionHandler = () => {
		dispatch(deleteAuction({ id }));
		push(AUCTIONS_ROUTE);
	};
	return (
		<>
			{!!logisticsType && (
				<div className='mt-5'>
					<Text variant='heading' color={$basicGrey2}>
						<FormattedMessage id='logisticsType' />
					</Text>
					<div className='d-md-flex justify-content-between mt-3'>
						<div className='d-md-flex mb-md-0 mb-3'>
							<Text variant='heading2'> {logisticsType[locale]}</Text>
						</div>
					</div>
				</div>
			)}
			<div className='mt-5'>
				<Text variant='heading' color={$basicGrey2}>
					<FormattedMessage id='payDeliveryConditions' />
				</Text>
				<div className='d-md-flex justify-content-between mt-3'>
					<div className='d-flex'>
						<Text variant='heading2'>
							<FormattedMessage id='shipmentETD' />
							,&nbsp;
						</Text>
						<Text variant='heading2'>{etdDate}</Text>
					</div>
				</div>
				<div className='d-md-flex justify-content-between mt-3'>
					{/* <div className='d-md-flex mb-md-0 mb-3'>
						<Text variant='heading2'>{deliveryConditionTitle}</Text>
					</div> */}
					<div className='d-md-flex mb-md-0 mb-3'>
						<Text variant='heading2'>{paymentConditions}</Text>
					</div>
				</div>
			</div>
			{isPlanned && role === SELLER && (
				<div className='flex-grow-1 mt-4'>
					<Button
						onClick={deleteAuctionHandler}
						title={formatMessage({ id: "undoAuction" })}
						variant='white'
						width='100%'
					/>
				</div>
			)}
			{isCompleted && role === SELLER && (
				<div className='flex-grow-1 mt-4'>
					<Button
						onClick={repeatActionHandler}
						title={formatMessage({ id: "repeatAuction" })}
						variant='white'
						width='100%'
					/>
				</div>
			)}
		</>
	);
};

export default AuctionDetailInfoFooter;
