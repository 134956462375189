import React from 'react';
import styled from 'styled-components';

const StyledFormControls = styled.div`
  display: flex;
  flex-wrap: wrap;

  .styled-form-controls {
    &__triple {
      &-left {
        > div {
          padding: 0 16px 0 0;
          @media screen and (max-width: 1200px) {
            padding: 0 12px 0 0;
          }
          @media screen and (max-width: 992px) {
            padding: 0;
          }
        }
      }

      &-center {
        > div {
          padding: 0 8px;
          @media screen and (max-width: 1200px) {
            padding: 0;
          }
          //@media screen and (max-width: 992px) {
          //  margin-top: 20px;
          //}
        }
      }

      &-right {
        > div {
          padding: 0 0 0 16px;
          @media screen and (max-width: 1200px) {
            padding: 0 0 0 12px;
          }
          @media screen and (max-width: 992px) {
            padding: 0;
            //margin-top: 20px;
          }
        }
      }
    }
  }
`;

const FormControlsWrapper = ({ children }) => (
    <StyledFormControls>
        {children}
    </StyledFormControls>
);

export default FormControlsWrapper;
