import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../../ui';
import { productState, resetCustomerFilters, setCustomerFilters } from '../../../../store/reducers/productReducer';
import { productsFetch } from '../../../../store/actions/productActions';
import { mdQueryDown } from '../../../../styles/styled/sizes';
import FormSelect from '../../../../components/Form/FormSelect';
import {
    StyledFilters, StyledFiltersCol, StyledFiltersLabel, StyledResetButton,
} from './styles';
import { localeState } from '../../../../store/reducers/localesReducer';

const ProductsFilter = ({ onSearch }) => {
    const dispatch = useDispatch();
    const isTablet = useMediaQuery({ query: mdQueryDown });
    const { formatMessage } = useIntl();
    const { currentLocale } = useSelector(localeState);
    const {
        customerFilters,
        factories,
        subcategories,
        countries,
    } = useSelector(productState);
    const [countriesVal, setCountriesVal] = useState(customerFilters.country);
    const [factoriesVal, setFactoriesVal] = useState(customerFilters.factory);
    const [subcategoriesVal, setSubcategoriesVal] = useState(customerFilters.subcategory || []);

    // const countries = [{ id: '1', name: 'Россия' }];

    const search = () => {
        dispatch(productsFetch());

        if (onSearch) onSearch();
    };

    const handleChangeCountries = (value) => {
        setCountriesVal(value);
        dispatch(setCustomerFilters({ property: 'country', value }));
    };

    const handleChangeFactories = (value) => {
        setFactoriesVal(value);
        dispatch(setCustomerFilters({ property: 'factory', value }));
    };
    const handleChangeSubcategories = (value) => {
        setSubcategoriesVal(value);
        dispatch(setCustomerFilters({ property: 'subcategory', value }));
    };

    const handleReset = () => {
        setFactoriesVal([]);
        setSubcategoriesVal([]);
        setCountriesVal([]);
        dispatch(resetCustomerFilters());
    };

    useEffect(() => {
        dispatch(productsFetch());
    }, [customerFilters]);

    const factoriesOptions = factories.map(({ id, name }) => ({ id, name }));
    const subcategoriesOptions = subcategories.map(({
                                                        id,
                                                        name,
                                                        nameEn,
                                                        nameRu,
                                                    }) => ({ id, name: name[currentLocale] || nameEn || nameRu }));

    return (
        <StyledFilters>
            <div className="row">
                <StyledFiltersCol className="col-md-3">
                    <StyledFiltersLabel><FormattedMessage id="country" /></StyledFiltersLabel>
                    <FormSelect
                        value={countriesVal}
                        placeholder={
                            isTablet
                                ? formatMessage({ id: 'selectFromList' })
                                : formatMessage({ id: 'selectCountry' })
                        }
                        onChange={handleChangeCountries}
                        options={countries}
                    />
                </StyledFiltersCol>
                <StyledFiltersCol className="col-md-3">
                    <StyledFiltersLabel>
                        <FormattedMessage id="factoryNumber" />
                    </StyledFiltersLabel>
                    <FormSelect
                        placeholder={isTablet ? formatMessage({ id: 'selectFromList' }) : formatMessage({ id: 'meatFactory' })}
                        value={factoriesVal}
                        onChange={handleChangeFactories}
                        options={factoriesOptions}
                    />
                </StyledFiltersCol>
                <StyledFiltersCol className="col-md-3">
                    <StyledFiltersLabel>
                        <FormattedMessage id="productTypeAndCategory" />
                    </StyledFiltersLabel>
                    <FormSelect
                        multiple
                        placeholder={isTablet ? formatMessage({ id: 'selectFromList' }) : formatMessage({ id: 'productType' })}
                        value={subcategoriesVal}
                        onChange={handleChangeSubcategories}
                        options={subcategoriesOptions}
                    />
                </StyledFiltersCol>
                <div className="col-md-3">
                    <Button
                        title={isTablet ? formatMessage({ id: 'apply' }) : formatMessage({ id: 'find' })}
                        className="w-100"
                        variant="filled"
                        bordered
                        onClick={search}
                    />
                </div>
                <div className="col-md-3">
                    <StyledResetButton type="button" onClick={handleReset}>
                        <FormattedMessage id="resetFilter" />
                    </StyledResetButton>
                </div>
            </div>
        </StyledFilters>
    );
};

export default ProductsFilter;
