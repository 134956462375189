import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import FormInput from './FormInput';
import { CATEGORY, DELIVERY_PORT } from '../../utils/consts';
import FormSelect from './FormSelect';
import { authState, setAuthProperty } from '../../store/reducers/authReducer';
import FormControlsWrapper from './FormControlsWrapper';
import { Link } from '../../ui';
import { localeState } from '../../store/reducers/localesReducer';
import deliveryPorts from '../../utils/deliveryPorts';

const FormSectionControls = ({
                                 controls, onChange, position, categories = [], mainProductCategoryId,
                                 errors, onFocus, options = {}, canDelete = false,
                                 deleteHandler, values,
                             }) => {
    const { currentLocale } = useSelector(localeState);
    const { deliveryPort } = useSelector(authState);
    const dispatch = useDispatch();
    const selectHandler = (controlId) => (value) => {
        dispatch(setAuthProperty({
            property: controlId,
            value,
        }));
    };

    const categoriesOptions = categories.map(({
                                                  id,
                                                  name,
                                                  nameEn,
                                                  nameRu,
                                              }) => ({ id, name: name[currentLocale] || nameEn || nameRu }));

    return (
        <FormControlsWrapper>
            {
                controls.map((control, idx, array) => {
                    const className = ['flex-grow-1 col-12 col-lg-6 pe-0 ps-0'];

                    if (array.length > 1) {
                        className.push(`${idx % 2 === 0 ? 'pe-lg-2 pe-xl-3' : 'ps-lg-2 ps-xl-3'}`);
                    }

                    if (control.id) {
                        className.push('pt-4 pt-lg-4');
                    }
                    return (
                        <div
                            key={control.id ? control.id + idx : idx}
                            className={className.join(' ')}
                        >
                            {
                                control.id && control.type !== 'select' && (
                                    <FormattedMessage
                                        id={control.placeholder}
                                    >
                                        {(placeholder) => {
                                            const pos = position || control.position;
                                            return (
                                                <FormInput
                                                    label={control.label ? <FormattedMessage id={control.label} /> : ''}
                                                    placeholder={placeholder || ''}
                                                    onChange={onChange(control.id, pos, control.type)}
                                                    // error={(!pos || pos === 1) ? errors[control.id] : null}
                                                    error={!!errors && (errors[control.id] || errors[`${control.id}/${pos}`])}
                                                    onFocus={onFocus}
                                                    required={!!control.required}
                                                    link={control.id === 'factories/address' && canDelete ? (
                                                        <Link
                                                            onClick={() => deleteHandler(position)}
                                                        >
                                                            <FormattedMessage id="delete" />
                                                            &nbsp;
                                                            <FormattedMessage id="factoriesTitle" />

                                                        </Link>
                                                    ) : null}
                                                    value={values ? values[control.selfId] : undefined}
                                                />
                                            );
                                        }}
                                    </FormattedMessage>
                                )
                            }
                            {
                                control.id === CATEGORY && (
                                    <FormSelect
                                        onChange={selectHandler(control.id)}
                                        options={categoriesOptions}
                                        label={control.label ? <FormattedMessage id={control.label} /> : ''}
                                        placeholder=""
                                        value={mainProductCategoryId}
                                        disabled={options.disabled && options.disabled.includes(control.id)}
                                    />
                                )
                            }
                            {
                                control.id === DELIVERY_PORT && (
                                    <FormSelect
                                        onChange={selectHandler(control.id)}
                                        options={deliveryPorts}
                                        label={control.label ? <FormattedMessage id={control.label} /> : ''}
                                        placeholder=""
                                        value={deliveryPort}
                                    //    disabled={options.disabled && options.disabled.includes(control.id)}
                                    />
                                )
                            }
                            {
                                control.controls
                                && (
                                    <div className="d-flex flex-wrap pt-4 pt-lg-4">
                                        {
                                            control.controls.map((subControl, subIdx, subArray) => {
                                                const subClassName = ['col-12 pe-0 ps-0'];
                                                if (subArray.length === 2) {
                                                    subClassName.push(`col-lg-6 ${subIdx % 2 === 0 ? 'pe-lg-2 pe-xl-3' : 'ps-lg-2 ps-xl-3 pt-4 pt-lg-0'}`);
                                                }
                                                if (subArray.length === 3) {
                                                    subClassName.push(`col-lg-4 styled-form-controls__triple
                                                ${subIdx === 0 ? 'styled-form-controls__triple-left' : ''}
                                                ${subIdx === 1 ? 'styled-form-controls__triple-center' : ''}
                                                ${subIdx === 2 ? 'styled-form-controls__triple-right' : ''}
                                                `);
                                                }

                                                if (subIdx > 0) {
                                                    subClassName.push('pt-4 pt-lg-0');
                                                }

                                                return (
                                                    <div
                                                        key={subControl.id + control.id}
                                                        className={subClassName.join(' ')}
                                                    >
                                                        <FormattedMessage
                                                            id={subControl.placeholder}
                                                        >
                                                            {(placeholder) => {
                                                                const pos = position || control.position;
                                                                return (
                                                                    <FormInput
                                                                        label={
                                                                            subControl.label
                                                                                ? (
                                                                                    <FormattedMessage
                                                                                        id={subControl.label}
                                                                                    />
                                                                                )
                                                                                : ''
                                                                        }
                                                                        placeholder={placeholder || ''}
                                                                        onChange={onChange(subControl.id, pos, subControl.type)}
                                                                        // error={(!pos || pos === 1) ? errors[subControl.id] : null}
                                                                        error={errors[subControl.id] || errors[`${subControl.id}/${pos}`]}
                                                                        onFocus={onFocus}
                                                                        required={!!subControl.required}
                                                                    />
                                                                );
                                                            }}
                                                        </FormattedMessage>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                )
                            }
                        </div>
                    );
                })
            }
        </ FormControlsWrapper>
    );
};

export default FormSectionControls;
