import localeZH from 'antd/es/date-picker/locale/zh_CN';
import localeEN from 'antd/es/date-picker/locale/en_US';
import localeRU from 'antd/es/date-picker/locale/ru_RU';
import { EN, ZH } from '../locales/localesMap';

export const getDatePickerLocale = (currentLocale) => {
    switch (currentLocale) {
        case EN:
            return localeEN;
        case ZH:
            return localeZH;
        default:
            return localeRU;
    }
};
