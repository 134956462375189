import React from 'react';
import styled from 'styled-components';
import { $basicGrey } from '../styles/styled/colors';

const StyledDot = styled.div`
  position: relative;
  height: 12px;
  width: 12px;

  &:after {
    content: '';
    display: block;
    height: 2px;
    width: 2px;
    border-radius: 50%;
    background: ${$basicGrey};
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Dot = () => (
    <StyledDot />
);

export default Dot;
