import React from 'react';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { notificationState } from '../../../../store/reducers/notificationReducer';

const StyledQR = styled.div`
  width: 300px;
  height: 300px;
  position: relative;

  ${({ size }) => size === 1 && css`
    svg {
      height: 100%;
      width: 100%;
    }
  `}
  ${({ size }) => size === 2 && css`
    svg {
      position: absolute;
      left: -72px;
      top: -72px;
    }

    * {
      transform: scale(0.75);
    }
  `}
`;

const WeChatQR = ({ auth = false }) => {
    const { qrSubscribe, qrAuth } = useSelector(notificationState);
    return (
        <StyledQR size={auth ? 2 : 1}>
            {parse(auth ? qrAuth : qrSubscribe)}
        </StyledQR>
    );
};

export default WeChatQR;
