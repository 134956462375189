import styled from 'styled-components';
import { $basicDarkGreen, $basicWhite } from '../../../../styles/styled/colors';
import { mdQueryDown } from '../../../../styles/styled/sizes';

export const StyledShipments = styled.div`
  .shipments__title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  .shipments__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;

    @media ${mdQueryDown} {
      display: block;
      margin-bottom: 20px;
    }

    &-info {
      width: 100%;
      height: 40px;
      background: ${$basicWhite};
      display: flex;
      align-items: center;
      border-radius: 4px;
      padding: 0 10px;
      margin-right: 30px;
      color: ${$basicDarkGreen};
    }

    &-btn {
      text-transform: uppercase;
      color: ${$basicDarkGreen};
      border-color: ${$basicDarkGreen};
      height: 40px;

      @media ${mdQueryDown} {
        margin-top: 50px;
        width: 100%;
      }
    }

    @media (max-width: 600px) {
      flex-wrap: wrap;
      &-info {
        margin: 0 0 20px 0;
      }

      &-btn {
        width: 100%;
      }
    }

    @media (max-width: 320px) {
      flex-direction: column;
      margin-bottom: 16px;
      &-info {
        width: 100%;
        margin: 0 0 50px 0;
        font-size: 12px;
      }

      &-btn {
        width: 100%;
      }
    }
  }
`;
