import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { authNavRoutes, publicNavRoutes } from '../../routes';
import { localeState } from '../../store/reducers/localesReducer';
import NavMenu from '../NavMenu/NavMenu';
import { lgQueryDown, mdQueryDown, xsQueryDown } from '../../styles/styled/sizes';
import useAuth from '../../utils/hooks/useAuth';

const StyledNav = styled.nav`
  margin: 30px 0 0;

  @media ${lgQueryDown} {
    margin: 0 -30px 0 0;
  }

  @media ${mdQueryDown} {
    margin: 30px 0 15px;
  }

  ul li a {
    @media ${mdQueryDown} {
      margin-right: 15px;
    }

    @media ${xsQueryDown} {
      font-size: 12px;
    }
  }
`;

const FooterNav = () => {
    const { isAuth } = useAuth();
    const { currentLocale } = useSelector(localeState);

    const routes = useMemo(() => (isAuth ? authNavRoutes : publicNavRoutes)
        .map(({ path, title }) => ({
            path,
            title: title[currentLocale],
        })), [isAuth, currentLocale]);

    return (
        <StyledNav>
            <NavMenu routes={routes} />
        </StyledNav>
    );
};

export default FooterNav;
