import getSymbolFromCurrency from "currency-symbol-map";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useCallback, useEffect, useState } from "react";
// import { getDateLocale } from '../../../utils/dateHandlers';
import { getCategoryIcon } from "../../../utils/getCategoryIcon";
import { ZH, RU, EN } from "../../../locales/localesMap";
import {
	auctionsState,
	setAuctionsProperty,
} from "../../../store/reducers/auctionsReducer";
import { AUCTIONS_ROUTE, CUSTOMER } from "../../../utils/consts";
import { userState } from "../../../store/reducers/userReducer";
import { localeState } from "../../../store/reducers/localesReducer";

const useAuction = (auction) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { role } = useSelector(userState);
	const { deliveryTerms } = useSelector(auctionsState);
	const { currentLocale } = useSelector(localeState);
	const { formatMessage } = useIntl();
	const [paymentConditions, setPaymentConditions] = useState("");
	const {
		id,
		status,
		currencyCode,
		currentPrice_CNY,
		currentPrice_USD,
		// startPricePerTon,
		startPricePerTon_CNY,
		startPricePerTon_USD,
		// currency,
		totalWeight,
		// etdShipmentDate,
		etdShipmentDateFrom,
		etdShipmentDateTo,
		deliveryConditions,
		paymentConditionsCn,
		paymentConditionsEn,
		paymentConditionsRu,
		endDate,
		startDate,
		lastUserBid,
		isWinner,
		isFavorite,
		deliveryStatus,
		adminAccount,
		sellerAccount,
		documentsArchive,
		isFinished,
		logisticsType,
		product: {
			category,
			factory,
			boxWeight,
			storageLife,
			name,
			subcategory,
			specifications,
			media,
			id: productId,
		},
		deliveryDate,
	} = auction;

	useEffect(() => {
		let condition = "";

		if (currentLocale === ZH) {
			condition = paymentConditionsCn;
		} else if (currentLocale === RU) {
			condition = paymentConditionsRu;
		} else if (currentLocale === EN) {
			condition = paymentConditionsEn;
		}

		setPaymentConditions(condition);
	}, [
		currentLocale,
		paymentConditionsCn,
		paymentConditionsEn,
		paymentConditionsRu,
	]);

	const cardDetailHandler = async () => {
		await dispatch(
			setAuctionsProperty({
				property: "auction",
				value: null,
			}),
		);
		history.push(`${AUCTIONS_ROUTE}/${id}`);
	};

	// eslint-disable-next-line no-shadow
	const getDate = useCallback(
		(date, isDate = true) => {
			if (isDate) {
				const year = format(new Date(date), "yyyy");
				const month = format(new Date(date), "MM");
				const day = format(new Date(date), "dd");
				return formatMessage({ id: "dateFormat" }, { year, month, day });
			}
			return format(new Date(date), "HH:mm");
		},
		[formatMessage],
	);

	const icon = getCategoryIcon(category?.logo);
	const price = (role === CUSTOMER ? currentPrice_CNY : currentPrice_USD) || "";
	const currencySymbol = getSymbolFromCurrency(
		role === CUSTOMER ? "CNY" : "USD",
	);
	const startPrice =
		role === CUSTOMER ? startPricePerTon_CNY : startPricePerTon_USD;
	// const etdDateFrom = format(
	//     new Date(etdShipmentDateFrom),
	//     'LLLL yyyy',
	//     { locale: getDateLocale(locale) },
	// );
	// const etdDateTo = format(
	//     new Date(etdShipmentDateTo),
	//     'LLLL yyyy',
	//     { locale: getDateLocale(locale) },
	// );
	const etdDateFrom = getDate(etdShipmentDateFrom);
	const etdDateTo = getDate(etdShipmentDateTo);
	const etdDate =
		etdDateFrom === etdDateTo ? etdDateFrom : `${etdDateFrom} - ${etdDateTo}`;

	const startDateFormat = getDate(startDate);
	const endDateFormat = getDate(endDate);
	// const startTime = format(
	//     new Date(startDate),
	//     'HH:mm',
	// );
	// const endTime = format(
	//     new Date(endDate),
	//     'HH:mm',
	// );
	const startTime = getDate(startDate, false);
	const endTime = getDate(endDate, false);
	const deliveryCondition = deliveryTerms.find(
		(term) => term.id === deliveryConditions,
	);
	const isCompleted = status === "completed";
	const isPlanned = status === "planned";
	const isActive = status === "active";
	const country = formatMessage({ id: "russia" });

	return {
		status,
		id,
		icon,
		price,
		startPrice,
		currencySymbol,
		currencyCode,
		etdDate,
		boxWeight,
		storageLife,
		name,
		subcategory,
		totalWeight,
		specifications,
		media,
		endDate,
		isCompleted,
		isPlanned,
		isActive,
		startDate,
		startDateFormat,
		endDateFormat,
		lastUserBid,
		isWinner,
		isFavorite,
		deliveryStatus,
		category,
		factory,
		country,
		productId,
		cardDetailHandler,
		deliveryConditions,
		deliveryCondition,
		paymentConditions,
		adminAccount,
		sellerAccount,
		documentsArchive,
		deliveryDate,
		isFinished,
		startTime,
		endTime,
		logisticsType,
	};
};

export default useAuction;
