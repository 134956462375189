import { configureStore } from '@reduxjs/toolkit';
import localesReducer from './reducers/localesReducer';
import userReducer from './reducers/userReducer';
import authReducer from './reducers/authReducer';
import productsReducer from './reducers/productReducer';
import auctionsReducer from './reducers/auctionsReducer';
import notificationReducer from './reducers/notificationReducer';
import pageReducer from './reducers/pageReducer';
import supportReducer from './reducers/supportReducer';
import contactsReducer from './reducers/contactsReducer';

export const store = configureStore({
    reducer: {
        locales: localesReducer,
        user: userReducer,
        auth: authReducer,
        products: productsReducer,
        auctions: auctionsReducer,
        notifications: notificationReducer,
        page: pageReducer,
        support: supportReducer,
        contacts: contactsReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});
