import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { localeState } from '../../store/reducers/localesReducer';
import StaticPage from '../../Layouts/StaticPage';
import { Text } from '../../ui';
import { en, ru, zh } from './locales';
import { EN, RU, ZH } from '../../locales/localesMap';

const locales = {
    [RU]: ru,
    [EN]: en,
    [ZH]: zh,
};

const Policy = () => {
    const { currentLocale } = useSelector(localeState);

    return (
        <StaticPage>
            <h1><FormattedMessage id="policy" /></h1>
            <article>
                <Text variant="big">{locales[currentLocale]}</Text>
            </article>
        </StaticPage>
    );
};

export default Policy;
