import { createAsyncThunk } from '@reduxjs/toolkit';
import $api from '../../api/api';
import { prepareFiles } from './utilsActions';
import { SELLER } from '../../utils/consts';

const resize = (file, maxWidth = 1400, maxHeight = 1000) => {
    if (!file.type.match(/image.*/)) return file;

    return new Promise((resolve) => {
        const reader = new FileReader();

        reader.onload = (readerEvent) => {
            const image = new Image();
            image.onload = () => {
                const canvas = document.createElement('canvas');

                let { width, height } = image;

                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }

                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }

                canvas.width = width;
                canvas.height = height;
                canvas.getContext('2d').drawImage(image, 0, 0, width, height);

                canvas.toBlob((blob) => {
                    resolve(blob);
                }, file.type);
            };

            image.src = readerEvent.target.result;
        };

        reader.readAsDataURL(file);
    });
};

const uploadFile = async (dispatch, name, files) => {
    if (!!files.length) {
        const fd = new FormData();
        const names = [];
        const pr = [];

        files.forEach((file) => {
            names.push(file.name);
            if (!file.type.match(/image.*/)) {
                pr.push(new Promise((resolve) => {
                    resolve(file);
                }));
            } else {
                pr.push(resize(file));
            }
        });

        const res = await Promise.all(pr);

        res.forEach((file, i) => fd.append(name, file, names[i]));

        const result = await dispatch(prepareFiles(fd));

        return result.payload.map((item) => item.uid);
    }

    return [];
};

export const productsFetch = createAsyncThunk(
    'products/fetch',
    async (_, { rejectWithValue, getState }) => {
        try {
            const {
                products: {
                    moderationStatus, pageSize, currentPage, customerFilters, isFavouriteStatus, activeCategory,
                },
            } = getState();

            const isFavourite = isFavouriteStatus === 'favourite';

            const params = {
                moderation_status: moderationStatus,
                page: currentPage,
                page_size: pageSize,
                factory_id: customerFilters.factory,
                subcategory_id: customerFilters.subcategory,
                is_favorite: isFavourite || null,
                country_id: customerFilters.country,
            };

            if (activeCategory) params.category_id = activeCategory;

            return await $api.get('products/', { params });
        } catch (e) {
            return rejectWithValue(e.message);
        }
    },
);

export const productFetchById = createAsyncThunk(
    'product/fetchById',
    async (productId, { rejectWithValue }) => {
        try {
            return await $api.get(`products/${productId}/`);
        } catch (e) {
            return rejectWithValue(e.message);
        }
    },
);

export const categoriesFetch = createAsyncThunk(
    'categories/fetch',
    async (_, { rejectWithValue }) => {
        try {
            return await $api.get('product-categories/');
        } catch (e) {
            return rejectWithValue(e.message);
        }
    },
);

export const factoriesFetch = createAsyncThunk(
    'factories',
    async (role, { rejectWithValue }) => {
        try {
            return await $api.get(role === SELLER ? 'seller/factories/' : 'factories/');
        } catch (e) {
            return rejectWithValue(e.message);
        }
    },
);

export const subcategoriesFetch = createAsyncThunk(
    'subcategories/fetch',
    async (_, { rejectWithValue }) => {
        try {
            return await $api.get('product-subcategories/');
        } catch (e) {
            return rejectWithValue(e.message);
        }
    },
);

export const addToFavourite = createAsyncThunk(
    'favourite/add',
    async (itemId, { rejectWithValue }) => {
        try {
            await $api.post(
                `/products/${itemId}/favorites/`,
            );

            return itemId;
        } catch (e) {
            return rejectWithValue(e.message);
        }
    },
);

export const removeFromFavourite = createAsyncThunk(
    'favourite/remove',
    async (itemId, { rejectWithValue }) => {
        try {
            await $api.delete(
                `/products/${itemId}/favorites/`,
            );

            return itemId;
        } catch (e) {
            return rejectWithValue(e.message);
        }
    },
);

export const productAdd = createAsyncThunk(
    'product/add',
    async (_, { rejectWithValue, dispatch, getState }) => {
        try {
            const { products: { product } } = getState();

            const productData = {
                nameRu: product.name,
                boxWeight: product.weight,
                storageLife: 10,
                categoryId: product.category,
                mediaUuids: [],
                specificationsUuids: [],
                factoryId: product.factory,
            };

            if (product.pictures && product.pictures.length) {
                productData.mediaUuids = await uploadFile(dispatch, 'file', product.pictures);
            }

            if (product.specifications && product.specifications.length) {
                productData.specificationsUuids = await uploadFile(dispatch, 'file', product.specifications);
            }

            const response = await $api.post(
                'products/',
                productData,
            );
            dispatch(productsFetch());
            return { data: response.data };
        } catch (e) {
            console.log(e);
            return rejectWithValue(e.response);
        }
    },
);

export const productEdit = createAsyncThunk(
    'product/edit',
    async (itemId, { rejectWithValue, dispatch, getState }) => {
        try {
            const { products: { product } } = getState();

            const productData = {
                nameRu: product.name,
                boxWeight: product.weight,
                categoryId: product.category,
                factoryId: product.factory,
                mediaUuids: product.pictures.filter((file) => file.uid).map((file) => file.uid),
                specificationsUuids: product.specifications.filter((pic) => pic.uid).map((pic) => pic.uid),
            };

            if (product.pictures?.length) {
                const uploaded = await uploadFile(dispatch, 'file', product.pictures.filter((file) => !file.uid));
                productData.mediaUuids = [...productData.mediaUuids, ...uploaded];
            }

            if (product.specifications?.length) {
                const uploaded = await uploadFile(dispatch, 'file', product.specifications.filter((file) => !file.uid));
                productData.specificationsUuids = [...productData.specificationsUuids, ...uploaded];
            }

            const response = await $api.put(
                `/products/${itemId}/`,
                productData,
            );
            return { data: response.data };
        } catch (e) {
            return rejectWithValue(e.response);
        }
    },
);

export const productSuggest = createAsyncThunk(
    'product/suggest',
    async ({ name, subcategories, boxWeight }, { rejectWithValue }) => {
        try {
            const response = await $api.post(
                '/suggested-products/',
                { name, subcategories, boxWeight },
            );

            return { data: response.data };
        } catch (e) {
            return rejectWithValue(e.response);
        }
    },
);

export const countries = createAsyncThunk(
    'utils/countries',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await $api.get('countries/');
            return { data };
        } catch (e) {
            return rejectWithValue(e.message);
        }
    },
);
