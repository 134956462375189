import React, { Fragment, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import {
	BaiduMap,
	Marker,
	InfoWindow,
	asyncWrapper,
	OverviewMapControl,
	ScaleControl,
} from "react-baidu-maps";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
// import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { FormattedMessage } from "react-intl";
import StaticPage from "../../Layouts/StaticPage";
import contacts from "../../img/contacts.png";
import Text from "../../ui/Text";
import { $basicRed } from "../../styles/styled/colors";
import {
	lgQueryDown,
	smQueryDown,
	xlQueryDown,
} from "../../styles/styled/sizes";
import { contactsFetch } from "../../store/actions/contactsActions";
import { contactsState } from "../../store/reducers/contactsReducer";
import { localeState } from "../../store/reducers/localesReducer";
import { BAIDU_API_KEY } from "../../utils/consts";
// import { RU, EN, ZH } from '../../locales/localesMap';

const StyledImage = styled.img`
	max-width: 600px;
	float: right;

	@media ${xlQueryDown} {
		max-width: 450px;
	}

	@media ${lgQueryDown} {
		float: none;
		max-width: 100%;
		margin-bottom: 30px;
	}
`;

const StyledEmailRow = styled.div`
	display: flex;
	max-width: 700px;

	@media ${smQueryDown} {
		flex-direction: column-reverse;
	}

	> div {
		flex: 1;

		&:last-child {
			flex: 1;
			padding-left: 30px;

			@media ${smQueryDown} {
				padding: 0;
				text-align: left;
				flex: auto;
			}
		}
	}
`;

// const mapLocales = {
//     [RU]: 'ru_RU',
//     [EN]: 'en_US',
//     [ZH]: 'en_US',
// };

const Contacts = () => {
	const dispatch = useDispatch();
	const { address, emails } = useSelector(contactsState);
	const { currentLocale } = useSelector(localeState);
	// const center = [39.80211543366512, 116.53999354536984];
	const center = [39.80931458358819, 116.54576565916986];
	const zoom = 18;
	const isTablet = useMediaQuery({ query: lgQueryDown });

	const AsyncMap = asyncWrapper(BaiduMap);

	useEffect(() => {
		dispatch(contactsFetch());
	}, []);

	return (
		<StaticPage>
			{isTablet && (
				<h1>
					<FormattedMessage id='contactsTitle' />
				</h1>
			)}
			<StyledImage src={contacts} alt='' />
			{!isTablet && (
				<h1>
					<FormattedMessage id='contactsTitle' />
				</h1>
			)}
			<Text variant='big' style={{ fontWeight: 500 }}>
				<p style={{ color: $basicRed }}>
					MEAT ASIA LTD
					<br />
					<br />
					亚洲肉类拍卖平台
				</p>
				<br />
				<br />
				<p style={{ maxWidth: 280 }}>
					<FormattedMessage id='address' />:<br />
					{!!address && address[currentLocale]}
				</p>
				<br />
				<p>北京亦庄经济技术开发区科创六街87号</p>
				<br />
				<br />
				<br />
				<br />
				<p>
					<FormattedMessage id='personEmailLabel' />
					<br />
					<br />
					{emails.map(({ email, description }) => (
						<Fragment key={email}>
							<StyledEmailRow>
								<div>{email}</div>
								{!!description && <div>{description[currentLocale]}</div>}
							</StyledEmailRow>
							<br />
						</Fragment>
					))}
				</p>
				<br />
				<br />
				<br />
				<br />
				<br />
				{/*<YMaps*/}
				{/*    query={{*/}
				{/*        lang: mapLocales[currentLocale],*/}
				{/*    }}*/}
				{/*>*/}
				{/*    <Map*/}
				{/*        width="100%"*/}
				{/*        height={406}*/}
				{/*        defaultState={{ center, zoom }}*/}
				{/*    >*/}
				{/*        <Placemark geometry={center} />*/}
				{/*    </Map>*/}
				{/*</YMaps>*/}

				<AsyncMap
					zoom={zoom}
					enableDragging
					enableScrollWheelZoom
					enableDoubleClickZoom
					center={{ lat: center[0], lng: center[1] }}
					mapUrl={`https://api.map.baidu.com/api?v=2.0&ak=${BAIDU_API_KEY}`}
					loadingElement={<span>Loading map...</span>}
					mapContainer={<div style={{ height: "400px" }} />}
					mapType='normal'>
					<Marker position={{ lat: center[0], lng: center[1] }}>
						<InfoWindow content='MeatAsia' offset={{ width: 0, height: -20 }} />
					</Marker>
					{/*<MapTypeControl />*/}
					<OverviewMapControl />
					<ScaleControl />
				</AsyncMap>
			</Text>
		</StaticPage>
	);
};

export default Contacts;
