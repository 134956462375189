import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import FormSectionTitle from '../../components/Form/FormSectionTitle';
import FormInput from '../../components/Form/FormInput';
import { userState } from '../../store/reducers/userReducer';

const PersonalLogin = ({ changes, inputHandler }) => {
    const { phone } = useSelector(userState);
    return (
        <div className="row">
            <FormSectionTitle title={<FormattedMessage id="loginChangeDataTitle" />} />
            <div className="d-flex flex-wrap py-4 py-sm-5 px-0">
                <div
                    className="col-12 col-sm-4 pe-0 ps-0 pe-sm-2 pe-lg-3"
                >
                    <FormattedMessage id="personPhonePlaceholder">
                        {
                            (placeholder) => (
                                <FormInput
                                    label={<FormattedMessage id="personPhoneLabel" />}
                                    placeholder={placeholder}
                                    value={changes.phone || phone}
                                    onChange={inputHandler('phone')}
                                />
                            )
                        }
                    </FormattedMessage>
                </div>

                <div
                    className="col-12 col-sm-4 pt-4 pt-sm-0 pe-0 ps-0 pe-sm-2 ps-sm-2 ps-lg-3 pe-lg-3"
                >
                    <FormattedMessage id="passwordPlaceholder">
                        {
                            (placeholder) => (
                                <FormInput
                                    label={<FormattedMessage id="passwordLabel" />}
                                    placeholder={placeholder}
                                    type="password"
                                    value={changes.password || ''}
                                    onChange={inputHandler('password')}
                                />
                            )
                        }
                    </FormattedMessage>
                </div>

                <div
                    className="col-12 col-sm-4 pt-4 pt-sm-0 pe-0 ps-0 ps-sm-2 ps-lg-2"
                >
                    <FormattedMessage id="passwordConfirmPlaceholder">
                        {
                            (placeholder) => (
                                <FormInput
                                    label={<FormattedMessage id="passwordConfirmLabel" />}
                                    placeholder={placeholder}
                                    type="password"
                                    value={changes.confirmPassword || ''}
                                    onChange={inputHandler('confirmPassword')}
                                />
                            )
                        }
                    </FormattedMessage>
                </div>
            </div>
        </div>
    );
};

export default PersonalLogin;
