import React from 'react';
import styled, { css } from 'styled-components';
import { $basicDarkGreen, $basicGreen, $basicGrey } from '../styles/styled/colors';

const StyledLink = styled.div`
  * {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: ${$basicDarkGreen};
  transition: .25s ease-in;

  ${({ disabled }) => disabled && css`
    text-decoration-line: none;
    color: ${$basicGrey};
    cursor: none;
    pointer-events: none;

    &:hover {
      color: ${$basicGrey}
    }
  `}
  &:hover {
    color: ${$basicGreen}
  }
`;

const Link = ({
                  children,
                  onClick,
                  className = '',
                  disabled = false,
              }) => (
    <StyledLink
        onClick={onClick}
        role="button"
        className={className}
        disabled={disabled}
    >{children}
    </StyledLink>
);

export default Link;
