import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';
import { auctionsState, setAuctionsProperty } from '../../../store/reducers/auctionsReducer';
import { $basicBlack, $basicGreen, $basicWhite } from '../../../styles/styled/colors';
import { localeState } from '../../../store/reducers/localesReducer';
import { mdQueryDown } from '../../../styles/styled/sizes';

const StyledLink = styled.div`
  color: ${$basicWhite};
  flex-grow: 0;
  margin: 0 25px 0 0;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-decoration-line: underline;
  transition: .25s ease-in;
  cursor: pointer;

  @media ${mdQueryDown} {
    color: ${$basicBlack};
  }

  ${({ isActive }) => isActive && css`
    color: ${$basicGreen};

    @media ${mdQueryDown} {
      color: ${$basicGreen};
    }
  `}
  &:hover {
    color: ${$basicGreen};
  }
`;

const AuctionDetailCustomerDeliveryFilter = () => {
    const { deliveryStatuses, delivery_status_id } = useSelector(auctionsState);
    const { currentLocale } = useSelector(localeState);
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const setStatusHandler = (id) => () => {
        dispatch(setAuctionsProperty({
            property: 'delivery_status_id',
            value: id,
        }));
    };
    const all = formatMessage({ id: 'all' });
    return (
        <div className="d-flex align-items-center flex-wrap">
            <StyledLink
                isActive={!delivery_status_id}
                onClick={setStatusHandler('')}
            >
                {all}
            </StyledLink>
            {
                deliveryStatuses.map((status) => (
                    <StyledLink
                        key={status.id}
                        isActive={status.id === delivery_status_id}
                        onClick={setStatusHandler(status.id)}
                    >
                        {status.name[currentLocale]}
                    </StyledLink>
                ))
            }
        </div>
    );
};

export default AuctionDetailCustomerDeliveryFilter;
