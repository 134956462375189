export default {
	accountLabel: "Account",
	accountPlaceholder: "Account",
	active: "Active",
	add: "Add",
	addAuction: "Add an auction",
	addNewCompany: "New company or individual",
	addProduct: "Add product",
	address: "Address",
	all: "All",
	allAuctions: "All auctions",
	apply: "Apply",
	attachInvoice: "Attach an invoice",
	auctionCompleted: "Auction completed",
	auctionCreateResultText:
		"Due to the addition of a new product, the lot is undergoing moderation. It may take up to 1 business day.",
	auctionInfoTitle: "Auction Information",
	auctionsActiveButtonTitle: "Active",
	auctionsCompletedButtonTitle: "Completed",
	auctionsFavoriteButtonTitle: "Favorites",
	auctionsIsWinnerButtonTitle: "Won",
	auctionsPlannedButtonTitle: "Planned",
	auctionsTitle: "Auctions",
	backToAuctionsPage: "Back to the auction list",
	backToProductsPage: "Back to product's page",
	bankAddressLabel: "Bank address",
	bankAddressPlaceholder: "Bank address",
	bankNameLabel: "Bank",
	bankNamePlaceholder: "Bank",
	beforeEnd: "Until the end",
	beforeStart: "Before start",
	bidAccessDenied: "You do not have access to this auction. Contact support.",
	bidNotification: "Insurance and survey services are included in the price",
	boxWeight: "Box weight",
	buyer: "Buyer",
	cancel: "Cancel",
	categoryLabel: "The main product of the auction (category)",
	categoryPlaceholder: "The main product of the auction (category)",
	commonQuestions: "common questions",
	company: "Company",
	companyAddressLabel: "Company address",
	companyAddressPlaceholder: "Company address",
	companyNameLabel: "Name of the company or sole proprietor",
	companyNamePlaceholder: "Name of the company or sole proprietor",
	companyTitle: "Company information",
	completed: "Completed",
	congrats: "Congratulations, you are the winner!",
	contactsTitle: "Contacts",
	country: "Country",
	createAuction: "Create auction",
	createAuctionDescription:
		"Due to the addition of a new auction, the lot is undergoing moderation. It may take up to 1 business day.",
	createAuctionFormTitle: "Create an auction",
	createAuctionResultFormTitle: "Auction created",
	createPassword: "Create a password",
	currencyOfContract: "Contract currency",
	currentPrice: "Current price",
	currentPricePerTonn: "Current price per ton:",
	customerMainProfile: "The main profile for making transactions",
	dateShippingETO: "Shipment period ETD",
	delete: "Remove",
	deliveryConditionCustomer:
		"30% Prepayment, 70% Payment against BL or Telex Release",
	deliveryConditionSeller: "100% Prepayment",
	deliveryTerms: "Terms of delivery",
	descriptionForRegMsg:
		"After passing moderation, you will be able to participate in auctions.",
	doBid: "Place a bet",
	documentation: "Documentation",
	documents: "Documents",
	documentsHint: "Upload documents",
	edit: "Edit",
	editProduct: "Edit product",
	editProfileTitle: "Editing a profile",
	emptyBid: "You haven't placed a bet yet",
	endTime: "End time GMT+3",
	enterCorrectEmail: "Enter the correct email",
	enterCorrectPhone: "Enter the correct telephone number",
	enterNewPassword: "Enter a new password",
	existAccount: "Have an account? Log in",
	factoriesTitle: "Factory",
	factoryAddressLabel: "Factory address",
	factoryAddressPlaceholder: "Factory address",
	factoryName: "Factory number",
	factoryNameLabel: "Factory number and name",
	factoryNamePlaceholder: "Factory number and name",
	factoryNumber: "Factory number",
	factorySelect: "Choose factory",
	favourites: "Favourites",
	fieldRequired: "This field is required",
	filter: "Filter",
	finalPrice: "Final price",
	find: "Find",
	finishPrice: "Final price",
	forgotButtonTitle: "Send an email with a link",
	forgotPasswordFormTitle: "Restoring access",
	forgotPasswordLink: "Forgot your password?",
	forgotPasswordMsg:
		"A letter with a link will be sent to the mail to restore your account, if the email has not arrived, check the correctness of writing the mail",
	generalTitle: "General data",
	getInvoice: "Receive an invoice",
	goToAuction: "Go to Auction",
	goToProductsPage: "Go to product's page",
	goToProductsPageMobile: "To product's page",
	homeSliderTitle: "They work with us:",
	homeTitle2: "for online auctions of meat products",
	homeTitle: "International platform",
	innLabel: "INN",
	innPlaceholder: "INN",
	insuranceServices: "Insurance and survey services are included in the price",
	ip: "Individual entrepreneur",
	itIssues: "IT issues",
	kgValue: "{value} KG",
	kppLabel: "KPP",
	kppPlaceholder: "KPP",
	loginButtonTitle: "Log in",
	loginChangeDataTitle: "Change login details",
	loginFormTitle: "Log in to your personal account!",
	logisticsIssues: "logistics and customs clearance issues",
	logoutButtonTitle: "Log out",
	meatFactory: "Meat Processing Plant",
	messageSuccessUndoChanges: "Changes have been successfully canceled",
	moderationProfileStatusInfo: "Profile is still under moderation",
	moneyPerTon: "{value} {currency}/T.",
	monthValue: "{value} mon",
	notificationsEmailLabel: "On email",
	notificationsSmsLabel: "SMS messages",
	notificationsTitle: "Notifications",
	onModeration: "On moderation",
	onModerationFrom: "On moderation from {date}",
	paginationProducts: "Showing {current} of {total} products",
	paginationTotals: "Showing {current} of {total} trades",
	passwordConfirmLabel: "Repeat the password",
	passwordConfirmPlaceholder: "Repeat the password",
	passwordLabel: "Password",
	passwordPlaceholder: "Enter password",
	payAuctionMsg: "Please pay the bill within 2 days.",
	payButtonTitle: "Pay the bill",
	payDeliveryConditions: "Payment and Delivery terms",
	payWaitingAuctionMsg: "The invoice is being generated.",
	periodEnd: "Period for",
	periodStart: "Period from",
	periodPlaceholder: "Auction period",
	personAddNameLabel: "Additional contact person (Full name)",
	personAddNamePlaceholder: "Additional contact person (Full name)",
	personalAccount: "Personal account",
	personEmailLabel: "E-mail",
	personEmailPlaceholder: "E-mail",
	personMainNameLabel: "Main contact person (Full name)",
	personMainNamePlaceholder: "Main contact person (Full name)",
	personNameLabel: "Contact person (Full name)",
	personNamePlaceholder: "Contact person (Full name)",
	personPassportLabel: "Passport number",
	personPassportPlaceholder: "Passport number",
	personPhoneLabel: "Phone",
	personPhonePlaceholder: "Phone",
	personWechatLabel: "WeChat ID",
	personWechatPlaceholder: "WeChat ID",
	policy: "Privacy Policy",
	portPlaceholder: "For example: Rostov Multimodal Sea Port",
	portTitle: "Specify the delivery port in China",
	productAdded: "Product added",
	productCategory: "Product category",
	productChanged: "Product changed",
	productExpirationDate: "Expiration date",
	productImages: "Product images",
	productName: "Product name",
	productNamePlaceholder2: "Hen",
	productNamePlaceholder: "Frozen chicken legs, B-category (monolith)",
	productNote:
		"In connection with the addition of a new product, the lot is being moderated. It may take up to 1 business day",
	productSpecification: "Product specification",
	productsTitle: "Products",
	productSubcategory: "Product subcategory",
	productSubcategoryPlaceholder: "Body, chest, wing",
	productSuggested: "Product suggested",
	productType: "Product type",
	productTypeAndCategory: "Product type and category",
	question: "Question",
	questionsAboutProducts: "questions about products",
	questionSent: "The question has been successfully sent!",
	read: "Read",
	registerAgreement:
		"By clicking the button, I consent to the processing of personal data",
	registerButtonTitle: "REGISTER",
	registerFormTitle: "Register profile",
	registerLink: "Registration",
	repeatAuction: "Repeat the auction",
	requisitesTitle: "Requisites",
	resetFilter: "Reset filter",
	resetPasswordSuccess:
		"You have successfully changed your password, now you can log in.",
	resetSuccess: "Request was successfully sent!",
	responsiblePersonsTitle1: "Responsible person in bidding #1",
	responsiblePersonsTitle2: "Responsible person in bidding #2",
	responsiblePersonsTitle: "Responsible persons",
	resultInProcess: "Results are being processed",
	russia: "Russia",
	saveButtonTitle: "Save",
	selectCountry: "Choose country",
	selected: "selected",
	selectFromList: "Choose from list",
	selectOrCreateProduct: "Select a product or add a new one",
	seller: "Seller",
	send: "Send",
	sent: "Sent",
	shipmentETD: "ETD Shipment",
	shipmentFactory: "Shipment factory",
	showMore: "Show more",
	showProduct: "Show Product",
	sortByDateDown: "Sort by decreasing date",
	sortByDateUp: "Sort by date increase",
	sortByPriceDown: "Sort by price increase",
	sortByPriceUp: "Sort by price reduction",
	specifications: "Specifications",
	specificationsHint: "Load the photo of product documentation",
	startDate: "Auction start date",
	startPrice: "Start price",
	startPricePerTon: "Start price per ton",
	startTime: "Start time GMT+3",
	status: "Status",
	suggest: "To offer",
	suggestProduct: "Offer a product",
	support: "support",
	supportText:
		"Have questions? Write to us and we will reply as soon as possible",
	supportTitle: "Support",
	swiftCodeLabel: "SWIFT code",
	swiftCodePlaceholder: "SWIFT code",
	thankForAuction: "Thank you for participating!",
	thankForRegMsg: "Thank you for registering!",
	time: "{hours}h {minutes}m",
	timeFull: "{days}d {hours}h {minutes}m {seconds}s",
	timeMinutesSeconds: "{minutes}m {seconds}s",
	timeSeconds: "{seconds}s",
	timeShort: "{days}d {hours}h {minutes}m",
	timeWithSeconds: "{hours}h {minutes}m {seconds}s",
	toAuction: "To the auction",
	toAuctions: "To auctions",
	ton: "T.",
	tonFullValue: "{value} tons",
	tonValue: "{value} T.",
	toProducts: "To products",
	totalWeightPlaceholder: "Total weight",
	undoAuction: "Undo auction",
	undoChanges: "Undo changes",
	unread: "Unread",
	uploadInvoiceMsg: "Please upload the invoice within 2 days.",
	view: "Preview",
	weightNet: "Weight",
	weightProducts: "Product weight",
	writeSupport: "Support",
	writeSupportDescription:
		"You can direct your question by writing to us in support",
	yourApplicationAccepted: "Your application has been accepted!",
	yourName: "Your name",
	yuoBid: "Your bid",
	weChatInfo:
		"To receive notifications in WeChat, you need to do 2 simple steps",
	weChatLink: "Click to execute them",
	weChatStep1: "Step 1",
	weChatStep2: "Step 2",
	weChatStep1Title: "Subscribe to us",
	weChatStep2Title: "Confirm your account",
	weChatStep1Button: "done, next",
	weChatStep2Button: "done",
	nameTitle: "Название профиля",
	namePlaceholder: "Введите название профиля",
	anyQuestions: "Если у вас возникнут вопросы, свяжитесь с нами.",
	endDate: "Дата завершения аукциона",
	logisticsType: "Вариант доставки",
	dateFormat: "{year}.{month}.{day}",
	auctionConfirm: "Are you sure you want to place a new bid?",
	yes: "Yes",
	no: "No",
};
