import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { message } from 'antd';
import cuid from 'cuid';
import is from 'is_js';
import FormSectionTitle from '../../components/Form/FormSectionTitle';
import { Button, Link, Text } from '../../ui';
import {
    setActiveIndex, setCreate, setUserProperty, userState,
} from '../../store/reducers/userReducer';
import { $basicBlack, $light } from '../../styles/styled/colors';
import {
    CUSTOMER, SELLER,
} from '../../utils/consts';
import { localeState } from '../../store/reducers/localesReducer';
import { EN, RU, ZH } from '../../locales/localesMap';
import { userLogout } from '../../store/actions/authActions';
import { userUpdate } from '../../store/actions/userActions';
import PersonalSeller from './PersonalSeller';
import PersonalCustomer from './PersonalCustomer';
import FormError from '../../components/Form/FormError';
import useNav from '../../Layouts/hooks/useNav';
import PersonalLogin from './PersonalLogin';
import FormUpload from '../../components/Form/FormUpload';
import useValidator from '../../utils/hooks/useValidator';
import validator from './validators';
import localesStatuses from '../../locales/localesStatuses';
import { statusColor } from '../../utils/statusColors';
import deliveryPorts from '../../utils/deliveryPorts';
import FormInput from '../../components/Form/FormInput';

const StyledPersonal = styled.div`
  background: ${$light};
  max-width: 1400px;
  margin: 0 auto;
`;

const StyledTitle = styled.div`
  padding: 40px 0 0;
  color: ${$basicBlack};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Personal = () => {
    const isMobile = is.mobile() || is.tablet();
    const [formErrors, setFormErrors] = useState({});
    const dispatch = useDispatch();
    const { currentLocale } = useSelector(localeState);
    const { create } = useSelector(userState);
    const { setPath } = useNav();
    const { formatMessage } = useIntl();
    const {
        role, companies, errors, isSuccess, isLoading,
        phone, mainProductCategory, activeIndex,
        moderationStatus, profileName,
    } = useSelector(userState);
    const [changes, setChanges] = useState({});
    const { checkForm } = useValidator(validator[role]);
    const createNewCompany = useCallback(() => {
        const newCompany = {
            name: '',
            address: '',
            deliveryPort: deliveryPorts[0].id,
            requisites: {
                bankAddress: '',
                account: '',
                bankName: '',
                inn: '',
            },
            factories: [],
            isIndividual: false,
            primary: false,
            responsiblePersons: [
                {
                    name: '',
                    phone: '',
                    email: '',
                    passport: '',
                    wechatId: '',
                    primary: true,
                },
                {
                    name: '',
                    phone: '',
                    email: '',
                    passport: '',
                    wechatId: '',
                },
            ],
        };
        setChanges({ ...changes, companies: [...companies, newCompany] });
        dispatch(setCreate(true));
        dispatch(setActiveIndex(companies.length));
        dispatch(setUserProperty({
            property: 'documents',
            value: [],
        }));
    }, [companies, changes]);
    const resetErrors = () => {
        dispatch(setUserProperty({
            property: 'errors',
            value: [],
        }));
        setFormErrors({});
    };
    const addFactory = () => {
        const factory = {
            id: cuid.slug(), name: '', address: '', isNew: true,
        };

        let company = { ...[...changes.companies][activeIndex] };
        const factories = [...company.factories, factory];
        company = { ...company, factories };
        setChanges({ ...changes, companies: [company] });
    };
    const deleteFactory = (factoryId) => {
        let company = { ...[...changes.companies][activeIndex] };
        const factories = [...company.factories].filter(({ id }) => id !== factoryId);
        company = { ...company, factories };
        setChanges({ ...changes, companies: [company] });
    };

    const resetNewCompany = () => {
        setChanges({ ...changes, companies });
    };

    useEffect(() => {
        const initialData = {
            ...changes, companies, phone, profileName,
        };
        if (role === CUSTOMER) {
            initialData.mainProductCategoryId = mainProductCategory.id;
        }
        setChanges(initialData);
    }, [companies, phone, mainProductCategory]);

    const inputHandler = (
        controlId,
        isCompanyProperty = false,
        companySubProperty = null,
        index = 0, // индекс компании
        checkbox = false,
        subIndex = undefined,
        select = false,
    ) => (evt) => {
        let inputValue = '';
        if (select) {
            inputValue = evt;
        } else {
            const { value, checked } = evt.target;
            inputValue = checkbox ? checked : value;
        }
        let currentChanges = { ...changes };
        if (isCompanyProperty) {
            const currentCompanies = [...changes.companies];
            if (companySubProperty) {
                if (typeof subIndex !== 'undefined') {
                    const subPropertyItem = {
                        ...currentCompanies[index][companySubProperty][subIndex], [controlId]: inputValue,
                    };
                    const subProperty = [...currentCompanies[index][companySubProperty].slice(0, subIndex), subPropertyItem, ...currentCompanies[index][companySubProperty].slice(subIndex + 1)];
                    currentCompanies[index] = { ...currentCompanies[index], [companySubProperty]: subProperty };
                } else {
                    const subProperty = { ...currentCompanies[index][companySubProperty], [controlId]: inputValue };
                    currentCompanies[index] = { ...currentCompanies[index], [companySubProperty]: subProperty };
                }
            } else if (checkbox && controlId === 'primary') {
                const companyId = currentCompanies[index].id;
                const anotherCompanyIndex = currentCompanies.findIndex((company) => company.id !== companyId);
                currentCompanies[index] = { ...currentCompanies[index], [controlId]: inputValue };
                currentCompanies[anotherCompanyIndex] = {
                    ...currentCompanies[anotherCompanyIndex],
                    [controlId]: !inputValue,
                };
            } else {
                currentCompanies[index] = { ...currentCompanies[index], [controlId]: inputValue };
            }
            currentChanges = { ...currentChanges, companies: currentCompanies };
        } else {
            currentChanges[controlId] = inputValue;
        }
        setChanges({ ...changes, ...currentChanges });
        dispatch(setUserProperty({ property: 'errors', value: [] }));
    };

    const undoChangesHandler = useCallback((text = '') => {
        if (create) {
            dispatch(setCreate(false));
        } else {
            dispatch(setUserProperty({
                property: 'documents', value: companies[activeIndex].documents,
            }));
            if (text) {
                message.success(text);
            }
        }
        setChanges({
            ...changes, companies, phone, mainProductCategoryId: mainProductCategory.id,
        });
    }, [activeIndex, companies, phone]);

    const fileHandler = (fileList) => {
        dispatch(setUserProperty({
            property: 'documents', value: fileList,
        }));
    };

    const saveHandler = () => {
        if ((
            changes.password || changes.confirmPassword
        ) && changes.password !== changes.confirmPassword) {
            dispatch(setUserProperty({ property: 'errors', value: [['Пароли', 'должны совпадать']] }));
        } else {
            const validateData = {
                ...changes,
                ...changes.companies[activeIndex],
            };
            const currentErrors = checkForm(validateData);
            setFormErrors(currentErrors);
            if (!Object.values(currentErrors).length) {
                const formattedCompanies = changes.companies.map((company) => ({
                    ...company,
                    responsiblePersons: company.responsiblePersons.filter((person) => !!person.name),
                }));
                dispatch(userUpdate({ changes: { ...changes, companies: formattedCompanies } }));
                dispatch(setCreate(false));
            }
        }
    };

    const logoutHandler = () => {
        dispatch(userLogout());
        setPath(null)();
    };

    useEffect(() => {
        if (companies[activeIndex]) {
            // eslint-disable-next-line no-shadow
            const { documents } = companies[activeIndex];
            dispatch(setUserProperty({
                property: 'documents', value: documents,
            }));
        }
    }, [companies, activeIndex, dispatch]);

    useEffect(() => {
        const messages = {
            [RU]: 'Изменения успешно применены', [EN]: 'Changes have been successfully applied', [ZH]: '更改已成功应用',
        };
        if (isSuccess) {
            message.success(messages[currentLocale]).then(() => {
                dispatch(setUserProperty({
                    property: 'isSuccess', value: false,
                }));
            });
        }
    }, [isSuccess]);
    if (!role) {
        return null;
    }
    return (
        <StyledPersonal className="my-5 px-3 px-sm-5 mx-3 mx-sm-auto">
            <div className="container">
                <div className="row">
                    <StyledTitle>
                        <Text variant="heading3">
                            <FormattedMessage id={`${isMobile ? 'personalAccount' : 'editProfileTitle'}`} />
                        </Text>
                        <div className="d-flex align-items-center justify-content-end">
                            <Text variant="heading">
                                <FormattedMessage id="status" />:
                            </Text>
                            <Text className="ms-3" variant="heading" color={statusColor(moderationStatus)}>
                                {localesStatuses[currentLocale][moderationStatus]}
                            </Text>
                        </div>
                    </StyledTitle>

                </div>
                <div className="row">
                    <div className="col-12 pt-4 pt-sm-4 px-0">
                        <FormInput
                            label={formatMessage({ id: 'nameTitle' })}
                            placeholder={formatMessage({ id: 'namePlaceholder' })}
                            value={changes.profileName || profileName}
                            onChange={inputHandler('profileName')}
                        />
                    </div>
                </div>
                {role === SELLER && (
                    <PersonalSeller
                        onChange={inputHandler}
                        values={changes}
                        errors={formErrors}
                        addFactory={addFactory}
                        deleteFactory={deleteFactory}
                    />
                )}
                {role === CUSTOMER && (
                    <PersonalCustomer
                        onChange={inputHandler}
                        values={changes}
                        errors={formErrors}
                        createNewCompany={createNewCompany}
                        resetNewCompany={resetNewCompany}
                        resetErrors={resetErrors}
                    />
                )}
                <div className="row">
                    <FormSectionTitle
                        title={<FormattedMessage id="documents" />}
                        hint={<FormattedMessage id="documentsHint" />}
                        //isMobile={isMobile}
                    />
                    <FormUpload
                        key={activeIndex}
                        files={companies[activeIndex]?.documents || []}
                        onChange={fileHandler}
                        cle
                    />
                </div>
                <PersonalLogin changes={changes} inputHandler={inputHandler} />
                {!!errors.length && <FormError errors={errors} />}
                <div className="row pb-3 mb-3 mb-sm-4">
                    <div
                        className=" d-flex align-items-center justify-content-between mt-4 mt-sm-0 col-12 col-sm-4 order-1 order-sm-0 px-0"
                    >
                        <FormattedMessage id="messageSuccessUndoChanges">
                            {(text) => (
                                <Link onClick={() => undoChangesHandler(text)}>
                                    <FormattedMessage id="undoChanges" />
                                </Link>
                            )}
                        </FormattedMessage>

                        <div className="d-block d-sm-none">
                            <Link onClick={logoutHandler}>
                                <FormattedMessage id="logoutButtonTitle" />
                            </Link>
                        </div>

                    </div>
                    <div className="col-12 col-sm-4 order-0 order-sm-1 px-0 px-lg-3">
                        <Button
                            disabled={isLoading}
                            onClick={saveHandler}
                            width="100%"
                            title={formatMessage({ id: 'saveButtonTitle' })}
                        />
                    </div>
                    <div
                        className="d-flex d-none d-sm-flex align-items-center mt-3 mt-sm-0 col-12 col-sm-4 order-2 order-sm-2 justify-content-end px-0"
                    >
                        <Link onClick={logoutHandler}>
                            <FormattedMessage id="logoutButtonTitle" />
                        </Link>
                    </div>
                </div>
            </div>
        </StyledPersonal>
    );
};

export default Personal;
