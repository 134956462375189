import React from 'react';
import styled, { css } from 'styled-components';
import is from 'is_js';
import { useSelector } from 'react-redux';
import AboutCount from './AboutCount';
import imgPatternLeft from '../../../img/patterns/2.png';
import imgPatternRight from '../../../img/patterns/3.png';
import { pageState } from '../../../store/reducers/pageReducer';

const StyledCounts = styled.div`
  position: relative;

  .pattern {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    height: 120%;
    width: 100%;
    top: -10%;
    z-index: 9999;

    ${({ isMobile }) => isMobile && css`
      height: 110%;
      top: -5%;
    `}
    div {
      background-size: contain;
      background-repeat: no-repeat;
      position: relative;
    }

    &__left {
      background-image: url(${imgPatternLeft});
      ${({ isMobile }) => isMobile && css`
      left: 50px;
    `}
      
    }

    &__right {
      background-image: url(${imgPatternRight});
      background-position: right;
    }
  }
`;

const AboutCounts = () => {
    const isMobile = is.mobile() || is.tablet();
    const {
        advances,
    } = useSelector(pageState);
    return (
        <StyledCounts className="row py-3 my-lg-4" isMobile={isMobile}>
            <div className="pattern row">
                <div className="pattern__left col-12 col-sm-7" />
                <div className="pattern__right col-12 col-sm-5" />
            </div>
            {
                !!advances.length && advances.map((item, idx) => (
                    <div key={idx} className="col-12 col-lg-4 mt-3">
                        <AboutCount item={item} />
                    </div>
                ))
            }
        </StyledCounts>
    );
};

export default AboutCounts;
