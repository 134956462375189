import { createAsyncThunk } from '@reduxjs/toolkit';
import $api from '../../api/api';

export const supportFetch = createAsyncThunk(
    'cms/help',
    async (_, { rejectWithValue, getState }) => {
        try {
            const { support: { currentPage, pageSize } } = getState();

            const params = {
                page: currentPage,
                page_size: pageSize,
            };

            return (await $api.get('/cms/help', { params })).data;
        } catch (e) {
            return rejectWithValue(e.response);
        }
    },
);
