import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowBack } from '../svg/arrow-back.svg';
import { $basicWhite } from '../styles/styled/colors';

const StyledLink = styled(Link)`
  display: flex;
  color: ${$basicWhite};
  text-decoration: none;
  align-items: center;

  &:hover {
    color: ${$basicWhite};
  }

  > div {
    margin: 3px 0 0 15px;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }
`;

const BackBtn = ({
                     to = '/',
                     title = '',
                     className = '',
                     onClick,
                 }) => (
    <StyledLink
        to={to}
        className={className}
        onClick={onClick}
    >
        <ArrowBack />
        <div>{title}</div>
    </StyledLink>
);

export default BackBtn;
