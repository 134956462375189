import React from "react";
import styled, { keyframes } from "styled-components";
import { $light } from "../styles/styled/colors";

export const grow = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
`;

const StyledParallax = styled.div`
	height: 430px;
	width: 100%;
	//@media (max-width: 1399px) {
	//  height: 400px;
	//}
	@media (max-width: 1199px) {
		height: 330px;
	}
	@media (max-width: 991px) {
		height: 280px;
	}
	@media (max-width: 767px) {
		height: 230px;
	}
	@media (max-width: 575px) {
		height: 180px;
	}
	overflow: hidden;
	position: relative;

	> div {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		background-color: ${$light};

		img {
			width: 100%;
			height: 100%;
			transform-origin: bottom right;
			animation: ${grow} 150000ms ease;
			object-fit: cover;
			object-position: center center;
		}
	}
`;

const ParallaxImage = ({ src, alt = "", isMobile }) => (
	<StyledParallax isMobile={isMobile}>
		<div>
			<img src={src} alt={alt} />
		</div>
	</StyledParallax>
);

export default ParallaxImage;
