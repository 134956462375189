import React, { useEffect, useState } from 'react';
import { DatePicker, TimePicker } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import moment from 'moment';
import FormLabel from './FormLabel';
import { localeState } from '../../store/reducers/localesReducer';
import { getDatePickerLocale } from '../../utils/getDatePickerLocale';
import FormInputError from './FormInputError';
import { $basicGrey, $basicRed } from '../../styles/styled/colors';

const { RangePicker } = DatePicker;

const StyledDatePicker = styled.div`
  padding: 0;
  flex: 1 0 auto;
  color: ${({ error }) => (error ? $basicRed : 'currentColor')} !important;

  .ant-picker {
    border-color: ${({ error }) => (error ? $basicRed : $basicGrey)} !important;
    color: ${({ error }) => (error ? $basicRed : $basicGrey)} !important;
    border-radius: 4px;

    svg {
      fill: ${({ error }) => (error ? $basicRed : $basicGrey)} !important;
    }
  }

`;

const StyledTimePicker = styled.div`
  position: relative;
`;

const FormDatePicker = ({
                            onChange, label = '',
                            link = null, type = 'date',
                            error = '', onFocus, placeholder = '',
                            range = false, value,
                        }) => {
    const [, setOpen] = useState(false);
    const [timeValue, setTimeValue] = useState();
    const [clickedTimeColIndex, setClickedTimeColIndex] = useState(0);
    const { currentLocale } = useSelector(localeState);
    const handleFocus = (e) => {
        document.querySelector('body').style.overflow = 'hidden';
        document.querySelector('html').style.overflow = 'hidden';

        if (onFocus) onFocus(e);
    };

    const handleBlur = () => {
        document.querySelector('body').style.overflow = 'visible';
        document.querySelector('html').style.overflow = 'visible';
        setOpen(false);
    };

    const handleSelect = (time) => {
        setTimeValue(time);
        onChange(time);
    };

    const onTimeCellClick = (e) => {
        if (e.target.classList.contains('ant-picker-time-panel-cell-inner')) {
            const content = e.target.parentNode.parentNode.parentNode;
            const col = e.target.parentNode.parentNode;
            const index = Array.from(content.children).indexOf(col);

            setClickedTimeColIndex(index);
        }
    };

    useEffect(() => {
        document.addEventListener('click', onTimeCellClick);

        return () => document.removeEventListener('click', onTimeCellClick);
    }, []);

    useEffect(() => {
        if (clickedTimeColIndex) {
            setClickedTimeColIndex(0);
            setOpen(false);
            handleBlur();

            if (onChange && timeValue) onChange(timeValue);
        }
    }, [clickedTimeColIndex]);

    return (
        <StyledDatePicker error={!!error}>
            {
                label && <FormLabel label={label} link={link} />
            }
            <div className="position-relative">
                {
                    type === 'time'
                        ? (
                            <StyledTimePicker>
                                <TimePicker
                                    inputReadOnly
                                    locale={getDatePickerLocale(currentLocale)}
                                    format="HH:mm"
                                    onChange={onChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onSelect={handleSelect}
                                    placeholder={placeholder}
                                    value={value ? moment(value) : ''}
                                    // open={open}
                                    onOpenChange={() => setOpen(true)}
                                    showNow={false}
                                />
                            </StyledTimePicker>
                        )
                        : (
                            <>
                                {
                                    range
                                        ? (
                                            <RangePicker
                                                inputReadOnly
                                                onChange={onChange}
                                                locale={getDatePickerLocale(currentLocale)}
                                                format="YYYY.MM.DD"
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                                placeholder={placeholder}
                                                separator={null}
                                                value={value}
                                            />
                                        )
                                        : (
                                            <DatePicker
                                                inputReadOnly
                                                onChange={onChange}
                                                locale={getDatePickerLocale(currentLocale)}
                                                format="YYYY.MM.DD"
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                                placeholder={placeholder}
                                                value={value ? moment(value) : ''}
                                                disabledDate={(d) => !d || d.isBefore(moment(), 'day')}
                                            />
                                        )
                                }
                            </>
                        )
                }
                {
                    error && <FormInputError error={error} />
                }
            </div>
        </StyledDatePicker>
    );
};

export default FormDatePicker;
