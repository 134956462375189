import React from 'react';

export default (
    <>
        <p>欢迎您加入这个创新型的商业模式，走进肉类进口新时代!
            亚洲肉类拍卖平台即将正式上线，它是中国进口肉类使用者和国际生产工厂之间直接沟通的肉类进口交易俱乐部，也是世界上第一个将肉类从国际生产工厂直接输送到中国国内肉类使用者手中的专业型肉类拍卖平台。
        </p>
        <p>我们倡导透明的商业原则，保障中国国内肉类使用者直接从获得中国许可的肉类工厂选择货源、获得直接报盘。</p>
        <p>我们的使命是用安全构筑贸易基石，用服务搭建交易桥梁。</p>
        <p>我们有着雄厚的实力，最大股东是2021年位列“财富”世界500强171位的厦门国贸控股集团有限公司，承接进口货运代理的是国内货代行业规模最大的优合集团有限公司。所有这些将共同保障我们的交易安全，真正让您的肉类进口业务安心无忧。<br/>愿我们携手成功！
        </p>
    </>
);
