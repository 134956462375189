import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import useAuction from '../hooks/useAuction';
import { localeState } from '../../../store/reducers/localesReducer';
import DetailInfo from '../../../components/Detail/DetailInfo';
import { userState } from '../../../store/reducers/userReducer';
import { CUSTOMER } from '../../../utils/consts';
import { addAuctionToFavourite, removeAuctionFromFavourite } from '../../../store/actions/auctionsActions';

const AuctionDetailInfoHeader = ({ auction }) => {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const { role } = useSelector(userState);
    const { currentLocale } = useSelector(localeState);
    const {
        id,
        icon,
        boxWeight,
        storageLife,
        name,
        category,
        totalWeight,
        isFavorite,
    } = useAuction(auction);

    const items = [{
        title: formatMessage({ id: 'productExpirationDate' }),
        value: formatMessage({ id: 'monthValue' }, { value: (+storageLife).toFixed() }),
    }, {
        title: formatMessage({ id: 'boxWeight' }),
        value: formatMessage({ id: 'kgValue' }, { value: boxWeight }),
    }, {
        title: formatMessage({ id: 'weightNet' }),
        value: formatMessage({ id: 'tonFullValue' }, { value: totalWeight }),
    }];

    const withFavourite = role === CUSTOMER;
    const onFavourite = useCallback(() => {
        if (isFavorite) {
            dispatch(removeAuctionFromFavourite({ id }));
        } else {
            dispatch(addAuctionToFavourite({ id }));
        }
    }, [dispatch, isFavorite]);

    return (
        <DetailInfo
            icon={icon}
            name={name[currentLocale]}
            categoryName={category?.name[currentLocale]}
            items={items}
            isFavourite={isFavorite}
            withFavourite={withFavourite}
            onFavourite={onFavourite}
        />
    );
};

export default AuctionDetailInfoHeader;
