import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { IntlProvider } from "react-intl";
import styled, { css } from "styled-components";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { messages, RU } from "../locales/localesMap";
import Footer from "./Footer/Footer";
import { HOME_ROUTE } from "../utils/consts";
import customerBackground from "../img/customer-bg.jpg";
import sellerBackground from "../img/seller-bg.jpg";
import { localeState } from "../store/reducers/localesReducer";
import { userState } from "../store/reducers/userReducer";
import { mdQueryDown, xlQueryDown, xlQueryUp } from "../styles/styled/sizes";
import AuctionModal from "../pages/Auctions/components/AuctionModal";
import AuctionConfirm from "../pages/Auctions/components/AuctionConfirm";
import {
	auctionsState,
	setAddModalVisibility,
	setAuctionsProperty,
} from "../store/reducers/auctionsReducer";
import SupportForm from "../components/Support/SupportForm";
import { notificationState } from "../store/reducers/notificationReducer";
import AuctionsCreateResult from "../pages/Auctions/components/AuctionsCreateResult";
import Header from "./Header/Header";

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	position: relative;

	${({ isHome }) =>
		!isHome &&
		css`
			background-position: center 0%;
			background-repeat: no-repeat;
			background-attachment: fixed;

			@media ${xlQueryUp} {
				background-size: 100% auto;
			}
		`}
`;

const StyledWrapper2 = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	position: relative;

	${({ isHome }) =>
		!isHome &&
		css`
			background-image: linear-gradient(
				0.63deg,
				rgba(146, 186, 122, 0.97) 0.43%,
				rgba(21, 171, 255, 0.1261) 99.35%
			);
			background-position: center 0;
			background-repeat: no-repeat;
		`}
`;

const StyledMain = styled.main`
	flex: 1;
	display: flex;
	flex-direction: column;

	${({ isHome }) => css`
		@media ${xlQueryDown} {
			padding-top: ${isHome ? 0 : 75}px;
		}

		@media ${mdQueryDown} {
			padding-top: ${isHome ? 0 : 55}px;
			z-index: 1;
		}
	`}
`;

const getScrollPercent = () => {
	const h = document.documentElement;
	const b = document.body;
	const st = "scrollTop";
	const sh = "scrollHeight";

	return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
};

const Base = ({ children }) => {
	const dispatch = useDispatch();
	const { role } = useSelector(userState);
	const { supportFormOpen } = useSelector(notificationState);
	const { currentLocale } = useSelector(localeState);
	const { addModalOpen, isNewAuction, auction, confirmOpen } =
		useSelector(auctionsState);
	const { pathname } = useLocation();
	const [bgTop, setBgTop] = useState(0);
	const isTablet = useMediaQuery({ query: xlQueryDown });
	const isHome = pathname === HOME_ROUTE;

	const closeResult = () => {
		dispatch(setAddModalVisibility(false));
		dispatch(
			setAuctionsProperty({
				property: "isNewAuction",
				value: false,
			}),
		);
	};

	const handleWindowScroll = () => {
		if (
			document.body.scrollHeight - document.documentElement.clientHeight >
			window.innerHeight
		) {
			setBgTop(getScrollPercent());
		} else {
			setBgTop(0);
		}
	};

	const handleAuctionCancel = () => {
		dispatch(
			setAuctionsProperty({
				property: "addModalOpen",
				value: false,
			}),
		);
	};

	const handleConfirmCancel = () => {
		dispatch(
			setAuctionsProperty({
				property: "confirmOpen",
				value: false,
			}),
		);
	};

	useEffect(() => {
		window.addEventListener("scroll", handleWindowScroll);

		return () => window.removeEventListener("scroll", handleWindowScroll);
	}, []);

	return (
		<IntlProvider
			messages={messages[currentLocale]}
			locale={currentLocale}
			defaultLocale={RU}>
			<StyledWrapper
				isHomeLayout={isHome}
				style={{
					backgroundPositionY: `${bgTop}%`,
					backgroundImage: `url(${
						role !== "seller" ? customerBackground : sellerBackground
					})`,
				}}>
				<StyledWrapper2>
					<Header />
					<StyledMain isHome={isHome}>{children}</StyledMain>
					{(isTablet && !isHome) || !isTablet ? <Footer /> : null}
					{addModalOpen && <AuctionModal handleCancel={handleAuctionCancel} />}
					{confirmOpen && <AuctionConfirm handleCancel={handleConfirmCancel} />}
					{supportFormOpen && <SupportForm />}
					{isNewAuction && auction && (
						<AuctionsCreateResult
							handleCancel={closeResult}
							auction={auction}
						/>
					)}
				</StyledWrapper2>
			</StyledWrapper>
		</IntlProvider>
	);
};
export default Base;
