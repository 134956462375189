import { createAction, createReducer } from '@reduxjs/toolkit';
import { EN, RU, ZH } from '../../locales/localesMap';

const acceptedLocales = [RU, EN, ZH];
const locale = localStorage.getItem('locale');

let currentLocale = RU;
if (locale && acceptedLocales.includes(locale)) {
    currentLocale = locale;
}

const initialState = {
    currentLocale,
};

export const updateLocales = createAction('UPDATE_LOCALES');

export default createReducer(initialState, {
    [updateLocales](state, action) {
        // eslint-disable-next-line no-param-reassign
        state.currentLocale = action.payload;
    },
});
export const localeState = (state) => state.locales;
