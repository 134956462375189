import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import is from 'is_js';
import { Button } from '../../ui';
import { ReactComponent as Back } from '../../svg/left.svg';
import { $basicBlack, $basicGrey } from '../../styles/styled/colors';
import { authState } from '../../store/reducers/authReducer';
import { passwordReset } from '../../store/actions/authActions';
import FormInput from '../Form/FormInput';
import localesErrors from '../../locales/localesErrors';
import { FIELD_REQUIRED, VALIDATION_ERROR } from '../../utils/consts';

const StyledBack = styled.div`
  color: ${$basicGrey};
  transition: .25s ease-in;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  z-index: 999;
  top: 2px;
  left: 20px;

  &:hover {
    color: ${$basicBlack};
  }
`;

const Forgot = ({ backHandler }) => {
    const dispatch = useDispatch();
    const { locale } = useIntl();
    const [phone, setPhone] = useState('');
    const [error, setError] = useState(null);
    const { errorCode, isLoading } = useSelector(authState);

    const inputHandler = (evt) => {
        const { value } = evt.target;
        setPhone(value);
        setError(null);
    };

    const sendHandler = useCallback(() => {
        setError(null);
        if (phone) {
            if (!is.number(+phone)) {
                setError(localesErrors[locale][VALIDATION_ERROR]);
            } else {
                dispatch(passwordReset({ phone }));
            }
        } else {
            setError(localesErrors[locale][FIELD_REQUIRED]);
        }
    }, [phone]);

    useEffect(() => () => {
        setError(localesErrors[locale][errorCode]);
    }, [errorCode, isLoading]);
    return (
        <>
            <StyledBack onClick={backHandler}>
                <Back />
            </StyledBack>

            <FormattedMessage id="personPhonePlaceholder">
                {
                    (placeholder) => (
                        <FormInput
                            placeholder={placeholder}
                            onChange={inputHandler}
                            value={phone}
                            error={error}
                        />
                    )
                }
            </FormattedMessage>

            <div className="styled-auth-body__button">
                <Button
                    onClick={sendHandler}
                    title={<FormattedMessage id="forgotButtonTitle" />}
                    width="100%"
                />
            </div>

            <div className="styled-auth-body__policy">
                <FormattedMessage id="forgotPasswordMsg" />
            </div>
        </>
    );
};

export default Forgot;
