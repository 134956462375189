import saveAs from 'file-saver';
import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { localeState } from '../../../store/reducers/localesReducer';
import useAuction from '../hooks/useAuction';
import {
    Dot, Link, Paper, Text,
} from '../../../ui';
import { $basicDarkGreen, $basicGreen, $basicGrey2 } from '../../../styles/styled/colors';
import { ReactComponent as Pdf } from '../../../svg/pdf.svg';
import { ReactComponent as Track } from '../../../svg/truck.svg';
import { smQueryDown } from '../../../styles/styled/sizes';
import $api from '../../../api/api';
import { getImagePath } from '../../../utils/getImagePath';
import linkClick from '../../../utils/linkClick';

const StyledCardWinner = styled.div`
  min-height: 148px;

  @media ${smQueryDown} {
    max-height: 9999px;
    height: auto;
  }
`;

const downloadProductDocs = (productId) => {
    $api
        .get(`/products/${productId}/specifications-archive/`, {
            responseType: 'arraybuffer',
        })
        .then((res) => {
            const blob = new Blob([res.data], {
                type: 'application/octet-stream',
            });
            const filename = 'docs.zip';
            saveAs(blob, filename);
        });
};

const AuctionsCardWinner = ({ auction }) => {
    const { currentLocale } = useSelector(localeState);
    const {
        icon,
        price,
        currencySymbol,
        name,
        category,
        totalWeight,
        deliveryStatus,
        country,
        factory,
        cardDetailHandler,
        productId,
        documentsArchive,
        specifications,
        startDateFormat,
        id,
    } = useAuction(auction);

    const handleDocumentationClick = (e) => {
        e.preventDefault();

        linkClick(getImagePath({
            path: documentsArchive.path,
            uid: documentsArchive.uid,
            extension: documentsArchive.extension,
        }));
    };

    const handleSpecificationsClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        downloadProductDocs(productId);
    };

    return (
        <StyledCardWinner>
            <Paper isLink>
                <div className="row">

                    <div className="col-12 d-flex justify-content-between flex-wrap align-items-center">

                        <div className="d-flex pt-1">
                            <Text><FormattedMessage id="factoryName" /> {factory.name}</Text>
                            <Dot />
                            <Text variant="bold">
                                {country}
                            </Text>
                        </div>
                        <div className="d-flex pt-1">
                            <Text>№ {id}</Text>
                            <Dot />
                            <Text>{startDateFormat}</Text>
                        </div>

                        {!!documentsArchive && (
                            <Link onClick={handleDocumentationClick}>
                                <FormattedMessage id="documentation" />
                            </Link>
                        )}
                    </div>

                    <div className="col-12 d-sm-flex align-items-center justify-content-between mt-3">
                        <div className="col-8 d-flex align-items-center">
                            <div className="col-3 text-center">
                                {icon}
                            </div>
                            <div className="d-flex flex-column ms-3 col" onClick={cardDetailHandler}>
                                <Text variant="heading" color={$basicDarkGreen} className="mt-2">
                                    {category?.name[currentLocale]}
                                </Text>
                                <Text variant="bold2" color={$basicDarkGreen}>
                                    {name[currentLocale]}
                                </Text>
                            </div>
                        </div>
                        <div className="col-sm-4 d-flex justify-content-sm-end mt-sm-0 mt-3">
                            <Text variant="bold2" color={$basicGreen}>
                                <FormattedMessage
                                    id="moneyPerTon"
                                    values={{ value: price, currency: currencySymbol }}
                                />
                            </Text>
                        </div>
                    </div>
                    <div className="col-12 d-flex align-items-center justify-content-between mt-3">
                        <div className="col-xl-8 d-xs-flex align-items-center">
                            <div className="d-flex align-items-center me-5">
                                <div className="col-3 text-center" style={{ color: $basicGrey2 }}>
                                    <Pdf />
                                </div>
                                <Link
                                    className="ms-2"
                                    onClick={handleSpecificationsClick}
                                    disabled={!specifications.length}
                                >
                                    <FormattedMessage id="specifications" />
                                </Link>
                            </div>
                            <Text variant="bold" color={$basicDarkGreen} className="mt-3 mt-xs-0">
                                <FormattedMessage id="weightNet" />:&nbsp;
                                <FormattedMessage id="tonFullValue" values={{ value: totalWeight }} />
                            </Text>
                        </div>
                        {
                            deliveryStatus && (
                                <div className="d-flex align-items-center justify-content-end">
                                    <div className="me-3" style={{ color: $basicGrey2 }}>
                                        <Track />
                                    </div>
                                    <Text variant="bold" color={$basicDarkGreen}>{deliveryStatus.name[currentLocale]}</Text>
                                </div>
                            )
                        }
                    </div>
                </div>
            </Paper>
        </StyledCardWinner>
    );
};

export default AuctionsCardWinner;
