import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import logo from '../img/MeatAsia.png';
import { HOME_ROUTE } from '../utils/consts';

const StyledLogo = styled(NavLink)`
  display: block;
  position: relative;
  top: -1px;
  width: 125px;

  img {
    width: 100%;
    height: auto;
  }

  ${({ small = 0 }) => small && css`
    width: 89px;
  `}
`;

const Logo = ({ small, className = '' }) => (
    <StyledLogo
        className={className}
        small={small ? 1 : 0}
        to={HOME_ROUTE}
    >
        <img src={logo} alt="logo" />
    </StyledLogo>
);

export default Logo;
