import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { $basicWhite } from '../../styles/styled/colors';
import { mdQueryDown } from '../../styles/styled/sizes';

const StyledTitle = styled.div`
  color: ${$basicWhite};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.24em;
  text-align: center;
  width: 100%;

  ${({ size }) => size === 'lg' && css`
    font-weight: 300;
    font-size: 50px;
    line-height: 59px;
    letter-spacing: .05em;
    margin-top: 25px;

    ${({ isMobile }) => isMobile && css`
      font-size: 40px;
      line-height: 47px;
      margin-top: 5.875vh;
    `}
  `}

  ${({ isMobile }) => isMobile && css`
    text-align: left;
    letter-spacing: .04em;
  `}
`;

const HomeTitle = () => {
    const isMobile = useMediaQuery({ query: mdQueryDown });
    return (
        <div className="d-flex flex-column align-items-start align-items-sm-center px-md-3 px-sm-0">
            <StyledTitle isMobile={isMobile}><FormattedMessage id="homeTitle" /></StyledTitle>
            <StyledTitle isMobile={isMobile} size="lg"><FormattedMessage id="homeTitle2" /></StyledTitle>
        </div>
    );
};

export default HomeTitle;
