import React from 'react';
import { ReactComponent as LikeFilled } from '../svg/like-filled.svg';
import { ReactComponent as LikeEmpty } from '../svg/like-empty.svg';

const Like = (props) => {
    const { active } = props;

    return (
        <button type="button" {...props}>
            {active ? (
                <LikeFilled className="card__img-icon-like" />
            ) : (
                <LikeEmpty className="card__img-icon-like" />
            )}
        </button>
    );
};

export default Like;
