import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { $basicGrey2 } from '../../styles/styled/colors';
import Forgot from './Forgot';
import Login from './Login';
import { authState, setAuthProperty } from '../../store/reducers/authReducer';
import AuthModal from './AuthModal';

const Auth = () => {
    const dispatch = useDispatch();
    const [forgot, setForgot] = useState(false);
    const [errors, setErrors] = useState({});
    const { submitOpen } = useSelector(authState);

    const handleCancel = () => {
        dispatch(setAuthProperty({
            property: 'authFormOpen',
            value: false,
        }));
        setErrors({});
        dispatch(setAuthProperty({ property: 'errors', value: [] }));
    };

    return (
            <AuthModal
                    onCancel={handleCancel}
                    title={
                        submitOpen
                                ? <FormattedMessage id="yourApplicationAccepted"/>
                                : (
                                        <>
                                            {
                                                forgot
                                                        ? <FormattedMessage id="forgotPasswordFormTitle"/>
                                                        : <FormattedMessage id="loginFormTitle"/>
                                            }
                                        </>
                                )
                    }
                    body={
                        submitOpen
                                ? (
                                        <p className="text-center pb-5" style={{ color: $basicGrey2, lineHeight: '18px' }}>
                                            <FormattedMessage id="thankForRegMsg"/>
                                            <br/>
                                            <FormattedMessage id="descriptionForRegMsg"/>
                                        </p>
                                )
                                : (
                                        <>
                                            {
                                                forgot
                                                        ? <Forgot backHandler={() => setForgot(false)}/>
                                                        : (
                                                                <Login
                                                                        forgotHandler={() => setForgot(true)}
                                                                        errors={errors}
                                                                        setErrors={setErrors}
                                                                />
                                                        )
                                            }
                                        </>
                                )
                    }

            />
    );
};

export default Auth;
