const useNav = () => {
    const setPath = (path) => () => {
        if (path) {
            localStorage.setItem('lastPage', path);
        } else {
            localStorage.removeItem('lastPage');
        }
    };
    const getPath = () => localStorage.getItem('lastPage') || null;
    return {
        setPath,
        getPath,
    };
};

export default useNav;
