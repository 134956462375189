import styled from 'styled-components';
import { smQueryDown } from '../styles/styled/sizes';

export const StyledDetail = styled.div`
  overflow: hidden;
  padding: 34px 0 160px 0;
  height: 100%;

  @media ${smQueryDown} {
    padding-bottom: 50px;
  }
`;

export const StyledDetailHeader = styled.div`
  margin-bottom: 30px;
`;
