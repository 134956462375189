import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { Button } from '../../../ui';
import { auctionsState, setAuctionsProperty } from '../../../store/reducers/auctionsReducer';
import { userState } from '../../../store/reducers/userReducer';
import { CUSTOMER, SELLER } from '../../../utils/consts';
import ButtonsGroup from '../../../ui/ButtonsGroup';
import { mdQueryDown } from '../../../styles/styled/sizes';
import FormSelect from '../../../components/Form/FormSelect';

const StyledFormSelect = styled(FormSelect)`
  .ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-radius: 4px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`;

const buttons = [
    {
        id: 'auctionsCompletedButtonTitle', status: 'completed', role: [SELLER], sort: 1,
    },
    {
        id: 'auctionsActiveButtonTitle', status: 'active', role: [SELLER, CUSTOMER], sort: 2,
    },
    {
        id: 'auctionsPlannedButtonTitle', status: 'planned', role: [SELLER, CUSTOMER], sort: 3,
    },
    {
        id: 'auctionsIsWinnerButtonTitle', status: 'is_winner', role: [CUSTOMER], sort: 4,
    },
    {
        id: 'auctionsFavoriteButtonTitle', status: 'is_favorite', role: [CUSTOMER], sort: 5,
    },
];

const AuctionsFilter = () => {
    const { formatMessage } = useIntl();
    const { status } = useSelector(auctionsState);
    const { role } = useSelector(userState);
    const dispatch = useDispatch();
    const isMobile = useMediaQuery({ query: mdQueryDown });

    const filterHandler = useCallback((currentStatus) => {
        if (currentStatus !== status) {
            dispatch(setAuctionsProperty({
                property: 'auctions',
                value: [],
            }));
        }
        dispatch(setAuctionsProperty({
            property: 'status',
            value: currentStatus,
        }));
    }, []);
    const options = buttons.filter((button) => button.role.includes(role))
        .sort((a, b) => a.sort - b.sort);
    return (
        !isMobile ? (
            <ButtonsGroup>
                {
                    options.map((button) => (
                        <Button
                            key={button.id}
                            title={formatMessage({ id: button.id })}
                            variant={`${status === button.status ? 'filled' : 'white'}`}
                            onClick={() => filterHandler(button.status)}
                        />
                    ))
                }
            </ButtonsGroup>
        ) : (
            <StyledFormSelect
                allowClear={false}
                value={status ? buttons.find((button) => button.status === status).id : 'auctionsActiveButtonTitle'}
                options={options.map((button) => ({
                    id: button.id,
                    label: formatMessage({ id: button.id }),
                    name: formatMessage({ id: button.id }),
                    value: button.id,
                }))}
                onChange={(value) => filterHandler(buttons.find((button) => button.id === value).status)}
            />
        )
    );
};

export default AuctionsFilter;
