import React from 'react';
import styled from 'styled-components';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Button } from '../../ui';
import FormLabel from './FormLabel';

const StyledCurrencySelect = styled.div`
  padding: 0;
  flex: 1 0 auto;
`;

const currencyCodes = ['RUB', 'USD', 'CNY'];

const FormCurrencySelect = ({
                                currencyCode = 'RUB',
                                onClick, label = '',
                                link = null,
                            }) => (
    <StyledCurrencySelect>
        {
            label && <FormLabel label={label} link={link} />
        }
        <div className="d-flex align-items-center">
            {
                currencyCodes.map((code) => (
                    <Button
                        key={code}
                        variant="tab"
                        title={getSymbolFromCurrency(code)}
                        className="me-2"
                        isActive={code === currencyCode}
                        width="42px"
                        uppercase
                        onClick={onClick(code)}
                    />
                ))
            }
        </div>

    </StyledCurrencySelect>
);

export default FormCurrencySelect;
