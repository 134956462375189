import styled from 'styled-components';
import { smQueryDown } from '../../styles/styled/sizes';
import { $basicGrey2 } from '../../styles/styled/colors';

export const StyledModalContent = styled.div`
  .modal__title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;

    @media ${smQueryDown} {
      margin-bottom: 10px;
    }
  }

  .form-upload {
    margin-top: 0 !important;
  }
`;

export const StyledModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;

  @media ${smQueryDown} {
    margin-top: 40px;
    flex-direction: column;
  }

  .footer {
    &__text {
      width: 100%;
      max-width: 526px;
      line-height: 18px;
      color: ${$basicGrey2};
      width: 100%;

      @media ${smQueryDown} {
        margin-bottom: 15px;
      }
    }

    &__btn {
      width: 100%;
      text-transform: uppercase;
    }
  }
`;
