import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Image } from 'antd';
import {
    $basicGreen, $basicGrey3, $basicRed, $basicWhite,
} from '../../styles/styled/colors';
import { smQueryDown } from '../../styles/styled/sizes';
import FileUploader from '../../ui/FileUploader';
import getFileIcon from '../../utils/getFileIcon';
import { getImagePath } from '../../utils/getImagePath';

const StyledUploadFile = styled.div`
  background: ${$basicWhite};
  border: 1px solid ${$basicGrey3};
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  height: 76px;
  width: 76px;
  transition: .25s ease-in;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 16px 8px 0;
  overflow: hidden;

  @media ${smQueryDown} {
    height: 70px;
    width: 70px;
    margin: 0 16px 11px 0;
  }

  img {
    object-fit: cover;
  }

  ${({ isButton }) => isButton && css`
    &:before, &:after {
      content: '';
      display: block;
      width: 30px;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: ${$basicGreen};
      transition: .25s ease-in;
    }

    &:after {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &:hover {
      &:before, &:after {
        width: 50%;
      }
    }
  `}
`;

const StyledUploadClose = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  right: 5px;
  top: 5px;
  background: ${$basicGrey3};
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    &:before, &:after {
      content: '';
      display: block;
      width: 70%;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 50%;
      background: ${$basicRed};
      transition: .25s ease-in;
    }

    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

`;

const FormUpload = ({
                        onChange,
                        files = [],
                        accept,
                    }) => {
    const [items, setItems] = useState([]);
    const [currentItems, setCurrentItems] = useState([]);
    const maxNumber = 20;

    const handleChange = (newFiles) => {
        onChange([...currentItems, ...newFiles]);
    };

    const removeHandler = useCallback((index) => () => {
        const fileList = currentItems.filter((item, i) => index !== i);
        setCurrentItems(fileList);
        onChange(fileList);
    }, [currentItems]);

    useEffect(() => {
        if (!!files?.length) {
            setCurrentItems(files.map(({
                                           filename,
                                           mimetype,
                                           path,
                                           extension,
                                           uid,
                                       }) => ({
                uid,
                type: mimetype,
                name: filename,
                src: getImagePath({ path, extension, uid }),
                current: true,
            })));
        }
    }, []);

    return (
        <div className="p-0 mt-5 form-upload">
            <FileUploader
                accept={accept}
                multiple
                onLoad={setItems}
                onChange={handleChange}
                max={maxNumber}
                render={({ onOpen, onRemove }) => (
                    <div className="d-flex flex-wrap align-items-center">
                        <StyledUploadFile
                            className="form-upload__content"
                            onClick={onOpen}
                            isButton
                        />

                        {currentItems.map(({ src, name, type }, index) => (
                            <StyledUploadFile
                                key={src || name}
                                style={{
                                    border: 0,
                                }}
                            >
                                {type && type.indexOf('image/') > -1 ? (
                                    <Image
                                        width="100%"
                                        height="100%"
                                        src={src}
                                    />
                                ) : getFileIcon(type)}
                                <StyledUploadClose
                                    onClick={() => {
                                        setCurrentItems(removeHandler(index));
                                    }}
                                >
                                    <div />
                                </StyledUploadClose>
                            </StyledUploadFile>
                        ))}

                        {items.map(({ src, name, type }, index) => (
                            <StyledUploadFile
                                key={src || name}
                                style={{
                                    border: 0,
                                }}
                            >
                                {type.indexOf('image/') > -1 ? (
                                    <Image
                                        width="100%"
                                        height="100%"
                                        src={src}
                                    />
                                ) : getFileIcon(type)}
                                <StyledUploadClose onClick={() => onRemove(index)}>
                                    <div />
                                </StyledUploadClose>
                            </StyledUploadFile>
                        ))}
                    </div>
                )}
            />
        </div>
    );
};

export default FormUpload;
