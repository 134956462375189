import React from 'react';
import styled from 'styled-components';
import { mdQueryDown, smQueryDown, xsQueryDown } from '../../styles/styled/sizes';

const StyledDetailContent = styled.div`
  box-shadow: 0px 10px 30px rgba(10, 9, 75, 0.14);
  padding: 30px 25px 40px 25px;
  width: 100%;
  max-width: ${({ width }) => width};
  background: rgba(255, 255, 255, 0.9);
  margin: 0 auto;
  opacity: 0.9;
  border-radius: 4px;

  @media ${mdQueryDown} {
    padding-bottom: 30px;
  }

  @media ${smQueryDown} {
    padding: 30px 15px;
  }

  @media ${xsQueryDown} {
    margin-bottom: 100px;
    padding: 26px 10px 30px 10px;
    .details__mini-cards {
      margin-bottom: 50px;
    }
  }

  .details__mini-cards {
    margin-bottom: 40px;
  }

  .create-btn {
    text-transform: uppercase;
  }

  .details__specs-btn {
    @media ${xsQueryDown} {
      margin-bottom: 10px;
    }
  }
`;

const DetailContent = ({ children, width = '770px', style }) => (
    <StyledDetailContent
        width={width}
        style={style}
    >
        {children}
    </StyledDetailContent>
);

export default DetailContent;
