export default {
	accountLabel: "Номер счета",
	accountPlaceholder: "Номер счета",
	active: "Активные",
	add: "Добавить",
	addAuction: "Добавить аукцион",
	addNewCompany: "Новая компания или частное лицо",
	addProduct: "Добавить продукт",
	address: "Адрес",
	all: "Все",
	allAuctions: "Все аукционы",
	apply: "Применить",
	attachInvoice: "Прикрепить счет",
	auctionCompleted: "Аукцион завершен!",
	auctionCreateResultText:
		"В связи с добавлением нового продукта, лот проходит модерацию. Это может занять до 1-го рабочего дня.",
	auctionInfoTitle: "Информация по аукциону",
	auctionsActiveButtonTitle: "Текущие",
	auctionsCompletedButtonTitle: "Прошедшие",
	auctionsFavoriteButtonTitle: "Избранные",
	auctionsIsWinnerButtonTitle: "Выигранные",
	auctionsPlannedButtonTitle: "Будущие",
	auctionsTitle: "Аукционы",
	backToAuctionsPage: "Назад к списку аукционов",
	backToProductsPage: "Назад к списку продуктов",
	bankAddressLabel: "Адрес банка",
	bankAddressPlaceholder: "Введите адрес банка",
	bankNameLabel: "Банк",
	bankNamePlaceholder: "Банк",
	beforeEnd: "До окончания",
	beforeStart: "До начала",
	bidAccessDenied:
		"У вас нет доступа к данному аукциону. Обратитесь к поддержке.",
	bidNotification: "Страховка и сюрвеерские услуги включены в стоимость",
	boxWeight: "Вес коробки",
	buyer: "Покупатель",
	cancel: "Отменить",
	categoryLabel: "Основной продукт торгов (категория)",
	categoryPlaceholder: "Основной продукт торгов (категория)",
	commonQuestions: "общие вопросы",
	company: "Компания",
	companyAddressLabel: "Адрес компании",
	companyAddressPlaceholder: "Адрес компании",
	companyNameLabel: "Наименование компании или ИП",
	companyNamePlaceholder: "Наименование компании или ИП",
	companyTitle: "О компании",
	completed: "Завершен",
	congrats: "Поздравляем, вы объявлены победителем!",
	contactsTitle: "Контакты",
	country: "Страна",
	createAuction: "Создать аукцион",
	createAuctionDescription:
		"В связи с добавлением нового аукциона, лот проходит модерацию. Это может занять до 1-го рабочего дня.",
	createAuctionFormTitle: "Создать аукцион",
	createAuctionResultFormTitle: "Аукцион создан",
	createPassword: "Создайте пароль",
	currencyOfContract: "Валюта контракта",
	currentPrice: "Текущая цена",
	currentPricePerTonn: "Текущая цена за тонну:",
	customerMainProfile: "Основной профиль для совершения сделок",
	dateShippingETO: "Период отгрузки ETD",
	delete: "Удалить",
	deliveryConditionCustomer:
		"30% Prepayment, 70% Payment against BL or Telex Release",
	deliveryConditionSeller: "100% Prepayment",
	deliveryTerms: "Условия поставки",
	descriptionForRegMsg:
		"После прохождения модерации Вам будет доступно участие в аукционах.",
	doBid: "Сделать ставку",
	documentation: "Документация",
	documents: "Документы",
	documentsHint: "Загрузите документы",
	edit: "Изменить",
	editProduct: "Изменить продукт",
	editProfileTitle: "Редактирование профиля",
	emptyBid: "Вы пока не сделали ставку",
	endTime: "Время окончания GMT+3",
	enterCorrectEmail: "Введите корректный email",
	enterCorrectPhone: "Введите корректный номер телефона",
	enterNewPassword: "Введите новый пароль",
	existAccount: "Есть аккаунт? Войдите",
	factoriesTitle: "Завод",
	factoryAddressLabel: "Адрес завода",
	factoryAddressPlaceholder: "Адрес завода",
	factoryName: "Номер завода",
	factoryNameLabel: "Номер завода и название",
	factoryNamePlaceholder: "Номер завода и название",
	factoryNumber: "Номер завода",
	factorySelect: "Выберите завод отгрузки",
	favourites: "Избранные",
	fieldRequired: "Это поле обязательно для заполнения",
	filter: "Фильтр",
	finalPrice: "Финальная цена",
	find: "Найти",
	finishPrice: "Финальная цена",
	forgotButtonTitle: "Отправить письмо со ссылкой",
	forgotPasswordFormTitle: "Востановление доступа",
	forgotPasswordLink: "Забыли пароль?",
	forgotPasswordMsg:
		"На почту будет выслано письмо со ссылкой на восстановление аккаунта, если письмо не пришло, проверьте правильность написания почты",
	generalTitle: "Общие данные",
	getInvoice: "Получить счет",
	goToAuction: "Перейти к аукциону",
	goToProductsPage: "Перейти на страницу продуктов",
	goToProductsPageMobile: "На страницу продуктов",
	homeSliderTitle: "С нами работают:",
	homeTitle2: "онлайн аукционов мясной продукции",
	homeTitle: "Международная платформа",
	innLabel: "ИНН",
	innPlaceholder: "ИНН",
	insuranceServices: "Страховка и сюрвейерские услуги включены в стоимость",
	ip: "Индивидуальный предприниматель",
	itIssues: "IT вопросы",
	kgValue: "{value} кг",
	kppLabel: "КПП",
	kppPlaceholder: "КПП",
	loginButtonTitle: "Войти",
	loginChangeDataTitle: "Изменить данные для входа",
	loginFormTitle: "Войти в личный кабинет!",
	logisticsIssues: "вопросы по логистике и таможенному оформлению",
	logoutButtonTitle: "Выйти",
	meatFactory: "Номер завода",
	messageSuccessUndoChanges: "Изменения успешно отменены",
	moderationProfileStatusInfo: "Профиль еще на модерации",
	moneyPerTon: "{value} {currency}/т.",
	monthValue: "{value} мес",
	notificationsEmailLabel: "На почту",
	notificationsSmsLabel: "СМС-сообщениями",
	notificationsTitle: "Уведомления",
	onModeration: "На модерации",
	onModerationFrom: "На модерации от {date}",
	paginationProducts: "Показано {current} из {total} продуктов",
	paginationTotals: "Показано {current} из {total} торгов",
	passwordConfirmLabel: "Повторите пароль",
	passwordConfirmPlaceholder: "Повторите пароль",
	passwordLabel: "Пароль",
	passwordPlaceholder: "Введите пароль",
	payAuctionMsg: "Пожалуйста, оплатите счет в течение 2х дней.",
	payButtonTitle: "Оплатить счет",
	payDeliveryConditions: "Условия оплаты и поставки",
	payWaitingAuctionMsg: "Счет формируется.",
	periodEnd: "Период по",
	periodStart: "Период с",
	periodPlaceholder: "Период проведения аукциона",
	personAddNameLabel: "Дополнительное контактное лицо (Ф.И.О.)",
	personAddNamePlaceholder: "Дополнительное контактное лицо (Ф.И.О.)",
	personalAccount: "Личный кабинет",
	personEmailLabel: "E-mail",
	personEmailPlaceholder: "E-mail",
	personMainNameLabel: "Основное контактное лицо (Ф.И.О.)",
	personMainNamePlaceholder: "Основное контактное лицо (Ф.И.О.)",
	personNameLabel: "Контактное лицо (Ф.И.О.)",
	personNamePlaceholder: "Контактное лицо (Ф.И.О.)",
	personPassportLabel: "Номер паспорта",
	personPassportPlaceholder: "Номер паспорта",
	personPhoneLabel: "Телефон",
	personPhonePlaceholder: "Телефон",
	personWechatLabel: "WeChat ID",
	personWechatPlaceholder: "WeChat ID",
	policy: "Политика конфиденциальности",
	portPlaceholder: "Например: Ростовский морской мультимодальный порт",
	portTitle: "Укажите порт доставки в Китае",
	productAdded: "Продукт добавлен",
	productCategory: "Категория продукта",
	productChanged: "Продукт изменен",
	productExpirationDate: "Срок годности",
	productFormCategoryError:
		"Пользователь не может учавствовать в торгах в данной категории",
	productImages: "Фотографии продукта",
	productName: "Наименование продукции",
	productNamePlaceholder2: "Курица",
	productNamePlaceholder:
		"Ноги цыплят-бройлеров замороженные, категории B (монолит)",
	productNote:
		"В связи с добавлением нового продукта, лот проходит модерацию. Это может занять до 1-го рабочего дня",
	productSpecification: "Спецификация продукта",
	productsTitle: "Продукты",
	productSubcategory: "Подкатегории продукции",
	productSubcategoryPlaceholder: "Тушка, грудка, крыло",
	productSuggested: "Продукт предложен",
	productType: "Тип продукта",
	productTypeAndCategory: "Тип и категория продукта",
	question: "Вопрос",
	questionsAboutProducts: "вопросы по продуктам",
	questionSent: "Вопрос успешно отправлен!",
	read: "Прочитанные",
	registerAgreement:
		"Нажимая кнопку, я даю согласие на обработку персональных данных",
	registerButtonTitle: "ЗАРЕГИСТРИРОВАТЬСЯ",
	registerFormTitle: "Регистрация профиля",
	registerLink: "Регистрация",
	repeatAuction: "Повторить аукцион",
	requisitesTitle: "Реквизиты",
	resetFilter: "Сбросить фильтр",
	resetPasswordSuccess:
		"Вы успешно изменили пароль, теперь вы можете авторизоваться.",
	resetSuccess: "Запрос успешно отправлен!",
	responsiblePersonsTitle1: "Ответственное лицо в торгах #1",
	responsiblePersonsTitle2: "Ответственное лицо в торгах #2",
	responsiblePersonsTitle: "Ответственные лица",
	resultInProcess: "Идет обработка результатов",
	russia: "Россия",
	saveButtonTitle: "Сохранить",
	selectCountry: "Выберите страну",
	selected: "выбрано",
	selectFromList: "Выберите из списка",
	selectOrCreateProduct: "Выбрать продукт или добавить новый",
	seller: "Продавец",
	send: "Отправить",
	sent: "Отправлено",
	shipmentETD: "Отгрузка ETD",
	shipmentFactory: "Завод отгрузки",
	showMore: "Показать еще",
	showProduct: "Посмотреть продукт",
	sortByDateDown: "Сортировать по уменьшению даты",
	sortByDateUp: "Сортировать по увеличению даты",
	sortByPriceDown: "Сортировать по увеличению цены",
	sortByPriceUp: "Сортировать по уменьшению цены",
	specifications: "Спецификации",
	specificationsHint: "Загрузите фото документации продукта",
	startDate: "Дата начала аукциона",
	endDate: "Дата завершения аукциона",
	startPrice: "Начальная цена",
	startPricePerTon: "Начальная цена за тонну",
	startTime: "Время начала GMT+3",
	status: "Статус",
	suggest: "Предложить",
	suggestProduct: "Предложить продукт",
	support: "поддержка",
	supportText: "Есть вопросы? Напишите нам, и мы ответим в ближайшее время",
	supportTitle: "Помощь",
	swiftCodeLabel: "SWIFT-код",
	swiftCodePlaceholder: "SWIFT-код",
	thankForAuction: "Спасибо за участие!",
	thankForRegMsg: "Спасибо за регистрацию!",
	time: "{hours}ч {minutes}мин",
	timeFull: "{days}д {hours}ч {minutes}мин {seconds}сек",
	timeMinutesSeconds: "{minutes}мин {seconds}сек",
	timeSeconds: "{seconds}сек",
	timeShort: "{days}д {hours}ч {minutes}мин",
	timeWithSeconds: "{hours}ч {minutes}мин {seconds}сек",
	toAuction: "К аукциону",
	toAuctions: "К аукционам",
	ton: "т.",
	tonFullValue: "{value} тонн",
	tonValue: "{value} т.",
	toProducts: "К продуктам",
	totalWeightPlaceholder: "Общий",
	undoAuction: "Отменить аукцион",
	undoChanges: "Отменить изменения",
	unread: "Непрочитанные",
	uploadInvoiceMsg: "Пожалуйста, загрузите счет в течение 2х дней.",
	view: "Просмотр",
	weightNet: "Вес",
	weightProducts: "Вес продукции",
	writeSupport: "Написать в поддержку",
	writeSupportDescription:
		"Вы можете направить свой вопрос, написав нам в поддержку",
	yourApplicationAccepted: "Ваша заявка принята!",
	yourName: "Ваше имя",
	yuoBid: "Ваша ставка",
	weChatInfo:
		"Для получения уведомлений в WeChat необходимо проделать 2 простых шага",
	weChatLink: "Нажмите, чтобы их выполнить",
	weChatStep1: "Шаг 1",
	weChatStep2: "Шаг 2",
	weChatStep1Title: "Подпишитесь на нас",
	weChatStep2Title: "Подтвердите ваш аккаунт",
	weChatStep1Button: "готово, дальше",
	weChatStep2Button: "готово",
	nameTitle: "Название профиля",
	namePlaceholder: "Введите название профиля",
	anyQuestions: "Если у вас возникнут вопросы, свяжитесь с нами.",
	logisticsType: "Вариант доставки",
	dateFormat: "{year}.{month}.{day}",
	auctionConfirm: "Вы действительно хотите сделать новую ставку?",
	yes: "Да",
	no: "Нет",
};
