import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { FormattedMessage, useIntl } from 'react-intl';
import useAuction from '../hooks/useAuction';
import { Button, Text } from '../../../ui';
import { $basicDarkGreen, $basicGreen } from '../../../styles/styled/colors';
import { userState } from '../../../store/reducers/userReducer';
import { CUSTOMER, SELLER } from '../../../utils/consts';
import { mdQueryDown } from '../../../styles/styled/sizes';
import linkClick from '../../../utils/linkClick';
import { getImagePath } from '../../../utils/getImagePath';
import Upload from '../../../ui/Upload';
import { attachFile } from '../../../store/actions/auctionsActions';
import { ZH } from '../../../locales/localesMap';

const AuctionDetailInfoDescription = ({ auction }) => {
    const dispatch = useDispatch();
    const [port, setPort] = useState();
    const { locale } = useIntl();
    const { role, companies } = useSelector(userState);
    const isMobile = useMediaQuery({ query: mdQueryDown });
    const {
        id,
        price,
        startPrice,
        currencySymbol,
        isCompleted,
        deliveryCondition,
        isWinner,
        adminAccount,
        sellerAccount,
    } = useAuction(auction);

    useEffect(() => {
        const primaryCompany = companies.find((company) => company.primary);
        if (primaryCompany) {
            setPort(primaryCompany.deliveryPort);
        }
    }, [companies]);
    return (
        <div className="mb-md-0 mb-4 flex-grow-1">
            {
                isCompleted
                    ? (
                        <div className="d-flex flex-column w-100">
                            <div
                                className={`d-md-flex align-items-center justify-content-${isWinner ? 'between' : 'center'}`}
                            >
                                <div
                                    className={`d-flex align-items-center justify-content-md-center mb-md-0 mb-3 ${!isMobile && 'w-50'}`}
                                >
                                    <Text variant="heading" className={`me-2 pt1 ${isMobile && 'w-50'}`}>
                                        <FormattedMessage id="finishPrice" />:
                                    </Text>
                                    <Text
                                        variant="bold2"
                                        color={$basicGreen}
                                        className={isMobile && 'w-50'}
                                        align={isMobile && 'right'}
                                    >
                                        <FormattedMessage
                                            id="moneyPerTon"
                                            values={{ value: price, currency: currencySymbol }}
                                        />
                                    </Text>
                                </div>
                                {
                                    role === SELLER && (
                                        <Text variant="heading" className={!isMobile && 'w-50'}>
                                            <FormattedMessage id="uploadInvoiceMsg" />
                                            &nbsp;
                                            {
                                                locale !== ZH && <FormattedMessage id="anyQuestions" />
                                            }
                                        </Text>
                                    )
                                }
                                {
                                    role === CUSTOMER && isWinner && (
                                        <div
                                            className={`d-flex align-items-center justify-content-center ${!isMobile && 'w-50'}`}
                                        >
                                            <Text
                                                variant="heading"
                                                className={`me-2 pt1 ${isMobile && 'w-50'}`}
                                            >
                                                <FormattedMessage id="deliveryTerms" />
                                                &nbsp;
                                                {deliveryCondition.title[locale]}:
                                            </Text>
                                            <Text
                                                variant="bold2"
                                                color={$basicGreen}
                                                className={isMobile && 'w-50'}
                                                align={isMobile && 'right'}
                                            >
                                                {port}
                                            </Text>
                                        </div>
                                    )
                                }
                            </div>
                            {
                                role === SELLER && (
                                    <div className="flex-grow-1 mt-4">
                                        {!sellerAccount ? (
                                            <Upload
                                                buttonProps={{
                                                    title: <FormattedMessage id="attachInvoice" />,
                                                    uppercase: true,
                                                    width: '100%',
                                                }}
                                                onChange={(file) => {
                                                    dispatch(attachFile({ id, file }));
                                                }}
                                            />
                                        ) : (
                                            <a
                                                href={getImagePath({
                                                    path: sellerAccount.path,
                                                    uid: sellerAccount.uid,
                                                    extension: sellerAccount.extension,
                                                })}
                                                rel="noreferrer"
                                                target="_blank"
                                                className="d-block mt-2 text-center text-decoration-underline"
                                            >
                                                Прикрепленный счет
                                            </a>
                                        )}
                                    </div>
                                )
                            }
                            {
                                role === CUSTOMER && isWinner && (
                                    <>
                                        <div className="flex-grow-1 mt-4">
                                            <Button
                                                disabled={!adminAccount}
                                                uppercase
                                                width="100%"
                                                onClick={() => {
                                                    linkClick(getImagePath({
                                                        path: adminAccount.path,
                                                        uid: adminAccount.uid,
                                                        extension: adminAccount.extension,
                                                    }));
                                                }}
                                            >
                                                <FormattedMessage id="payButtonTitle" />
                                            </Button>
                                        </div>
                                        <Text variant="heading" className="mt-4 text-center">
                                            <FormattedMessage
                                                id={`${adminAccount ? 'payAuctionMsg' : 'payWaitingAuctionMsg'}`}
                                            />
                                            &nbsp;
                                            <FormattedMessage id="anyQuestions" />
                                        </Text>
                                    </>
                                )
                            }
                        </div>
                    )
                    : (
                        <div className="d-flex flex-column">
                            <Text variant="heading" color={$basicDarkGreen}>
                                <FormattedMessage id="currentPrice" />:
                            </Text>
                            <Text variant="bold2" color={$basicGreen} className="mt-2">
                                <FormattedMessage
                                    id="moneyPerTon"
                                    values={{ value: price, currency: currencySymbol }}
                                />
                            </Text>
                            <Text variant="bold" className="mt-2">
                                <FormattedMessage id="startPrice" />:
                                &nbsp;
                                <FormattedMessage
                                    id="moneyPerTon"
                                    values={{ value: startPrice, currency: currencySymbol }}
                                />
                            </Text>
                        </div>
                    )
            }
        </div>
    );
};

export default AuctionDetailInfoDescription;
