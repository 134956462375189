import React from 'react';
import styled from 'styled-components';
import { $basicGreen } from '../styles/styled/colors';
import { ReactComponent as PhoneIcon } from '../svg/phone.svg';
import { CONTACTS } from '../utils/contacts';

const StyledPhone = styled.div`
  display: flex;
  align-items: center;
  transition: .25s ease-in;
  color: currentColor;

  .styled-phone {
    &__link {
      text-decoration: none;
      color: currentColor;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.02em;
      display: flex;
      align-items: center;

      span {
        margin: 0 0 0 8px;
      }
    }
  }

  &:hover {
    color: ${$basicGreen};
  }

`;

const Phone = () => (
    <StyledPhone>
        <a className="styled-phone__link" href={`tel:${CONTACTS.phoneDigits}`}>
            <PhoneIcon />
            <span>{CONTACTS.phoneDisplay}</span>
        </a>
    </StyledPhone>
);

export default Phone;
