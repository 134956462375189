import React from 'react';
import { useIntl } from 'react-intl';
import { Select } from 'antd';
import styled from 'styled-components';
import cx from 'classnames';
import { $basicGreen, $basicGrey, $basicRed } from '../../styles/styled/colors';
import FormLabel from './FormLabel';
import FormInputError from './FormInputError';

const StyledSelect = styled.div`
  .ant-select {
    height: 42px;

    .ant-select-selector {
      height: 100%;
      border: 1px solid ${({ error }) => (error ? $basicRed : $basicGrey)} !important;

    }

    .ant-select-suffix {
      color: ${({ error }) => (error ? $basicRed : $basicGrey)} !important;
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }

    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
    }
  }
`;

const StyledOptionContent = styled.div`
  padding: 3px 5px;
  display: flex;
  align-items: center;

  .option__box {
    border-radius: 4px;
    margin-right: 8px;
    width: 18px;
    min-width: 18px;
    height: 18px;
    border: 1px solid ${$basicGrey};
    display: flex;
    justify-content: center;
    align-items: center;

    .option__box-content {
      display: none;
      background: ${$basicGreen};
      width: 12px;
      height: 12px;
      border-radius: 2px;
    }
  }

  .option__box_checked {
    border: 1px solid ${$basicGreen};

    .option__box-content {
      display: block;
    }
  }
`;

const FormSelect = ({
                        onChange,
                        multiple = false,
                        options = [],
                        label,
                        placeholder = '',
                        value,
                        disabled = false,
                        showSearch = false,
                        link = null,
                        error = '',
                        onFocus,
                        className,
                        allowClear = true,
                    }) => {
    const { formatMessage, locale } = useIntl();

    return (
        <StyledSelect
            className={className}
            error={!!error}
        >
            {
                label && <FormLabel label={label} link={link} />
            }
            <div className="position-relative">
                <Select
                    allowClear={allowClear}
                    dropdownClassName="ant-select-custom-dropdown"
                    mode={multiple ? 'multiple' : 'default'}
                    onChange={onChange}
                    onFocus={onFocus}
                    style={{ width: '100%' }}
                    placeholder={!error ? placeholder : ''}
                    value={value}
                    disabled={disabled}
                    showSearch={showSearch}
                    menuItemSelectedIcon={null}
                    // filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    // filterSort={(optionA, optionB) => optionA.name.toLowerCase().localeCompare(optionB.name.toLowerCase())}
                    maxTagCount={0}
                    maxTagPlaceholder={`${placeholder} (${formatMessage({ id: 'selected' })}: ${value?.length || 0})`}
                    showArrow
                    onClear={onChange}
                >
                    {
                        options.map((option) => (
                            !multiple ? (
                                <Select.Option key={option.id} value={option.id}>
                                    {option.name[locale] || option.name}
                                </Select.Option>
                            ) : (
                                <Select.Option key={option.id}>
                                    <StyledOptionContent>
                                    <span
                                        className={cx('option__box', { option__box_checked: value.includes(option.id.toString()) })}
                                    >
                                        <span className="option__box-content" />
                                    </span>
                                        {option.name[locale] || option.name}
                                    </StyledOptionContent>
                                </Select.Option>
                            )
                        ))
                    }
                </Select>
                {
                    error && <FormInputError error={error} />
                }
            </div>
        </StyledSelect>
    );
};

export default FormSelect;
