import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, useHistory } from 'react-router-dom';
import { message } from 'antd';
import HomeTitle from './HomeTitle';
import HomeButtons from './HomeButtons';
import HomeSlider from './HomeSlider';
import Auth from '../../components/Auth/Auth';
import Registration from '../../components/Auth/Registration';
import ResetPassword from '../../components/Auth/ResetPassword';
import ModerationInfo from '../../components/Auth/ModerationInfo';
import { authState, setAuthProperty } from '../../store/reducers/authReducer';
import { userState } from '../../store/reducers/userReducer';
import { $basicGrey } from '../../styles/styled/colors';
import { mdQueryDown, xlQueryDown } from '../../styles/styled/sizes';
import { AUCTIONS_ROUTE, PASSWORD_RESET, POLICY_ROUTE } from '../../utils/consts';
import bg from '../../img/home_bg.jpg';

const StyledPolicyLink = styled.div`
  color: ${$basicGrey};

  * {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }
`;

const StyledHomeWrapper = styled.div`
  padding-top: 100px;
  height: calc(100vh - 151px);
  min-height: 740px;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${bg});
  margin-top: -75px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ isTablet }) => isTablet && css`
    height: 100vh;
    margin-top: 0;
    padding-top: 175px;
  `}

  ${({ isMobile }) => isMobile && css`
    height: auto;
    padding-top: 21.125vh;
    justify-content: flex-start;
    margin-top: 0;
    min-height: 100vh;
  `}
`;

const Home = () => {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const { push, location } = useHistory();
    const {
        regFormOpen, authFormOpen, passResetFormOpen,
        moderationFormOpen, isSuccess,
    } = useSelector(authState);
    const {
        role,
    } = useSelector(userState);
    const isTablet = useMediaQuery({ query: xlQueryDown });
    const isMobile = useMediaQuery({ query: mdQueryDown });

    const currentYear = new Date().getFullYear();

    useEffect(() => {
        if (role) {
            push(AUCTIONS_ROUTE);
        }
    }, [role]);

    useEffect(() => {
        if (location.pathname === PASSWORD_RESET) {
            const token = location.search.replace('?token=', '');
            if (token) {
                dispatch(setAuthProperty({
                    property: 'passResetFormOpen',
                    value: true,
                }));
                dispatch(setAuthProperty({
                    property: 'token',
                    value: token,
                }));
            }
        }
    }, []);

    useEffect(() => {
        if (isSuccess) {
            message.success(formatMessage({ id: 'resetSuccess' })).then(() => {
                dispatch(setAuthProperty({
                    property: 'isSuccess',
                    value: false,
                }));
            });
        }
    }, [isSuccess, dispatch]);
    return (
        <StyledHomeWrapper
            isTablet={isTablet}
            isMobile={isMobile}
        >
            <div className="container">
                <HomeTitle />
                <HomeButtons />
            </div>
            <HomeSlider />
            {
                isTablet && (
                    <div className="container d-flex flex-column flex-grow-1 justify-content-end py-3">
                        <StyledPolicyLink className="d-flex flex-column px-3">
                            <NavLink
                                to={POLICY_ROUTE}
                                style={{
                                    textDecoration: 'underline',
                                }}
                            >
                                <FormattedMessage id="policy" />
                            </NavLink>
                            <span className="mt-2">
                                MeatAsia&nbsp;
                                {currentYear}
                            </span>
                        </StyledPolicyLink>
                    </div>
                )
            }
            {
                authFormOpen && <Auth />
            }
            {
                regFormOpen && <Registration />
            }
            {
                passResetFormOpen && <ResetPassword />
            }
            {
                moderationFormOpen && <ModerationInfo />
            }
        </StyledHomeWrapper>
    );
};

export default Home;
