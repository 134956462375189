export default {
	anyQuestions: "如有问题，请联系我们",
	accountLabel: "账号",
	accountPlaceholder: "账号",
	active: "拍卖中",
	add: "添加",
	addAuction: "添加拍卖",
	addNewCompany: "新公司或个人",
	addProduct: "添加产品",
	address: "地址",
	all: "全部",
	allAuctions: "所有拍卖",
	apply: "应用",
	attachInvoice: "上传发票",
	auctionCompleted: "拍卖结束了",
	auctionCreateResultText:
		"由于添加了一种新产品，该货物正在进行审核中。可能需要一工作日。",
	auctionInfoTitle: "拍卖信息",
	auctionsActiveButtonTitle: "拍卖中",
	auctionsCompletedButtonTitle: "完成",
	auctionsFavoriteButtonTitle: "选择的拍卖",
	auctionsIsWinnerButtonTitle: "竞拍成功",
	auctionsPlannedButtonTitle: "将要拍卖",
	auctionsTitle: "拍卖",
	backToAuctionsPage: "返回到拍卖页面",
	backToProductsPage: "返回产品列表",
	bankAddressLabel: "银行地址",
	bankAddressPlaceholder: "银行地址",
	bankNameLabel: "银行名称",
	bankNamePlaceholder: "银行名称",
	beforeEnd: "距离结束",
	beforeStart: "距离开始",
	bidAccessDenied: "谢谢你的等待!我们还需要一点时间。您将很快能够参与拍卖",
	bidNotification:
		"价格说明:包含运费，保险，海关税费，增值税，代理，新冠病毒消杀等",
	boxWeight: "包装规格",
	buyer: "买方",
	cancel: "取消",
	categoryLabel: "竞拍的主要产品（类别）",
	categoryPlaceholder: "鸡产品",
	commonQuestions: "通用问题",
	company: "公司账户",
	companyAddressLabel: "地址:",
	companyAddressPlaceholder:
		"中国（河北）自由贸易试验区曹妃甸片区曹妃甸 综合保税区A02路以西， B3S路以北金贸大厦A 座2层202号",
	companyNameLabel: "公司名称/ 个人姓名",
	companyNamePlaceholder: "北京欧里恩特进出口有限公司",
	companyTitle: "公司资料",
	completed: "完成",
	congrats: "恭喜竞拍成功!",
	contactsTitle: "联络人",
	country: "国家",
	createAuction: "查看产品",
	createAuctionDescription:
		"由于增加了一个新拍卖，该拍品正在进行版主审核。 可能会需要1个工作日",
	createAuctionFormTitle: "创建拍卖",
	createAuctionResultFormTitle: "创建拍卖",
	createPassword: "创建密码",
	currencyOfContract: "货币",
	currentPrice: "实时价格",
	currentPricePerTonn: "实时价格:",
	customerMainProfile: "主要交易公司",
	dateShippingETO: "起拍价格",
	delete: "删除",
	deliveryConditionCustomer: "付款条件: 预付款30％，提货前付70％",
	deliveryConditionSeller: "预付款100%",
	deliveryTerms: "交货条款",
	descriptionForRegMsg: "如未收到，请核对邮箱地址是否填写正确",
	doBid: "提交",
	documentation: "单证",
	documents: "资质",
	documentsHint: "上传资质照片",
	edit: "编辑",
	editProduct: "更改产品",
	editProfileTitle: "修改账户信息",
	emptyBid: "你还没出价",
	endTime: "结束时间 GMT+3",
	enterCorrectEmail: "输入正确的电子邮件",
	enterCorrectPhone: "输入正确的电话号码",
	enterNewPassword: "输入新密码",
	existAccount: "已有账户请直接登录",
	factoriesTitle: "工厂",
	factoryAddressLabel: "工厂地址",
	factoryAddressPlaceholder: "工厂地址",
	factoryName: "工厂注册编号",
	factoryNameLabel: "厂号和厂名",
	factoryNamePlaceholder: "厂号和厂名",
	factoryNumber: "工厂注册编号",
	factorySelect: "装运工厂",
	favourites: "选择的拍卖",
	fieldRequired: "此字段是必需的",
	filter: "排序",
	finalPrice: "成交价格",
	find: "确认",
	finishPrice: "成交价格",
	forgotButtonTitle: "发送带有链接的电子邮件",
	forgotPasswordFormTitle: "忘记密码",
	forgotPasswordLink: "忘记密码？",
	forgotPasswordMsg:
		"带有帐户恢复链接的电子邮件将发送到您的邮箱，如果电子邮件尚未到达，请检查邮箱地址的拼写",
	generalTitle: "一般数据",
	getInvoice: "拍卖发票已发送给您了",
	goToAuction: "前往拍卖",
	goToProductsPage: "返回产品页面",
	goToProductsPageMobile: "返回食品页面",
	homeSliderTitle: "他们和我们一起工作",
	homeTitle2: "亚洲肉类拍卖平台",
	homeTitle: "平台首页",
	innLabel: "纳税人识别号",
	innPlaceholder: "纳税人识别号",
	insuranceServices: "价格说明:包含运费，保险，新冠病毒消杀等",
	ip: "个人账户",
	itIssues: "IT 问题",
	kgValue: "{value}公斤/箱",
	kppLabel: "银行代码",
	kppPlaceholder: "银行代码",
	loginButtonTitle: "登录",
	loginChangeDataTitle: "更改登录详细信息",
	loginFormTitle: "登录我的个人账户",
	logisticsIssues: "物流及清关问题",
	logoutButtonTitle: "登出",
	meatFactory: "注册编号",
	messageSuccessUndoChanges: "更改已成功取消",
	moderationProfileStatusInfo: "账户正在审核",
	moneyPerTon: "{value} {currency}/吨",
	monthValue: "{value}个月",
	notificationsEmailLabel: "通知",
	notificationsSmsLabel: "发到手机",
	notificationsTitle: "通知",
	onModeration: "审核中",
	// onModerationFrom:
	paginationProducts: "显示了{total}笔交易中的{current}产品中心",
	paginationTotals: "显示了{total}笔交易中的{current}笔",
	passwordConfirmLabel: "确认密码",
	passwordConfirmPlaceholder: "确认密码",
	passwordLabel: "密码",
	passwordPlaceholder: "密码",
	payAuctionMsg: "请在2天内付账 如果您有任何问题，请与我们联系。",
	payButtonTitle: "付账",
	payDeliveryConditions: "付款条件",
	payWaitingAuctionMsg: "发票已生成，如有问题，请您及时联系我们。",
	periodEnd: "期间",
	periodStart: "期间从",
	periodPlaceholder: "拍卖时间",
	personAddNameLabel: "操作人",
	personAddNamePlaceholder: "操作人",
	personalAccount: "个人账户",
	personEmailLabel: "邮箱地址",
	personEmailPlaceholder: "邮箱地址",
	personMainNameLabel: "总经理",
	personMainNamePlaceholder: "总经理",
	personNameLabel: "姓名",
	personNamePlaceholder: "姓名",
	personPassportLabel: "身份证号",
	personPassportPlaceholder: "身份证号",
	personPhoneLabel: "电话号码",
	personPhonePlaceholder: "电话号码",
	personWechatLabel: "WECHAT ID（微信ID）",
	personWechatPlaceholder: "WECHAT ID（微信ID）",
	policy: "私隐政策",
	portPlaceholder: "例如，罗斯托夫多式联运海港",
	portTitle: "指定中国的交货港口",
	productAdded: "添加产品",
	productCategory: "产品种类",
	productChanged: "变更产品",
	productExpirationDate: "保质期",
	productFormCategoryError: "用户不能参与这类交易”",
	productImages: "产品照片",
	productName: "产品名称",
	productNamePlaceholder2: "鸡产品",
	productNamePlaceholder: "产品名称",
	productNote: "由于添加了一种新产品，该货物正在进行审核中。可能需要一工作日。",
	productSpecification: "规格书:",
	productsTitle: "产品中心",
	productSubcategory: "产品种类",
	productSubcategoryPlaceholder: "肉，胸，翅膀",
	productSuggested: "所提供的产品",
	productType: "产品分类",
	productTypeAndCategory: "产品类型",
	question: "问题",
	questionsAboutProducts: "产品问题",
	questionSent: "问题已成功发送",
	read: "已读通知",
	registerAgreement:
		"确认登录，代表用户同意本平台相关《用户协议》及《隐私政策》等",
	registerButtonTitle: "登记注册",
	registerFormTitle: "个人资料登记",
	registerLink: "新会员注册",
	repeatAuction: "重复拍卖",
	requisitesTitle: "银行帐户信息",
	resetFilter: "删除",
	resetPasswordSuccess: "您已成功更改密码，现在您可以登录。",
	resetSuccess: "请求已成功发送!",
	responsiblePersonsTitle1: "法人（负责人）信息",
	responsiblePersonsTitle2: "拍卖负责人",
	responsiblePersonsTitle: "法定代表人",
	resultInProcess: "结果正在处理中",
	russia: "俄罗斯",
	saveButtonTitle: "确认修改",
	selectCountry: "国家",
	selected: "选定",
	selectFromList: "从名单上选择",
	selectOrCreateProduct: "选择产品或添加新产品",
	seller: "卖方",
	send: "发送",
	sent: "已发送",
	shipmentETD: "预计出厂时间",
	shipmentFactory: "国家",
	showMore: "更多",
	showProduct: "查看产品",
	sortByDateDown: "按日期递减排序",
	sortByDateUp: "按日期增加排序",
	sortByPriceDown: "按价格上涨排序",
	sortByPriceUp: "按降价排序",
	specifications: "规格书",
	specificationsHint: "上传产品文档的照片",
	startDate: "A拍卖开始日期",
	startPrice: "起拍价格",
	startPricePerTon: "S每吨初始价格",
	startTime: "开始时间 GMT+3",
	status: "状态",
	suggest: "建议",
	suggestProduct: "提供产品",
	support: "帮助",
	supportText: "有什么问题吗？ 写信给我们，我们会尽快回复",
	supportTitle: "支援服务",
	swiftCodeLabel: "SWIFT",
	swiftCodePlaceholder: "SWIFT",
	thankForAuction: "感谢您的参与！",
	thankForRegMsg:
		"您的资料变更申请已收到，我们将向您注册时预留的邮箱发送账户重置邮件，",
	time: "{hours}小时 {minutes}分",
	timeFull: "{days}天 {hours}小时 {minutes}分 {seconds}秒",
	timeMinutesSeconds: "{minutes}分 {seconds}秒",
	timeSeconds: "{seconds}秒",
	timeShort: "{days}天 {hours}小时 {minutes}分",
	timeWithSeconds: "{hours}小时 {minutes}分 {seconds}秒",
	toAuction: "去竞拍",
	toAuctions: "去竞拍",
	ton: "吨",
	tonFullValue: "{value} 吨",
	tonValue: "{value}吨",
	toProducts: "产品",
	totalWeightPlaceholder: "总重",
	undoAuction: "取消拍卖",
	undoChanges: "撤消更改",
	unread: "未读通知",
	uploadInvoiceMsg: "请在2日内上传发票",
	view: "单证",
	weightNet: "净重",
	weightProducts: "产品重量",
	writeSupport: "写信给支持",
	writeSupportDescription: "如有问题，您可以直接发邮件给我们",
	yourApplicationAccepted: "您的申请已通过审核!",
	yourName: "你的名字",
	yuoBid: "您的出价",
	weChatInfo: "完成以下两步操作，即可关联微信，及时获取通知",
	weChatLink: "点击并完成",
	weChatStep1: "第一步",
	weChatStep2: "第二步",
	weChatStep1Title: "关注我们",
	weChatStep2Title: "在微信确认账户",
	weChatStep1Button: "完成并继续下一步",
	weChatStep2Button: "完成",
	nameTitle: "资料名称",
	namePlaceholder: "输入文件名称",
	endDate: "Дата завершения аукциона",
	logisticsType: "运输方式",
	dateFormat: "{year}年{month}月{day}日",
	auctionConfirm: "您确定要进行新的投注吗？",
	yes: "是的",
	no: "不",
};
