export const $basicWhite = '#fff';
export const $basicBlack = '#000';
export const $basicDarkGreen = '#03422F';
export const $basicGreen = '#9FC031';
export const $basicBlue = '#EFF7FF';
export const $basicRed = '#DE010E';
export const $basicYellow = '#E5A708';
export const $basicGrey = '#B3BDC6';
export const $basicGrey2 = '#6F819B';
export const $basicGrey3 = '#E5EAF0';

// custom colors
export const $light = '#F9F9F9';
