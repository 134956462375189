import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Text } from '../../../ui';
import { $basicGrey2 } from '../../../styles/styled/colors';
import useAuction from '../hooks/useAuction';
import { CUSTOMER } from '../../../utils/consts';
import AuctionDetailCustomerBids from './AuctionDetailCustomerBids';
import { userState } from '../../../store/reducers/userReducer';
import DetailsCards from '../../../components/Detail/DetailsCards/DetailsCards';

const AuctionDetailInfoBody = ({ auction }) => {
    const { role } = useSelector(userState);
    const {
        specifications,
        isActive,
    } = useAuction(auction);

    return (
        <>
            {
                role === CUSTOMER && isActive && <AuctionDetailCustomerBids auction={auction} />
            }
            <div className="pt-4">
                <Text variant="heading2">
                    <FormattedMessage id="auctionInfoTitle" />
                </Text>
            </div>
            {
                !!specifications && !!specifications.length && (
                    <div className="mt-5">
                        <DetailsCards
                            cards={specifications}
                            title={(
                                <Text
                                    variant="heading"
                                    color={$basicGrey2}
                                >
                                    <FormattedMessage id="specifications" />:
                                </Text>
                            )}
                        />
                    </div>
                )
            }
        </>
    );
};

export default AuctionDetailInfoBody;
