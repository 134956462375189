import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { productState, setProductProperty } from '../../../../store/reducers/productReducer';
import { productAdd, productEdit } from '../../../../store/actions/productActions';
import { getCategoryIcon } from '../../../../utils/getCategoryIcon';
import { StyledModalButton } from '../../../../ui/Modal/styles';
import FormSelect from '../../../../components/Form/FormSelect';
import FormInput from '../../../../components/Form/FormInput';
import ProductImages from '../ProductImages';
import { StyledModalContent, StyledModalFooter } from '../../styles';
import { StyledCategories, StyledNaming } from './styles';
import FormInputError from '../../../../components/Form/FormInputError';
import { userState } from '../../../../store/reducers/userReducer';
import { APPROVED } from '../../../../utils/consts';

const ProductForm = ({
                         isChanging = false,
                         edit = false,
                         onOffer = () => {
                         },
                         values = {},
                         allowCategories = [],
                     }) => {
    const [pictures] = useState(values.media || []);
    const [specifications] = useState(values.specifications || []);
    const [activeCategory, setActiveCategory] = useState(values.category);
    const [name, setName] = useState(values.name);
    const [weight, setWeight] = useState(values.weight);
    const [factory, setFactory] = useState(values.factory);
    const [isValid, setIsValid] = useState(false);
    const { Option } = Select;

    const {
        categories,
        factories,
        detailedItem,
        activeCategory: currentCategory,
        product,
    } = useSelector(productState);
    const { moderationStatus } = useSelector(userState);
    const dispatch = useDispatch();
    const { locale, formatMessage } = useIntl();

    const fileHandler = (property) => (fileList) => {
        dispatch(setProductProperty({
            property,
            value: fileList,
        }));
    };

    const inputHandler = (property, value) => {
        dispatch(setProductProperty({
            property,
            value,
        }));
    };

    const handleCategory = (categoryId) => {
        setActiveCategory(categoryId);
        inputHandler('category', categoryId);
    };

    const submitHandler = () => {
        if (edit) {
            dispatch(productEdit(detailedItem?.id));
            return;
        }
        dispatch(productAdd());
    };

    const handleName = (prodName) => {
        setName(prodName);
        inputHandler('name', prodName);
    };

    const handleWeight = (prodWeight) => {
        if (prodWeight.toString().match(/[^\d\\.]/g)) return;

        if (prodWeight < 0) return;

        setWeight(prodWeight);
        inputHandler('weight', prodWeight);
    };

    const handleFactory = (prodFactory) => {
        setFactory(prodFactory);
        inputHandler('factory', prodFactory);
    };

    const factoriesOptions = factories.map((item) => ({
        id: item.id,
        name: item.name,
    }));

    useEffect(() => {
        if (isChanging) {
            if (Object.keys(detailedItem).length !== 0) {
                handleCategory(detailedItem?.category?.id);
                handleName(detailedItem?.name[locale]);
                handleWeight(detailedItem?.boxWeight);
            }
        }
    }, [dispatch, isChanging, detailedItem]);

    useEffect(() => {
        if (
            (
                (
                    allowCategories?.length
                    && activeCategory
                    && allowCategories?.includes(activeCategory)
                )
                || !allowCategories
            )
            && name
            && weight
            && factory
            && !!product.pictures.length
            && !!product.specifications.length
        ) {
            setIsValid(true);
            return;
        }

        setIsValid(false);
    }, [activeCategory, name, weight, factory, product]);
    useEffect(() => {
        if (detailedItem) {
            dispatch(setProductProperty({
                property: 'category',
                value: detailedItem.categoryId,
            }));

            dispatch(setProductProperty({
                property: 'weight',
                value: detailedItem.boxWeight,
            }));

            dispatch(setProductProperty({
                property: 'factory',
                value: detailedItem.factoryId,
            }));

            dispatch(setProductProperty({
                property: 'pictures',
                value: detailedItem.media,
            }));

            dispatch(setProductProperty({
                property: 'specifications',
                value: detailedItem.specifications,
            }));
        }
    }, [detailedItem]);

    useEffect(() => {
        handleCategory(currentCategory);
    }, [currentCategory]);

    return (
        <StyledModalContent>
            <StyledCategories className="modal__categories categories">
                <div className="categories__top">
                    <p className="modal__title">
                        <FormattedMessage id="productCategory" />
                    </p>
                    {!isChanging && !edit && (
                        <button
                            type="button"
                            className="categories__top-btn"
                            onClick={onOffer}
                        >
                            <FormattedMessage id="suggestProduct" />
                        </button>
                    )}
                </div>
                <div className="categories__list">
                    {categories?.map((category) => (
                        <button
                            onClick={() => handleCategory(category.id)}
                            className={cx('categories__list-item', { 'categories__list-item-active': activeCategory === category.id })}
                            type="button"
                            key={category.id}
                        >
                            <div className="categories__list-item-img">{getCategoryIcon(category.logo)}</div>
                            <p className="categories__list-item-text">{category?.name[locale]}</p>
                        </button>
                    ))}
                    {!!allowCategories?.length && !allowCategories?.includes(activeCategory) && (
                        <FormInputError
                            error={formatMessage({ id: 'productFormCategoryError' })}
                            position="under"
                        />
                    )}
                </div>
                <div className="categories__list_mobile">
                    <Select value={activeCategory} style={{ width: '100%' }} onChange={handleCategory}>
                        {categories?.map((category) => (
                            <Option className="category-select-option" key={category.id} value={category.id}>
                                <div className="category-select-option__content">
                                    <span
                                        className="category-select-option__content-icon"
                                    >{getCategoryIcon(category.logo)}
                                    </span>
                                    {category?.name[locale]}
                                </div>
                            </Option>
                        ))}
                    </Select>
                </div>
            </StyledCategories>
            <StyledNaming className="modal__naming naming">
                <div className="naming__left">
                    <p className="modal__title">
                        <FormattedMessage id="productName" />
                    </p>
                    <FormInput
                        className="naming__left-input"
                        placeholder={formatMessage({ id: 'productNamePlaceholder' })}
                        value={name}
                        onChange={(e) => handleName(e.target.value)}
                    />
                </div>
                <div className="naming__right">
                    <p className="modal__title">
                        <FormattedMessage id="boxWeight" />
                    </p>
                    <FormInput
                        className="naming__right-input"
                        placeholder={formatMessage({ id: 'kgValue' }, { value: 15 })}
                        value={weight}
                        onChange={(e) => handleWeight(e.target.value)}
                    />
                </div>
            </StyledNaming>
            <ProductImages
                accept="image/*"
                onChange={fileHandler('pictures')}
                title={formatMessage({ id: 'productImages' })}
                picArray={pictures}
            />
            <ProductImages
                onChange={fileHandler('specifications')}
                title={formatMessage({ id: 'specifications' })}
                subtitle={formatMessage({ id: 'specificationsHint' })}
                picArray={specifications}
            />
            <div>
                <p className="modal__title">
                    <FormattedMessage id="factorySelect" />
                </p>
                <FormSelect
                    placeholder={formatMessage({ id: 'factorySelect' })}
                    value={factory}
                    onChange={handleFactory}
                    options={factoriesOptions}
                />
            </div>
            <StyledModalFooter>
                <p className="footer__text">
                    <FormattedMessage id="productNote" />
                </p>
                <StyledModalButton
                    title={formatMessage({ id: !edit ? 'add' : 'edit' })}
                    width="26%"
                    variant="filled"
                    onClick={() => submitHandler()}
                    disabled={!isValid || (moderationStatus !== APPROVED)}
                />
            </StyledModalFooter>
        </StyledModalContent>
    );
};

export default ProductForm;
