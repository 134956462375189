import React from 'react';
import styled, { css } from 'styled-components';
import { $basicWhite } from '../styles/styled/colors';

const StyledPaper = styled.div`
  background: ${({ filled }) => (filled ? $basicWhite : 'rgba(255, 255, 255, .85)')};
  box-shadow: 0 10px 30px rgba(10, 9, 75, 0.14);
  border-radius: 4px;
  display: flex;
  padding: 15px;
  transition: .25s ease-in;
  height: 100%;
  ${({ isLink }) => isLink && css`
    cursor: pointer;

    &:hover {
      box-shadow: 0 10px 30px rgba(10, 9, 75, 0.34);
    }
  `}
`;

const Paper = (props) => {
    const { children } = props;
    return (
        <StyledPaper {...props}>
            {children}
        </StyledPaper>
    );
};

export default Paper;
