import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { auctionsFetch } from '../../../store/actions/auctionsActions';
import { userState } from '../../../store/reducers/userReducer';
import { productState } from '../../../store/reducers/productReducer';
import { auctionsState } from '../../../store/reducers/auctionsReducer';

const useAuctionFilter = () => {
    const dispatch = useDispatch();
    const { role } = useSelector(userState);
    const { activeCategory } = useSelector(productState);
    const {
        // status,
        customerFilters,
        delivery_status_id,
        ordering,
    } = useSelector(auctionsState);

    const applyFilter = useCallback(() => {
        const filters = {
            ...customerFilters,
            category_id: activeCategory,
        };

        const period = !!customerFilters.period
            ? customerFilters.period.map((date) => moment(date).format('YYYY-MM-DD')).join(',')
            : '';

        if (period) {
            filters.period = period;
        }
        // if (delivery_status_id && status === 'is_winner') {
        //     filters.delivery_status_id = delivery_status_id;
        // }

        if (role) {
            // const statuses = ['completed', 'active', 'planned'];
            const args = {
                role,
                filters,
                ordering,
            };
            // if (statuses.includes(status)) {
            //     args.status = status;
            // } else {
            //     args.filters[status] = true;
            // }

            dispatch(auctionsFetch(args));
        }
    }, [dispatch, role, activeCategory, customerFilters, delivery_status_id, ordering]);

    return { applyFilter };
};

export default useAuctionFilter;
