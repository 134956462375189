import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import AuthModal from './AuthModal';
import { authState, setAuthProperty } from '../../store/reducers/authReducer';
import FormInput from '../Form/FormInput';
import { Button } from '../../ui';
import { passwordResetConfirm } from '../../store/actions/authActions';
import localesErrors from '../../locales/localesErrors';
import { PASSWORDS_MUST_MATCH } from '../../utils/consts';

const ResetPassword = () => {
    const dispatch = useDispatch();
    const { formatMessage, locale } = useIntl();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [error, setError] = useState(null);
    const {
        submitOpen, token, errorCode, isLoading,
    } = useSelector(authState);
    const handleCancel = () => {
        dispatch(setAuthProperty({
            property: 'passResetFormOpen',
            value: false,
        }));
        dispatch(setAuthProperty({
            property: 'submitOpen',
            value: false,
        }));
    };
    const passwordHandler = (confirm = false) => (evt) => {
        const { value } = evt.target;
        if (confirm) {
            setConfirmPassword(value);
        } else {
            setPassword(value);
        }
        setError(null);
    };

    const applyPassword = useCallback(() => {
        if (password !== confirmPassword) {
            setError(formatMessage(localesErrors[locale][PASSWORDS_MUST_MATCH]));
        } else {
            dispatch(passwordResetConfirm({ token, password }));
        }
    }, [password, confirmPassword, token]);

    useEffect(() => () => {
        setError(localesErrors[locale][errorCode]);
    }, [errorCode, isLoading]);

    return (
        <AuthModal
            onCancel={handleCancel}
            title={
                submitOpen
                    ? <FormattedMessage id="resetPasswordSuccess" />
                    : <FormattedMessage id="enterNewPassword" />
            }
            body={
                !submitOpen && (
                    <div className="pb-4">
                        <FormInput
                            placeholder={formatMessage({ id: 'passwordPlaceholder' })}
                            onChange={passwordHandler()}
                            value={password}
                            error={error}
                            type="password"
                        />

                        <FormInput
                            placeholder={formatMessage({ id: 'passwordConfirmPlaceholder' })}
                            onChange={passwordHandler(true)}
                            value={confirmPassword}
                            error={error}
                            className="mt-4"
                            type="password"
                        />
                        <Button
                            onClick={applyPassword}
                            width="100%"
                            className="mt-4"
                        >
                            <FormattedMessage id="send" />
                        </Button>

                    </div>
                )
            }

        />
    );
};

export default ResetPassword;
