import styled from 'styled-components';
import { Button, Text } from '../../../../ui';
import { lgQueryDown, smQueryDown, xlQueryDown } from '../../../../styles/styled/sizes';

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -64px;
  width: 52%;
  margin-left: auto;

  @media ${xlQueryDown} {
    width: 70%;
  }

  @media ${lgQueryDown} {
    width: 100%;
    margin-top: 0;
    justify-content: center;
  }

  @media ${smQueryDown} {
    flex-direction: column-reverse;
  }
`;

export const StyledText = styled(Text)`
  text-align: right;

  @media ${lgQueryDown} {
    text-align: left;
  }

  @media ${smQueryDown} {
    text-align: center;
  }
`;

export const StyledButton = styled(Button)`
  border: 0;
  margin-left: 33px;
  width: 100%;
  max-width: 276px;

  @media ${smQueryDown} {
    margin-left: 0;
    margin-bottom: 12px;
  }
`;
