import styled from 'styled-components';
import { mdQueryDown } from '../../../../styles/styled/sizes';
import { $basicDarkGreen, $basicGrey, $basicWhite } from '../../../../styles/styled/colors';

export const StyledFilters = styled.div`
  @media ${mdQueryDown} {
    margin: 0;
    padding-top: 20px;
  }

  .filters {
    .ant-select {
      width: 100%;
    }

    .ant-select-selector {
      border-color: ${$basicGrey} !important;
      border-radius: 4px;
      height: 42px;
      display: flex;
      align-items: center;

      .ant-select-selection-item {
        display: flex;
        align-items: center;
      }

      .ant-select-selection-overflow {
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;

        .ant-select-selection-item-content,
        .ant-select-selection-item-remove {
          display: flex;
          align-items: center;
        }

        .option__box {
          display: none;
        }
      }

      &:last-child {
        min-height: 42px;
        height: 100%;
      }
    }
  }
`;

export const StyledFiltersCol = styled.div`
  @media ${mdQueryDown} {
    margin: 0 0 30px;
  }
`;

export const StyledFiltersLabel = styled.label`
  font-weight: 500;
  margin-bottom: 18px;
  display: none;

  @media ${mdQueryDown} {
    display: block;
    margin-bottom: 15px;
  }
`;

export const StyledResetButton = styled.button`
  margin-top: 10px;
  text-decoration: underline;
  color: ${$basicWhite};
  font-weight: 500;

  @media ${mdQueryDown} {
    font-weight: 400;
    color: ${$basicDarkGreen};
    text-align: center;
    width: 100%;
  }
`;
