import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Button from './Button';

const StyledInput = styled.input`
    display: none;
`;

const StyledFilename = styled.div`
    margin: 10px 0 0;
    text-align: center;
`;

const Upload = ({ onChange, accept, buttonProps = {} }) => {
    const ref = useRef();
    const [file, setFile] = useState();

    const handleClick = (e) => {
        e.stopPropagation();

        if (ref.current) {
            ref.current.dispatchEvent(new MouseEvent('click', { bubbles: true }));
        }
    };

    const handleChange = () => {
        if (ref.current) {
            const _ = ref.current.files[0];

            setFile(_);

            if (onChange) onChange(_);
        }
    };

    return (
        <>
            <Button
                {...buttonProps}
                onClick={handleClick}
            />
            <StyledInput
                accept={accept}
                type="file"
                ref={ref}
                onChange={handleChange}
            />
            {!!file && <StyledFilename>Прикрепленный файл: {file.name}</StyledFilename>}
        </>
    );
};

export default Upload;
