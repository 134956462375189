import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { $basicBlue, $basicDarkGreen, $basicGreen } from '../../../styles/styled/colors';
import { Text } from '../../../ui';
import { lgQueryDown, smQueryDown } from '../../../styles/styled/sizes';

const StyledCount = styled.div`
  background: ${$basicBlue};
  border: 1px dashed ${$basicGreen};
  border-radius: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 190px;
  padding: 30px 45px;
  position: relative;
  z-index: 999;

  * {
    text-align: center;
  }

  @media ${lgQueryDown} {
    padding: 15px 25px;
    height: 150px;
  }
  // @media ${lgQueryDown} {
  //}
  @media ${smQueryDown} {
    height: 120px;
  }

`;

const AboutCount = ({ item }) => {
    const { unit, description } = item;
    const { locale } = useIntl();
    return (
        <StyledCount>
            <Text variant="bold4" color={$basicDarkGreen}>
                {unit[locale]}
            </Text>

            <Text variant="heading2" color={$basicDarkGreen} className="text-center mt-3">
                {description[locale]}
            </Text>
        </StyledCount>
    );
};

export default AboutCount;
