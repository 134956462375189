import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { getCategoryIcon } from '../../../../utils/getCategoryIcon';
import { addToFavourite, removeFromFavourite } from '../../../../store/actions/productActions';
import { userState } from '../../../../store/reducers/userReducer';
import DetailInfo from '../../../../components/Detail/DetailInfo';

const ProductInfo = ({
                         id, isFavorite, category, name, boxWeight, storageLife,
                     }) => {
    const { role } = useSelector(userState);
    const dispatch = useDispatch();
    const { locale, formatMessage } = useIntl();

    const handleFavourite = () => {
        if (isFavorite) {
            dispatch(removeFromFavourite(id));
            return;
        }
        dispatch(addToFavourite(id));
    };

    const items = [
        {
            title: `${formatMessage({ id: 'boxWeight' })}:`,
            value: formatMessage({
                id: 'kgValue',
            }, {
                value: +boxWeight,
            }),
        },
        {
            title: `${formatMessage({ id: 'productExpirationDate' })}:`,
            value: formatMessage({
                id: 'monthValue',
            }, {
                value: +storageLife,
            }),
        },
    ];

    return (
        <DetailInfo
            onFavourite={handleFavourite}
            withFavourite={role === 'customer'}
            isFavourite={isFavorite}
            icon={getCategoryIcon(category?.logo)}
            categoryName={category?.name[locale]}
            name={name[locale]}
            items={items}
        />
    );
};

export default ProductInfo;
