import { createAsyncThunk } from '@reduxjs/toolkit';
import $api from '../../api/api';
import { prepareFiles } from './utilsActions';

export const userFetch = createAsyncThunk(
    'user/user',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await $api.get('user/');
            return { data };
        } catch (e) {
            return rejectWithValue(e.message);
        }
    },
);

export const userUpdate = createAsyncThunk(
    'user/update',
    async ({ changes }, { rejectWithValue, dispatch, getState }) => {
        try {
            const {
                user: {
                    id,
                    role,
                    documents,
                    activeIndex,
                },
            } = getState();
            console.log('changes', changes);

            let companyDocuments = [];
            try {
                if (!!documents.length) {
                    const files = new FormData();
                    let isNewFiles = false;
                    documents.forEach((document) => {
                        if (!document.uid) {
                            files.append('file', document, document.name);
                            isNewFiles = true;
                        }
                    });
                    if (isNewFiles) {
                        const { payload } = await dispatch(prepareFiles(files));
                        if (payload && !!payload.length) {
                            companyDocuments = [...payload];
                        }
                    }
                }
            } catch (e) {
                console.log(e.message);
            }
            const prepareDocuments = [
                ...companyDocuments,
                ...documents.filter((doc) => doc.uid),
            ];
            const updatedCompanies = [...changes.companies];
            const updatedFactories = updatedCompanies[activeIndex].factories.map((factory) => {
                if (factory.isNew) {
                    return {
                        name: factory.name,
                        address: factory.address,
                    };
                }
                return factory;
            });
            updatedCompanies[activeIndex] = {
                ...updatedCompanies[activeIndex],
                documents: prepareDocuments,
                factories: updatedFactories,
            };

            const updatedData = {
                id,
                role,
                ...changes,
                name: changes.profileName,
                companies: updatedCompanies,
            };

            if (changes.password) {
                updatedData.password = changes.password;
            }

            const response = await $api.put(
                'user/',
                updatedData,
            );
            return { data: response.data };
        } catch (e) {
            console.log(e.message);
            const data = { ...e.response.data };
            return rejectWithValue({ data });
        }
    },
);

export const notificationOptions = createAsyncThunk(
    'user/notification-options',
    async (_, { rejectWithValue }) => {
        try {
            return (await $api.get('/user/notification-options/')).data;
        } catch (e) {
            return rejectWithValue(e.response);
        }
    },
);

export const notificationOptionsEdit = createAsyncThunk(
    'user/notification-options/edit',
    async (data, { rejectWithValue }) => {
        try {
            return (await $api.put('/user/notification-options/', data)).data;
        } catch (e) {
            return rejectWithValue(e.response);
        }
    },
);
