import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { userLogout } from '../../store/actions/authActions';
import useNav from '../hooks/useNav';

const LogoutLink = () => {
    const dispatch = useDispatch();
    const { setPath } = useNav();
    const logoutHandler = () => {
        dispatch(userLogout());
        setPath(null)();
    };
    return (
        <div onClick={logoutHandler}>
            <FormattedMessage id="logoutButtonTitle" />
        </div>
    );
};

export default LogoutLink;
