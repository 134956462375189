import React, { useEffect, useState } from 'react';
import { ReactComponent as IconClose } from '../../svg/close.svg';
import { StyledModal, StyledModalBack, StyledModalShort } from './styles';
import { ReactComponent as ArrowBack } from '../../svg/arrow-back.svg';

export const ModalHeader = ({
                                children,
                                withBack = false,
                                onBack = () => {
                                },
                            }) => (
    <p style={{ fontSize: '28px', fontWeight: '300' }} className="modal__header">
        {withBack ? (
            <StyledModalBack
                type="button"
                onClick={onBack}
            >
                <ArrowBack />
            </StyledModalBack>
        ) : null}
        {children}
    </p>
);

const ModalOuter = ({
                        isModalVisible, toggleModal, title, children, size,
                    }) => {
    const [child, setChild] = useState(null);
    const width = size === 'short' ? 770 : 970;

    const modalProps = {
        width,
        title,
        footer: null,
        onCancel: toggleModal,
        visible: isModalVisible,
        closeIcon: <IconClose />,
    };

    useEffect(() => {
        if (isModalVisible) {
            setChild(children);
        } else {
            setChild(null);
        }
    }, [isModalVisible, children]);

    return (
        size === 'short' ? (
            <StyledModalShort {...modalProps}>{child}</StyledModalShort>
        ) : (
            <StyledModal {...modalProps}>{child}</StyledModal>
        )
    );
};

export default ModalOuter;
