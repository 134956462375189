import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { $basicGreen } from '../../styles/styled/colors';
import useNav from '../hooks/useNav';

const StyledList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  align-items: center;

  li {
    a {
      color: currentColor;
      text-decoration: none;
      margin: 0 30px 15px 0;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.02em;
      transition: .25s ease-in;
      display: flex;
      align-items: center;

      &:hover {
        color: ${$basicGreen};
      }

      &.active {
        color: ${$basicGreen};
      }
    }
  }
`;

const NavMenu = ({ routes, className }) => {
    const { setPath } = useNav();
    return (
        <StyledList className={className}>
            {
                routes.map(({ path, title }) => (
                    <li key={path}>
                        <NavLink to={path} onClick={setPath(path)}>{title}</NavLink>
                    </li>
                ))
            }
        </StyledList>
    );
};

export default NavMenu;
