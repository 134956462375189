import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Radio } from 'antd';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as Close } from '../../svg/close.svg';
import {
    $basicBlack, $basicDarkGreen, $basicGrey, $basicGrey2, $light,
} from '../../styles/styled/colors';
import { authState, clearAuthRegistration, setAuthProperty } from '../../store/reducers/authReducer';
import FormSectionTitle from '../Form/FormSectionTitle';
import FormInput from '../Form/FormInput';
import FormUpload from '../Form/FormUpload';
import { Button, Link, Loader } from '../../ui';
import FormSectionControls from '../Form/FormSectionControls';
import {
    CATEGORY, CHECK_FORM, CUSTOMER, DELIVERY_PORT,
} from '../../utils/consts';
import { userRegister } from '../../store/actions/authActions';
import FormError from '../Form/FormError';
import { categoriesFetch } from '../../store/actions/productActions';
import { productState } from '../../store/reducers/productReducer';
import localesErrors from '../../locales/localesErrors';
import { localeState } from '../../store/reducers/localesReducer';
import validator from './validators';
import { mdQueryDown } from '../../styles/styled/sizes';
import useValidator from '../../utils/hooks/useValidator';
import deliveryPorts from '../../utils/deliveryPorts';

const StyledTitle = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.02em;
  padding: 20px 0 0;
  color: ${$basicBlack};
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
`;
const StyledClose = styled.div`
  color: ${$basicGrey};
  transition: .25s ease-in;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 10px 0 0;

  &:hover {
    color: ${$basicBlack};
  }
`;
const StyledModal = styled(Modal)`
  max-width: 1400px;

  .ant-modal-content, .ant-modal-header {
    background: ${$light};
  }
`;

const StyledModalFooter = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;

  .styled-modal-footer {
    &__text {
      color: ${$basicGrey2};
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      padding: 0;
      @media screen and (max-width: 575px) {
        top: 60px;
        text-align: center;
      }
    }
  }
`;

const StyledCustomerTypeSwitcher = styled.div`
  border: 1px solid ${$basicDarkGreen};
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 40px;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center !important;

  @media ${mdQueryDown} {
    border: 0;
    padding: 0;
  }

  ${({ isMobile }) => isMobile && css`
    border: none;
  `}
  .styled-customer-type-switcher__label {
    position: relative;
    top: -2px;
  }
`;

const Registration = () => {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const { currentLocale } = useSelector(localeState);
    const [company, setCompany] = useState({});
    const [user, setUser] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [globalErrors, setGlobalErrors] = useState({});
    const {
        registrationControls,
        additionalSections, role, isIndividual,
        errors, isLoading,
        mainProductCategoryId,
    } = useSelector(authState);
    const { categories } = useSelector(productState);
    const { checkForm } = useValidator(validator[role]);

    const isMobile = useMediaQuery({ query: mdQueryDown });

    const [customerType, setCustomerType] = useState(1);

    const customTypeSwitchHandler = ({ target: { value } }) => {
        dispatch(setAuthProperty({ property: 'isIndividual', value: value === 2 }));
    };

    const clearFactories = (factories) => {
        const formattedFactories = {};
        Object.values(factories).forEach((factory, idx) => {
            if (factory.name && factory.address) {
                formattedFactories[idx + 1] = { ...factory };
            }
        });
        return formattedFactories;
    };

    const submitHandler = useCallback(() => {
        const currentErrors = checkForm({ ...company, ...user });
        console.log('currentErrors', currentErrors);
        setFormErrors(currentErrors);
        if (!Object.values(currentErrors).length) {
            const currentCompany = { ...company, factories: clearFactories(company.factories) };
            dispatch(userRegister({
                company: currentCompany,
                phone: user.phone,
                password: user.password,
                name: user.profileName,
            }));
        } else {
            setGlobalErrors([[localesErrors[currentLocale][CHECK_FORM]]]);
        }
    }, [user, company, role]);

    const handleCancel = () => {
        dispatch(setAuthProperty({ property: 'regFormOpen', value: false }));
        dispatch(clearAuthRegistration());
    };

    const addSection = (id) => () => {
        const additionalControls = { ...additionalSections };
        const control = registrationControls[role]
            && registrationControls[role].find((registrationControl) => registrationControl.id === id);
        if (!!control) {
            if (additionalControls[control.id]) {
                additionalControls[control.id] = [...additionalControls[control.id], control.controls];
            } else {
                additionalControls[control.id] = [control.controls];
            }
            dispatch(setAuthProperty({
                property: 'additionalSections',
                value: additionalControls,
            }));

            const factories = { ...company.factories };
            const position = Object.keys(factories).length + 1;
            factories[position] = {
                name: '',
                address: '',
            };
            setCompany({ ...company, factories });
        }
    };

    useEffect(() => {
        setCompany({
            ...company,
            factories: {
                1: {
                    name: '',
                    address: '',
                },
            },
        });
    }, []);

    const deleteFactoryHandler = useCallback(
        (index) => {
            const factories = { ...company.factories };
            const currentFactories = {};
            let position = 1;
            Object.keys(factories).forEach((key) => {
                if (+key !== +index) {
                    currentFactories[position] = factories[key];
                    position += 1;
                }
            });
            setCompany({ ...company, factories: currentFactories });

            const addFactories = [...additionalSections.factories].filter((_, idx) => idx !== index - 2);

            dispatch(setAuthProperty({
                property: 'additionalSections',
                value: { ...additionalSections, factories: addFactories },
            }));
        },
        [company, additionalSections],
    );

    const phoneHandler = (value) => value.replace(/[^0-9]/g, '');

    const clearErrors = () => {
        if (!!globalErrors.length) {
            dispatch(setAuthProperty({
                property: 'errors',
                value: [],
            }));
            setGlobalErrors([]);
        }
    };

    const formControlHandler = (controlId, position) => (evt) => {
        const { value } = evt.target;
        const ids = controlId.split('/');
        let companyData = { ...company };
        if (ids.length > 1) {
            let list = { ...companyData[ids[0]] };
            if (!!position) {
                const item = { ...list[position] };
                list[position] = { ...item, [ids[1]]: value };
            } else {
                list = { ...list, [ids[1]]: value };
            }
            companyData = { ...companyData, [ids[0]]: list };
            setCompany({ ...companyData });
        } else {
            setCompany({ ...companyData, [controlId]: value });
        }
        clearErrors();
    };

    const loginDataHandler = (type) => (evt) => {
        const { value } = evt.target;
        let inputValue = value;
        if (type === 'phone') {
            inputValue = phoneHandler(value);
        }
        setUser({ ...user, [type]: inputValue });
        clearErrors();
    };

    const onFocusHandler = () => {
        setFormErrors({});
        clearErrors();
    };

    const documentHandler = (fileList) => {
        dispatch(setAuthProperty({
            property: 'documents',
            value: fileList,
        }));
    };

    const loginOpenHandler = () => {
        dispatch(setAuthProperty({
            property: 'authFormOpen',
            value: true,
        }));
        dispatch(setAuthProperty({
            property: 'regFormOpen',
            value: false,
        }));
    };

    useEffect(() => {
        setCustomerType(isIndividual ? 2 : 1);
    }, [isIndividual]);

    useEffect(() => {
        dispatch(categoriesFetch());
    }, [dispatch]);

    useEffect(() => {
        if (role === CUSTOMER) {
            if (!!categories.length) {
                dispatch(setAuthProperty({
                    property: CATEGORY,
                    value: categories[0].id,
                }));
            }
            if (!!deliveryPorts.length) {
                dispatch(setAuthProperty({
                    property: DELIVERY_PORT,
                    value: deliveryPorts[0].id,
                }));
            }
        }
    }, [categories, dispatch, role, deliveryPorts]);

    useEffect(() => {
        setGlobalErrors(errors);
    }, [errors]);
    return (
        <>
            {
                isLoading && <Loader />
            }
            <StyledModal
                title={(
                    <div className="container px-0">
                        <StyledTitle>
                            <FormattedMessage id="registerFormTitle" />
                            <Link className="me-5 mt-4 mt-lg-0" onClick={loginOpenHandler}>
                                <FormattedMessage id="existAccount" />
                            </Link>
                        </StyledTitle>
                    </div>
                )}
                visible
                onCancel={handleCancel}
                centered
                footer={null}
                width={isMobile ? '96%' : '75%'}
                closeIcon={<StyledClose><Close /></StyledClose>}
                className="position-relative"
            >
                <div className="container px-0">

                    <div
                        className="col-12 mt-5"
                    >
                        <FormInput
                            label={formatMessage({ id: 'nameTitle' })}
                            placeholder={formatMessage({ id: 'namePlaceholder' })}
                            onChange={loginDataHandler('profileName')}
                            value={user.profileName}
                            error={formErrors.profileName}
                            onFocus={onFocusHandler}
                            required
                            role={role}
                        />

                    </div>

                    {
                        role === CUSTOMER
                        && (
                            <StyledCustomerTypeSwitcher
                                isMobile={isMobile}
                                className="mt-5 px-0"
                            >
                                <Radio.Group
                                    onChange={customTypeSwitchHandler}
                                    value={customerType}
                                    className="d-md-flex justify-content-center p-0"
                                    size="large"
                                >
                                    <Radio className="me-md-3" value={1}>
                                    <span className="styled-customer-type-switcher__label">
                                        <FormattedMessage id="company" />
                                    </span>
                                    </Radio>
                                    <Radio className="ms-md-3" value={2}>
                                    <span
                                        className="styled-customer-type-switcher__label"
                                    >
                                         <FormattedMessage id="ip" />
                                    </span>
                                    </Radio>
                                </Radio.Group>
                            </StyledCustomerTypeSwitcher>
                        )
                    }

                    {
                        registrationControls[role]
                        && registrationControls[role].map((registrationControl) => (
                            <div className="px-0" key={registrationControl.id}>
                                <FormSectionTitle
                                    title={<FormattedMessage id={registrationControl.title} />}
                                    isMobile={isMobile}
                                >
                                    {
                                        registrationControl.multiply
                                        && (
                                            <Link onClick={addSection(registrationControl.id)}>
                                                <FormattedMessage id="add" />
                                                &nbsp;
                                                <span style={{ textTransform: 'lowercase' }}>
                                                <FormattedMessage id={registrationControl.title} />
                                                </span>
                                            </Link>
                                        )
                                    }
                                </FormSectionTitle>
                                <div className="d-flex flex-column px-0">
                                    <FormSectionControls
                                        position={registrationControl.position}
                                        controls={registrationControl.controls}
                                        onChange={formControlHandler}
                                        categories={categories}
                                        mainProductCategoryId={mainProductCategoryId}
                                        errors={formErrors}
                                        onFocus={onFocusHandler}
                                    />
                                    {
                                        additionalSections[registrationControl.id]
                                        && additionalSections[registrationControl.id].map((controls, idx) => (
                                            <FormSectionControls
                                                key={idx}
                                                position={idx + 2}
                                                controls={controls}
                                                onChange={formControlHandler}
                                                onFocus={onFocusHandler}
                                                canDelete
                                                deleteHandler={deleteFactoryHandler}
                                                values={company.factories[idx + 2]}
                                            />
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }

                    <div>
                        <FormSectionTitle
                            title={<FormattedMessage id="documents" />}
                            hint={<FormattedMessage id="documentsHint" />}
                            isMobile={isMobile}
                        />
                        <FormUpload onChange={documentHandler} />
                    </div>

                    <div>
                        <FormSectionTitle title={<FormattedMessage id="createPassword" />} isMobile={isMobile} />
                        <div className="d-flex flex-wrap py-4 py-sm-5 px-0">
                            <div
                                className="col-12 col-md-4 col-sm-12 pe-0 ps-0 pe-md-3"
                            >
                                <FormattedMessage id="personPhonePlaceholder">
                                    {
                                        (placeholder) => (
                                            <FormInput
                                                label={<FormattedMessage id="personPhoneLabel" />}
                                                placeholder={placeholder}
                                                onChange={loginDataHandler('phone')}
                                                value={user.phone}
                                                error={formErrors.phone}
                                                onFocus={onFocusHandler}
                                                required
                                                type="phone"
                                                role={role}
                                            />
                                        )
                                    }
                                </FormattedMessage>
                            </div>

                            <div
                                className="col-12 col-md-4 col-sm-6 pt-4 pt-md-0 pe-0 ps-0 pe-sm-3 ps-md-3"
                            >
                                <FormattedMessage id="passwordPlaceholder">
                                    {
                                        (placeholder) => (
                                            <FormInput
                                                label={<FormattedMessage id="passwordLabel" />}
                                                placeholder={placeholder}
                                                type="password"
                                                onChange={loginDataHandler('password')}
                                                value={user.password}
                                                error={formErrors.password}
                                                onFocus={onFocusHandler}
                                                required
                                            />
                                        )
                                    }
                                </FormattedMessage>
                            </div>

                            <div
                                className="col-12 col-md-4 col-sm-6 pt-4 pt-md-0 pe-0 ps-0 ps-sm-3"
                            >
                                <FormattedMessage id="passwordConfirmPlaceholder">
                                    {
                                        (placeholder) => (
                                            <FormInput
                                                label={<FormattedMessage id="passwordConfirmLabel" />}
                                                placeholder={placeholder}
                                                type="password"
                                                onChange={loginDataHandler('confirmPassword')}
                                                value={user.confirmPassword}
                                                error={formErrors.password}
                                                onFocus={onFocusHandler}
                                                required
                                            />
                                        )
                                    }
                                </FormattedMessage>
                            </div>
                        </div>
                    </div>
                    {
                        !!globalErrors.length
                        && <div><FormError errors={globalErrors} className="mb-3 px-0" /></div>
                    }
                    <StyledModalFooter className="pb-3 mb-3 mb-sm-4">
                        <div
                            className="styled-modal-footer__text mt-3 mt-sm-0 col-12 col-lg-4 col-sm-6 order-1 order-sm-0"
                        >
                            <div className="pe-3">
                                <FormattedMessage id="registerAgreement" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col-sm-6 order-0 order-sm-1">
                            <Button
                                onClick={submitHandler}
                                width="100%"
                                title={<FormattedMessage id="registerButtonTitle" />}
                            />
                        </div>
                    </StyledModalFooter>
                </div>
            </StyledModal>
        </>
    );
};

export default Registration;
