import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { ReactComponent as FilterIcon } from '../svg/filter.svg';
import { mdQueryDown } from '../styles/styled/sizes';
import { Button } from '../ui';
import Modal from '../ui/Modal/Modal';

const StyledFilterIcon = styled(FilterIcon)`
  position: absolute;
  top: 15px;
  left: 15px;
`;

const FilterContainer = ({ children, open = false, onToggle }) => {
    const { formatMessage } = useIntl();
    const isMobile = useMediaQuery({ query: mdQueryDown });

    return (
        <>
            {
                isMobile
                    ? (
                        <>
                            <Button
                                title={formatMessage({ id: 'filter' })}
                                width="100%"
                                variant="white"
                                bordered
                                onClick={onToggle}
                            />
                            <Modal
                                className="modal"
                                isModalVisible={open}
                                toggleModal={onToggle}
                            >
                                <StyledFilterIcon />
                                {children}
                            </Modal>
                        </>
                    )
                    : children
            }
        </>
    );
};

export default FilterContainer;
