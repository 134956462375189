import styled from 'styled-components';
import { Modal } from 'antd';
import Button from '../Button';
import { $basicGrey } from '../../styles/styled/colors';
import { mdQueryDown, smQueryDown, xsQueryDown } from '../../styles/styled/sizes';

export const StyledModal = styled(Modal)`
  color: #000;

  .ant-modal {
    &-content {
      padding: 46px 70px 50px 70px;
      margin: 0 auto;
      border-radius: 4px;

      @media ${smQueryDown} {
        padding: 46px 30px 50px 30px;
      }

      @media ${xsQueryDown} {
        padding: 46px 10px 50px 10px;
      }
    }

    &-header {
      padding: 0 0 40px 0 !important;
    }

    &-title {
      color: #000;
    }

    &-body {
      padding: 0;
    }

    &-modal-close-x {
      color: ${$basicGrey};
    }
  }
`;

export const StyledModalShort = styled(StyledModal)`
  .ant-modal {
    &-content {
      padding-left: 100px;
      padding-right: 100px;
      padding-bottom: 35px;

      @media ${mdQueryDown} {
        padding-left: 30px;
        padding-right: 30px;
      }

      @media ${xsQueryDown} {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
`;

export const StyledModalButton = styled(Button)`
  text-transform: uppercase;
  font-size: 16px;

  @media ${smQueryDown} {
    width: 100%;
  }
`;

export const StyledModalBack = styled.button`
  margin-right: 20px;
  position: relative;
  top: -1px;
  color: #000;

  @media ${smQueryDown} {
    display: block;
    margin: -30px 0 10px;
  }

  svg {
    stroke: #000;
  }
`;
