import React from 'react';
import styled from 'styled-components';
import FormUpload from '../../../components/Form/FormUpload';
import { $basicGrey } from '../../../styles/styled/colors';
import { smQueryDown } from '../../../styles/styled/sizes';

export const StyledImages = styled.div`
  margin-bottom: 40px;

  .pictures__info {
    display: flex;
    align-items: center;

    &-subtitle {
      font-weight: 500;
      color: ${$basicGrey};
      margin: 0 0 15px 15px;
    }
  }

  .pictures__content {
    display: flex;

    &-item {
      position: relative;
      width: fit-content;
      height: fit-content;
      margin-right: 16px;

      &-img {
        width: 76px;
        height: 76px;
        border-radius: 4px;
      }

      &-btn {
        position: absolute;
        top: 5px;
        right: 0px;
      }
    }
  }

  @media ${smQueryDown} {
    margin-bottom: 20px;
    .pictures__info {
      flex-direction: column;
      align-items: flex-start;

      &-subtitle {
        margin: 0 0 16px 0;
      }
    }

    .pictures__content {
      flex-wrap: wrap;

      .form-upload {
        &__content {
          width: 70px;
          height: 70px;
        }
      }

      &-item {
        margin-bottom: 10px;

        &-img {
          width: 70px;
          height: 70px;
        }
      }
    }
  }
`;

const ProductImages = ({
                           picArray,
                           title,
                           subtitle,
                           onChange,
                           accept,
                       }) => (
    <StyledImages>
        <div className="pictures__info">
            <p className="modal__title">{title}</p>
            {subtitle && <p className="pictures__info-subtitle">{subtitle}</p>}
        </div>
        <div className="pictures__content">
            <FormUpload
                onChange={onChange}
                files={picArray}
                accept={accept}
            />
        </div>
    </StyledImages>
);

export default ProductImages;
