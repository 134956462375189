import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../../Layouts/Page';
import { notificationsFetch } from '../../../store/actions/notificationActions';
import {
    clearNotifications,
    notificationState,
    setReadCurrentPage,
    setUnreadCurrentPage,
} from '../../../store/reducers/notificationReducer';
import NotificationsList from './components/NotificationsList';
import NotificationsControls from './components/NotificationsControls/NotificationsControls';
import Support from '../../../components/Support/Support';
import { $basicWhite } from '../../../styles/styled/colors';
import Text from '../../../ui/Text';
import Loader from '../../../ui/Loader';
import { notificationOptions } from '../../../store/actions/userActions';
import WeChaiQRStep from './components/WeChaiQRStep';

const Notifications = () => {
    const [stepOneOpen, setStepOneOpen] = useState(false);
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const {
        items,
        unreadCurrentPage,
        readCurrentPage,
        unreadLastPage,
        readLastPage,
        loading,
        loaded,
        // unreadTotal,
    } = useSelector(notificationState);
    const unreadItems = items.filter(({ isRead }) => !isRead);
    const readItems = items.filter(({ isRead }) => isRead);

    const handleShowMoreRead = (e) => {
        e.preventDefault();

        dispatch(setReadCurrentPage(readCurrentPage + 1));
    };

    const handleShowMoreUnread = (e) => {
        e.preventDefault();

        dispatch(setUnreadCurrentPage(unreadCurrentPage + 1));
    };

    const onCancelHandler = () => {
        setStepOneOpen(false);
    };

    useEffect(() => {
        dispatch(notificationsFetch(false));
    }, [unreadCurrentPage]);

    useEffect(() => {
        dispatch(notificationsFetch(true));
    }, [readCurrentPage]);

    useEffect(() => () => {
        dispatch(clearNotifications());
    }, []);

    useEffect(() => {
        dispatch(notificationOptions());
    }, []);

    return (
        <>
            <Page
                withBack
                title={formatMessage({ id: 'notificationsTitle' })}
            >
                <NotificationsControls setStepOneOpen={setStepOneOpen} stepOneOpen={stepOneOpen} />

                {!loading || loaded ? (
                    <>
                        <div className="mb-5">
                            <NotificationsList
                                // title={`${formatMessage({ id: 'unread' })} (${unreadTotal})`}
                                title={`${formatMessage({ id: 'unread' })} (${unreadItems.length})`}
                                items={unreadItems}
                            />
                            {unreadCurrentPage < unreadLastPage && (
                                <a href="#" onClick={handleShowMoreUnread}>
                                    <Text color={$basicWhite}><FormattedMessage id="showMore" /></Text>
                                </a>
                            )}
                        </div>
                        <NotificationsList
                            title={formatMessage({ id: 'read' })}
                            items={readItems}
                        />
                        {readCurrentPage < readLastPage && (
                            <a href="#" onClick={handleShowMoreRead}>
                                <Text color={$basicWhite}><FormattedMessage id="showMore" /></Text>
                            </a>
                        )}
                    </>
                ) : (
                    <Loader active={loading} />
                )}
            </Page>
            <Support />
            {
                stepOneOpen && <WeChaiQRStep onCancel={onCancelHandler} />
            }
        </>
    );
};

export default Notifications;
