import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Support from '../../components/Support/Support';
import { productState, setProductChangeStatus } from '../../store/reducers/productReducer';
import { factoriesFetch, productFetchById } from '../../store/actions/productActions';
import { localeState } from '../../store/reducers/localesReducer';
import { BackBtn } from '../../ui';
import {
    HOME_ROUTE, ON_MODERATION, PRODUCTS_ROUTE,
} from '../../utils/consts';
import ProductModal from './components/ProductModal';
import ProductInfo from './components/ProductInfo/ProductInfo';
import ProductDetails from './components/ProductDetails/ProductDetails';
import DetailSlider from '../../components/Detail/DetailSlider';
import { StyledDetail, StyledDetailHeader } from '../../Layouts/styles';
import Loader from '../../ui/Loader';
import { userState } from '../../store/reducers/userReducer';
import useNav from '../../Layouts/hooks/useNav';

const ProductDetail = () => {
    const { formatMessage } = useIntl();
    const { push } = useHistory();
    const { setPath } = useNav();
    const { role } = useSelector(userState);
    const { currentLocale } = useSelector(localeState);
    const dispatch = useDispatch();
    const { id: pageId } = useParams();
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const { detailedItem, loading, moderationStatus } = useSelector(productState);

    useEffect(() => {
        dispatch(productFetchById(pageId));
    }, [dispatch]);

    useEffect(() => {
        if (!role) return;

        dispatch(factoriesFetch(role));
    }, [dispatch, role]);

    useEffect(() => {
        setPath(`${PRODUCTS_ROUTE}/${pageId}`)();
        if (!role) {
            push(HOME_ROUTE);
        }
    }, [role, pageId]);

    if (!detailedItem) return null;

    const {
        isFavorite,
        category,
        boxWeight,
        storageLife,
        specifications,
        media,
        factory,
        name,
        hasActiveAuctions,
    } = detailedItem;

    const toggleModal = () => {
        dispatch(setProductChangeStatus(false));
        setIsAddModalVisible(!isAddModalVisible);
    };

    window.scrollTo(0, 0);

    return (
        <>
            <StyledDetail className="flex-grow-1">
                <div className="container">
                    <StyledDetailHeader>
                        <BackBtn
                            to={PRODUCTS_ROUTE}
                            title={formatMessage({ id: 'backToProductsPage' })}
                            onClick={setPath(PRODUCTS_ROUTE)}
                        />
                    </StyledDetailHeader>
                    <div>
                        {!loading ? (
                            <>
                                <DetailSlider items={media} />
                                <ProductInfo
                                    id={pageId}
                                    isFavorite={isFavorite}
                                    category={category}
                                    name={name}
                                    boxWeight={boxWeight}
                                    storageLife={storageLife}
                                />
                                <ProductDetails
                                    hasActiveAuctions={hasActiveAuctions}
                                    productId={pageId}
                                    specifications={specifications}
                                    media={media}
                                    factory={factory}
                                    onEdit={toggleModal}
                                    edit={moderationStatus !== ON_MODERATION}
                                />
                            </>
                        ) : (
                            <Loader active={loading} />
                        )}
                    </div>
                </div>
                <ProductModal
                    visible={isAddModalVisible}
                    toggleModal={toggleModal}
                    edit
                    values={{
                        category: category?.id,
                        name: name[currentLocale],
                        weight: boxWeight,
                        factory: factory?.id,
                        media,
                        specifications,
                    }}
                />
            </StyledDetail>
            <Support />
        </>
    );
};

export default ProductDetail;
