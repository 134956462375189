import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import {
    categoriesFetch, countries,
    factoriesFetch,
    productsFetch,
    subcategoriesFetch,
} from '../../store/actions/productActions';
import { productState } from '../../store/reducers/productReducer';
import { userState } from '../../store/reducers/userReducer';
import { userFetch } from '../../store/actions/userActions';
import ProductsPagination from './components/ProductsPagination';
import { mdQueryDown } from '../../styles/styled/sizes';
import Page from '../../Layouts/Page';
import ProductsHeader from './components/ProductsHeader';
import ProductsList from './components/ProductsList';
import Loader from '../../ui/Loader';
import { HOME_ROUTE } from '../../utils/consts';

const Products = () => {
    const { formatMessage } = useIntl();
    const { push } = useHistory();
    const pageTitle = formatMessage({ id: 'productsTitle' });
    const isMobile = useMediaQuery({ query: mdQueryDown });
    const dispatch = useDispatch();
    const { role } = useSelector(userState);
    const {
        moderationStatus,
        isFavouriteStatus,
        currentPage,
        pageSize,
        activeCategory,
        loading,
    } = useSelector(productState);

    useEffect(() => {
        dispatch(userFetch());
    }, []);

    useEffect(() => {
        if (activeCategory) dispatch(productsFetch());
    }, [dispatch, moderationStatus, isFavouriteStatus, currentPage, pageSize, activeCategory]);

    useEffect(() => {
        dispatch(categoriesFetch());
        dispatch(subcategoriesFetch());
        dispatch(countries());
    }, [dispatch]);

    useEffect(() => {
        dispatch(factoriesFetch(role));
    }, [dispatch, role]);

    useEffect(() => {
        if (!role) {
            push(HOME_ROUTE);
        }
    }, [role]);

    return (
        <>
            <Page title={!isMobile ? pageTitle : null}>
                <ProductsHeader />
                {!loading ? (
                    <ProductsList role={role} />
                ) : (
                    <Loader active={loading} />
                )}
            </Page>
            <ProductsPagination />
        </>
    );
};

export default Products;
