import { createAsyncThunk } from '@reduxjs/toolkit';
import qs from 'qs';
import $api from '../../api/api';
import { prepareFiles } from './utilsActions';
import { userFetch } from './userActions';

export const userLogin = createAsyncThunk(
    'user/login',
    async ({ password, login, role }, { rejectWithValue, getState, dispatch }) => {
        try {
            const params = {
                password,
                login,
                role,
            };
            const { data } = await $api.post(
                'auth/login/',
                qs.stringify(params),
            );
            if (data.token) {
                localStorage.setItem('token', data.token);
                dispatch(userFetch());
            }
            return { data };
        } catch (e) {
            const data = { ...e.response.data, locale: getState().locales.currentLocale };
            return rejectWithValue(data);
        }
    },
);

export const userLogout = createAsyncThunk(
    'user/logout',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await $api.post(
                'auth/logout/',
            );
            localStorage.removeItem('token');
            document.location.reload();
            return { data };
        } catch (e) {
            const data = { ...e.response.data };
            return rejectWithValue(data);
        }
    },
);

export const userRegister = createAsyncThunk(
    'user/register',
    async ({
               company, password, phone, name,
           }, { rejectWithValue, dispatch, getState }) => {
        try {
            const {
                auth: {
                    role,
                    isIndividual,
                    documents,
                    mainProductCategoryId,
                    deliveryPort,
                },
            } = getState();

            let companyDocuments = {
                payload: {
                    data: [],
                },
            };
            try {
                if (!!documents.length) {
                    const files = new FormData();
                    documents.forEach((file) => {
                        files.append('file', file, file.name);
                    });
                    companyDocuments = await dispatch(prepareFiles(files));
                }
            } catch (e) {
                console.log(e.message);
            }
            const registerData = {
                role,
                password,
                phone: Number(phone),
                mainProductCategoryId,
                company,
                name,
            };

            registerData.company = { ...registerData.company, primary: true };

            if (company.responsiblePersons) {
                const responsiblePersons = Object.values(company.responsiblePersons);
                if (responsiblePersons[0]) {
                    responsiblePersons[0] = { ...responsiblePersons[0], primary: true };
                }
                registerData.company = { ...registerData.company, responsiblePersons };
            }

            if (company.factories) {
                const factories = Object.values(company.factories);
                registerData.company = { ...registerData.company, factories };
            }

            if (deliveryPort) {
                registerData.company = { ...registerData.company, deliveryPort };
            }

            if (!!companyDocuments.payload.length) {
                registerData.company = { ...registerData.company, documents: companyDocuments.payload };
            }

            registerData.company = { ...registerData.company, isIndividual };
            const response = await $api.post(
                'auth/register/',
                registerData,
            );
            localStorage.setItem('token', response.data.token);
            return { data: response.data };
        } catch (e) {
            const data = { ...e.response.data };
            return rejectWithValue({ data });
        }
    },
);

export const passwordReset = createAsyncThunk(
    'user/passwordReset',
    async ({ phone }, { rejectWithValue }) => {
        try {
            const params = {
                phone,
            };
            const { data } = await $api.post(
                'password-reset/',
                qs.stringify(params),
            );
            return { data };
        } catch (e) {
            const data = { ...e.response.data };
            return rejectWithValue(data);
        }
    },
);

export const passwordResetConfirm = createAsyncThunk(
    'user/passwordResetConfirm',
    async ({ token, password }, { rejectWithValue, getState }) => {
        try {
            const params = {
                token,
                password,
            };
            const { data } = await $api.post(
                'password-reset/confirm/',
                qs.stringify(params),
            );
            return { data };
        } catch (e) {
            const data = { ...e.response.data, locale: getState().locales.currentLocale };
            return rejectWithValue(data);
        }
    },
);
