import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { auctionDetailFetch } from '../../store/actions/auctionsActions';
import { userState } from '../../store/reducers/userReducer';
import AuctionDetailInfo from './components/AuctionDetailInfo';
import { BackBtn, Loader } from '../../ui';
import { AUCTIONS_ROUTE, HOME_ROUTE } from '../../utils/consts';
import { auctionsState, setAddModalVisibility, setAuctionsProperty } from '../../store/reducers/auctionsReducer';
import AuctionsDetailSlider from './components/AuctionsDetailSlider';
import AuctionsEndTimer from './components/AuctionsEndTimer';

import { StyledDetail, StyledDetailHeader } from '../../Layouts/styles';
import AuctionsCreateResult from './components/AuctionsCreateResult';
import Support from '../../components/Support/Support';
import { deliveryStatuses } from '../../store/actions/utilsActions';
import useNav from '../../Layouts/hooks/useNav';

const AuctionDetail = () => {
    const dispatch = useDispatch();
    const { push } = useHistory();
    const { setPath } = useNav();
    const { role } = useSelector(userState);
    const { auction, isNewAuction, isLoading } = useSelector(auctionsState);

    const { id } = useParams();
    // const isMobile = useMediaQuery({ query: lgQueryDown });
    const closeResult = () => {
        dispatch(setAddModalVisibility(false));
        dispatch(setAuctionsProperty({
            property: 'isNewAuction',
            value: false,
        }));
    };
    useEffect(() => {
        if (id && role) {
            dispatch(auctionDetailFetch({ role, id }));
            dispatch(deliveryStatuses());
        }
    }, [dispatch, id, role]);

    useEffect(() => {
        let interval = null;
        if (auction) {
            const { status, isFinished } = auction;
            if (status === 'active') {
                interval = setInterval(() => {
                    dispatch(auctionDetailFetch({ role, id }));
                }, 5000);
            }
            if (status === 'completed' && !isFinished) {
                interval = setInterval(() => {
                    dispatch(auctionDetailFetch({ role, id }));
                }, 1000);
            }
        }
        return () => {
            clearInterval(interval);
        };
    }, [auction]);

    useEffect(() => {
        setPath(`${AUCTIONS_ROUTE}/${id}`)();
        if (!role) {
            push(HOME_ROUTE);
        }
    }, [role, id]);

    return (
        <>
            {
                isLoading && <Loader />
            }
            <StyledDetail className="flex-grow-1">
                <div className="container">
                    <StyledDetailHeader className="d-flex justify-content-between flex-wrap">
                        <BackBtn
                            to={AUCTIONS_ROUTE}
                            title={<FormattedMessage id="backToAuctionsPage" />}
                            onClick={setPath(AUCTIONS_ROUTE)}
                        />
                        {
                            auction && <AuctionsEndTimer auction={auction} />
                        }
                    </StyledDetailHeader>
                    <div>
                        {
                            auction && (
                                <>
                                    <AuctionsDetailSlider auction={auction} />
                                    <AuctionDetailInfo auction={auction} />
                                </>
                            )
                        }
                    </div>
                </div>
                {
                    (isNewAuction && auction) && (
                        <AuctionsCreateResult
                            handleCancel={closeResult}
                            auction={auction}
                        />
                    )
                }
            </StyledDetail>
            <Support />
        </>
    );
};

export default AuctionDetail;
