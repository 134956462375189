import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { productState, setCurrentPage, setPageSize } from '../../../store/reducers/productReducer';
import Pagination from '../../../ui/Pagination/Pagination';

const ProductsPagination = ({ pageType = 'products' }) => {
    const dispatch = useDispatch();
    const {
        total, pageSize, currentPage, items, lastPage,
    } = useSelector(productState);

    const handleJumperChange = (page) => dispatch(setCurrentPage(page));
    const showMore = () => dispatch(setPageSize(pageSize + 15));

    return (
        <Pagination
            pageType={pageType}
            total={total}
            pageSize={pageSize}
            lastPage={lastPage}
            currentPage={currentPage}
            items={items}
            onChange={handleJumperChange}
            showMore={showMore}
        />
    );
};

export default ProductsPagination;
