import { createSlice } from "@reduxjs/toolkit";
import {
	auctionCreate,
	auctionDetailFetch,
	auctionsFetch,
	productsForAuctionList,
	auctionBid,
} from "../actions/auctionsActions";
import { EN, RU, ZH } from "../../locales/localesMap";
import { deliveryStatuses } from "../actions/utilsActions";
import { PERMISSION_DENIED } from "../../utils/consts";
import userErrors from "../../locales/localesErrors";

const initialState = {
	status: "active",
	auctions: [],
	products: [],
	deliveryStatuses: [],
	delivery_status_id: "",
	auction: null,
	customerFilters: {},
	currencyCode: "RUB",
	deliveryTerms: [
		{
			id: "exw",
			title: {
				[RU]: "EXW factory",
				[EN]: "EXW factory",
				[ZH]: "EXW工厂",
			},
			checked: true,
			disabled: false,
		},
		{
			id: "fca",
			title: {
				[RU]: "FCA factory",
				[EN]: "FCA factory",
				[ZH]: "FCA工厂",
			},
			checked: false,
			disabled: false,
		},
		{
			id: "cif",
			title: {
				[RU]: "CIF China",
				[EN]: "CIF China",
				[ZH]: "CIF交货条款",
			},
			checked: false,
			disabled: false,
		},
	],
	validation: [
		{
			id: "productId",
			errors: [
				{
					type: "required",
					errorMessage: {
						[RU]: "Это поле обязательно для заполнения",
						[EN]: "This field is required",
						[ZH]: "This field is required",
					},
				},
			],
		},
		{
			id: "startPricePerTon",
			errors: [
				{
					type: "required",
					errorMessage: {
						[RU]: "Это поле обязательно для заполнения",
						[EN]: "This field is required",
						[ZH]: "This field is required",
					},
				},
			],
		},
		{
			id: "totalWeight",
			errors: [
				{
					type: "required",
					errorMessage: {
						[RU]: "Это поле обязательно для заполнения",
						[EN]: "This field is required",
						[ZH]: "This field is required",
					},
				},
				{
					type: "min",
					value: 0,
					errorMessage: {
						[RU]: "Введите корректное значение",
						[EN]: "Enter the correct value",
						[ZH]: "Enter the correct value",
					},
				},
				{
					type: "notNull",
					errorMessage: {
						[RU]: "Введите корректное значение",
						[EN]: "Enter the correct value",
						[ZH]: "Enter the correct value",
					},
				},
			],
		},
		{
			id: "date",
			errors: [
				{
					type: "required",
					errorMessage: {
						[RU]: "Это поле обязательно для заполнения",
						[EN]: "This field is required",
						[ZH]: "This field is required",
					},
				},
				{
					type: "minDate",
					value: new Date(),
					errorMessage: {
						[RU]: "Введите корректную дату",
						[EN]: "Введите корректную дату",
						[ZH]: "Введите корректную дату",
					},
				},
			],
		},
		{
			id: "startTime",
			errors: [
				{
					type: "required",
					errorMessage: {
						[RU]: "Это поле обязательно для заполнения",
						[EN]: "This field is required",
						[ZH]: "This field is required",
					},
				},
			],
		},
		{
			id: "endTime",
			errors: [
				{
					type: "required",
					errorMessage: {
						[RU]: "Это поле обязательно для заполнения",
						[EN]: "This field is required",
						[ZH]: "This field is required",
					},
				},
			],
		},
		{
			id: "etdShipmentDate",
			errors: [
				{
					type: "required",
					errorMessage: {
						[RU]: "Это поле обязательно для заполнения",
						[EN]: "This field is required",
						[ZH]: "This field is required",
					},
				},
			],
		},
		// {
		//     id: 'etdShipmentDateTo',
		//     errors: [{
		//         type: 'required',
		//         errorMessage: {
		//             [RU]: 'Это поле обязательно для заполнения',
		//             [EN]: 'This field is required',
		//             [ZH]: 'This field is required',
		//         },
		//     },
		//     ],
		// },
	],
	total: 1,
	pageSize: 10,
	currentPage: 1,
	lastPage: 1,
	ordering: "current_price_USD",
	isLoading: false,
	isSuccess: false,
	isNewAuction: false,
	addModalOpen: false,
	formData: null,
	confirmOpen: false,
	bidParams: null,
};

const setPending = (state) => {
	state.isLoading = true;
	state.errors = [];
};
const setRejected = (state, { payload }) => {
	state.isLoading = false;
	if (payload && payload.code === PERMISSION_DENIED) {
		state.errors = [[userErrors[payload.locale][PERMISSION_DENIED]]];
	}
};

const auctionsSlice = createSlice({
	name: "auctions",
	initialState,
	reducers: {
		setAuctionsProperty: (state, { payload }) => {
			state[payload.property] = payload.value;
		},
		setDeliveryTerms: (state, { payload }) => {
			const deliveryTerms = [...state.deliveryTerms].map((checkbox) => ({
				...checkbox,
				checked: false,
			}));
			const termIndex = deliveryTerms.findIndex(
				(term) => term.id === payload.id,
			);
			deliveryTerms[termIndex].checked = true;
			state.deliveryTerms = deliveryTerms;
		},
		setAddModalVisibility: (state, { payload }) => {
			state.addModalOpen = payload;
		},
		clearAuction: (state) => {
			state.auction = null;
		},
		setCurrency: (state, { payload }) => {
			state.currencyCode = payload;
		},
		switchSort: (state, { payload }) => {
			state.ordering = payload;
		},
		setConfirmOpen: (state, { payload }) => {
			state.confirmOpen = payload;
		},
		setBidParams: (state, { payload }) => {
			state.bidParams = payload;
		},
	},
	extraReducers: {
		[auctionsFetch.pending]: setPending,
		[auctionsFetch.rejected]: setRejected,
		[auctionsFetch.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.auctions = payload.data.items;
			state.total = payload.data.total;
			state.pageSize = payload.data.pageSize;
			state.currentPage = payload.data.currentPage;
			state.lastPage = payload.data.lastPage;
		},

		[auctionDetailFetch.pending]: setPending,
		[auctionDetailFetch.rejected]: setRejected,
		[auctionDetailFetch.fulfilled]: (state, { payload }) => {
			state.auction = payload.data;
			state.isLoading = false;
		},

		[auctionCreate.pending]: setPending,
		[auctionCreate.rejected]: setRejected,
		[auctionCreate.fulfilled]: (state, { payload }) => {
			state.auction = payload.data;
			state.isLoading = false;
			state.isNewAuction = true;
			state.addModalOpen = false;
		},

		[productsForAuctionList.fulfilled]: (state, { payload }) => {
			state.products = payload.data.items;
		},
		[deliveryStatuses.fulfilled]: (state, { payload }) => {
			state.deliveryStatuses = payload.data.items;
		},
		[auctionBid.pending]: setPending,
		[auctionBid.rejected]: setRejected,
		[auctionBid.fulfilled]: (state) => {
			state.confirmOpen = false;
			state.isLoading = false;
			state.bidParams = null;
		},
	},
});

export const {
	setAuctionsProperty,
	setDeliveryTerms,
	setAddModalVisibility,
	clearAuction,
	setCurrency,
	switchSort,
	setConfirmOpen,
	setBidParams,
} = auctionsSlice.actions;
export const auctionsState = (state) => state.auctions;
export default auctionsSlice.reducer;
