import React, { useEffect, useRef, useState } from 'react';

const readFile = (file) => new Promise((resolve) => {
    if (file.type.indexOf('image/') > -1) {
        const fr = new FileReader();

        fr.onloadend = () => {
            resolve({
                name: file.name,
                type: file.type,
                src: fr.result,
            });
        };

        fr.readAsDataURL(file);
    } else {
        resolve({
            name: file.name,
            type: file.type,
        });
    }
});

const FileUploader = ({
                          render,
                          multiple = false,
                          max = 0,
                          onChange,
                          onLoad,
                          accept,
                      }) => {
    const [currentFiles, setCurrentFiles] = useState([]);
    const [uploaded, setUploaded] = useState([]);
    const ref = useRef();

    const handleChange = (e) => {
        const pr = Array.from(e.target.files).map((file) => readFile(file));

        Promise.all(pr).then((result) => {
            setUploaded([...uploaded, ...result]);
            setCurrentFiles([...currentFiles, ...Array.from(e.target.files)]);
        });
    };

    const onOpen = () => {
        if (!ref.current) return;

        ref.current.dispatchEvent(new MouseEvent('click', { bubbles: true }));
    };

    const onRemove = (index) => {
        setUploaded(uploaded.filter((item, i) => index !== i));
        setCurrentFiles(currentFiles.filter((item, i) => index !== i));
    };

    useEffect(() => {
        if (onChange) onChange(currentFiles);
    }, [currentFiles]);

    useEffect(() => {
        if (onLoad) onLoad(uploaded);
    }, [uploaded]);

    return (
        <>
            <input
                ref={ref}
                type="file"
                multiple={multiple}
                onChange={handleChange}
                max={max}
                style={{
                    display: 'none',
                }}
                accept={accept}
            />
            {!!render && render({ onOpen, onRemove })}
        </>
    );
};

export default FileUploader;
