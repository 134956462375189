import React from 'react';
import styled, { css } from 'styled-components';
import { $basicBlack } from '../styles/styled/colors';
import { lgQueryDown, mdQueryDown, smQueryDown } from '../styles/styled/sizes';

const StyledText = styled.div`
  color: ${({ color }) => color};
  font-weight: normal;
  font-style: normal;

  ${({ variant }) => variant === 'normal' && css`
    font-size: 14px;
    line-height: 16px;
  `}

  ${({ variant }) => variant === 'small' && css`
    font-size: 10px;
    line-height: 12px;
  `}

  ${({ variant }) => variant === 'default' && css`
    font-size: 12px;
    line-height: 14px;
  `}

  ${({ variant }) => variant === 'big' && css`
    font-size: 22px;
    line-height: 26px;

    @media ${smQueryDown} {
      font-size: 16px;
      line-height: 22px;
    }
  `}

  ${({ variant }) => variant === 'heading' && css`
    font-size: 14px;
    line-height: 20px;
  `}

  ${({ variant }) => variant === 'heading2' && css`
    font-size: 18px;
    line-height: 21px;

    @media ${mdQueryDown} {
      font-size: 16px;
    }
  `}

  ${({ variant }) => variant === 'heading3' && css`
    font-size: 26px;
    line-height: 30px;
  `}

  ${({ variant }) => variant === 'body2' && css`
    font-size: 16px;
    line-height: 19px;
  `}

  ${({ variant }) => variant === 'bold' && css`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
  `}

  ${({ variant }) => variant === 'bold2' && css`
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
  `}

  ${({ variant }) => variant === 'bold3' && css`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  `}

  ${({ variant }) => variant === 'bold4' && css`
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    // @media ${lgQueryDown} {
    //   font-size: 24px;
    //   line-height: 30px;
    // }
  `}
  ${({ variant }) => variant === 'bold5' && css`
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
  `}

  ${({ underline = false }) => underline && css`
    text-decoration: underline;
  `}

  ${({ align }) => align && css`
    text-align: ${align};
  `}
`;

const Text = ({
                  variant = 'default',
                  underline = false,
                  children,
                  className = '',
                  color = $basicBlack,
                  style,
                  align,
              }) => (
    <StyledText
        variant={variant}
        className={className}
        color={color}
        style={style}
        underline={underline}
        align={align}
    >
        {children}
    </StyledText>
);

export default Text;
