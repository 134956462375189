import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Badge } from 'antd';
import { ReactComponent as Bell } from '../../svg/bell.svg';
import { PERSONAL_NOTIFICATIONS_ROUTE } from '../../utils/consts';
import { $basicGreen } from '../../styles/styled/colors';
import Lang from '../../ui/Lang/Lang';
import Phone from '../../components/Phone';
import { userState } from '../../store/reducers/userReducer';
import { mdQueryDown } from '../../styles/styled/sizes';
import { notificationState } from '../../store/reducers/notificationReducer';
import HeaderPersonalSelect from './HeaderPersonalSelect';

const StyledLink = styled(NavLink)`
  color: currentColor;
  transition: .25s ease-in;

  &:hover {
    color: ${$basicGreen};
  }
`;

const HeaderUser = () => {
    const { id, profileName } = useSelector(userState);
    const { unreadTotal } = useSelector(notificationState);
    const isMobile = useMediaQuery({ query: mdQueryDown });

    return (
        <div className="flex-grow-1 d-flex justify-content-end align-items-center">
            {
                id && (
                    <>
                        <HeaderPersonalSelect profileName={profileName} />
                        <StyledLink
                            to={PERSONAL_NOTIFICATIONS_ROUTE}
                            className="styled-phone__link me-4"
                        >
                            <Badge count={unreadTotal}>
                                <Bell />
                            </Badge>
                        </StyledLink>
                    </>
                )
            }
            {!isMobile ? (
                <>
                    <Phone />
                    <Lang variant="inline" className="ms-3" />
                </>
            ) : null}
        </div>
    );
};

export default HeaderUser;
