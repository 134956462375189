import { EN, RU, ZH } from './localesMap';
import {
    APPROVED,
    ON_MODERATION,
} from '../utils/consts';

export default {
    [RU]: {
        [ON_MODERATION]: 'Пользователь на модерации',
        [APPROVED]: 'Пользователь активен',
    },
    [EN]: {
        [ON_MODERATION]: 'User on moderation',
        [APPROVED]: 'The user is active',
    },
    [ZH]: {
        [ON_MODERATION]: 'User on moderation',
        [APPROVED]: '用户处于活动状态',
    },
};
