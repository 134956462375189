import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map';
import { localeState } from '../../../../../store/reducers/localesReducer';
import { Button } from '../../../../../ui';
import Text from '../../../../../ui/Text';
import {
    $basicBlack,
    $basicDarkGreen,
    $basicGreen,
    $basicGrey2,
    $basicRed,
} from '../../../../../styles/styled/colors';
import { AUCTIONS_ROUTE, PRODUCTS_ROUTE, SELLER } from '../../../../../utils/consts';
import {
    StyledDescription, StyledHeader, StyledPaper, StyledText,
} from './styles';
import { userState } from '../../../../../store/reducers/userReducer';
import { getImagePath } from '../../../../../utils/getImagePath';

const NotificationsItem = ({ item }) => {
    const { formatMessage } = useIntl();
    const { role } = useSelector(userState);
    const { currentLocale } = useSelector(localeState);
    const history = useHistory();
    const {
        name,
        description,
        code,
        subject,
        subjectId,
        createdAt,
    } = item;
    const date = moment(createdAt).format('DD.MM HH:mm');
    const product = subject?.product || subject;

    let button;
    let nameColor = $basicDarkGreen;
    let text = '';

    // eslint-disable-next-line no-shadow
    const getStartPrice = (role, { startPricePerTonUSD, startPricePerTonCNY }) => (
        role === SELLER ? startPricePerTonUSD : startPricePerTonCNY
    );

    // eslint-disable-next-line no-shadow
    const getStartPriceText = (role, { startPricePerTonUSD, startPricePerTonCNY }) => {
        const startPrice = getStartPrice(role, { startPricePerTonUSD, startPricePerTonCNY });

        if (!startPrice) return null;

        const currencySymbol = getSymbolFromCurrency(role === SELLER ? 'USD' : 'CNY');
        return `${formatMessage({ id: 'moneyPerTon' }, { value: startPrice, currency: currencySymbol })}`;
        // return `${startPrice} ${currencySymbol}`;
    };

    // eslint-disable-next-line no-shadow
    const getCurrentPrice = (role, { currentPriceUSD, currentPriceCNY }) => (
        role === SELLER ? currentPriceUSD : currentPriceCNY
    );

    // eslint-disable-next-line no-shadow
    const getCurrentPriceText = (role, { currentPriceUSD, currentPriceCNY }) => {
        const currentPrice = getCurrentPrice(role, { currentPriceUSD, currentPriceCNY });

        if (!currentPrice) return null;

        const currencySymbol = getSymbolFromCurrency(role === SELLER ? 'USD' : 'CNY');
        return `${formatMessage({ id: 'moneyPerTon' }, { value: currentPrice, currency: currencySymbol })}`;
    };

    const handleViewProduct = () => {
        if (!subjectId) return;

        history.push(`${PRODUCTS_ROUTE}/${subjectId}`);
    };

    const handleViewAuction = () => {
        if (!subjectId) return;

        history.push(`${AUCTIONS_ROUTE}/${subjectId}`);
    };

    const handleViewProducts = () => {
        history.push(PRODUCTS_ROUTE);
    };
    const handleViewAuctions = () => {
        history.push(AUCTIONS_ROUTE);
    };

    const productDetailBtn = subjectId
        ? (
            <Button onClick={handleViewProduct} className="w-100">
                <FormattedMessage id="view" />
            </Button>
        ) : null;
    const productsBtn = (
        <Button onClick={handleViewProducts} className="w-100">
            <FormattedMessage id="toProducts" />
        </Button>
    );
    const auctionDetailBtn = subjectId
        ? (
            <Button onClick={handleViewAuction} className="w-100">
                <FormattedMessage id="toAuction" />
            </Button>
        ) : null;
    const auctionsBtn = (
        <Button onClick={handleViewAuctions} className="w-100">
            <FormattedMessage id="toAuctions" />
        </Button>
    );
    const categoryText = product?.category?.name[currentLocale];
    const nameText = product?.name[currentLocale];
    let startPrice = '';
    let currentPrice = '';
    let startPriceText = '';
    let currentPriceText = '';
    if (subject) {
        startPrice = getStartPrice(role, subject);
        currentPrice = getCurrentPrice(role, subject);
        startPriceText = !!startPrice && `${formatMessage({ id: 'startPrice' })}: ${getStartPriceText(role, subject)}`;
        currentPriceText = !!currentPrice && `${formatMessage({ id: 'finalPrice' })}: ${getCurrentPriceText(role, subject)}`;
    }

    switch (code) {
        case 'product_moderation_denied':
            nameColor = $basicRed;
            button = productsBtn;
            break;
        case 'product_moderation_approved':
            nameColor = $basicGreen;
            button = productDetailBtn;
            text = (
                <StyledText variant="normal" color={$basicGrey2}>
                    {!!categoryText && <div>{categoryText}</div>}
                    {!!nameText && <div>{nameText}</div>}
                </StyledText>
            );
            break;
        case 'auction_moderation_denied':
            nameColor = $basicRed;
            button = auctionsBtn;
            break;
        case 'auction_moderation_approved':
            nameColor = $basicGreen;
            button = auctionDetailBtn;
            text = (
                <StyledText variant="normal" color={$basicGrey2}>
                    {!!categoryText && <div>{categoryText}</div>}
                    {!!nameText && <div>{nameText}</div>}
                    {!!startPriceText && <div>{startPriceText}</div>}
                </StyledText>
            );
            break;
        case 'profile_moderation_denied':
            nameColor = $basicRed;
            break;
        case 'profile_moderation_approved':
            nameColor = $basicRed;
            break;
        case 'auction_defeat':
            nameColor = $basicRed;
            button = auctionDetailBtn;
            text = (
                <StyledText variant="normal" color={$basicGrey2}>
                    {!!categoryText && <div>{categoryText}</div>}
                    {!!nameText && <div>{nameText}</div>}
                    {!!startPriceText && <div>{startPriceText}</div>}
                </StyledText>
            );
            break;
        case 'auction_won':
        case 'auction_invoice':
            button = auctionDetailBtn;
            text = (
                <StyledText variant="normal" color={$basicBlack}>
                    {!!categoryText && <div>{categoryText}</div>}
                    {!!nameText && <div>{nameText}</div>}
                    {!!startPriceText && <div>{startPriceText}</div>}
                    {!!currentPriceText
                        && <div><Text variant="normal" color={$basicRed}>{currentPriceText}</Text></div>}
                    {
                        subject && subject.adminAccount
                        && (
                            <div>
                                <a
                                    href={getImagePath({
                                        path: subject.adminAccount.path,
                                        uid: subject.adminAccount.uid,
                                        extension: subject.adminAccount.extension,
                                    })}
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <Text variant="normal" underline color={$basicDarkGreen}>
                                        <FormattedMessage id="getInvoice" />
                                    </Text>
                                </a>

                            </div>
                        )
                    }
                </StyledText>
            );
            break;
        case 'auction_planned':
            button = auctionDetailBtn;
            text = (
                <StyledText variant="normal" color={$basicGrey2}>
                    {!!categoryText && <div>{categoryText}</div>}
                    {!!nameText && <div>{nameText}</div>}
                    {!!startPriceText && <div>{startPriceText}</div>}
                </StyledText>
            );
            break;
        default:
    }
    const auctionCodes = [
        'auction_planned',
        'auction_moderation_approved',
    ];
    const isAuction = auctionCodes.includes(code);
    return (
        <StyledPaper>
            <div className="w-100">
                <div className="row">
                    <div className="col-lg-9">
                        <StyledHeader className="d-flex flex-wrap">
                            <Text
                                variant="bold"
                                color={$basicGrey2}
                                className="me-md-3 me-0 ms-md-0 ms-auto order-md-0 order-last"
                            >{date}
                            </Text>
                            <Text
                                variant="bold"
                                color={nameColor}
                                className="order-md-0 order-first me-md-3"
                            >{name[currentLocale]}
                            </Text>
                            {
                                subject && isAuction && (
                                    <Text
                                        variant="bold"
                                        color={$basicDarkGreen}
                                        className="order-md-0 order-first my-2 my-sm-0"
                                    >
                                        {formatMessage({ id: 'startDate' })} № {subject.id}:
                                        &nbsp;
                                        {moment(subject.startDate).format('YYYY.MM.DD')}
                                    </Text>
                                )
                            }
                            <StyledDescription>
                                {description[currentLocale] && (
                                    <Text
                                        variant="normal"
                                        color={$basicGrey2}
                                        className=""
                                    >{description[currentLocale]}
                                    </Text>
                                )}
                            </StyledDescription>
                        </StyledHeader>
                        <div className="d-flex">{text}</div>
                    </div>
                    {button && (
                        <div className="col-md-3 col-sm-4 mt-lg-0 mt-4 d-flex align-items-center">{button}</div>
                    )}
                </div>
            </div>
        </StyledPaper>
    );
};

export default NotificationsItem;
