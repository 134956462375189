import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { Select } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { localeState, updateLocales } from '../../store/reducers/localesReducer';
import { CUSTOMER, LANGUAGES } from '../../utils/consts';
import { $basicGreen, $basicGrey } from '../../styles/styled/colors';
import { ReactComponent as ArrowDown } from '../../svg/arrow-down.svg';
import { mdQueryDown } from '../../styles/styled/sizes';
import { userState } from '../../store/reducers/userReducer';
import { RU, ZH } from '../../locales/localesMap';

const StyledLang = styled.div`
  display: flex;
  justify-content: flex-end;

  * {
    color: currentColor;
  }

  .ant-select-arrow {
    margin-top: -8px;
  }
`;

const StyledLink = styled.div`
  cursor: pointer;
  padding: 0 0 0 8px;
  font-size: 16px;
  line-height: 19px;
  ${({ active }) => active && css`
    color: ${$basicGreen};
  `}
  ${({ border }) => border && css`
    border-right: 1px solid ${$basicGrey};
    padding: 0 8px;
  `}
`;

const Lang = ({ variant = 'select', className = '' }) => {
    const isMobile = useMediaQuery({ query: mdQueryDown });
    const dispatch = useDispatch();
    const { currentLocale } = useSelector(localeState);
    const { role } = useSelector(userState);
    const langHandler = (value) => {
        dispatch(updateLocales(value));
        localStorage.setItem('locale', value);
    };
    useEffect(() => {
        if (role) {
          langHandler(role === CUSTOMER ? ZH : RU);
        }
    }, [role]);

    return (
        <StyledLang key={currentLocale} className={className}>
            {
                variant === 'inline'
                    ? (
                        <div className="d-flex">
                            {LANGUAGES.map(({ name, code }, index) => (
                                <StyledLink
                                    key={code}
                                    active={code === currentLocale}
                                    border={!!LANGUAGES[index + 1]}
                                    onClick={() => langHandler(code)}
                                >
                                    {name}
                                </StyledLink>
                            ))}
                        </div>
                    )
                    : (
                        <Select
                            defaultValue={currentLocale}
                            bordered={false}
                            onChange={langHandler}
                            className="ms-4"
                            style={{ width: 'auto', maxWidth: '84px' }}
                            suffixIcon={<ArrowDown />}
                            dropdownAlign={{
                                offset: [0, isMobile ? -150 : 0],
                            }}
                        >
                            {LANGUAGES.map(({ name, code }) => (
                                <Select.Option key={code} value={code}>{name}</Select.Option>
                            ))}
                        </Select>
                    )
            }

        </StyledLang>
    );
};

export default Lang;
