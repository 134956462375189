import { Image } from 'antd';
import React from 'react';
import cx from 'classnames';
import { StyledDetailsCard } from './style';
import { getImagePath } from '../../../utils/getImagePath';
import getFileIcon from '../../../utils/getFileIcon';
import linkClick from '../../../utils/linkClick';

const DetailsCards = ({ title, cards, className }) => (
    <StyledDetailsCard className={cx('details__card', className)}>
        <div className="details__title">{title}</div>
        <div className="details__specs">
            <Image.PreviewGroup>
                {cards?.map(({
                                 path,
                                 uid,
                                 extension,
                                 mimetype,
                             }) => (
                    <button
                        key={uid}
                        className="details__specs-btn"
                        type="button"
                        onClick={() => {
                            if (mimetype.indexOf('image/') < 0) {
                                linkClick(getImagePath({ path, uid, extension }));
                            }
                        }}
                    >
                        {mimetype.indexOf('image/') > -1 ? (
                            <Image
                                src={getImagePath({ path, uid, extension })}
                                preview={getImagePath({ path, uid, extension })}
                                className="details__specs-img"
                            />
                        ) : (
                            <div className="details__specs-img">{getFileIcon(mimetype)}</div>
                        )}
                    </button>
                ))}
            </Image.PreviewGroup>
        </div>
    </StyledDetailsCard>
);

export default DetailsCards;
