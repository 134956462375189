import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { $basicDarkGreen, $basicGrey } from '../../../styles/styled/colors';
import { ReactComponent as Pdf } from '../../../svg/pdf.svg';

const StyledCardInfoMiddle = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 14px;
  padding-bottom: 14px;
  border-bottom: 1px dashed ${$basicGrey};

  .info {
    &__specifications {
      margin-bottom: 3px;
    }

    &__middle-row {
      display: flex;
      justify-content: space-between;
      padding-top: 14px;
      border-bottom: 1px dashed ${$basicGrey};
    
      &-left-col {
        max-width: 60%;
        width: 100%;
        display: flex;
        flex-direction: column;

        @media (max-width: 320px) {
          max-width: 60%;
        }
      }

      &-right-col {
        max-width: 35%;
        width: 100%;
        display: flex;
        flex-direction: column;

        @media (max-width: 320px) {
          max-width: 35%;
        }
      }

      &-item {
        display: flex;
        align-items: center;
        margin-bottom: 14px;

        &:first-child {
          color: ${$basicDarkGreen};
        }

        &:last-child {
          margin-bottom: 0;
          margin-top: auto;

          p {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        &.info__specifications {
          margin-bottom: 3px;
        }

        .icon-pdf,
        .icon-chicken {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          margin-right: 10px;
        }

        &-inner {
          display: flex;
          flex-direction: column;
        }

        &_columned {
          flex-direction: column;
          align-items: baseline;
        }
      }
    }
  }
`;

const CardInfoMiddle = ({
                            categoryIcon,
                            categoryName,
                            name,
                            weight,
                            expiration,
                            style,
                            className,
                        }) => (
    <StyledCardInfoMiddle style={style} className={className}>
        <div className="info__middle-row-left-col">
            <div className="info__middle-row-item">
                <div className="icon-chicken">
                    {categoryIcon}
                </div>
                <div className="info__middle-row-item-inner">
                    <p className="info__title">{categoryName}</p>
                    <p className="info__description">{name}</p>
                </div>
            </div>
            <div className="info__middle-row-item info__specifications">
                <div className="icon-pdf">
                    <Pdf className="info__middle-row-item-icon" />
                </div>
                <p className="info__title-small">
                    <FormattedMessage id="productSpecification" />
                </p>
            </div>
        </div>
        <div className="info__middle-row-right-col">
            <div className="info__middle-row-item info__middle-row-item_columned">
                <p className="info__title">
                    <FormattedMessage id="boxWeight" />
                </p>
                <p className="info__description">
                    <FormattedMessage
                        id="kgValue"
                        values={{ value: weight }}
                    />
                </p>
            </div>
            <div className="info__middle-row-item info__middle-row-item_columned">
                <p className="info__title-small">
                    <FormattedMessage id="productExpirationDate" />
                </p>
                <p className="info__description-small">
                    <FormattedMessage
                        id="monthValue"
                        values={{ value: expiration }}
                    />
                </p>
            </div>
        </div>
    </StyledCardInfoMiddle>
);

export default CardInfoMiddle;
