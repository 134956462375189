import React from 'react';
import VideoPlayer from 'react-video-js-player';
// import is from 'is_js';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { pageState } from '../../../store/reducers/pageReducer';
import { ZH } from '../../../locales/localesMap';

const StyledVideo = styled.div`
  position: relative;
  padding-bottom: 56.25%; /*пропорции видео 16:9 */
  //padding-top: 25px;
  padding-top: 0;
  height: 0;

  .video-js {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .vjs-poster {
      background-size: cover;
    }
  }
`;

const AboutVideos = () => {
    const { locale } = useIntl();
    const {
        videos,
    } = useSelector(pageState);
    if (!videos.length) {
        return null;
    }

    const { src, poster } = videos[0];
    return (
        <StyledVideo key={locale || ZH}>
            <VideoPlayer
                controls
                src={src[locale]}
                poster={poster[locale] || poster[ZH]}
            />
        </StyledVideo>
    );
};

export default AboutVideos;
