import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import styled from 'styled-components';
import { switchSort } from '../../../store/reducers/auctionsReducer';
import { userState } from '../../../store/reducers/userReducer';
import { CUSTOMER } from '../../../utils/consts';
import { ReactComponent as SelectArrow } from '../../../svg/select-arrow.svg';
import { $basicWhite } from '../../../styles/styled/colors';

const StyledSelect = styled(Select)`
  .ant-select-selection-item {
    font-weight: 500;
    color: ${$basicWhite};
  }

  &.ant-select-open {
    .ant-select-selection-item {
      color: ${$basicWhite};
    }
  }

  svg {
    position: relative;
    top: -2px;

    * {
      stroke: ${$basicWhite};
    }
  }
`;

const AuctionSort = () => {
    const dispatch = useDispatch();
    const { role } = useSelector(userState);

    const PRICE_SORT = role === CUSTOMER
        ? 'extra_current_price_CNY'
        : 'current_price_CNY';

    const [activeSorting, setActiveSorting] = useState(PRICE_SORT);

    const sortHandler = useCallback((value) => {
        setActiveSorting(value);
        dispatch(switchSort(value));
    }, [activeSorting]);

    return (
        <div className="d-flex align-items-center justify-content-end flex-wrap mt-3">
            <StyledSelect
                bordered={false}
                value={activeSorting}
                onChange={sortHandler}
                suffixIcon={<SelectArrow />}
                dropdownClassName="custom-dropdown"
                className="mt-1 mt-md-5"
                dropdownMatchSelectWidth={false}
            >
                <Select.Option value="start_date">
                    <FormattedMessage id="sortByDateUp" />
                </Select.Option>
                <Select.Option value="-start_date">
                    <FormattedMessage id="sortByDateDown" />
                </Select.Option>
                <Select.Option value={PRICE_SORT}>
                    <FormattedMessage id="sortByPriceUp" />
                </Select.Option>
                <Select.Option value={`-${PRICE_SORT}`}>
                    <FormattedMessage id="sortByPriceDown" />
                </Select.Option>
            </StyledSelect>
        </div>
    );
};

export default AuctionSort;
