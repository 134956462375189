import React, { useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Image } from 'antd';
import Slider from 'react-slick';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Left } from '../../svg/left.svg';
import { ReactComponent as Right } from '../../svg/right.svg';
import { $basicGrey, $basicWhite } from '../../styles/styled/colors';
import { mdQueryDown, xsQueryDown } from '../../styles/styled/sizes';

/* eslint-disable import/no-duplicates */
import slide1 from '../../img/slides/1.png';
import slide2 from '../../img/slides/2.png';
import slide3 from '../../img/slides/3.png';
import slide4 from '../../img/slides/4.png';
import slide5 from '../../img/slides/8.png';
import slide6 from '../../img/slides/1.png';
import slide7 from '../../img/slides/2.png';
import slide8 from '../../img/slides/3.png';
import slide9 from '../../img/slides/4.png';
import slide10 from '../../img/slides/8.png';

const slides = Array.from(Array(10).keys());

const StyledSlider = styled.div`
  padding-top: 135px;
  overflow: hidden;

  .styled-slider {
    &__title {
      font-family: Montserrat, serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: ${$basicWhite};
      margin: 0 0 20px;
      text-align: center;

      &-mobile {
        text-align: left;
        margin-top: 10%;
      }
    }

    &__slide {
      padding: 0 31px;
      display: flex;
      align-items: center;

      ${({ isMobile }) => isMobile && css`
        padding-left: 0;
      `}
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 46px;

        ${({ isMobile }) => isMobile && css`
          height: 32px;
        `}
        ${({ isMobileXs }) => isMobileXs && css`
          height: 24px;
        `}
        img {
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
        }
      }
    }

    &__arrows {
      display: flex;
      width: 100px;
      justify-content: space-between;
      margin: 15px auto;
    }

    &__arrow {
      color: ${$basicWhite};
      transition: .25s ease-in;

      &:hover {
        color: ${$basicGrey};
      }
    }
  }

  ${({ isMobile }) => isMobile && css`
    padding-top: 8.75vh;

    .slick-list {
      overflow: visible;
    }
  `}
`;

const pictures = [slide1, slide2, slide3, slide4, slide5, slide6,
    slide7, slide8, slide9, slide10];

export const mediaByIndex = (index) => pictures[index % pictures.length];

const HomeSlider = () => {
    const sliderRef = useRef();
    const isMobile = useMediaQuery({ query: mdQueryDown });
    const isMobileXs = useMediaQuery({ query: xsQueryDown });
    const settings = {
        autoplay: true,
        infinite: true,
        speed: 3000,
        centerMode: !isMobile,
        variableWidth: true,
        draggable: true,
        arrows: false,
    };

    const prevHandler = () => {
        if (sliderRef) {
            sliderRef.current.slickPrev();
        }
    };

    const nextHandler = () => {
        if (sliderRef) {
            sliderRef.current.slickNext();
        }
    };

    const getSlider = () => (
        <Slider {...settings} ref={sliderRef}>
            {slides.map((idx) => (
                <div key={idx} className="styled-slider__slide">
                    <Image
                        src={mediaByIndex(idx)}
                        preview={false}
                    />
                </div>
            ))}
        </Slider>
    );

    return (
        <StyledSlider
            isMobile={isMobile}
            isMobileXs={isMobileXs}
        >
            <div className="container">
                <div className={`styled-slider__title px-lg-3 ${isMobile ? 'styled-slider__title-mobile' : ''}`}>
                    <FormattedMessage id="homeSliderTitle" />
                </div>
            </div>
            {isMobile ? (
                <div className="container">{getSlider()}</div>
            ) : getSlider()}
            {
                !isMobile && (
                    <div className="styled-slider__arrows d-none">
                        <div
                            className="styled-slider__arrow styled-slider__arrow_left"
                            role="button"
                            onClick={prevHandler}
                        >
                            <Left />
                        </div>
                        <div
                            className="styled-slider__arrow styled-slider__arrow_right"
                            role="button"
                            onClick={nextHandler}
                        >
                            <Right />
                        </div>
                    </div>
                )
            }
        </StyledSlider>
    );
};

export default HomeSlider;
