import React from 'react';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { HOME_ROUTE } from '../utils/consts';
import { mdQueryDown } from '../styles/styled/sizes';

const StyledHum = styled.div`
  cursor: pointer;

  span.line {
    width: 30px;
    height: 1px;
    background-color: #000;
    display: block;
    margin: 8px auto;
    transition: all 0.3s ease-in-out;

    @media ${mdQueryDown} {
      width: 24px;
    }
  }

  span.line:nth-child(2) {
    width: 20px;
    position: relative;
    left: 5px;

    @media ${mdQueryDown} {
      width: 13px;
    }
  }

  ${({ isHome }) => isHome && css`
    span.line {
      background: #fff;
    }
  `}

  ${({ isOpen }) => isOpen && css`
    transition: all 0.3s ease-in-out;
    transition-delay: 0.6s;
    transform: rotate(45deg);

    span.line {
      background-color: #000;
    }

    span.line:nth-child(2) {
      width: 0;
    }

    span.line:nth-child(1),
    span.line:nth-child(3) {
      transition-delay: 0.3s;
    }

    span.line:nth-child(1) {
      transform: translateY(12px);
    }

    span.line:nth-child(3) {
      transform: translateY(-6px) rotate(90deg);
    }
  `}
`;

const Hum = ({ isOpen }) => {
    const { pathname } = useLocation();
    const isHome = pathname === HOME_ROUTE;

    return (
        <StyledHum
            className="hamburger"
            isOpen={isOpen}
            isHome={isHome}
        >
            <span className="line" />
            <span className="line" />
            <span className="line" />
        </StyledHum>
    );
};

export default Hum;
