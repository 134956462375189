import styled from 'styled-components';
import {
    $basicBlack, $basicBlue, $basicGreen,
} from '../../styles/styled/colors';

export const StyledPagination = styled.div`
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
    }
  }

  height: 50px;
  background: ${$basicBlue};
  margin-bottom: 5px;

  .pagination__counter,
  .pagination__show-more,
  .pagination__jumper {
    width: 33%;
    white-space: nowrap;
  }

  .pagination__counter {
    font-weight: 500;
    color: ${$basicBlack};
  }

  .pagination__show-more {
    color: ${$basicGreen};
    text-decoration: underline;
    height: fit-content;
  }

  .pagination__jumper {
    display: flex;
    justify-content: flex-end;

    .ant-pagination-item {
      background: none;
      border: none;

      &-active {
        .pagination__jumper-btn {
          color: ${$basicGreen};
        }
      }
    }

    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      .ant-pagination-item-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .anticon {
          color: ${$basicBlack};
        }
      }
    }

    &-btn {
      color: ${$basicBlack};
    }
  }

  @media (max-width: 768px) {
    height: unset;
    padding: 10px 0;
    align-items: center;
    .pagination__counter,
    .pagination__show-more,
    .pagination__jumper {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .pagination__counter,
    .pagination__show-more {
      margin-bottom: 10px;

      &-mobile {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
`;
