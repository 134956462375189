import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const StyledLoader = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, .25);
  z-index: 9999;
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
`;

const Loader = ({ active = false }) => (
    <StyledLoader
        style={{
            display: active ? 'flex' : 'none',
        }}
    >
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </StyledLoader>
);

export default Loader;
