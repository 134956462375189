import React, { useMemo, useState } from 'react';
import { Image } from 'antd';
import Slider from 'react-slick';
import placeholder from '../../../svg/img-placeholder.svg';
import { ReactComponent as Lens } from '../../../svg/lens.svg';
import {
    StyledButtonLens, StyledButtonLike,
    StyledButtonSpecs, StyledPlaceholder, StyledPreview,
} from './styles';
import SlickArrow from './SlickArrow';

const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlickArrow isNext />,
    prevArrow: <SlickArrow isNext={false} />,
};

const CardPreview = ({
                         images = [],
                         extraImages = [],
                         withLens = false,
                         withLike = false,
                         isFavourite = false,
                         onFavourite,
                     }) => {
    const [visible, setVisible] = useState(false);
    const [previewVisible, setPreviewVisible] = useState(false);

    const handleZoom = (e) => {
        e.stopPropagation();
        setVisible(true);
    };

    const handleExtraImages = (e) => {
        e.stopPropagation();

        setPreviewVisible(true);
    };

    const getImages = () => (images.length ? images.map((src) => (
        <Image
            key={src}
            preview={{
                src,
                visible,
                mask: null,
                onVisibleChange: setVisible,
            }}
            src={src}
            fallback={placeholder}
        />
    )) : (
        <StyledPlaceholder>
            <img src={placeholder} alt="" />
        </StyledPlaceholder>
    ));

    const imagesContent = useMemo(() => getImages(), [images]);

    return (
        <StyledPreview>
            <div style={{ display: 'none' }}>
                {extraImages.length ? (
                    <Image.PreviewGroup
                        preview={{
                            visible: previewVisible,
                            onVisibleChange: (value) => {
                                setPreviewVisible(value);
                            },
                        }}
                    >
                        {extraImages.map((src) => (
                            <Image key={src} src={src} preview={src} />
                        ))}
                    </Image.PreviewGroup>
                ) : null}
            </div>
            {images.length > 1 ? (
                <Slider {...sliderSettings}>
                    {imagesContent}
                </Slider>
            ) : imagesContent}

            {extraImages.length ? (
                <StyledButtonSpecs
                    type="button"
                    className="button-specs"
                    style={{ backgroundImage: `url(${extraImages[0]})` }}
                    onClick={handleExtraImages}
                >
                    <span>{extraImages.length <= 10 ? extraImages.length : '+10'}</span>
                </StyledButtonSpecs>
            ) : null}

            {withLike && (
                <StyledButtonLike onClick={onFavourite} active={isFavourite} />
            )}

            {withLens && (
                <StyledButtonLens type="button" onClick={handleZoom} className="button-lens"><Lens /></StyledButtonLens>
            )}
        </StyledPreview>
    );
};

export default CardPreview;
