import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Text } from '../../../ui';
import { $basicGreen, $basicGrey, $basicWhite } from '../../../styles/styled/colors';
import useAuction from '../hooks/useAuction';
import { userState } from '../../../store/reducers/userReducer';
import { CUSTOMER } from '../../../utils/consts';
import { mdQueryDown } from '../../../styles/styled/sizes';

const StyledBid = styled.div`
  background: ${$basicWhite};
  border-radius: 6px;
  padding: 20px;
  min-width: 230px;
  text-align: right;

  @media ${mdQueryDown} {
    text-align: left;
  }
`;

const AuctionDetailCustomerCurrentBid = ({ auction }) => {
    const { role } = useSelector(userState);
    const {
        currencySymbol,
        lastUserBid,
        isActive,
    } = useAuction(auction);
    return (
        <>
            {
                isActive && role === CUSTOMER && (
                    <StyledBid>
                        <Text variant="bold">
                            <FormattedMessage id="yuoBid" />:
                        </Text>
                        {
                            lastUserBid
                                ? (
                                    <Text variant="bold2" color={$basicGreen} className="mt-2">
                                        <FormattedMessage
                                            id="moneyPerTon"
                                            values={{ value: lastUserBid?.amount_CNY, currency: currencySymbol }}
                                        />
                                    </Text>
                                )
                                : (
                                    <Text variant="small" color={$basicGrey} className="mt-2">
                                        <FormattedMessage id="emptyBid" />
                                    </Text>
                                )
                        }
                    </StyledBid>
                )
            }
        </>
    );
};

export default AuctionDetailCustomerCurrentBid;
