import React from 'react';
import styled, { css } from 'styled-components';
import { $basicGrey2 } from '../../styles/styled/colors';

const StyledSectionTitle = styled.div`
  //border-bottom: 1px dashed #B3BDC6;
  //padding: 0 0 16px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  margin: 50px 0 0;

  ${({ isMobile }) => isMobile && css`
    margin: 30px 0 0;
  `}
  .styled-section-title {
    &__heading {
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
    }

    &__hint {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      margin: 0 0 0 17px;
      color: ${$basicGrey2};
    }
  }
`;

const FormSectionTitle = ({
                              title, children, hint = '', isMobile = false,
                          }) => (
    <StyledSectionTitle isMobile={isMobile}>
        <div className="styled-section-title__heading">
            {title}
            {hint && <span className="styled-section-title__hint">{hint}</span>}
        </div>
        {
            children && <div>{children}</div>
        }
    </StyledSectionTitle>
);

export default FormSectionTitle;
