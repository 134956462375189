import { createSlice } from '@reduxjs/toolkit';
import { aboutFetch } from '../actions/pageActions';

const initialState = {
    title: {},
    advances: [],
    articleTop: {},
    articleBottom: {},
    videos: [],
};

const pageSlice = createSlice({
    name: 'page',
    initialState,
    extraReducers: {
        [aboutFetch.fulfilled]: (state, { payload }) => {
            state.title = payload.title;
            state.advances = payload.advances;
            state.articleTop = payload.articletop.shift();
            state.articleBottom = payload.articlebottom.shift();
            state.videos = payload.videos;
        },
    },
});

export const pageState = (state) => state.page;
export default pageSlice.reducer;
