import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
// import styled from 'styled-components';
import { Dot, Paper, Text } from '../../../ui';
import { $basicDarkGreen, $basicGreen } from '../../../styles/styled/colors';
import useAuction from '../hooks/useAuction';
import { localeState } from '../../../store/reducers/localesReducer';
// import { xlQueryDown } from '../../../styles/styled/sizes';

// const StyledRow = styled.div`
//   > * {
//     width: 50%;
//
//     @media ${xlQueryDown} {
//       width: 100%;
//     }
//   }
// `;

const AuctionsCardSmall = ({ auction }) => {
    const { currentLocale } = useSelector(localeState);
    const {
        icon,
        startPrice,
        currencySymbol,
        etdDate,
        name,
        category,
        totalWeight,
        country,
        factory,
    } = useAuction(auction);
    return (
        <div className="col-12 col-sm-10 mx-auto mt-4">
            <Paper>
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center flex-wrap">
                            <Text><FormattedMessage id="factoryName" /> {factory.name}</Text>
                            <Dot />
                            <Text variant="bold">
                                {country}
                            </Text>
                        </div>
                        <div className="col-12 d-sm-flex align-items-center mt-4">
                            <div className="col-12 col-md-8 d-flex align-items-center">
                                <div className="col-3 text-center">
                                    {icon}
                                </div>
                                <div className="d-flex flex-column ms-3 col">
                                    <Text variant="heading" color={$basicDarkGreen} className="mt-2">
                                        {category?.name[currentLocale]}
                                    </Text>
                                    <Text variant="bold2" color={$basicDarkGreen}>
                                        {name[currentLocale]}
                                    </Text>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 d-flex flex-sm-column mt-3 mt-sm-0">
                                <Text variant="heading" color={$basicDarkGreen}>
                                    <FormattedMessage id="startPrice" />:
                                </Text>
                                <Text variant="bold2" color={$basicGreen} className="mt-sm-2 ms-sm-0 ms-2 col">
                                    <FormattedMessage
                                        id="moneyPerTon"
                                        values={{ value: startPrice, currency: currencySymbol }}
                                    />
                                </Text>
                            </div>
                        </div>
                        <div className="col-12 d-sm-flex align-items-center mt-4">
                            <div className="col-12 col-md-8 d-flex align-items-center">
                                <div className="col-3 me-3 d-md-block d-none" />
                                <Text>
                                    <FormattedMessage id="shipmentETD" />:&nbsp;
                                </Text>
                                <Text variant="heading" color={$basicDarkGreen}>{etdDate}</Text>
                            </div>
                            <div className="col-12 col-md-4 d-flex align-items-center mt-2 mt-sm-0">
                                <Text>
                                    <FormattedMessage id="weightNet" />:&nbsp;
                                </Text>
                                <Text variant="heading" color={$basicDarkGreen}>
                                    <FormattedMessage id="tonFullValue" values={{ value: totalWeight }} />
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>
            </Paper>
        </div>
    );
};

export default AuctionsCardSmall;
