import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import FormSectionTitle from '../../components/Form/FormSectionTitle';
import FormInput from '../../components/Form/FormInput';
import { Link } from '../../ui';
import FormControlsWrapper from '../../components/Form/FormControlsWrapper';
import { userState } from '../../store/reducers/userReducer';
import {
    ACCOUNT,
    BANK_ADDRESS,
    BANK_NAME,
    INN,
    KPP,
    PERSON_EMAIL,
    PERSON_NAME,
    PERSON_PHONE,
    SWIFT,
} from '../../utils/consts';

const PersonalSeller = ({
                            onChange, values: { companies }, addFactory, deleteFactory, errors,
                        }) => {
    const { activeIndex } = useSelector(userState);
    const [company, setCompany] = useState(null);
    const [persons, setPersons] = useState([]);
    const [requisites, setRequisites] = useState({});

    useEffect(() => {
        if (companies) {
            const currentCompany = companies[activeIndex];
            if (currentCompany) {
                setCompany(currentCompany);
            }
        }
    }, [companies, activeIndex]);

    useEffect(() => {
        if (!!company) {
            const requisitesCompany = company.requisites || {};
            const responsiblePersons = company.responsiblePersons.length === 1
                ? [
                    ...company.responsiblePersons,
                    {
                        name: '',
                        phone: '',
                        email: '',
                        passport: '',
                        wechatId: '',
                    },
                ]
                : [...company.responsiblePersons];
            setPersons(responsiblePersons);
            setRequisites(requisitesCompany);
            // setPrimary(isPrimaryCompany);
        }
    }, [company]);

    return (
        <>
            {
                company && (
                    <>
                        <div className="row px-0">
                            <FormSectionTitle title={<FormattedMessage id="generalTitle" />} />
                            <div className="d-flex flex-column px-0">
                                <FormControlsWrapper>
                                    <div className="flex-grow-1 col-12 col-sm-6 pe-0 ps-0 pt-4 pt-sm-4 pe-sm-2 pe-lg-3">
                                        <FormInput
                                            label={<FormattedMessage id="companyNameLabel" />}
                                            value={company.name}
                                            onChange={onChange('name', true)}
                                            error={errors.name}
                                        />
                                    </div>
                                    <div className="flex-grow-1 col-12 col-sm-6 pe-0 ps-0 pt-4 pt-sm-4 ps-sm-2 ps-lg-3">
                                        <FormInput
                                            label={<FormattedMessage id="companyAddressLabel" />}
                                            value={company.address}
                                            onChange={onChange('address', true)}
                                            error={errors.address}
                                        />
                                    </div>
                                </FormControlsWrapper>
                            </div>
                        </div>
                        <div className="row px-0">
                            <FormSectionTitle title={<FormattedMessage id="factoriesTitle" />}>
                                <Link onClick={addFactory}>
                                    <FormattedMessage id="add" />
                                    &nbsp;
                                    <FormattedMessage id="factoriesTitle" />
                                </Link>
                            </FormSectionTitle>
                            <div className="d-flex flex-column px-0">
                                {
                                    company.factories.map(({ id, address, name }, idx, array) => {
                                        const canDelete = array.length > 1;
                                        return (
                                            <FormControlsWrapper key={idx}>
                                                <div
                                                    className="flex-grow-1 col-12 col-sm-6 pe-0 ps-0 pt-4 pt-sm-4 pe-sm-2 pe-lg-3"
                                                >
                                                    <FormInput
                                                        label={<FormattedMessage id="factoryNameLabel" />}
                                                        value={name}
                                                        onChange={onChange('name', true, 'factories', activeIndex, false, idx)}
                                                    />
                                                </div>
                                                <div
                                                    className="flex-grow-1 col-12 col-sm-6 pe-0 ps-0 pt-4 pt-sm-4 ps-sm-2 ps-lg-3"
                                                >
                                                    <FormInput
                                                        label={<FormattedMessage id="factoryAddressLabel" />}
                                                        link={
                                                            canDelete
                                                                ? (
                                                                    <Link
                                                                        onClick={() => deleteFactory(id)}
                                                                    >
                                                                        <FormattedMessage id="delete" />
                                                                        &nbsp;
                                                                        <FormattedMessage id="factoriesTitle" />

                                                                    </Link>
                                                                )
                                                                : null
                                                        }
                                                        value={address}
                                                        // onChange={factoryHandler('address', id)}
                                                        onChange={onChange('address', true, 'factories', activeIndex, false, idx)}
                                                    />
                                                </div>
                                            </FormControlsWrapper>
                                        );
                                    })
                                }
                            </div>
                        </div>
                        <div className="row px-0">
                            <FormSectionTitle title={<FormattedMessage id="responsiblePersonsTitle" />} />
                            <div className="d-flex flex-column px-0">
                                <FormControlsWrapper>
                                    {
                                        persons.map((person, idx) => (
                                            <div
                                                key={idx}
                                                className={`flex-grow-1 col-12 col-lg-6 pe-0 ps-0 pt-4 pt-lg-4 ${idx === 0 ? 'pe' : 'ps'}-lg-3`}
                                            >
                                                <FormInput
                                                    label={(
                                                        <FormattedMessage
                                                            id={idx === 0 ? 'personMainNameLabel' : 'personAddNameLabel'}
                                                        />
                                                    )}
                                                    value={person.name}
                                                    onChange={onChange('name', true, 'responsiblePersons', activeIndex, false, idx)}
                                                    error={idx === 0 ? errors[PERSON_NAME] : null}
                                                />
                                                <div className="d-flex flex-wrap pt-4 pt-lg-4">
                                                    <div className="col-12 col-sm-6 pe-0 ps-0 pe-sm-2 pe-lg-3">
                                                        <FormInput
                                                            label={<FormattedMessage id="personPhoneLabel" />}
                                                            value={person.phone}
                                                            onChange={onChange('phone', true, 'responsiblePersons', activeIndex, false, idx)}
                                                            error={idx === 0 ? errors[PERSON_PHONE] : null}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-6 pe-0 ps-0 ps-sm-2 ps-lg-3 pt-4 pt-sm-0">
                                                        <FormInput
                                                            label={<FormattedMessage id="personEmailLabel" />}
                                                            value={person.email}
                                                            onChange={onChange('email', true, 'responsiblePersons', activeIndex, false, idx)}
                                                            error={idx === 0 ? errors[PERSON_EMAIL] : null}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        ))
                                    }
                                </FormControlsWrapper>
                            </div>
                        </div>
                        <div className="row px-0">
                            <FormSectionTitle title={<FormattedMessage id="requisitesTitle" />} />
                            <div className="d-flex flex-column px-0">
                                <FormControlsWrapper>
                                    <div
                                        className="flex-grow-1 col-12 col-lg-6 pe-0 ps-0 pt-4 pt-sm-4  pe-lg-3"
                                    >
                                        <FormInput
                                            label={<FormattedMessage id="bankNameLabel" />}
                                            value={requisites.bankName}
                                            onChange={onChange('bankName', true, 'requisites', activeIndex)}
                                            error={errors[BANK_NAME]}
                                        />
                                        <div className="d-flex flex-wrap pt-4 pt-sm-4">
                                            <div className="col-12 col-sm-6 pe-0 ps-0 pe-sm-2 pe-lg-2">
                                                <FormInput
                                                    label={<FormattedMessage id="innLabel" />}
                                                    value={requisites.inn}
                                                    onChange={onChange('inn', true, 'requisites', activeIndex)}
                                                    error={errors[INN]}
                                                />
                                            </div>
                                            <div className="col-12 col-sm-6 pe-0 ps-0 ps-sm-2 ps-lg-3 pt-4 pt-sm-0">
                                                <FormInput
                                                    label={<FormattedMessage id="kppLabel" />}
                                                    value={requisites.kpp}
                                                    onChange={onChange('kpp', true, 'requisites', activeIndex)}
                                                    error={errors[KPP]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="flex-grow-1 col-12 col-lg-6 pe-0 ps-0 pt-4 pt-sm-4 ps-lg-3"
                                    >
                                        <FormInput
                                            label={<FormattedMessage id="bankAddressLabel" />}
                                            value={requisites.bankAddress}
                                            onChange={onChange('bankAddress', true, 'requisites', activeIndex)}
                                            error={errors[BANK_ADDRESS]}
                                        />
                                        <div className="d-flex flex-wrap pt-4 pt-sm-4">
                                            <div className="col-12 col-sm-6 pe-0 ps-0 pe-sm-2 pe-lg-3">
                                                <FormInput
                                                    label={<FormattedMessage id="accountLabel" />}
                                                    value={requisites.account}
                                                    onChange={onChange('account', true, 'requisites', activeIndex)}
                                                    error={errors[ACCOUNT]}
                                                />
                                            </div>
                                            <div className="col-12 col-sm-6 pe-0 ps-0 ps-sm-2 ps-lg-3 pt-4 pt-sm-0">
                                                <FormInput
                                                    label={<FormattedMessage id="swiftCodeLabel" />}
                                                    value={requisites.swiftCode}
                                                    onChange={onChange('swiftCode', true, 'requisites', activeIndex)}
                                                    error={errors[SWIFT]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </FormControlsWrapper>
                            </div>
                        </div>
                    </>
                )
            }
        </>
    );
};

export default PersonalSeller;
