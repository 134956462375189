import React from 'react';
import { Menu, Dropdown, Popover } from 'antd';
import is from 'is_js';
import TextTruncate from 'react-text-truncate';
import { ReactComponent as User } from '../../svg/user.svg';
import PersonalLink from './PersonalLink';
import LogoutLink from './LogoutLink';

const menu = (
    <Menu>
        <Menu.Item>
            <PersonalLink />
        </Menu.Item>
        <Menu.Item>
            <LogoutLink />
        </Menu.Item>
    </Menu>
);

const HeaderPersonalSelect = ({ profileName, open }) => {
    const isMobile = is.mobile() || is.tablet();
    return (
        <div className="d-flex">
            <Dropdown overlay={menu} placement={`${isMobile ? 'bottomLeft' : 'bottomRight'}`} className="me-2">
                <User />
            </Dropdown>
            {
                !open && (
                    <Popover
                        content={profileName}
                    >
                        <div
                            style={{ cursor: 'default', width: 100 }}
                        >
                            <TextTruncate
                                line={1}
                                truncateText="…"
                                text={profileName}
                            />
                        </div>
                    </Popover>
                )
            }
            {
                (isMobile && open) && <div>{profileName}</div>
            }
        </div>
    );
};
export default HeaderPersonalSelect;
