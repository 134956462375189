import Logo from './Logo';
import Button from './Button';
import Link from './Link';
import Text from './Text';
import Paper from './Paper';
import BackBtn from './BackBtn';
import Checkbox from './Checkbox';
import Dot from './Dot';
import Loader from './Loader';
import ParallaxImage from './ParallaxImage';

export {
    Logo,
    Button,
    Link,
    Text,
    Paper,
    BackBtn,
    Checkbox,
    Dot,
    Loader,
    ParallaxImage,
};
