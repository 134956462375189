import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, Link } from '../../ui';
import { userLogin } from '../../store/actions/authActions';
import { setAuthProperty, authState } from '../../store/reducers/authReducer';
import FormInput from '../Form/FormInput';
import { localeState } from '../../store/reducers/localesReducer';
import localesErrors from '../../locales/localesErrors';
import { FIELD_REQUIRED } from '../../utils/consts';
import FormError from '../Form/FormError';
import { clearProducts } from '../../store/reducers/productReducer';

const Login = ({ forgotHandler, errors, setErrors }) => {
    const dispatch = useDispatch();
    const { role, errors: serverErrors } = useSelector(authState);
    const { currentLocale } = useSelector(localeState);
    const [formData, setFormData] = useState({
        login: '',
        password: '',
    });

    const inputHandler = (field) => ({ target: { value } }) => {
        setFormData({ ...formData, [field]: value });
        setErrors({});
        dispatch(setAuthProperty({
            property: 'errors',
            value: [],
        }));
    };

    const submitHandler = () => {
        const loginErrors = { ...errors };
        if (!formData.login) {
            loginErrors.login = localesErrors[currentLocale][FIELD_REQUIRED];
        }
        if (!formData.password) {
            loginErrors.password = localesErrors[currentLocale][FIELD_REQUIRED];
        }
        if (formData.login && formData.password) {
            dispatch(userLogin({ role, ...formData }));
            dispatch(clearProducts());
        } else {
            setErrors(loginErrors);
        }
    };

    const registerHandler = () => {
        dispatch(setAuthProperty({ property: 'authFormOpen', value: false }));
        dispatch(setAuthProperty({ property: 'regFormOpen', value: true }));
    };

    return (
        <>
            {
                !!serverErrors.length && <FormError errors={serverErrors} />
            }
            <FormattedMessage id="personPhonePlaceholder">
                {
                    (placeholder) => (
                        <FormInput
                            value={formData.login}
                            placeholder={placeholder}
                            onChange={inputHandler('login')}
                            error={errors.login}
                        />
                    )
                }
            </FormattedMessage>

            <FormattedMessage id="passwordPlaceholder">
                {
                    (placeholder) => (
                        <FormInput
                            value={formData.password}
                            placeholder={placeholder}
                            type="password"
                            onChange={inputHandler('password')}
                            error={errors.password}
                            className="mt-4"
                        />
                    )
                }
            </FormattedMessage>
            <div className="styled-auth-body__button">
                <Button title={<FormattedMessage id="loginButtonTitle" />} width="100%" onClick={submitHandler} />
            </div>
            <div className="styled-auth-body__links">
                <Link onClick={registerHandler}><FormattedMessage id="registerLink" /></Link>
                <Link onClick={forgotHandler}><FormattedMessage id="forgotPasswordLink" /></Link>
            </div>
            <div className="styled-auth-body__policy">
                <FormattedMessage id="registerAgreement" />
            </div>
        </>
    );
};

export default Login;
