import { createAsyncThunk } from '@reduxjs/toolkit';
import qs from 'qs';
import $api from '../../api/api';

export const notificationsRead = createAsyncThunk(
    'notifications/read',
    async (_, { rejectWithValue, getState }) => {
        try {
            const {
                notifications: { unreadCurrentPage, pageSize },
            } = getState();

            const params = {
                page: unreadCurrentPage,
                page_size: pageSize,
            };

            const { data } = await $api.put('/notifications/read/', null, { params });

            return { data, pageSize };
        } catch (e) {
            return rejectWithValue(e.response);
        }
    },
);

export const notificationsFetch = createAsyncThunk(
    'notifications/fetch',
    //eslint-disable-next-line
    async (isRead, { rejectWithValue, getState, dispatch }) => {
        try {
            const {
                notifications: { readCurrentPage, pageSize },
            } = getState();

            const params = {
                is_read: isRead,
                page_size: pageSize,
                page: isRead ? readCurrentPage : 1,
            };

            const { data } = await $api.get('/notifications/', { params });

            if (!isRead) await dispatch(notificationsRead());

            return { data, isRead };
        } catch (e) {
            return rejectWithValue(e.response);
        }
    },
);

export const notificationsCount = createAsyncThunk(
    'notifications/count',
    async (onlyUnread, { rejectWithValue }) => {
        try {
            const params = {};

            if (onlyUnread) {
                params.is_read = false;
            }

            const { data } = await $api.get('/notifications/count/', { params });

            return { data };
        } catch (e) {
            return rejectWithValue(e.response);
        }
    },
);

export const supportSend = createAsyncThunk(
    'support/faq',
    async ({
               authorName,
               authorWechatId,
               authorPhone,
               authorEmail,
               question,
               isAuth = false,
           }, { rejectWithValue }) => {
        try {
            const params = {
                question,
            };
            if (!isAuth) {
                params.authorName = authorName;
                params.authorWechatId = authorWechatId;
                params.authorPhone = authorPhone;
                params.authorEmail = authorEmail;
            }
            const url = isAuth ? '/user/faq/' : '/faq/';
            const { data } = await $api.post(
                url,
                qs.stringify(params),
            );
            return { data };
        } catch (e) {
            const data = { ...e.response.data };
            return rejectWithValue(data);
        }
    },
);

export const wechatAuth = createAsyncThunk(
    'wechatAuth/qr',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await $api.get('/wechat/oauth/qr/');
            return { data };
        } catch (e) {
            return rejectWithValue(e.response);
        }
    },
);

export const wechatSubscribe = createAsyncThunk(
    'wechatSubscription/qr',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await $api.get('/wechat/subscription/qr/');
            return { data };
        } catch (e) {
            return rejectWithValue(e.response);
        }
    },
);
