import styled from 'styled-components';
import { $basicWhite } from '../../styles/styled/colors';
import { mdQueryDown, xsQueryDown } from '../../styles/styled/sizes';

export const CardInfo = styled.div`
  position: relative;
  padding: 8px 15px 16px;
  background: ${$basicWhite};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  //width: calc(100% - 10px);
  //margin: -16px auto 0;
  margin: -4px auto 0;
  width: 100%;  

  @media ${xsQueryDown} {
    padding: 10px 15px 16px;
    margin-top: -5px;
    //margin-top: -30px;
    //width: calc(100% - 14px);
  }

  .info {
    &__text {
      font-size: 12px;
      font-weight: 500;
      @media (max-width: 320px) {
        font-weight: 400;
      }
    }

    &__title {
      font-size: 14px;
      font-weight: 400;
      padding-left: 1px;

      @media ${mdQueryDown} {
        font-size: 12px;
      }

      @media ${xsQueryDown} {
        font-size: 12px;
      }
    }

    &__title-small {
      font-size: 12px;
      font-weight: 400;
      max-width: 100px;
      line-height: 16px;
    }

    &__description {
      font-size: 20px;
      line-height: 21px;
      font-weight: 500;
      word-break: break-word;

      @media ${mdQueryDown} {
        font-size: 18px;
      }

      @media ${xsQueryDown} {
        font-size: 16px;
      }
    }

    &__description-small {
      font-size: 14px;
      font-weight: 500;
      margin-top: -1px;
    }

    &__text,
    &__description,
    &__description-small {
      margin-bottom: 3px;
    }
  }
`;
