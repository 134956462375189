import { createSlice } from '@reduxjs/toolkit';
import {
    notificationOptions,
    notificationOptionsEdit,
    userFetch,
    userUpdate,
} from '../actions/userActions';
import { userLogout } from '../actions/authActions';

const initialState = {
    id: null,
    phone: '',
    profileName: '',
    role: '',
    companies: [],
    documents: [],
    mainProductCategory: {},
    availableProductCategories: [],
    moderationStatus: '',
    activeIndex: 0,
    wechatOpenId: null,
    create: false,
    errors: [],
    isLoading: false,
    isSuccess: false,
    notificationOptions: {
        notifyByWechat: false,
        notifyByEmail: false,
        notifyByPhone: false,
    },
};

const setPending = (state) => {
    state.isLoading = true;
    state.error = null;
    state.isSuccess = false;
};

const setUserData = (state, { payload }) => {
    state.id = payload.data.id;
    state.phone = payload.data.phone;
    state.profileName = payload.data.name;
    state.role = payload.data.role;
    state.companies = payload.data.companies;
    state.mainProductCategory = payload.data.mainProductCategory;
    state.moderationStatus = payload.data.moderationStatus;
    state.availableProductCategories = payload.data.availableProductCategories;
    state.wechatOpenId = payload.data.wechatOpenId;
    state.isLoading = false;
};
const updateUserData = (state, { payload }) => {
    setUserData(state, { payload });
    state.isSuccess = true;
    state.isLoading = false;
};

const playerSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserProperty: (state, { payload }) => {
            state[payload.property] = payload.value;
        },
        setCreate: (state, { payload }) => {
            state.create = payload;
        },
        setActiveIndex: (state, { payload }) => {
            state.activeIndex = payload;
        },
    },
    extraReducers: {
        [userFetch.pending]: setPending,
        [userFetch.fulfilled]: setUserData,
        [userUpdate.pending]: setPending,
        [userUpdate.fulfilled]: updateUserData,
        [userUpdate.rejected]: (state) => {
            // const { message } = data;
            // const messages = message.split('/n').filter((error) => !!error);
            // if (data.data.fields && !!data.data.fields.length) {
            //     const errors = data.data.fields.map((field, idx) => [field, messages[idx]]);
            //     if (!!errors.length) {
            //         state.errors = errors;
            //     }
            // }
            state.isLoading = false;
        },
        [userLogout.fulfilled]: (state) => {
            state.id = null;
        },
        [notificationOptions.fulfilled]: (state, { payload }) => {
            state.notificationOptions = {
                ...state.notificationOptions,
                ...payload,
            };
        },
        [notificationOptionsEdit.fulfilled]: (state, { payload }) => {
            state.notificationOptions = {
                ...state.notificationOptions,
                ...payload,
            };
        },
    },
});

export const {
    setUserProperty,
    setCreate,
    setActiveIndex,
} = playerSlice.actions;
export const userState = (state) => state.user;
export default playerSlice.reducer;
