import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Base from './Layouts/Base';
import AppRouter from './components/AppRouter';
import { userFetch } from './store/actions/userActions';
import { notificationsCount } from './store/actions/notificationActions';

const App = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        dispatch(notificationsCount(true));
    }, [location]);

    useEffect(() => {
        dispatch(userFetch());
    }, []);

    return (
        <Base>
            <AppRouter />
        </Base>
    );
};

export default App;
