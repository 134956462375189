import React from 'react';
import styled, { css } from 'styled-components';
import is from 'is_js';
import { Modal } from 'antd';
import { ReactComponent as Close } from '../../svg/close.svg';
import { $basicBlack, $basicGrey } from '../../styles/styled/colors';

const StyledAuthBody = styled.div`
  margin: 0 auto;
  width: 370px;
  max-width: 100%;

  .styled-auth-body {
    &__input {
      margin: 0 0 20px;

      input {
        height: 40px;
        border-radius: 4px;
        border: 1px solid ${$basicGrey};
      }
    }

    &__button {
      margin: 40px 0 10px;
    }

    &__links {
      display: flex;
      justify-content: space-between;
      margin: 15px 0 0;
    }

    &__policy {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      margin: 16px auto 0;
      text-align: center;
      color: ${$basicGrey};
      width: 80%;
    }
  }
`;

const StyledTitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  padding: 24px 0 30px;
  color: ${$basicBlack};
  ${({ isMobile }) => isMobile && css`
    font-size: 22px;
    line-height: 26px;
  `}
`;

const StyledClose = styled.div`
  color: ${$basicGrey};
  transition: .25s ease-in;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: ${$basicBlack};
  }
`;

const isMobile = is.mobile() || is.tablet();
const AuthModal = ({
                       title, body, onCancel, width = 570,
                   }) => (
    <Modal
        title={(
            <StyledTitle isMobile={isMobile}>
                {title}
            </StyledTitle>
        )}
        visible
        onCancel={onCancel}
        centered
        footer={null}
        width={width}
        closeIcon={<StyledClose><Close /></StyledClose>}
        className="position-relative"
    >
        <StyledAuthBody>
            {body}
        </StyledAuthBody>
    </Modal>
);

export default AuthModal;
