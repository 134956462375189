import React from 'react';
import styled from 'styled-components';
import { $basicGreen, $basicGrey, $basicGrey3 } from '../styles/styled/colors';

const StyledCheckBox = styled.label`
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;

  > span {
    margin-right: 8px;
    width: 18px;
    height: 18px;
    border: 1px solid ${$basicGrey};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > span:before {
    content: '';
    display: none;
    background: ${$basicGreen};
    width: 12px;
    height: 12px;
  }

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked + span {
      border: 1px solid ${$basicGreen};
    }


    &:checked + span:before {
      display: block;
    }

    &:not(:disabled):not(:checked) + span:hover::before {
      border-color: ${$basicGrey3};
    }


    &:focus + span {
      box-shadow: 0 0 0 0.1rem rgba(159, 192, 49, 0.25);
    }

  }

  > div {
    padding: 3px 0 0;
  }

`;

const Checkbox = ({
                      label = '', onChange,
                      disabled = false,
                      id, className = '',
                      checked = false,
                  }) => (
    <StyledCheckBox className={className}>
        <input
            id={id}
            type="checkbox"
            onChange={onChange}
            disabled={disabled}
            checked={checked}
            value=""
        />
        <span />
        {
            label && <div>{label}</div>
        }
    </StyledCheckBox>
);

export default Checkbox;
