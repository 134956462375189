import styled from 'styled-components';
import {
    lgQueryDown, smQueryDown, smQueryUp, xsQueryDown,
} from '../../../../../styles/styled/sizes';
import {
    $basicBlue, $basicGrey, $basicGrey2, $basicGrey3,
} from '../../../../../styles/styled/colors';
import Switch from '../../../../../ui/Switch/Switch';

export const StyledNotificationsControls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: -64px;

  @media ${lgQueryDown} {
    margin-top: 0;
    margin-bottom: 20px;
    background: ${$basicBlue};
    border-radius: 4px;
    padding: 14px 23px;
    display: block;
  }

  @media ${smQueryDown} {
    padding: 18px 14px;
    margin-left: -15px;
    margin-right: -15px;
  }

  > * {
    margin-bottom: 12px;

    @media ${lgQueryDown} {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const StyledSwitch = styled(Switch)`
  @media ${lgQueryDown} {
    justify-content: space-between;

    button {
      background: ${$basicGrey3};
    }
  }
`;

export const StyledSwitchLabel = styled.span`
  @media ${smQueryUp} {
    br {
      display: none;
    }
  }

  @media ${lgQueryDown} {
    color: ${$basicGrey};

    .checked & {
      color: ${$basicGrey2};
    }
  }

  @media ${smQueryDown} {
    font-size: 14px;
  }

  @media ${xsQueryDown} {
    font-size: 12px;
  }
`;
