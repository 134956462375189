import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Button } from '../../ui';
import { CUSTOMER, SELLER } from '../../utils/consts';
import { setAuthProperty } from '../../store/reducers/authReducer';
import { mdQueryDown } from '../../styles/styled/sizes';

const StyledButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
  ${({ isMobile }) => isMobile && css`
    flex-direction: column;
    justify-content: space-between;
    padding-top: 7.5vh;
  `}
`;

const HomeButtons = () => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery({ query: mdQueryDown });

    const authHAndler = (role) => {
        dispatch(setAuthProperty({ property: 'role', value: role }));
        dispatch(setAuthProperty({ property: 'authFormOpen', value: true }));
    };

    return (
        <StyledButtons isMobile={isMobile} className="px-md-3">
            <Button
                title={formatMessage({ id: 'buyer' })}
                width={isMobile ? '100%' : '270px'}
                className="mx-md-3"
                rounded={!isMobile}
                onClick={() => authHAndler(CUSTOMER)}
            />
            <Button
                title={formatMessage({ id: 'seller' })}
                width={isMobile ? '100%' : '270px'}
                className={`mx-md-3 ${isMobile ? 'mt-3' : ''}`}
                variant="outlined"
                rounded={!isMobile}
                onClick={() => authHAndler(SELLER)}
            />
        </StyledButtons>
    );
};

export default HomeButtons;
