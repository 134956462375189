import { createSlice } from '@reduxjs/toolkit';
import {
    notificationsCount,
    notificationsFetch, notificationsRead,
    supportSend, wechatAuth, wechatSubscribe,
} from '../actions/notificationActions';

const initialState = {
    supportFormOpen: false,
    total: 0,
    pageSize: 15,
    unreadCurrentPage: 1,
    readCurrentPage: 1,
    unreadLastPage: 0,
    readLastPage: 0,
    items: [],
    loading: false,
    loaded: false,
    unreadTotal: 0,
    qrAuth: '',
    qrSubscribe: '',
};

const notificationSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setReadCurrentPage: (state, { payload }) => {
            state.readCurrentPage = payload;
        },
        setUnreadCurrentPage: (state, { payload }) => {
            state.unreadCurrentPage = payload;
        },
        setSupportFormOpen: (state, { payload }) => {
            state.supportFormOpen = payload;
        },
        clearNotifications: (state) => {
            Object.keys(state).forEach((key) => {
                if (key !== 'unreadTotal') state[key] = initialState[key];
            });
        },
    },
    extraReducers: {
        [notificationsFetch.pending]: (state) => {
            state.loading = true;
        },
        [notificationsFetch.fulfilled]: (state, { payload }) => {
            const { isRead } = payload;

            if (state.items.length) {
                state.items = [...state.items, ...payload.data.items];
            } else {
                state.items = payload.data.items;
            }

            state.total = payload.data.total;
            state.pageSize = payload.data.pageSize;
            state.loading = false;
            state.loaded = true;

            if (isRead) {
                state.readCurrentPage = payload.data.currentPage;
                state.readLastPage = payload.data.lastPage;
            } else {
                state.unreadCurrentPage = payload.data.currentPage;
                state.unreadLastPage = payload.data.lastPage;
            }
        },
        [notificationsFetch.rejected]: (state) => {
            state.loading = false;
        },
        [notificationsCount.fulfilled]: (state, { payload }) => {
            state.unreadTotal = payload.data.count;
        },
        [notificationsRead.fulfilled]: (state, { payload }) => {
            state.unreadTotal -= payload.pageSize;

            if (state.unreadTotal < 0) state.unreadTotal = 0;
        },
        [supportSend.pending]: (state) => {
            state.supportFormOpen = false;
        },
        [wechatAuth.fulfilled]: (state, { payload }) => {
            state.qrAuth = payload.data;
        },
        [wechatSubscribe.fulfilled]: (state, { payload }) => {
            state.qrSubscribe = payload.data;
        },
    },
});

export const {
    setReadCurrentPage,
    setUnreadCurrentPage,
    setSupportFormOpen,
    clearNotifications,
} = notificationSlice.actions;
export const notificationState = (state) => state.notifications;
export default notificationSlice.reducer;
