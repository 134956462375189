import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { $basicWhite } from '../styles/styled/colors';
import { BackBtn } from '../ui';
import { mdQueryDown, smQueryDown } from '../styles/styled/sizes';
import { productState } from '../store/reducers/productReducer';
import { AUCTIONS_ROUTE, PRODUCTS_ROUTE } from '../utils/consts';

const StyledPage = styled.div`
  padding: 36px 0 90px;
  flex-grow: 1;
  @media ${mdQueryDown} {
    padding: 0 0 40px;
  }
  h1 {
    color: ${$basicWhite} !important;
    font-size: 22px;
    font-weight: 500;
    margin: 0!important;
  }
`;

const StyledHeading = styled.div`
  color: ${$basicWhite} !important;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
`;

const StyledBackBtn = styled(BackBtn)`
  margin-bottom: 20px;
  margin-top: -10px;
`;

const Page = (props) => {
    const { pathname } = useLocation();
    const { locale } = useIntl();
    const { categories, activeCategory } = useSelector(productState);
    const isMobile = useMediaQuery({ query: smQueryDown });
    const { children, title, withBack = false } = props;

    const currentCategoryTitle = categories.find((category) => category.id === activeCategory);
    const showCategoryTitle = pathname === AUCTIONS_ROUTE || pathname === PRODUCTS_ROUTE;

    return (
        <StyledPage>
            <div className="container flex-grow-1">
                {withBack && isMobile && <StyledBackBtn />}
                <StyledHeading>
                    <h1>{title}</h1>
                    <span
                        className="d-none d-md-block"
                    >
                        {showCategoryTitle && currentCategoryTitle && <> &nbsp;&mdash; &nbsp;{currentCategoryTitle.name[locale]}</>}
                    </span>
                </StyledHeading>
                {children}
            </div>
        </StyledPage>
    );
};

export default Page;
