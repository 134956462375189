import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import ButtonsGroup from '../../../ui/ButtonsGroup';
import { Button } from '../../../ui';
import { SELLER } from '../../../utils/consts';
import { ReactComponent as Plus } from '../../../svg/plus.svg';
import {
    productState, setCurrentPage,
    setIsAddModalVisible,
    setIsFavouriteStatus,
    setModerationStatus,
    setProductChangeStatus, setProductProperty,
} from '../../../store/reducers/productReducer';
import { userState } from '../../../store/reducers/userReducer';
import { mdQueryDown } from '../../../styles/styled/sizes';
import ProductModal from './ProductModal';
import FilterContainer from '../../../components/FilterContainer';
import ProductsFilter from './ProductsFilter/ProductsFilter';

const ProductsHeader = () => {
    const { role } = useSelector(userState);
    const { moderationStatus, isFavouriteStatus, isAddModalVisible } = useSelector(productState);
    const [filterOpen, setFilterOpen] = useState(false);
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const isMobile = useMediaQuery({ query: mdQueryDown });

    const pageTitle = formatMessage({ id: 'productsTitle' });

    const sellerStatuses = [
        { value: 'approved', title: formatMessage({ id: 'active' }) },
        { value: 'on_moderation', title: formatMessage({ id: 'onModeration' }) },
    ];

    const customerStatuses = [
        { value: 'all', title: formatMessage({ id: 'all' }) },
        { value: 'favourite', title: formatMessage({ id: 'favourites' }) },
    ];

    const currentStatuses = role === 'seller' ? sellerStatuses : customerStatuses;
    const currentStatusValue = role === 'seller' ? moderationStatus : isFavouriteStatus;

    const handleChooseFilter = async (value) => {
        if (role === 'seller') {
            dispatch(setModerationStatus(value));
            return;
        }
        await dispatch(setCurrentPage(1));
        dispatch(setIsFavouriteStatus(value));
    };

    const handleFilterToggle = () => {
        setFilterOpen((prev) => !prev);
    };

    const toggleModal = () => {
        dispatch(setProductChangeStatus(false));
        dispatch(setIsAddModalVisible());
    };

    useEffect(() => {
        dispatch(setProductProperty({
            property: 'pictures',
            value: [],
        }));

        dispatch(setProductProperty({
            property: 'specifications',
            value: [],
        }));
    }, [isAddModalVisible]);

    return (
        <>
            <div className="mb-5">
                <div className="d-flex flex-md-row flex-column-reverse mb-4">
                    <ButtonsGroup>
                        {currentStatuses.map(({ value, title }) => (
                            <Button
                                key={value}
                                title={title}
                                variant={currentStatusValue === value ? 'filled' : 'white'}
                                bordered
                                onClick={() => handleChooseFilter(value)}
                            />
                        ))}
                    </ButtonsGroup>
                    <ButtonsGroup className="justify-content-end align-items-center mb-md-0 mb-4">
                        {isMobile && <h1 className="flex-grow-1 mb-0">{pageTitle}</h1>}
                        {
                            role === SELLER && (
                                <Button
                                    variant="white"
                                    bordered
                                    onClick={toggleModal}
                                >
                                    <Plus />
                                    <span><FormattedMessage id={isMobile ? 'add' : 'addProduct'} /></span>
                                </Button>
                            )
                        }
                    </ButtonsGroup>
                </div>

                {role !== SELLER && (
                    <FilterContainer
                        open={filterOpen}
                        onToggle={handleFilterToggle}
                    >
                        <ProductsFilter
                            onSearch={() => setFilterOpen(false)}
                        />
                    </FilterContainer>
                )}
            </div>
            <ProductModal
                visible={isAddModalVisible}
                toggleModal={toggleModal}
            />
        </>
    );
};

export default ProductsHeader;
