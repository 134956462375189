import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { productState } from '../../../store/reducers/productReducer';
import ProductsCard from './ProductsCard';
import { CUSTOMER } from '../../../utils/consts';

const StyledProductsList = styled.div`
  --bs-gutter-y: 30px;
`;

const ProductsList = ({ role = CUSTOMER }) => {
    const { items } = useSelector(productState);
    return (
        <StyledProductsList className="row">
            {
                items.map((item) => (
                    <div key={item.id} className="col-12 col-md-6 col-lg-4 col-xxl-4 d-flex justify-content-center">
                        <ProductsCard product={item} role={role} />
                    </div>
                ))
            }
        </StyledProductsList>
    );
};

export default ProductsList;
