import React from 'react';
import { ReactComponent as Pdf } from '../svg/upload-pdf.svg';
import { ReactComponent as Doc } from '../svg/upload-doc.svg';

const getFileIcon = (type) => {
    if (type === 'application/pdf') return <Pdf />;

    return <Doc />;
};

export default getFileIcon;
