import React from 'react';
import styled, { css } from 'styled-components';
import { $basicRed } from '../../styles/styled/colors';

const StyledInputError = styled.span`
  color: ${$basicRed};

  ${({ position }) => !position && css`
    position: absolute;
    top: 3px;
    left: 5px;
    font-size: 10px;
    pointer-events: none;
  `}

  ${({ position }) => position === 'under' && css`
    font-size: 12px;
    width: 100%;
  `}
`;

const FormInputError = ({ error, position = '' }) => (
    <StyledInputError position={position}>
        {error}
    </StyledInputError>
);

export default FormInputError;
