import React from 'react';
import styled, { css } from 'styled-components';
import {
    $basicDarkGreen, $basicGreen, $basicGrey, $basicWhite, $light,
} from '../styles/styled/colors';

export const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 0 8px;
  border-radius: 4px;
  height: 42px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
  white-space: nowrap;
  width: ${({ width }) => width || 'fit-content'};
  cursor: pointer;
  user-select: none;
  transition: .1s ease-in;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 992px) {
    font-size: 12px;
  }

  svg {
    transition: .25s ease-in;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  &:disabled {
    cursor: default;
  }

  ${({ rounded }) => rounded && css`
    border-radius: 8px;
  `}
  ${({ variant }) => variant === 'filled' && css`
    background: ${$basicGreen};
    border: 1px solid ${$basicGreen};
    color: ${$basicWhite};

    &:disabled {
      background: ${$basicGrey};
    }
  `}
  ${({ variant }) => variant === 'outlined' && css`
    background: rgba(2, 38, 23, 0.67);
    border: 1px solid ${$basicGreen};
    color: ${$basicGreen};
  `}
  ${({ variant }) => variant === 'bordered' && css`
    background: none;
    border: 1px solid ${$basicDarkGreen};
    color: ${$basicDarkGreen};
  `}
  ${({ variant }) => variant === 'white' && css`
    background: ${$basicWhite};
    border: 1px solid ${$basicDarkGreen};
    color: ${$basicDarkGreen};

    &:hover {
      background: ${$light};
    }
  `}
  ${({ variant }) => variant === 'tab' && css`
    background: ${$basicWhite};
    border: 1px solid ${$basicGrey};
    color: ${$basicGrey};

    ${({ isActive }) => isActive && css`
      border: 1px solid ${$basicGreen};
      color: ${$basicGreen};
    `}
    &:hover {
      background: ${$light};
      border: 1px solid ${$basicDarkGreen};

      svg {
        fill: ${$basicDarkGreen};
      }
    }
  `}


  ${({ disabled }) => disabled && css`
    background: ${$basicGrey};
    border: 1px solid ${$basicGrey}!important;
    color: ${$basicWhite}!important;
    cursor: none;
    pointer-events: none;
  `}
  ${({ uppercase }) => uppercase && css`
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.04em;
  `}
`;

const Button = (props) => {
    const {
        children,
        title,
        variant = 'filled',
        rounded = false,
    } = props;

    return (
        <StyledButton
            variant={variant}
            rounded={rounded}
            {...props}
        >
            {children || title}
        </StyledButton>
    );
};

export default Button;
