import React, { memo } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { $basicWhite } from '../../../styles/styled/colors';
import AuctionsCardSlider from './AuctionsCardSlider';
import AuctionsCardInfo from './AuctionsCardInfo';
import { xlQueryDown } from '../../../styles/styled/sizes';
import { getImagePath } from '../../../utils/getImagePath';
import CardPreview from '../../../components/Card/CardPreview/CardPreview';

const StyledAuctionCard = styled.div`
  height: 215px;
  max-height: 215px;
  background: ${$basicWhite};
  border-radius: 4px;
  margin: 0 0 30px;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex: 1 0 auto;

  @media ${xlQueryDown} {
    flex-direction: column;
    max-height: 9999px;
    height: auto;
    align-items: flex-start;
    background: transparent;
  }
`;

const AuctionsCard = ({ auction }) => {
    const isTablet = useMediaQuery({ query: xlQueryDown });
    return (
        <StyledAuctionCard>
            {!isTablet ? (
                <AuctionsCardSlider pictures={auction.product.media} />
            ) : (
                <CardPreview
                    images={auction.product.media.map(({ path, uid, extension }) => (
                        getImagePath({ path, uid, extension })
                    ))}
                />
            )}
            <AuctionsCardInfo auction={auction} key={auction.endDate} />
        </StyledAuctionCard>
    );
};

export default memo(AuctionsCard);
