import React from 'react';
import styled from 'styled-components';
import Text from '../../../../ui/Text';
import { $basicWhite } from '../../../../styles/styled/colors';
import NotificationsItem from './NotificationsItem/NotificationsItem';

const StyledContainer = styled.div`
  margin-bottom: 35px;
`;

const StyledHeader = styled.div`
  margin-bottom: 30px;
  border-bottom: 1px dashed rgba(255, 255, 255, .5);
  padding-bottom: 14px;
`;

const StyledList = styled.div`
  > * {
    margin-bottom: 20px;
  }
`;

const NotificationsList = ({ title, items }) => (
    <StyledContainer>
        <StyledHeader>
            <Text variant="heading2" color={$basicWhite}>{title}</Text>
        </StyledHeader>
        <StyledList>
            {items.map((item) => (
                <NotificationsItem key={item.id} item={item} />
            ))}
        </StyledList>
    </StyledContainer>
);

export default NotificationsList;
