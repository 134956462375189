import {
    ABOUT_ROUTE,
    AUCTIONS_ROUTE,
    CONTACTS_ROUTE,
    HOME_ROUTE,
    PASSWORD_RESET,
    PERSONAL_NOTIFICATIONS_ROUTE,
    PERSONAL_ROUTE,
    POLICY_ROUTE,
    PRODUCTS_ROUTE,
    SUPPORT_ROUTE,
} from './utils/consts';
import { Notifications, Personal } from './pages/Personal';
import { ProductDetail, Products } from './pages/Products';
import { AuctionDetail, Auctions } from './pages/Auctions';
import { Support } from './pages/Support';
import { About } from './pages/About';
import { Contacts } from './pages/Contacts';
import { Home } from './pages/Home';
import { Policy } from './pages/Policy';
import { EN, RU, ZH } from './locales/localesMap';

export const authRoutes = [
    {
        path: `${PRODUCTS_ROUTE}/:id`,
        Component: ProductDetail,
    },
    {
        path: PRODUCTS_ROUTE,
        Component: Products,
    },
    {
        path: `${AUCTIONS_ROUTE}/:id`,
        Component: AuctionDetail,
    },
    {
        path: AUCTIONS_ROUTE,
        Component: Auctions,
    },
    {
        path: PERSONAL_NOTIFICATIONS_ROUTE,
        Component: Notifications,
    },
    {
        path: PERSONAL_ROUTE,
        Component: Personal,
    },
];

export const publicRoutes = [
    {
        path: `${AUCTIONS_ROUTE}/:id`,
        Component: AuctionDetail,
    },
    {
        path: `${PRODUCTS_ROUTE}/:id`,
        Component: ProductDetail,
    },
    {
        path: AUCTIONS_ROUTE,
        Component: Auctions,
    },
    {
        path: PRODUCTS_ROUTE,
        Component: Products,
    },
    {
        path: HOME_ROUTE,
        Component: Home,
    },
    {
        path: ABOUT_ROUTE,
        Component: About,
    },
    {
        path: CONTACTS_ROUTE,
        Component: Contacts,
    },
    {
        path: SUPPORT_ROUTE,
        Component: Support,
    },
    {
        path: POLICY_ROUTE,
        Component: Policy,
    },
    {
        path: PASSWORD_RESET,
        Component: Home,
    },
];

export const publicNavRoutes = [
    {
        path: ABOUT_ROUTE,
        Component: About,
        title: {
            [EN]: 'About',
            [RU]: 'О компании',
            [ZH]: '关于公司',
        },
    },
    {
        path: CONTACTS_ROUTE,
        Component: Contacts,
        title: {
            [EN]: 'Contacts',
            [RU]: 'Контакты',
            [ZH]: '联络人',
        },
    },
    {
        path: SUPPORT_ROUTE,
        Component: Support,
        title: {
            [EN]: 'Support',
            [RU]: 'Помощь',
            [ZH]: '支援服务',
        },
    },
];

export const authNavRoutes = [
    {
        path: AUCTIONS_ROUTE,
        Component: Auctions,
        title: {
            [EN]: 'Auctions',
            [RU]: 'Аукционы',
            [ZH]: '拍卖',
        },
    },
    {
        path: PRODUCTS_ROUTE,
        Component: Products,
        title: {
            [EN]: 'Products',
            [RU]: 'Продукты',
            [ZH]: '产品中心',
        },
    },
    ...publicNavRoutes,
];
