import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { useTimer } from 'react-timer-hook';
import { useIntl } from 'react-intl';
import useAuction from '../hooks/useAuction';
import { Text } from '../../../ui';
import { $basicDarkGreen, $basicGrey2, $basicRed } from '../../../styles/styled/colors';
import { mdQueryDown } from '../../../styles/styled/sizes';

const StyledTimer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 100px 0 30px;
  justify-content: ${({ isCompleted }) => (isCompleted ? 'flex-end' : 'space-between')};

  @media ${mdQueryDown} {
    padding: 0 30px;
    margin-top: 15px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    background: linear-gradient(90deg, #CEFFC1 0%, #A9FFEA 97.55%);
    ${({ isLastHour }) => isLastHour && css`
      background: linear-gradient(90deg, #FFC1E6 0%, #FFA9A9 97.55%);
    `}
    opacity: 0.6;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    z-index: 0;
  }

  > div {
    position: relative;
    z-index: 1;
  }
`;
const getTime = (value) => {
    const leftDigit = value >= 10 ? value.toString()[0] : '0';
    const rightDigit = value >= 10 ? value.toString()[1] : value.toString();
    return leftDigit + rightDigit;
};

const AuctionsEndTimer = ({ auction }) => {
    const { formatMessage } = useIntl();
    const {
        endDate, startDate, isActive,
        isCompleted,
    } = useAuction(auction);
    const [expiryTimestamp, setExpiryTimestamp] = useState(new Date());
    const [time, setTime] = useState('');
    const {
        seconds,
        minutes,
        hours,
        days,
        restart,
    } = useTimer({ expiryTimestamp });
    useEffect(() => {
        const date = isActive
            ? endDate
            : startDate;
        const expiryTime = new Date(date);
        setExpiryTimestamp(expiryTime);
        restart(expiryTime);
    }, [endDate, startDate, isActive]);

    useEffect(() => {
        let formattedTime = '';
        if (days) {
            formattedTime = formatMessage(
                {
                    id: 'timeFull',
                },
                {
                    days,
                    hours,
                    minutes: getTime(minutes),
                    seconds: getTime(seconds),
                },
            );
        }
        if (!days && hours) {
            formattedTime = formatMessage(
                {
                    id: 'timeWithSeconds',
                },
                {
                    hours,
                    minutes: getTime(minutes),
                    seconds: getTime(seconds),
                },
            );
        }
        if (!days && !hours && minutes) {
            formattedTime = formatMessage(
                {
                    id: 'timeMinutesSeconds',
                },
                {
                    minutes: getTime(minutes),
                    seconds: getTime(seconds),
                },
            );
        }
        if (!days && !hours && !minutes && seconds) {
            formattedTime = formatMessage(
                {
                    id: 'timeSeconds',
                },
                {
                    seconds: getTime(seconds),
                },
            );
        }
        setTime(formattedTime);
    }, [days, minutes, seconds, hours]);

    // eslint-disable-next-line no-nested-ternary
    const status = isCompleted
        ? formatMessage({ id: 'auctionCompleted' })
        : isActive
            ? formatMessage({ id: 'beforeEnd' })
            : formatMessage({ id: 'beforeStart' });
    const isLastHour = !hours && !days && (minutes || seconds);

    return (
        <StyledTimer isCompleted={isCompleted} className="col-12 col-sm-8" isLastHour={isLastHour}>
            <Text
                variant="heading"
                color={$basicGrey2}
                className="me-2 me-md-5"
                style={{ whiteSpace: 'nowrap' }}
            >
                {status}
            </Text>
            {
                !isCompleted && (
                    <div className="d-flex align-items-center">
                        <AiOutlineClockCircle
                            color={isLastHour ? $basicRed : $basicDarkGreen}
                            size={20}
                        />
                        <Text
                            variant="body2"
                            className="ms-2"
                            color={isLastHour ? $basicRed : $basicDarkGreen}
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            {time}
                        </Text>
                    </div>
                )
            }
        </StyledTimer>
    );
};

export default AuctionsEndTimer;
