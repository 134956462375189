import { createAsyncThunk } from '@reduxjs/toolkit';
import $api from '../../api/api';

export const prepareFiles = createAsyncThunk(
    'utils/file',
    async (files, { rejectWithValue }) => {
        try {
            const { data } = await $api.post(
                'utils/file/',
                files,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                },
            );

            return !data.error ? data : [];
        } catch (e) {
            return rejectWithValue(e.message);
        }
    },
);
export const deliveryStatuses = createAsyncThunk(
    'utils/deliveryStatuses',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await $api.get('delivery-statuses/');
            return { data };
        } catch (e) {
            return rejectWithValue(e.message);
        }
    },
);
