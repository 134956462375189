import React, { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { mdQueryDown, xsQueryDown } from '../../../styles/styled/sizes';
import { getCategoryIcon } from '../../../utils/getCategoryIcon';
import { addToFavourite, removeFromFavourite } from '../../../store/actions/productActions';
import { getImagePath } from '../../../utils/getImagePath';
import { ReactComponent as AlertIcon } from '../../../svg/alert.svg';
import { Button } from '../../../ui';
import { $basicYellow } from '../../../styles/styled/colors';
import {
    CUSTOMER, PRODUCTS_ROUTE, SELLER,
} from '../../../utils/consts';
import CardPreview from '../../../components/Card/CardPreview/CardPreview';
import CardInfoTop from '../../../components/Card/CardInfo/CardInfoTop';
import CardInfoMiddle from '../../../components/Card/CardInfo/CardInfoMiddle';
import CardInfoBottom from '../../../components/Card/CardInfo/CardInfoBottom';
import { CardInfo } from '../../../components/Card/styles';
import { setAuctionsProperty } from '../../../store/reducers/auctionsReducer';

export const StyledProductsCard = styled.div`
  max-width: 370px;
  width: 100%;
  margin: 0 auto;
  background: none;
  border: none;
  filter: drop-shadow(0px 10px 30px rgba(10, 9, 75, 0.14));
  flex: 1;
  display: flex;
  flex-direction: column;

  @media ${mdQueryDown} {
    margin-left: -7px;
    margin-right: -7px;
    width: calc(100% + 14px);
  }
`;

export const StyledProductsModeration = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;

  @media ${xsQueryDown} {
    padding: 8px 0 8px 0;
    &-text {
      color: ${$basicYellow};
      font-size: 14px;
    }
  }

  p {
    color: ${$basicYellow};
    font-size: 16px;
    margin-left: 10px;
  }

  svg {
    position: relative;
    top: -1px;
  }
`;

const StyledCardInfo = styled(CardInfo)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledCardInfoMiddle = styled(CardInfoMiddle)`
  flex: 1;
`;

const ProductsCard = ({ product, role = CUSTOMER }) => {
    const isMobile = useMediaQuery({ query: mdQueryDown });
    const dispatch = useDispatch();
    const history = useHistory();
    const { locale, formatMessage } = useIntl();
    const {
        isFavorite,
        factory,
        category,
        name,
        boxWeight,
        storageLife,
        specifications,
        media,
        id,
        moderationStatus,
        moderatedFromDt,
    } = product;

    const handleAuction = (e) => {
        e.stopPropagation();

        dispatch(setAuctionsProperty({ property: 'formData', value: { productId: id } }));
        dispatch(setAuctionsProperty({ property: 'addModalOpen', value: true }));
    };

    const handleProduct = (e) => {
        e.stopPropagation();
        history.push(`${PRODUCTS_ROUTE}/${id}`);
    };

    const icon = getCategoryIcon(category?.logo);

    const optionsToLocalShort = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    };

    const formatDate = (date) => new Date(date).toLocaleString('ru', optionsToLocalShort);

    const getPreviewMedia = () => {
        if (isMobile) return media;

        return media.length ? [media[0]] : [];
    };

    const handleFavorite = (e) => {
        e.stopPropagation();

        if (isFavorite) {
            dispatch(removeFromFavourite(id));
            return;
        }
        dispatch(addToFavourite(id));
    };

    const previewMedia = useMemo(() => getPreviewMedia(), [media]);

    return (
        <StyledProductsCard onClick={handleProduct}>
            <CardPreview
                withLike={role !== SELLER}
                onFavourite={handleFavorite}
                isFavourite={isFavorite}
                images={
                    previewMedia.map(({ path, extension, uid }) => (
                        getImagePath({ path, extension, uid })
                    ))
                }
                extraImages={
                    specifications
                        .filter(({ mimetype }) => mimetype.indexOf('image/') >= 0)
                        .map(({ path, extension, uid }) => (
                            getImagePath({ path, extension, uid })
                        ))
                }
            />
            <StyledCardInfo>
                <CardInfoTop
                    items={[factory?.country?.name[locale], factory?.name]}
                />
                <StyledCardInfoMiddle
                    categoryName={category?.name[locale]}
                    categoryIcon={icon}
                    name={name[locale]}
                    weight={+boxWeight}
                    expiration={+storageLife}
                />
                <CardInfoBottom>
                    {
                        moderationStatus === 'on_moderation' && role === 'seller'
                            ? (
                                <StyledProductsModeration>
                                    <AlertIcon />
                                    <p>
                                        <FormattedMessage
                                            id="onModerationFrom"
                                            values={{ date: formatDate(moderatedFromDt) }}
                                        />
                                    </p>
                                </StyledProductsModeration>
                            )
                            : (
                                <Button
                                    title={formatMessage({ id: role === SELLER ? 'createAuction' : 'showProduct' })}
                                    width="100%"
                                    variant="bordered"
                                    className="mx-auto"
                                    onClick={role === SELLER ? handleAuction : handleProduct}
                                />
                            )
                    }
                </CardInfoBottom>
            </StyledCardInfo>
        </StyledProductsCard>
    );
};

export default ProductsCard;
