import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled, { css } from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import is from 'is_js';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import getSymbolFromCurrency from 'currency-symbol-map';
import { $basicBlack, $basicGrey, $basicGrey2 } from '../../../styles/styled/colors';
import FormInput from '../../../components/Form/FormInput';
import FormSelect from '../../../components/Form/FormSelect';
import { auctionCreate, productsForAuctionList } from '../../../store/actions/auctionsActions';
import {
    auctionsState,
    setAddModalVisibility,
    setAuctionsProperty,
    setDeliveryTerms,
} from '../../../store/reducers/auctionsReducer';
import { localeState } from '../../../store/reducers/localesReducer';
import { Button, Loader, Text } from '../../../ui';
import {
    APPROVED, CANT_CREATE_OR_CHANGE_ON_MODERATION, PRODUCTS_ROUTE, TIME_AFTER,
} from '../../../utils/consts';
import FormCurrencySelect from '../../../components/Form/FormCurrencySelect';
import FormDatePicker from '../../../components/Form/FormDatePicker';
import FormCheckbox from '../../../components/Form/FormCheckbox';
import { RU } from '../../../locales/localesMap';
import FormError from '../../../components/Form/FormError';
import useValidator from '../../../utils/hooks/useValidator';
import { setIsAddModalVisible } from '../../../store/reducers/productReducer';
import Modal from '../../../ui/Modal/Modal';
import { xlQueryDown } from '../../../styles/styled/sizes';
import { userState } from '../../../store/reducers/userReducer';
import { statusColor } from '../../../utils/statusColors';
import localesErrors from '../../../locales/localesErrors';

const StyledAuthBody = styled.div`
  margin: 0 auto;
  max-width: 100%;

  .styled-auth-body {
    &__input {
      margin: 0 0 20px;

      input {
        height: 40px;
        border-radius: 4px;
        border: 1px solid ${$basicGrey};
      }
    }

    &__button {
      margin: 40px 0 10px;
    }

    &__links {
      display: flex;
      justify-content: space-between;
      margin: 15px 0 0;
    }

    &__policy {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      margin: 16px auto 0;
      text-align: center;
      color: ${$basicGrey};
      width: 80%;
    }
  }
`;

const StyledTitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 30px;
  text-align: left;
  color: ${$basicBlack};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  ${({ isMobile }) => isMobile && css`
    font-size: 22px;
    line-height: 26px;
  `}
`;

const StyledNoLabel = styled.div`
  padding-top: 34px;

  @media ${xlQueryDown} {
    padding-top: 26px;
  }
`;

const AuctionModal = ({ handleCancel }) => {
    const [values, setValues] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [optionsProducts, setOptionsProductions] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const { formatMessage } = useIntl();
    const {
        products,
        currencyCode,
        deliveryTerms,
        validation,
        errors,
        isLoading,
        auction,
        formData,
    } = useSelector(auctionsState);
    const { moderationStatus } = useSelector(userState);
    const { currentLocale } = useSelector(localeState);
    const isMobile = is.mobile() || is.tablet();
    const { checkForm } = useValidator(validation);

    const addProductHandler = async () => {
        await dispatch(setIsAddModalVisible());
        dispatch(setAddModalVisibility(false));
        history.push(PRODUCTS_ROUTE);
    };

    const clearErrors = () => {
        if (!!Object.values(formErrors).length) {
            setFormErrors({});
        }
    };

    const submitFormHandler = async () => {
        const currentErrors = await checkForm(values);
        setFormErrors(currentErrors);
        console.log('values', values);
        console.log('currentErrors', currentErrors);
        const canSubmit = !Object.values(currentErrors).length;
        if (canSubmit) {
            const {
                productId,
                startPricePerTon,
                totalWeight,
                date,
                startTime,
                endTime,
                etdShipmentDate,
            } = values;
            if (moment(startTime).isSameOrAfter(moment(endTime))) {
                setFormErrors({ endTime: localesErrors[currentLocale][TIME_AFTER] });
            } else {
                const deliveryTerm = deliveryTerms.find((term) => term.checked);
                const startDay = moment(date).format('YYYY-MM-DD');
                const data = {
                    productId,
                    currencyCode,
                    startPricePerTon: +startPricePerTon,
                    totalWeight: +totalWeight,
                    deliveryConditions: deliveryTerm.id,
                    startDate: `${startDay}T${moment(startTime).format('HH:mm')}`,
                    endDate: `${startDay}T${moment(endTime).format('HH:mm')}`,
                    etdShipmentDate: `${moment(etdShipmentDate).format('YYYY-MM-DD')}T23:59:59`,
                    etdShipmentDateFrom: etdShipmentDate[0],
                    etdShipmentDateTo: etdShipmentDate[1],
                };
                dispatch(auctionCreate({ data }));
            }
        }
    };

    const inputHandler = (id) => (evt) => {
        const { value } = evt.target;
        setValues({ ...values, [id]: value });
    };

    const selectHandler = (id) => (value) => {
        setValues({ ...values, [id]: value });
    };

    const datePickerHandler = (id) => (date) => {
        if (date) {
            const value = date[0] || date;
            if (moment(value).isValid()) {
                setValues({ ...values, [id]: date });
            } else {
                const currentValues = { ...values };
                delete currentValues[id];
                setValues(currentValues);
            }
        }
    };

    const currencySelectHandler = (value) => () => {
        dispatch(setAuctionsProperty({ property: 'currencyCode', value }));
    };

    const deliveryTermsHandler = (id) => {
        dispatch(setDeliveryTerms({ id }));
    };

    useEffect(() => {
        //TODO: нужно будет организовать поиск на сервере
        dispatch(productsForAuctionList({ pageSize: 1000 }));
    }, []);
    const ton = formatMessage({ id: 'ton' });
    const currencyPerTon = `${getSymbolFromCurrency(currencyCode)}/${ton}`;

    useEffect(() => {
        const options = products.filter(({ name }) => name[RU])
            .map(({ id, name }) => ({ id, name: name[currentLocale] || name[RU] }));
        if (!!options.length) {
            setOptionsProductions(options);
        }
    }, [products]);

    useEffect(() => {
        if (auction) {
            const {
                totalWeight, startPricePerTon,
            } = auction;
            setValues({
                ...values,
                productId: auction.product.id,
                startPricePerTon,
                totalWeight,
            });
        }
    }, [auction]);

    useEffect(() => {
        if (formData && formData.productId) {
            setValues({ ...values, productId: +formData.productId });
        }
    }, [formData]);
    return (
        <>
            <Loader active={isLoading} />
            <Modal
                title={(
                    <StyledTitle isMobile={isMobile}>
                        <Text variant="heading3"><FormattedMessage id="createAuctionFormTitle" /></Text>
                        {
                            moderationStatus !== APPROVED && (
                                <Text
                                    className="ms-3"
                                    variant="heading"
                                    color={statusColor(moderationStatus)}
                                >
                                    {localesErrors[currentLocale][CANT_CREATE_OR_CHANGE_ON_MODERATION]}
                                </Text>
                            )
                        }
                    </StyledTitle>
                )}
                isModalVisible
                toggleModal={handleCancel}
            >
                <StyledAuthBody>
                    <div className="row">
                        <div className="col-12 col-xl-9 col-md-8">
                            <FormSelect
                                showSearch
                                options={optionsProducts}
                                onChange={selectHandler('productId')}
                                label={formatMessage({ id: 'selectOrCreateProduct' })}
                                error={formErrors.productId}
                                onFocus={clearErrors}
                                value={values.productId}
                                placeholder={formatMessage({ id: 'selectOrCreateProduct' })}
                            />
                        </div>
                        <StyledNoLabel className="col-12 col-xl-3 col-md-4 col-sm-7">
                            <Button
                                title={formatMessage({ id: 'addProduct' })}
                                onClick={addProductHandler}
                                variant="bordered"
                                className="w-100"
                            />
                        </StyledNoLabel>
                    </div>

                    <div className="row mt-5">
                        <div className="col-md-3 col-sm-4 mb-5 mb-md-0">
                            <FormCurrencySelect
                                label={<FormattedMessage id="currencyOfContract" />}
                                onClick={currencySelectHandler}
                                currencyCode={currencyCode}
                            />
                        </div>
                        <div className="col-md-6 col-sm-8">
                            <FormInput
                                label={<FormattedMessage id="startPricePerTon" />}
                                suffix={currencyPerTon}
                                type="number"
                                error={formErrors.startPricePerTon}
                                onChange={inputHandler('startPricePerTon')}
                                onFocus={clearErrors}
                                value={values.startPricePerTon}
                            />
                        </div>
                        <div className="col-12 col-md-3 mt-sm-0 mt-5">
                            <FormInput
                                label={<FormattedMessage id="weightProducts" />}
                                placeholder={formatMessage({ id: 'totalWeightPlaceholder' })}
                                suffix={`${ton}.`}
                                type="number"
                                error={formErrors.totalWeight}
                                onChange={inputHandler('totalWeight')}
                                onFocus={clearErrors}
                                value={values.totalWeight}
                            />
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div
                            className="col-xl-6 col-lg-4 col-12 d-flex justify-content-between align-items-center mb-lg-0 mb-5"
                        >
                            <FormDatePicker
                                label={<FormattedMessage id="startDate" />}
                                onChange={datePickerHandler('date')}
                                error={formErrors.date}
                                onFocus={clearErrors}
                                value={values.date}
                            />
                        </div>
                        <div className="col-12 col-xl-3 col-lg-4 col-sm-6 mb-sm-0 mb-5">
                            <FormDatePicker
                                label={<FormattedMessage id="startTime" />}
                                onChange={datePickerHandler('startTime', 'time')}
                                type="time"
                                error={formErrors.startTime}
                                onFocus={clearErrors}
                                value={values.startTime}
                                id="startTime"
                            />
                        </div>
                        <div className="col-12 col-xl-3 col-lg-4 col-sm-6">
                            <FormDatePicker
                                label={<FormattedMessage id="endTime" />}
                                onChange={datePickerHandler('endTime', 'time')}
                                type="time"
                                error={formErrors.endTime}
                                onFocus={clearErrors}
                                value={values.endTime}
                                id="endTime"
                            />
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div
                            className="col-sm-6 d-flex justify-content-between align-items-center"
                        >
                            <FormCheckbox
                                label={<FormattedMessage id="deliveryTerms" />}
                                items={deliveryTerms}
                                onChange={deliveryTermsHandler}
                            />
                        </div>
                        <div
                            className="col-sm-6 d-flex justify-content-between align-items-center mt-sm-0 mt-5"
                        >
                            {/*    <FormDatePicker*/}
                            {/*        label={<FormattedMessage id="dateShippingETO" />}*/}
                            {/*        onChange={datePickerHandler('etdShipmentDate')}*/}
                            {/*        error={formErrors.etdShipmentDate}*/}
                            {/*        onFocus={clearErrors}*/}
                            {/*        value={values.etdShipmentDate}*/}
                            {/*    />*/}
                            <FormDatePicker
                                label={<FormattedMessage id="dateShippingETO" />}
                                onChange={datePickerHandler('etdShipmentDate')}
                                onFocus={clearErrors}
                                error={formErrors.etdShipmentDate}
                                value={values.etdShipmentDate}
                                range
                            />
                        </div>

                        {/*<div className="col-12 col-xl-3 col-lg-4 col-sm-6 mb-sm-0 mb-5">*/}
                        {/*    <FormDatePicker*/}
                        {/*        label={<FormattedMessage id="dateShippingETO" />}*/}
                        {/*        onChange={datePickerHandler('etdShipmentDateFrom')}*/}
                        {/*        error={formErrors.etdShipmentDateFrom}*/}
                        {/*        onFocus={clearErrors}*/}
                        {/*        value={values.etdShipmentDateFrom}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {/*<div className="col-12 col-xl-3 col-lg-4 col-sm-6">*/}
                        {/*    <FormDatePicker*/}
                        {/*        label={<FormattedMessage id="dateShippingETO" />}*/}
                        {/*        onChange={datePickerHandler('etdShipmentDateTo')}*/}
                        {/*        error={formErrors.etdShipmentDateTo}*/}
                        {/*        onFocus={clearErrors}*/}
                        {/*        value={values.etdShipmentDateTo}*/}
                        {/*        range*/}
                        {/*    />*/}
                        {/*</div>*/}

                    </div>
                    {
                        !!errors?.length && <FormError className="mt-4" errors={errors} />
                    }
                    <div className="row mt-5">
                        <div className="col-12 col-sm-7 mb-sm-0 mb-3">
                            <Text variant="heading" color={$basicGrey2}>
                                <FormattedMessage id="createAuctionDescription" />
                            </Text>
                        </div>
                        <div className="col-12 col-sm-5">
                            <Button
                                onClick={submitFormHandler}
                                variant="filled"
                                title={formatMessage({ id: 'createAuction' })}
                                width="100%"
                                uppercase
                                disabled={moderationStatus !== APPROVED || isLoading}
                            />
                        </div>
                    </div>
                </StyledAuthBody>
            </Modal>
        </>
    );
};

export default AuctionModal;
