import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import { auctionsState } from '../../../store/reducers/auctionsReducer';
import useAuction from '../hooks/useAuction';
import { $basicGrey, $basicGrey2 } from '../../../styles/styled/colors';
import { localeState } from '../../../store/reducers/localesReducer';
import { mdQueryDown, smQueryDown } from '../../../styles/styled/sizes';
import { ReactComponent as Truck } from '../../../svg/logistics-truck.svg';
import Steps from '../../../components/Steps';
import { Button } from '../../../ui';
import linkClick from '../../../utils/linkClick';
import { getImagePath } from '../../../utils/getImagePath';
import ButtonsGroup from '../../../ui/ButtonsGroup';

const StyledTruck = styled.div`
    margin-left: -25px;
    margin-right: 10px;
    flex: 1;
    overflow: hidden;
    max-width: 200px;
    
    svg {
        width: 100%;
        height: 100%;
    }
    
    path {
        stroke: ${$basicGrey2};
    }
`;

const StyledStepsContainer = styled.div`
    flex: 1;
`;

const StyledDateText = styled.div`
    font-size: 16px;
    line-height: 18px;
    color: ${$basicGrey};
    text-align: center;
    margin: 40px 0 0;
    
    @media ${smQueryDown} {
        order: -1;
        margin: 0 0 40px;
    }
`;

const StyledButtonsGroup = styled(ButtonsGroup)`
    justify-content: center;
    margin: 44px auto 0;
`;

const AuctionDetailInfoSteps = ({ auction }) => {
    const isMobile = useMediaQuery({ query: mdQueryDown });
    const { currentLocale } = useSelector(localeState);
    const [current, setCurrent] = useState(0);
    const { deliveryStatuses } = useSelector(auctionsState);
    const {
        id,
        deliveryStatus,
        deliveryDate,
        documentsArchive,
        adminAccount,
    } = useAuction(auction);

    const formattedDate = !!deliveryDate && moment(deliveryDate).format('YYYY.MM.DD');

    const handleDocClick = () => {
        if (!documentsArchive) return;

        linkClick(getImagePath({
            path: documentsArchive.path,
            uid: documentsArchive.uid,
            extension: documentsArchive.extension,
        }));
    };

    const handleAccountClick = () => {
        if (!adminAccount) return;

        linkClick(getImagePath({
            path: adminAccount.path,
            uid: adminAccount.uid,
            extension: adminAccount.extension,
        }));
    };

    useEffect(() => {
        if (deliveryStatus) {
            const currentIndex = deliveryStatuses.findIndex((status) => status.id === deliveryStatus.id);
            setCurrent(currentIndex);
        }
    }, [id, deliveryStatus]);

    return (
        <div className="d-flex flex-column">
            <div className="d-flex align-items-start w-100">
                {isMobile && (
                    <StyledTruck>
                        <Truck />
                    </StyledTruck>
                )}
                <StyledStepsContainer>
                    <Steps
                        items={deliveryStatuses.map(({ code, name }) => ({
                            key: code,
                            label: name[currentLocale],
                        }))}
                        current={current}
                        vertical={isMobile}
                    />
                </StyledStepsContainer>
            </div>
            {formattedDate && (
                <StyledDateText>Плановая дата получения: {formattedDate}</StyledDateText>
            )}
            <StyledButtonsGroup>
                {!!adminAccount && (
                    <Button
                        uppercase
                        onClick={handleAccountClick}
                    >
                        Посмотреть счет
                    </Button>
                )}

                {!!documentsArchive && (
                    <Button
                        uppercase
                        onClick={handleDocClick}
                    >
                        <FormattedMessage id="documentation" />
                    </Button>
                )}
            </StyledButtonsGroup>
        </div>
    );
};

export default AuctionDetailInfoSteps;
