import React, { useCallback, useState } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import useAuction from "../hooks/useAuction";
import { BID, ON_MODERATION } from "../../../utils/consts";
import {
	setBidParams,
	setConfirmOpen,
} from "../../../store/reducers/auctionsReducer";
import { Button, Text } from "../../../ui";
import { userState } from "../../../store/reducers/userReducer";

const AuctionDetailCustomerBids = ({ auction }) => {
	const dispatch = useDispatch();
	const [bid, setBid] = useState(BID);
	const { id, price, currencySymbol, category } = useAuction(auction);
	const { mainProductCategory, moderationStatus, availableProductCategories } =
		useSelector(userState);

	const increment = () => {
		setBid((value) => value + BID);
	};

	const decrement = () => {
		setBid((value) => (value > 0 ? value - BID : value));
	};

	const addBid = useCallback(() => {
		dispatch(setConfirmOpen(true));
		dispatch(
			setBidParams({
				amount: +price + +bid,
				currencyCode: "CNY",
				id,
			}),
		);
	}, [price, bid, dispatch]);
	const categoriesId = [...availableProductCategories, mainProductCategory].map(
		(c) => c.id,
	);
	const isAccess =
		categoriesId.includes(category.id) && moderationStatus !== ON_MODERATION;
	return (
		<div className='border-bottom pb-3'>
			{isAccess ? (
				<>
					<div className='pt-4 d-flex row flex-grow-1'>
						<div className='col-12 col-sm-4 d-flex justify-content-between align-items-center mb-md-0 mb-4'>
							<Button variant='tab' onClick={decrement} width='42px' uppercase>
								<AiOutlineMinus />
							</Button>
							<Text variant='bold'>
								{bid}&nbsp;{currencySymbol}
							</Text>
							<Button variant='tab' onClick={increment} width='42px' uppercase>
								<AiOutlinePlus />
							</Button>
						</div>
						<div className='col-12 col-sm-8'>
							<Button
								onClick={addBid}
								title={<FormattedMessage id='doBid' />}
								uppercase
								width='100%'
							/>
						</div>
					</div>
					<div className='d-flex justify-content-end mt-3'>
						<div className='col-12 col-sm-8 text-center'>
							<FormattedMessage id='bidNotification' />
						</div>
					</div>
				</>
			) : (
				<Text
					variant='heading2'
					className='d-flex justify-content-center col-12 pt-5 pb-4'>
					<FormattedMessage id='bidAccessDenied' />
				</Text>
			)}
		</div>
	);
};

export default AuctionDetailCustomerBids;
