import React from 'react';
import {
    StyledControl, StyledLabel, StyledSwitch,
} from './styles';

const Switch = ({
    label,
    checked = false,
    defaultChecked = false,
    className,
    onChange,
}) => (
    <StyledControl className={`${className} ${checked && 'checked'}`}>
        <StyledLabel>{label}</StyledLabel>
        <StyledSwitch
            checked={checked}
            defaultChecked={defaultChecked}
            onChange={onChange}
        />
    </StyledControl>
);

export default Switch;
