import { EN, RU, ZH } from './localesMap';
import {
    CANT_CHANGE_WHILE_AUCTION,
    CANT_CREATE_OR_CHANGE_ON_MODERATION, CANT_PARTICIPATE_IN_AUCTION,
    CHECK_FORM,
    FIELD_REQUIRED,
    NOT_FOUND,
    PASSWORDS_MUST_MATCH,
    PERMISSION_DENIED, PHONE_NOT_FOUND,
    TIME_AFTER,
    VALIDATION_ERROR,
    WRONG_LOGIN_OR_PASSWORD,
} from '../utils/consts';

export default {
    [RU]: {
        [NOT_FOUND]: 'Пользователь не найден',
        [WRONG_LOGIN_OR_PASSWORD]: 'Неправильный логин или пароль',
        [FIELD_REQUIRED]: 'Поле обязательно для заполнения',
        [VALIDATION_ERROR]: 'Введите корректный номер телефона',
        [CHECK_FORM]: 'Проверьте правильность заполнения формы',
        [PERMISSION_DENIED]: 'У вас нет разрешения на выполнение этого действия.',
        [TIME_AFTER]: 'Время завершения не может быть меньше или равно времени начала',
        [CANT_CREATE_OR_CHANGE_ON_MODERATION]: 'Вы не можете создавать/изменять продукты/аукционы, пока профиль на модерации',
        [CANT_CHANGE_WHILE_AUCTION]: 'Вы не можете изменять продукты, которые учавствуют в текущих или будущих аукционах',
        [CANT_PARTICIPATE_IN_AUCTION]: 'Вы не можете участвовать в аукционах, пока ваш профиль на модерации',
        [PASSWORDS_MUST_MATCH]: 'Пароли должны сопадать',
        [PHONE_NOT_FOUND]: 'Телефон не найден',
    },
    [EN]: {
        [NOT_FOUND]: 'User not found',
        [WRONG_LOGIN_OR_PASSWORD]: 'Incorrect login or password',
        [FIELD_REQUIRED]: 'The field is required to fill in',
        [VALIDATION_ERROR]: 'Enter the correct phone number',
        [CHECK_FORM]: 'Проверьте правильность заполнения формы',
        [PERMISSION_DENIED]: 'You do not have permission to perform this action.',
        [TIME_AFTER]: 'The completion time cannot be less than or equal to the start time',
        [CANT_CREATE_OR_CHANGE_ON_MODERATION]: 'You cannot create/modify products/auctions, open a moderation profile',
        [CANT_CHANGE_WHILE_AUCTION]: 'You cannot change products that participate in current or future auctions',
        [CANT_PARTICIPATE_IN_AUCTION]: 'You cannot participate in auctions while your profile is on moderation',
        [PASSWORDS_MUST_MATCH]: 'Passwords must match',
        [PHONE_NOT_FOUND]: 'Phone number not found',
    },
    [ZH]: {
        [NOT_FOUND]: '未找到用户',
        [WRONG_LOGIN_OR_PASSWORD]: '登入或密码不正确',
        [FIELD_REQUIRED]: '该字段需要填写',
        [VALIDATION_ERROR]: '输入正确的电话号码',
        [CHECK_FORM]: '检查填写表格的正确性',
        [PERMISSION_DENIED]: '您没有执行此操作的权限。',
        [TIME_AFTER]: '完成時間',
        [CANT_CREATE_OR_CHANGE_ON_MODERATION]: '由于您的产品简介正在审核中，因此您不能创建或修改产品及拍卖',
        [CANT_CHANGE_WHILE_AUCTION]: '由于您的产品已参加拍卖，因此无法更改产品。',
        [CANT_PARTICIPATE_IN_AUCTION]: '您的产品简介正在修改中，因此您无法参加拍卖。',
        [PASSWORDS_MUST_MATCH]: '密码必须匹配',
        [PHONE_NOT_FOUND]: '电话号码不存在',
    },
};
