import React, {
    useEffect, useMemo, useRef, useState,
} from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useIntl } from 'react-intl';
import { Badge } from 'antd';
import { useSelector } from 'react-redux';
import { authNavRoutes, publicNavRoutes } from '../../routes';
import { $basicBlack, $basicGreen, $basicWhite } from '../../styles/styled/colors';
import { PERSONAL_NOTIFICATIONS_ROUTE } from '../../utils/consts';
import Lang from '../../ui/Lang/Lang';
import Phone from '../../components/Phone';
import { ReactComponent as Bell } from '../../svg/bell.svg';
import { mdQueryDown, xlQueryDown } from '../../styles/styled/sizes';
import NavMenu from '../NavMenu/NavMenu';
import useAuth from '../../utils/hooks/useAuth';
import useNav from '../hooks/useNav';
import { notificationState } from '../../store/reducers/notificationReducer';
import HeaderPersonalSelect from './HeaderPersonalSelect';
import { userState } from '../../store/reducers/userReducer';

const StyledNav = styled.nav`
  display: flex;
  align-items: center;
  z-index: 2;

  &.mobile {
    position: fixed;
    top: 75px;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .6);
    z-index: 9999;
    opacity: 0;
    transition: .3s;
    display: none;

    @media ${mdQueryDown} {
      top: 55px;
      background: transparent;
    }

    &.opening {
      display: block;
    }

    &.open {
      opacity: 1;
      display: block;
    }
  }

  .styled-nav {
    &-wrapper {
      transition: .25s ease-in-out;

      &__mobile {
        background: ${$basicWhite};
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        transform: translateX(100%);
        padding: 30px;
        width: 100%;
        max-width: 320px;

        @media ${mdQueryDown} {
          background: rgba(255, 255, 255, .95);
          padding: 15px 0;
          max-width: 9999px;
          display: flex;
          flex-direction: column;

          > .container {
            flex: 1;
            display: flex;
            flex-direction: column;
          }
        }
      }

      &__open {
        transform: translateX(0);
      }
    }

    &-user {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: ${$basicBlack};

      @media ${mdQueryDown} {
        margin-bottom: 80px;
        justify-content: flex-start;

        &__bottom {
          margin-top: auto;
          margin-bottom: 0;
          justify-content: space-between;
        }
      }
    }

    &-list {
      &__mobile {
        flex-direction: column;
        align-items: flex-start;

        li {
          margin: 0 0 24px 0;
        }
      }

      li {
        a {
          margin-bottom: 0;
        }
      }
    }

    &-link {
      color: currentColor;
      text-decoration: none;
      margin: 0 30px 0 0;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.02em;
      transition: .25s ease-in;
      display: flex;
      align-items: center;

      @media ${mdQueryDown} {
        margin-right: 43px;

        &:last-child {
          margin-right: 0;
        }
      }

      &:hover {
        color: ${$basicGreen};
      }

      &__mobile {
        color: ${$basicBlack};
      }

      &__active {
        color: ${$basicGreen};
      }
    }
  }
`;

const HeaderNav = ({
                       open = false,
                       onClose,
                   }) => {
    const navRef = useRef();
    const { isAuth } = useAuth();
    const { setPath } = useNav();
    const { locale } = useIntl();
    const isMobile = useMediaQuery({ query: mdQueryDown });
    const isTablet = useMediaQuery({ query: xlQueryDown });
    const { unreadTotal } = useSelector(notificationState);
    const { profileName } = useSelector(userState);
    const classes = [];
    const [isOpening, setIsOpening] = useState(false);
    const [isOpened, setIsOpened] = useState(false);

    if (isTablet) classes.push('mobile');
    if (isOpening) classes.push('opening');
    if (isOpened) classes.push('open');

    const handleNavClick = (e) => {
        if (navRef.current === e.target && onClose) onClose();
    };

    useEffect(() => {
        let timerId;

        if (open) {
            setIsOpening(true);

            timerId = setTimeout(() => {
                setIsOpening(false);
                setIsOpened(true);
            }, 100);
        } else {
            setIsOpened(false);
            setIsOpening(true);

            timerId = setTimeout(() => {
                setIsOpening(false);
            }, 300);
        }

        return () => clearInterval(timerId);
    }, [open]);

    const routes = useMemo(() => (isAuth ? authNavRoutes : publicNavRoutes)
        .map(({ path, title }) => ({
            path,
            title: title[locale],
        })), [isAuth, locale]);

    return (
        <StyledNav
            ref={navRef}
            className={classes.join(' ')}
            onClick={handleNavClick}
        >
            <div className={`
                styled-nav-wrapper
                ${isTablet ? 'styled-nav-wrapper__mobile' : ''}
                ${open && !isOpening ? 'styled-nav-wrapper__open' : ''}
                `}
            >
                <div className={`${isMobile ? 'container' : ''}`}>
                    {
                        isMobile && isAuth && (
                            <div className="styled-nav-user justify-content-between">
                                <HeaderPersonalSelect profileName={profileName} open={open} />
                                <NavLink
                                    className={`d-flex justify-content-end styled-nav-link me-0 me-md-5 ${isTablet ? 'styled-nav-link__mobile' : ''}`}
                                    to={PERSONAL_NOTIFICATIONS_ROUTE}
                                    onClick={setPath(PERSONAL_NOTIFICATIONS_ROUTE)}
                                >
                                    <Badge count={unreadTotal}>
                                        <Bell />
                                    </Badge>
                                </NavLink>
                            </div>
                        )
                    }
                    <NavMenu
                        routes={routes}
                        className={`styled-nav-list ${isTablet ? 'styled-nav-list__mobile' : ''}`}
                    />
                    {
                        isMobile && (
                            <div className="styled-nav-user styled-nav-user__bottom">
                                <Phone />
                                <Lang variant="inline" />
                            </div>
                        )
                    }
                </div>
            </div>
        </StyledNav>
    );
};

export default HeaderNav;
