import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../ui/Pagination/Pagination';
import { auctionsState, setAuctionsProperty } from '../../../store/reducers/auctionsReducer';

const AuctionsPagination = () => {
    const dispatch = useDispatch();
    const {
        total, pageSize, currentPage, auctions, lastPage,
    } = useSelector(auctionsState);

    const onChangePageHandler = (value) => dispatch(setAuctionsProperty({
        property: 'currentPage',
        value,
    }));
    const showMore = () => dispatch(setAuctionsProperty({
        property: 'pageSize',
        value: pageSize + 10,
    }));

    return (
        <Pagination
            total={total}
            pageSize={pageSize}
            lastPage={lastPage}
            currentPage={currentPage}
            items={auctions}
            onChange={onChangePageHandler}
            showMore={showMore}
            pageType="auctions"
        />
    );
};

export default AuctionsPagination;
