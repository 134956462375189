import React from 'react';
import { ReactComponent as Pork } from '../svg/pork.svg';
import { ReactComponent as Beef } from '../svg/beef.svg';
import { ReactComponent as Chicken } from '../svg/chicken.svg';
import { ReactComponent as Lamb } from '../svg/lamb.svg';
import { ReactComponent as Duck } from '../svg/duck.svg';
import { ReactComponent as Fish } from '../svg/fish.svg';
import { ReactComponent as Turkey } from '../svg/turkey.svg';
import { ReactComponent as Seafood } from '../svg/seafood.svg';
import { ReactComponent as Milk } from '../svg/milk.svg';

export const getCategoryIcon = (name) => {
    const icons = {
        pork: <Pork />,
        beef: <Beef />,
        chicken: <Chicken />,
        lamb: <Lamb />,
        duck: <Duck />,
        fish: <Fish />,
        turkey: <Turkey />,
        seafood: <Seafood />,
        milk: <Milk />,
    };

    return icons[name];
};
