import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Image } from 'antd';
import { FormattedMessage } from 'react-intl';
import { POLICY_ROUTE } from '../../utils/consts';
import {
    $basicBlack, $basicBlue, $basicDarkGreen, $basicGreen, $basicGrey2,
} from '../../styles/styled/colors';
import { CONTACTS } from '../../utils/contacts';
import qr from '../../img/qr.jpg';
import Lang from '../../ui/Lang/Lang';
import { Logo } from '../../ui';
import { lgQueryDown, mdQueryDown } from '../../styles/styled/sizes';
import FooterNav from './FooterNav';

const StyledFooter = styled.footer`
  color: ${$basicBlack};
  padding: 0 0 12px;
  background: ${$basicBlue};
  min-height: 144px;

  .styled-footer {
    &__left {
      padding-top: 32px;
      height: 100%;

      @media ${lgQueryDown} {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      @media ${mdQueryDown} {
        display: block;
      }
    }

    &__copy {
      font-size: 12px;
      color: ${$basicGrey2};

      @media ${mdQueryDown} {
        margin-bottom: 10px;
      }
    }

    &__nav {
      margin: 30px 0 0;
    }

    &__lang {
      margin: 15px 0 5px;

      @media ${mdQueryDown} {
        margin-bottom: 10px;

        > * {
          justify-content: flex-start;
          margin-left: -8px;
        }
      }
    }

    &__link {
      color: ${$basicDarkGreen};
      text-decoration: none;
      transition: .25s ease-in;
      margin: 0 0 0 28px;

      @media ${mdQueryDown} {
        margin: 0 0 10px;
        display: block;
      }

      &:hover {
        color: ${$basicGreen};
      }
    }

    &__policy {
      color: ${$basicGrey2};
      text-decoration: none;
      transition: .25s ease-in;
      font-size: 12px;

      &:hover {
        color: ${$basicGreen};
      }
    }
  }
`;

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <StyledFooter>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6 styled-footer__left">
                        <Logo />
                        <FooterNav />
                    </div>
                    <div className="col-12 col-lg-6">
                        <div
                            className="d-md-flex justify-content-end align-items-center pt-md-3 mb-4 mb-md-0 pb-2 pb-md-0"
                        >
                            <a href={`mailto:${CONTACTS.email}`} className="styled-footer__link">{CONTACTS.email}</a>
                            <a
                                href={`tel:${CONTACTS.phoneDigits}`}
                                className="styled-footer__link"
                            >
                                {CONTACTS.phoneDisplay}
                            </a>
                            <div className="ms-md-4">
                                <Image src={qr} width={58} />
                            </div>
                        </div>
                        <div className="styled-footer__lang">
                            <Lang variant="inline" />
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mt-2">
                    <div className="col-12 col-md-6">
                        <div className="styled-footer__copy">
                            © 2018-
                            {currentYear}
                            , MEATASIA Ltd. All rights reserved.
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="d-flex justify-content-md-end align-items-center">
                            <NavLink
                                to={POLICY_ROUTE}
                                className="styled-footer__policy"
                            >
                                <FormattedMessage id="policy" />
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </StyledFooter>
    );
};

export default Footer;
