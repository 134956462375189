import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { $basicWhite } from '../../../../styles/styled/colors';
import { StyledButton, StyledHeader, StyledText } from './styles';
import { setSupportFormOpen } from '../../../../store/reducers/notificationReducer';

const SupportHeader = () => {
    const dispatch = useDispatch();
    const supportCallHandler = () => {
        dispatch(setSupportFormOpen(true));
    };
    return (
        <StyledHeader>
            <StyledText
                color={$basicWhite}
                variant="normal"
            >
                <FormattedMessage id="writeSupportDescription" />
            </StyledText>
            <StyledButton
                variant="white"
                onClick={supportCallHandler}
            >
                <FormattedMessage id="writeSupport" />
            </StyledButton>
        </StyledHeader>
    );
};

export default SupportHeader;
