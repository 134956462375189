import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import { useIntl } from 'react-intl';
import useAuction from '../hooks/useAuction';
import AuctionDetailInfoHeader from './AuctionDetailInfoHeader';
import AuctionDetailInfoBody from './AuctionDetailInfoBody';
import AuctionDetailInfoFooter from './AuctionDetailInfoFooter';
import AuctionDetailCustomerCurrentBid from './AuctionDetailCustomerCurrentBid';
import AuctionDetailInfoTitle from './AuctionDetailInfoTitle';
import AuctionDetailInfoDescription from './AuctionDetailInfoDescription';
import AuctionDetailInfoSteps from './AuctionDetailInfoSteps';
import DetailContent from '../../../components/Detail/DetailContent';
import { Button, Text } from '../../../ui';
import { userState } from '../../../store/reducers/userReducer';
import { $basicDarkGreen, $basicGreen } from '../../../styles/styled/colors';
import {
    APPROVED, AUCTIONS_ROUTE, CANT_PARTICIPATE_IN_AUCTION, CUSTOMER, SELLER,
} from '../../../utils/consts';
import { statusColor } from '../../../utils/statusColors';
import localesErrors from '../../../locales/localesErrors';

const StyledDetail = styled.div`
  position: relative;
  z-index: 999;
`;

const AuctionDetailInfo = ({ auction }) => {
    const history = useHistory();
    const { locale, formatMessage } = useIntl();
    const { role, moderationStatus } = useSelector(userState);
    const {
        isCompleted,
        deliveryStatus,
        isWinner,
        isFinished,
    } = useAuction(auction);

    const handleAllClick = () => {
        history.push(AUCTIONS_ROUTE);
    };

    return (
        <StyledDetail>
            <AuctionDetailInfoHeader auction={auction} />
            <DetailContent
                style={{
                    background: !!deliveryStatus && '#fff',
                }}
            >
                {
                    moderationStatus !== APPROVED && !isCompleted && role === CUSTOMER && (
                        <Text
                            className="mb-3 text-center"
                            variant="heading"
                            color={statusColor(moderationStatus)}
                        >
                            {localesErrors[locale][CANT_PARTICIPATE_IN_AUCTION]}
                        </Text>
                    )
                }
                {
                    isCompleted && !isFinished
                        ? (
                            <div className="d-flex align-items-center justify-content-center flex-column">
                                <Spin style={{ color: $basicGreen }} />
                                <Text variant="bold2" color={$basicDarkGreen} className="mt-3">
                                    {formatMessage({ id: 'resultInProcess' })}
                                </Text>
                            </div>
                        )
                        : (
                            <>{
                                deliveryStatus && role === CUSTOMER
                                    ? (
                                        <AuctionDetailInfoSteps auction={auction} />
                                    )
                                    : (
                                        <>
                                            <AuctionDetailInfoTitle auction={auction} />
                                            <div
                                                className={`d-md-flex justify-content-between pb-4 ${isCompleted ? '' : 'border-bottom'}`}
                                            >
                                                <AuctionDetailInfoDescription auction={auction} />
                                                <AuctionDetailCustomerCurrentBid auction={auction} />
                                            </div>
                                            {(!isCompleted || isWinner || role === SELLER) && (
                                                <>
                                                    <AuctionDetailInfoBody auction={auction} />
                                                    <AuctionDetailInfoFooter auction={auction} />
                                                </>
                                            )}
                                            {isCompleted && !isWinner && role === CUSTOMER && (
                                                <Button
                                                    uppercase
                                                    width="100%"
                                                    onClick={handleAllClick}
                                                    className="mt-2"
                                                >
                                                    {formatMessage({ id: 'allAuctions' })}
                                                </Button>
                                            )}
                                        </>
                                    )
                            }
                            </>
                        )
                }
            </DetailContent>
        </StyledDetail>
    );
};

export default AuctionDetailInfo;
