import { createAsyncThunk } from '@reduxjs/toolkit';
import $api from '../../api/api';

export const aboutFetch = createAsyncThunk(
    'cms/company-info',
    async (_, { rejectWithValue }) => {
        try {
            return (await $api.get('/cms/company-info/')).data;
        } catch (e) {
            return rejectWithValue(e.response);
        }
    },
);
