import React, { useCallback } from "react";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { auctionBid } from "../../../store/actions/auctionsActions";
import { auctionsState } from "../../../store/reducers/auctionsReducer";
import { $basicGrey2 } from "../../../styles/styled/colors";
import { Button, Text } from "../../../ui";
import Modal from "../../../ui/Modal/Modal";

const StyledAuthBody = styled.div`
	margin: 0 auto;
	max-width: 100%;

	.styled-auth-body {
		&__buttons {
			display: flex;
			gap: 16px;
			margin-top: 40px;
		}
	}
`;

const AuctionConfirm = ({ handleCancel }) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const { bidParams, isLoading } = useSelector(auctionsState);

	const submitFormHandler = useCallback(() => {
		dispatch(auctionBid(bidParams));
	}, [bidParams, dispatch]);

	return (
		<>
			<Modal isModalVisible toggleModal={handleCancel}>
				<StyledAuthBody>
					<div className='row mt-5'>
						<Text variant='bold2' color={$basicGrey2} align='center'>
							<FormattedMessage id='auctionConfirm' />
						</Text>
						<div className='styled-auth-body__buttons'>
							<Button
								onClick={submitFormHandler}
								variant='filled'
								title={formatMessage({ id: "yes" })}
								width='100%'
								uppercase
								disabled={isLoading}
							/>
							<Button
								onClick={handleCancel}
								variant='white'
								title={formatMessage({ id: "no" })}
								width='100%'
								uppercase
							/>
						</div>
					</div>
				</StyledAuthBody>
			</Modal>
		</>
	);
};

export default AuctionConfirm;
