import { useSelector } from 'react-redux';
import is from 'is_js';
import moment from 'moment';
import { localeState } from '../../store/reducers/localesReducer';

const useValidator = (validation) => {
    const { currentLocale } = useSelector(localeState);

    const checkForm = (data) => {
        console.log(data);
        const currentErrors = {};
        validation.forEach((validator) => {
            validator.errors.forEach((error) => {
                const { id } = validator;
                let value = data[id];
                const ids = id.split('/');
                if (ids.length > 1) {
                    const sectionName = ids[0];
                    const sectionProperty = ids[1];
                    const sectionPropertyIndex = ids[2];
                    const sectionData = data[sectionName];
                    // console.log('ids', ids);
                    // console.log('sectionName', sectionName);
                    // console.log('sectionProperty', sectionProperty);
                    // console.log('sectionPropertyIndex', sectionPropertyIndex);
                    // console.log('sectionData', sectionData);
                    if (sectionData) {
                        value = sectionData[sectionProperty];
                        if (sectionName === 'responsiblePersons') {
                            if (Array.isArray(sectionData) && !!sectionData[sectionPropertyIndex - 1]) {
                                value = sectionData[sectionPropertyIndex - 1][sectionProperty];
                            } else if (sectionData[sectionPropertyIndex]) {
                                value = sectionData[sectionPropertyIndex][sectionProperty];
                            }
                        }
                        // if (ids[0] === 'factories' && data[ids[0]]['1']) {
                        //     value = data[ids[0]]['1'][ids[1]];
                        // } else {
                        //     value = data[ids[0]][ids[1]];
                        // }
                        // console.log('value', value);
                    }
                }

                if (error.type === 'required') {
                    if (!value) {
                        currentErrors[id] = error.errorMessage[currentLocale];
                    }
                }
                if (error.type === 'number') {
                    if (value && !is.number(+value)) {
                        currentErrors[id] = error.errorMessage[currentLocale];
                    }
                }
                if (error.type === 'password') {
                    if ((value && !data.confirmPassword) || (value !== data.confirmPassword)) {
                        currentErrors[id] = error.errorMessage[currentLocale];
                    }
                }
                if (error.type === 'email') {
                    if (value && !is.email(value)) {
                        currentErrors[id] = error.errorMessage[currentLocale];
                    }
                }
                if (error.type === 'minLength') {
                    if (value && value.length < error.value) {
                        currentErrors[id] = error.errorMessage[currentLocale];
                    }
                }
                if (error.type === 'min') {
                    if (value && value < error.value) {
                        currentErrors[id] = error.errorMessage[currentLocale];
                    }
                }
                if (error.type === 'notNull') {
                    if (+value === 0) {
                        currentErrors[id] = error.errorMessage[currentLocale];
                    }
                }
                if (error.type === 'minDate') {
                    if (moment(value).isBefore(moment(error.value), 'day')) {
                        currentErrors[id] = error.errorMessage[currentLocale];
                    }
                }
            });
        });
        return currentErrors;
    };

    return { checkForm };
};

export default useValidator;
