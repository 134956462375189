import styled from 'styled-components';

export const StyledDetailsCard = styled.div`
  .details__title {
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  .details__specs {
    margin-left: -6px;
    margin-right: -6px;

    &-img {
      border-radius: 4px;
      width: 60px;
      height: 60px;
      object-fit: cover;
    }

    @media (max-width: 320px) {
      &-img {
        width: 50px;
        height: 50px;
      }
    }
  }
`;
