import { createSlice } from '@reduxjs/toolkit';
import {
    passwordReset,
    passwordResetConfirm, userLogin, userLogout, userRegister,
} from '../actions/authActions';
import {
    ACCOUNT,
    BANK_ADDRESS,
    BANK_NAME,
    CATEGORY,
    COMPANY_ADDRESS,
    COMPANY_NAME,
    CUSTOMER,
    DELIVERY_PORT,
    FACTORY_ADDRESS,
    FACTORY_NAME,
    INACTIVE,
    INN,
    KPP,
    NOT_FOUND,
    ON_MODERATION,
    PERSON_EMAIL,
    PERSON_NAME,
    PERSON_PASSPORT,
    PERSON_PHONE,
    PERSON_WECHAT_ID,
    // PROFILE_NAME,
    SELLER,
    SWIFT,
    VALIDATION_ERROR,
    WRONG_LOGIN_OR_PASSWORD,
} from '../../utils/consts';

import userErrors from '../../locales/localesErrors';
import { prepareFiles } from '../actions/utilsActions';

const initialState = {
    isAuth: false,
    authFormOpen: false,
    regFormOpen: false,
    passResetFormOpen: false,
    moderationFormOpen: false,
    submitOpen: false,
    token: '',
    role: SELLER,
    registrationControls: {
        [CUSTOMER]: [
            // {
            //     id: 'name',
            //     title: 'nameTitle',
            //     controls: [
            //         {
            //             id: PROFILE_NAME,
            //             label: '',
            //             placeholder: 'namePlaceholder',
            //             required: true,
            //         },
            //     ],
            // },
            {
                id: 'port',
                title: 'portTitle',
                controls: [
                    {
                        id: DELIVERY_PORT,
                        label: '',
                        placeholder: 'portPlaceholder',
                        required: true,
                        type: 'select',
                    },
                ],
            },
            {
                id: 'company',
                title: 'companyTitle',
                controls: [
                    {
                        id: COMPANY_NAME,
                        label: 'companyNameLabel',
                        placeholder: 'companyNamePlaceholder',
                        required: true,
                    },
                    {
                        id: COMPANY_ADDRESS,
                        label: 'companyAddressLabel',
                        placeholder: 'companyAddressPlaceholder',
                        required: true,
                    },
                    {
                        id: INN,
                        label: 'innLabel',
                        placeholder: 'innPlaceholder',
                        required: true,
                    },
                    {
                        id: CATEGORY,
                        label: 'categoryLabel',
                        placeholder: 'categoryPlaceholder',
                        type: 'select',
                    },
                ],
            },
            {
                id: 'responsiblePersons/1',
                title: 'responsiblePersonsTitle1',
                position: 1,
                controls: [
                    {
                        controls: [
                            {
                                id: PERSON_NAME,
                                label: 'personNameLabel',
                                placeholder: 'personNamePlaceholder',
                                required: true,
                            },
                            {
                                id: PERSON_PASSPORT,
                                label: 'personPassportLabel',
                                placeholder: 'personPassportPlaceholder',
                                required: true,
                            },
                        ],
                    },
                    {
                        controls: [
                            {
                                id: PERSON_WECHAT_ID,
                                label: 'personWechatLabel',
                                placeholder: 'personWechatPlaceholder',
                            },
                            {
                                id: PERSON_PHONE,
                                label: 'personPhoneLabel',
                                placeholder: 'personPhonePlaceholder',
                                type: 'tel',
                                required: true,
                            },
                            {
                                id: PERSON_EMAIL,
                                label: 'personEmailLabel',
                                placeholder: 'personEmailPlaceholder',
                                required: true,
                            },
                        ],

                    },
                ],
            },
            {
                id: 'responsiblePersons/2',
                title: 'responsiblePersonsTitle2',
                position: 2,
                controls: [
                    {
                        controls: [
                            {
                                id: PERSON_NAME,
                                label: 'personNameLabel',
                                placeholder: 'personNamePlaceholder',
                            },
                            {
                                id: PERSON_PASSPORT,
                                label: 'personPassportLabel',
                                placeholder: 'personPassportPlaceholder',
                            },
                        ],
                    },
                    {
                        controls: [
                            {
                                id: PERSON_WECHAT_ID,
                                label: 'personWechatLabel',
                                placeholder: 'personWechatPlaceholder',
                            },
                            {
                                id: PERSON_PHONE,
                                label: 'personPhoneLabel',
                                placeholder: 'personPhonePlaceholder',
                                type: 'tel',
                            },
                            {
                                id: PERSON_EMAIL,
                                label: 'personEmailLabel',
                                placeholder: 'personEmailPlaceholder',
                            },
                        ],
                    },
                ],
            },
            {
                id: 'requisites',
                title: 'requisitesTitle',
                controls: [
                    {
                        id: BANK_NAME,
                        label: 'bankNameLabel',
                        placeholder: 'bankNamePlaceholder',
                        required: true,
                    },
                    {
                        id: ACCOUNT,
                        label: 'accountLabel',
                        placeholder: 'accountPlaceholder',
                        required: true,
                    },
                ],
            },
        ],
        [SELLER]: [
            {
                id: 'general',
                title: 'generalTitle',
                controls: [
                    {
                        id: COMPANY_NAME,
                        label: 'companyNameLabel',
                        placeholder: 'companyNamePlaceholder',
                        type: 'text',
                        required: true,

                    },
                    {
                        id: COMPANY_ADDRESS,
                        label: 'companyAddressLabel',
                        placeholder: 'companyAddressPlaceholder',
                        type: 'text',
                        required: true,

                    },
                ],
            },
            {
                id: 'factories',
                title: 'factoriesTitle',
                multiply: true,
                position: 1,
                controls: [
                    {
                        id: FACTORY_NAME,
                        selfId: 'name',
                        label: 'factoryNameLabel',
                        placeholder: 'factoryNamePlaceholder',
                        type: 'text',
                        // required: true,

                    },
                    {
                        id: FACTORY_ADDRESS,
                        selfId: 'address',
                        label: 'factoryAddressLabel',
                        placeholder: 'factoryAddressPlaceholder',
                        type: 'text',
                        // required: true,
                    },
                ],
            },
            {
                id: 'responsiblePersons',
                title: 'responsiblePersonsTitle',
                controls: [
                    {
                        id: PERSON_NAME,
                        label: 'personMainNameLabel',
                        placeholder: 'personMainNamePlaceholder',
                        primary: true,
                        position: 1,
                        required: true,
                        controls: [
                            {
                                id: PERSON_PHONE,
                                label: 'personPhoneLabel',
                                placeholder: 'personPhonePlaceholder',
                                type: 'tel',
                                required: true,
                            },
                            {
                                id: PERSON_EMAIL,
                                label: 'personEmailLabel',
                                placeholder: 'personEmailPlaceholder',
                                type: 'email',
                                required: true,
                            },
                        ],
                    },
                    {
                        id: PERSON_NAME,
                        label: 'personAddNameLabel',
                        placeholder: 'personAddNamePlaceholder',
                        position: 2,
                        controls: [
                            {
                                id: PERSON_PHONE,
                                label: 'personPhoneLabel',
                                placeholder: 'personPhonePlaceholder',
                                type: 'tel',
                            },
                            {
                                id: PERSON_EMAIL,
                                label: 'personEmailLabel',
                                placeholder: 'personEmailPlaceholder',
                                type: 'email',
                            },
                        ],

                    },
                ],
            },
            {
                id: 'requisites',
                title: 'requisitesTitle',
                controls: [
                    {
                        id: BANK_NAME,
                        label: 'bankNameLabel',
                        placeholder: 'bankNamePlaceholder',
                        required: true,
                        controls: [
                            {
                                id: INN,
                                label: 'innLabel',
                                placeholder: 'innPlaceholder',
                                type: 'text',
                                required: true,
                            },
                            {
                                id: KPP,
                                label: 'kppLabel',
                                placeholder: 'kppPlaceholder',
                                type: 'text',
                                required: true,
                            },
                        ],
                    },
                    {
                        id: BANK_ADDRESS,
                        label: 'bankAddressLabel',
                        placeholder: 'bankAddressPlaceholder',
                        required: true,
                        controls: [
                            {
                                id: ACCOUNT,
                                label: 'accountLabel',
                                placeholder: 'accountPlaceholder',
                                type: 'text',
                                required: true,
                            },
                            {
                                id: SWIFT,
                                label: 'swiftCodeLabel',
                                placeholder: 'swiftCodePlaceholder',
                                type: 'text',
                            },
                        ],

                    },
                ],
            },
        ],
    },
    additionalSections: {},
    isIndividual: false,
    documents: [],
    errors: [],
    errorCode: '',
    isLoading: false,
    isSuccess: false,
};

const clearForm = (state) => {
    state.password = '';
    state.confirmPassword = '';
    state.phone = '';
    state.company = {};
    state.documents = [];
    state.isIndividual = false;
};

const setPending = (state) => {
    state.isLoading = true;
    state.errors = [];
};

const playerSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setAuthProperty: (state, { payload }) => {
            state[payload.property] = payload.value;
        },
        clearAuthRegistration: clearForm,
    },
    extraReducers: {
        [userLogin.fulfilled]: (state, { payload }) => {
            if (payload.data.token) {
                state.authFormOpen = false;
            }
        },
        [userLogout.fulfilled]: (state, { payload }) => {
            if (payload.data.code === 'success') {
                state.isAuth = false;
            }
        },
        [prepareFiles.pending]: setPending,
        [prepareFiles.fulfilled]: ((state) => {
            state.isLoading = false;
        }),
        [prepareFiles.rejected]: ((state) => {
            state.isLoading = false;
        }),
        [userRegister.pending]: setPending,
        [userRegister.fulfilled]: (state, { payload }) => {
            if (payload.data.token) {
                state.isAuth = true;
                state.regFormOpen = false;
                state.submitOpen = true;
                state.authFormOpen = true;
                state.isLoading = false;
                clearForm(state);
            }
        },
        [userRegister.rejected]: (state, { payload: { data } }) => {
            const { message } = data;
            state.isLoading = false;
            const messages = message.split('/n').filter((error) => !!error);
            if (data?.data?.fields && !!data.data.fields.length) {
                const errors = data.data.fields.map((field, idx) => [field, messages[idx]]);
                if (!!errors.length) {
                    state.errors = errors;
                }
            } else {
                state.errors = [[message]];
            }
        },
        [passwordResetConfirm.fulfilled]: (state, { payload }) => {
            if (payload.data.status && payload.data.status.toLowerCase() === 'ok') {
                state.submitOpen = true;
                state.errors = [];
            }
        },
        [passwordResetConfirm.rejected]: (state, { payload }) => {
            if (payload && payload.code === NOT_FOUND) {
                state.errors = [[userErrors[payload.locale][NOT_FOUND]]];
            }
        },
        [userLogin.rejected]: (state, { payload }) => {
            if (payload && (payload.code === ON_MODERATION || payload.code === INACTIVE)) {
                state.authFormOpen = false;
                state.moderationFormOpen = true;
            }
            if (payload && payload.code === WRONG_LOGIN_OR_PASSWORD) {
                state.errors = [[userErrors[payload.locale][WRONG_LOGIN_OR_PASSWORD]]];
            }
            if (payload && payload.code === VALIDATION_ERROR) {
                state.errors = [[userErrors[payload.locale][VALIDATION_ERROR]]];
            }
        },
        [passwordReset.pending]: (state) => {
            state.isLoading = true;
        },
        [passwordReset.fulfilled]: (state) => {
            state.isLoading = false;
            state.passResetFormOpen = false;
            state.errorCode = '';
            state.authFormOpen = false;
            state.isSuccess = true;
        },
        [passwordReset.rejected]: (state, { payload }) => {
            state.isLoading = false;
            if (payload?.code) {
                state.errorCode = payload?.code;
            }
            if (payload?.code === VALIDATION_ERROR) {
                state.errorCode = WRONG_LOGIN_OR_PASSWORD;
            }
        },
    },
});

export const { setAuthProperty, clearAuthRegistration } = playerSlice.actions;
export const authState = (state) => state.auth;
export default playerSlice.reducer;
