import React from 'react';
import styled from 'styled-components';
import { Paper } from '../ui';
import { mdQueryDown, smQueryDown } from '../styles/styled/sizes';

const StyledStaticPage = styled.div`
  margin: 58px 0 120px;
  position: relative;
  overflow-x: hidden;
  @media ${smQueryDown} {
    margin: 58px 0 80px;
  }
  

  h1 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 40px;
  }

  article {
    p {
      margin-bottom: 30px;
    }
  }
`;

const StyledPaper = styled(Paper)`
  padding: 45px 90px 120px;
  margin: 0 -30px;
  display: block;
  position: relative;

  @media ${mdQueryDown} {
    padding-left: 30px;
    padding-right: 30px;
    margin: 0 -15px;
  }

  @media ${smQueryDown} {
    padding: 45px 15px 80px;
  }
`;

const StaticPage = ({ children, filled = false }) => (
    <StyledStaticPage>
        <div className="container">
            <StyledPaper filled={filled}>{children}</StyledPaper>
        </div>
    </StyledStaticPage>
);

export default StaticPage;
