import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import AuthModal from './AuthModal';
import { Button, Text } from '../../ui';
import { setAuthProperty } from '../../store/reducers/authReducer';
import { $basicGrey2 } from '../../styles/styled/colors';
import { setSupportFormOpen } from '../../store/reducers/notificationReducer';

const ModerationInfo = () => {
    const dispatch = useDispatch();

    const handleCancel = () => {
        dispatch(setAuthProperty({
            property: 'moderationFormOpen',
            value: false,
        }));
    };

    const supportCallHandler = () => {
        dispatch(setSupportFormOpen(true));
    };
    return (
        <AuthModal
            onCancel={handleCancel}
            title={
                <FormattedMessage id="moderationProfileStatusInfo" />
            }
            body={(
                <div className="pb-4">
                    <Text color={$basicGrey2} className="text-center">
                        <FormattedMessage id="forgotPasswordMsg" />
                    </Text>
                    <Button
                        title={<FormattedMessage id="writeSupport" />}
                        onClick={supportCallHandler}
                        width="100%"
                        className="mt-4"
                        variant="bordered"
                        uppercase
                    />
                </div>
            )}

        />
    );
};

export default ModerationInfo;
