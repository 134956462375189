export const xs = 360;
export const sm = 576;
export const md = 768;
export const lg = 992;
export const xl = 1200;

export const xsQueryDown = `(max-width: ${xs}px)`;
export const xsQueryUp = `(min-width: ${xs}px)`;
export const smQueryDown = `(max-width: ${sm}px)`;
export const smQueryUp = `(min-width: ${sm}px)`;
export const mdQueryDown = `(max-width: ${md}px)`;
export const mdQueryUp = `(min-width: ${md}px)`;
export const lgQueryDown = `(max-width: ${lg}px)`;
export const lgQueryUp = `(min-width: ${lg}px)`;
export const xlQueryDown = `(max-width: ${xl}px)`;
export const xlQueryUp = `(min-width: ${xl}px)`;
