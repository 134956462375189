import React from 'react';
import parse from 'html-react-parser';
import { $basicGrey2 } from '../../../../styles/styled/colors';
import { ReactComponent as ArrowDown } from '../../../../svg/arrow-down.svg';
import { StyledCollapse, StyledCollapsePanel, StyledText } from './styles';

const SupportList = ({ items = [] }) => (
    <StyledCollapse
        accordion
        expandIcon={() => <ArrowDown />}
        expandIconPosition="right"
    >
        {
            items.map(({ title, description }, i) => (
               <StyledCollapsePanel
                   key={`${Date.now()}${i}`}
                   header={title}
               >
                   <StyledText variant="heading" color={$basicGrey2}>
                       {parse(description.replaceAll('\r\n', '<br />'))}
                   </StyledText>
               </StyledCollapsePanel>
            ))
        }
    </StyledCollapse>
);

export default SupportList;
