import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './styles/index.scss';
import { Provider } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';
import { BrowserRouter, Route } from 'react-router-dom';
import { store } from './store';
import App from './App';

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <QueryParamProvider ReactRouterRoute={Route}>
                <App />
            </QueryParamProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root'),
);
