import React from 'react';
import styled from 'styled-components';

const StyledCardInfoBottom = styled.div`
  padding-top: 18px;

  .info {
    @media (max-width: 320px) {
      padding-top: 12px;
    }

    &-button {
      text-transform: uppercase;
    }
  }
`;

const CardInfoBottom = ({ children }) => <StyledCardInfoBottom>{children}</StyledCardInfoBottom>;

export default CardInfoBottom;
