import React, { useEffect } from 'react';
import {
    Redirect, Route, Switch,
    useHistory,
} from 'react-router-dom';
import { authRoutes, publicRoutes } from '../routes';
import { HOME_ROUTE } from '../utils/consts';
import useAuth from '../utils/hooks/useAuth';
import useNav from '../Layouts/hooks/useNav';

const AppRouter = () => {
    const { isAuth } = useAuth();
    const { push } = useHistory();
    const { getPath } = useNav();

    useEffect(() => {
        if (isAuth) {
            const path = getPath();
            if (path) {
                push(path);
            }
        }
    }, [isAuth]);

    return (
        <Switch>
            {
                isAuth && authRoutes.map(({ path, Component }) => (
                    <Route key={path} path={path} component={Component} exact />
                ))
            }
            {
                publicRoutes.map(({ path, Component }) => (
                    <Route key={path} path={path} component={Component} exact />
                ))
            }
            <Redirect to={HOME_ROUTE} />
        </Switch>
    );
};

export default AppRouter;
