import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LikeFilled } from '../../svg/like-filled.svg';
import { ReactComponent as LikeEmpty } from '../../svg/like-empty.svg';
import {
    lgQueryDown, mdQueryDown, smQueryDown, xsQueryDown,
} from '../../styles/styled/sizes';
import { $basicDarkGreen, $basicWhite } from '../../styles/styled/colors';
import Text from '../../ui/Text';

const StyledItemInfo = styled.div`
  box-shadow: 0px 10px 30px rgba(10, 9, 75, 0.14);
  position: relative;
  padding: 12px 24px;
  background: ${$basicWhite};
  width: 100%;
  max-width: 770px;
  min-height: 100px;
  display: flex;
  align-items: center;
  margin: 0 auto 30px;
  border-radius: 4px;

  @media ${lgQueryDown} {
    display: block;
  }

  @media ${mdQueryDown} {
    height: auto;
    flex-wrap: wrap;
    margin-top: -10px;
    margin-bottom: 20px;

    > * {
      margin-top: 10px;
      margin-bottom: 10px;

      @media ${xsQueryDown} {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }

  @media ${smQueryDown} {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .info__left {
    width: 45%;
    display: flex;
    align-items: center;

    @media ${lgQueryDown} {
      margin-bottom: 15px;
      width: 100%;
    }

    @media ${mdQueryDown} {
      width: 80%;
    }

    @media ${smQueryDown} {
      margin-bottom: 30px;
      width: 100%;
    }

    svg {
      position: relative;
      top: 2px;
      min-width: 38px;
      width: 38px;
      height: 38px;
    }

    &-icon {
      width: 38px;
      height: 38px;

      path {
        stroke: ${$basicDarkGreen};
      }
    }

    &-content {
      margin-left: 15px;
      max-width: 250px;
    }
  }

  .info__right {
    width: 55%;
    display: flex;
    align-items: center;

    @media ${lgQueryDown} {
      width: 100%;
      flex-wrap: wrap;
      margin: -10px;
    }

    > * {
      flex: 1;

      @media ${lgQueryDown} {
        padding: 10px;
      }

      @media ${mdQueryDown} {
        flex: 1 1 50%;
      }

      @media ${smQueryDown} {
        width: 100%;
        flex: auto;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
      }
    }

    @media ${xsQueryDown} {
      width: 100%;
      padding-left: 57px;
      display: flex;
      align-items: flex-end;
    }
  }
`;

const StyledLike = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const DetailInfo = ({
                        onFavourite,
                        withFavourite = false,
                        isFavourite = false,
                        categoryName,
                        name,
                        icon,
                        items = [],
                    }) => (
    <StyledItemInfo>
        {withFavourite ? (
            <StyledLike type="button" onClick={onFavourite}>
                {isFavourite ? <LikeFilled className="icon" /> : <LikeEmpty className="icon" />}
            </StyledLike>
        ) : null}
        <div className="info__left">
            {icon}
            <div className="info__left-content">
                <Text variant="heading" color={$basicDarkGreen}>{categoryName}</Text>
                <Text variant="bold2" color={$basicDarkGreen} className="mt-sm-2">{name}</Text>
            </div>
        </div>
        <div className="info__right">
            {items.map(({ title, value }) => (
                <div key={title} className="ps-2">
                    <Text variant="heading" color={$basicDarkGreen}>{title}</Text>
                    <Text variant="bold2" color={$basicDarkGreen} className="mt-sm-2 ms-sm-0 ms-1">{value}</Text>
                </div>
            ))}
        </div>
    </StyledItemInfo>
);

export default DetailInfo;
