import React from 'react';
import cx from 'classnames';
import { ReactComponent as ArrowLeft } from '../../../svg/slider-arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../svg/slider-arrow-right.svg';

const SlickArrow = ({ onClick, isNext }) => {
    const handleClick = (e) => {
        e.stopPropagation();
        onClick();
    };
    return (
        <button
            className={cx({ 'slider-arrow-next': isNext, 'slider-arrow-prev': !isNext })}
            onClick={handleClick}
            type="button"
        >
            {isNext ? <ArrowRight /> : <ArrowLeft />}
        </button>
    );
};

export default SlickArrow;
