import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {
    setActiveIndex, setCreate, userState,
} from '../../store/reducers/userReducer';

import { categoriesFetch } from '../../store/actions/productActions';
import ButtonsGroup from '../../ui/ButtonsGroup';
import { Button } from '../../ui';

import PersonalCustomerCreate from './PersonalCustomerCreate';
import PersonalCustomerCompany from './PersonalCustomerCompany';

const PersonalCustomer = ({
                              onChange,
                              values: { companies },
                              resetNewCompany,
                              createNewCompany,
                              errors,
                              resetErrors,
                          }) => {
    const dispatch = useDispatch();
    const [company, setCompany] = useState(null);
    const { create, activeIndex } = useSelector(userState);
    const { formatMessage } = useIntl();

    const undoCreateCompany = () => {
        dispatch(setCreate(false));
        dispatch(setActiveIndex(0));
        resetNewCompany();
        resetErrors();
    };

    useEffect(() => {
        dispatch(categoriesFetch());
    }, [dispatch]);

    useEffect(() => {
        if (companies) {
            const currentCompany = companies[activeIndex];
            if (currentCompany) {
                setCompany(currentCompany);
            }
        }
    }, [companies, activeIndex]);
    return (
        <>

            <div className="row d-flex justify-content-between align-items-center mt-4">
                <ButtonsGroup className="px-0">
                    {
                        !create && !!companies && companies.filter(({ name }) => !!name).map(({ id, name }, idx) => (
                            <Button
                                key={id}
                                title={name}
                                variant={`${activeIndex === idx ? 'filled' : 'white'}`}
                                onClick={() => dispatch(setActiveIndex(idx))}
                            />
                        ))
                    }
                </ButtonsGroup>
                {
                    !create && companies && companies.length < 2 && (
                        <Button
                            className="px-3"
                            title={formatMessage({ id: 'addNewCompany' })}
                            variant="white"
                            onClick={createNewCompany}
                        />
                    )
                }
                {
                    create && (
                        <Button
                            className="px-3"
                            title={formatMessage({ id: 'cancel' })}
                            variant="white"
                            onClick={undoCreateCompany}
                        />
                    )
                }

            </div>

            {
                company && (
                    <>
                        {
                            create
                                ? (
                                    <PersonalCustomerCreate
                                        resetErrors={resetErrors}
                                        errors={errors}
                                        company={company}
                                        onChange={onChange}
                                    />
                                )
                                : (
                                    <PersonalCustomerCompany
                                        resetErrors={resetErrors}
                                        errors={errors}
                                        company={company}
                                        onChange={onChange}
                                    />
                                )
                        }
                    </>
                )
            }
        </>
    );
};

export default PersonalCustomer;
