import React from 'react';
import { useIntl } from 'react-intl';
import { Link, Text } from '../../../../ui';
import { $basicGrey2 } from '../../../../styles/styled/colors';

const WeChatConnectInfo = ({ stepHandler }) => {
    const { formatMessage } = useIntl();
    // const onClick = () => {
    //     setStepOneOpen(true);
    //     // setPopoverVisible(false);
    // };
    return (
        <div className="p-3" style={{ width: 380 }}>
            <Text
                color={$basicGrey2}
                className="mb-3"
                variant="normal"
            >
                {formatMessage({ id: 'weChatInfo' })}
            </Text>
            <Link
                onClick={stepHandler}
            >
                {formatMessage({ id: 'weChatLink' })}
            </Link>
        </div>
    );
};

export default WeChatConnectInfo;
