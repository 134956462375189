import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import AuthModal from '../../../../components/Auth/AuthModal';
import { Button, Text } from '../../../../ui';
import { $basicGrey } from '../../../../styles/styled/colors';
import { userFetch } from '../../../../store/actions/userActions';
import WeChatQR from './WeChatQR';

const WeChaiQRStep = ({ onCancel }) => {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const [step2, setStep2] = useState(false);
    const stepHandler = () => {
        setStep2(true);
    };
    const step2Handler = () => {
        onCancel();
        dispatch(userFetch());
    };
    return (
        <AuthModal
            title={(
                <div className="d-flex flex-column align-items-center">
                    <Text
                        color={$basicGrey}
                        variant="heading2"
                    >
                        {formatMessage({ id: step2 ? 'weChatStep2' : 'weChatStep1' })}
                    </Text>
                    <Text variant="heading3" className="mt-3">
                        {
                            formatMessage({ id: step2 ? 'weChatStep2Title' : 'weChatStep1Title' })
                        }
                    </Text>
                </div>
            )}
            body={(
                <div className="d-flex flex-column align-items-center mb-3">
                    <WeChatQR auth={step2} />
                    <Button
                        variant="filled"
                        className="mt-5 w-100"
                        uppercase
                        onClick={step2 ? step2Handler : stepHandler}
                    >
                        {formatMessage({ id: step2 ? 'weChatStep2Button' : 'weChatStep1Button' })}
                    </Button>
                </div>
            )}
            width={500}
            onCancel={onCancel}
        />
    );
};

export default WeChaiQRStep;
