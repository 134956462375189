import React from 'react';
import styled from 'styled-components';
import { mdQueryDown } from '../styles/styled/sizes';

const StyledButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin: -7px;

  @media ${mdQueryDown} {
    align-items: center;
  }

  h1 {
    @media ${mdQueryDown} {
      margin-bottom: 0;
    }
  }

  > * {
    margin: 7px;
  }

  button {
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 400;
    text-transform: none;
    letter-spacing: 0;

    @media ${mdQueryDown} {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`;

const ButtonsGroup = ({ children, className }) => (
    <StyledButtonGroup className={className}>{children}</StyledButtonGroup>
);

export default ButtonsGroup;
