import React from 'react';
import styled from 'styled-components';
import { $basicRed } from '../../styles/styled/colors';

const StyledFormError = styled.ul`
  color: ${$basicRed};

  li {
    padding: 2px 0;
    letter-spacing: .75px;
  }
`;

const FormError = ({ errors, className = '' }) => (
    <StyledFormError className={className}>
        {errors.map((error, idx) => (
            <li key={idx}>
                <span>{error[0]}</span>
                {
                    error[1] && (
                        <>
                            &nbsp;
                            -
                            &nbsp;
                            <span>{error[1]}</span>
                        </>
                    )
                }
            </li>
        ))}
    </StyledFormError>
);

export default FormError;
