// PRIVATE
import { EN, RU, ZH } from '../locales/localesMap';

export const BAIDU_API_KEY = 'Yho0ZXuuw0csUupA3GQ3DVnn5RzQe0fh';

export const PRODUCTS_ROUTE = '/products';
export const AUCTIONS_ROUTE = '/auctions';
export const PERSONAL_NOTIFICATIONS_ROUTE = '/personal/notifications';
export const PERSONAL_ROUTE = '/personal';

// PUBLIC
export const HOME_ROUTE = '/';
export const ABOUT_ROUTE = '/about';
export const CONTACTS_ROUTE = '/contacts';
export const SUPPORT_ROUTE = '/support';
export const POLICY_ROUTE = '/policy';
export const PASSWORD_RESET = '/password-reset';

// ROLES
export const SELLER = 'seller';
export const CUSTOMER = 'customer';

// LANG
export const LANGUAGES = [
    { name: '中文版', code: ZH },
    { name: 'Eng', code: EN },
    { name: 'Рус', code: RU },
];

// FORM
export const PHONE = 'phone';
export const PROFILE_NAME = 'profileName';
export const PASSWORD = 'password';
export const COMPANY_NAME = 'name';
export const COMPANY_ADDRESS = 'address';

export const FACTORY_NAME = 'factories/name';
export const FACTORY_ADDRESS = 'factories/address';

export const PERSON_NAME = 'responsiblePersons/name';
export const PERSON_PHONE = 'responsiblePersons/phone';
export const PERSON_EMAIL = 'responsiblePersons/email';
export const PERSON_PASSPORT = 'responsiblePersons/passport';
export const PERSON_WECHAT_ID = 'responsiblePersons/wechatId';

export const BANK_NAME = 'requisites/bankName';
export const BANK_ADDRESS = 'requisites/bankAddress';
export const INN = 'requisites/inn';
export const KPP = 'requisites/kpp';
export const ACCOUNT = 'requisites/account';
export const SWIFT = 'requisites/swiftCode';

export const DELIVERY_PORT = 'deliveryPort';
export const CATEGORY = 'mainProductCategoryId';

//BID

export const BID = 50;

// USER STATUSES
export const ON_MODERATION = 'on_moderation';
export const INACTIVE = 'inactive_user';
export const APPROVED = 'approved';
export const NOT_FOUND = 'not_found';
export const FIELD_REQUIRED = 'field_required';
export const ERROR = 'error';
export const WRONG_LOGIN_OR_PASSWORD = 'wrong_login_or_password';
export const VALIDATION_ERROR = 'validation_error';
export const CHECK_FORM = 'check_form';
export const PERMISSION_DENIED = 'permission_denied';
export const TIME_AFTER = 'time_after';
export const CANT_CREATE_OR_CHANGE_ON_MODERATION = 'CANT_CREATE_OR_CHANGE_ON_MODERATION';
export const CANT_CHANGE_WHILE_AUCTION = 'CANT_CHANGE_WHILE_AUCTION';
export const CANT_PARTICIPATE_IN_AUCTION = 'CANT_PARTICIPATE_IN_AUCTION';
export const PHONE_NOT_FOUND = 'phone_not_found';
export const PASSWORDS_MUST_MATCH = 'PASSWORDS_MUST_MATCH';
