import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
// import parse from 'html-react-parser';
import is from 'is_js';
import StaticPage from '../../Layouts/StaticPage';
import { Text } from '../../ui';
import { aboutFetch } from '../../store/actions/pageActions';
import { pageState } from '../../store/reducers/pageReducer';
import ParallaxImage from '../../ui/ParallaxImage';
import AboutCounts from './components/AboutCounts';
import imgPatternTop from '../../img/patterns/1.png';
import AboutVideos from './components/AboutVideos';
import { smQueryDown } from '../../styles/styled/sizes';
import { ZH } from '../../locales/localesMap';

const StyledColumn = styled.div`
  column-count: ${({ isAlone }) => (isAlone ? 2 : 1)};
  margin-bottom: 30px;
  font-size: ${({ locale }) => (locale === ZH ? '20px' : '14px')};
  @media ${smQueryDown} {
    column-count: 1;
  }
`;
const StyledPatternTop = styled.div`
  background-image: url(${imgPatternTop});
  background-repeat: no-repeat;
  position: absolute;
  z-index: 99;
  width: 140px;
  height: 170px;
  right: 30px;
  top: 10px;
  ${({ isMobile }) => isMobile && css`
    transform: scale(.9) rotate(-60deg);
  `}
`;

const About = () => {
    const dispatch = useDispatch();
    const isMobile = is.mobile() || is.tablet();
    const {
        title,
        articleTop,
        articleBottom,
    } = useSelector(pageState);
    const { locale } = useIntl();

    useEffect(() => {
        dispatch(aboutFetch());
    }, []);
    return (
        <StaticPage filled>
            <StyledPatternTop isMobile={isMobile} />
            <Text variant={`${locale === ZH ? 'bold5' : 'bold2'}`}>
                {title ? title[locale] : <FormattedMessage id="companyTitle" />}
            </Text>
            {
                !!articleTop && (
                    <article className="mt-3 mt-md-5 py-0 py-lg-3">
                        {
                            !!articleTop.image && <ParallaxImage src={articleTop.image} isMobile={isMobile} />
                        }
                        {
                            !!articleTop.title && (
                                <Text variant={`${locale === ZH ? 'bold5' : 'bold2'}`} className="mt-4 mt-sm-5">
                                    {articleTop.title[locale]}
                                </Text>
                            )
                        }
                        {
                            !!articleTop.description && (
                                <div className="row mt-3">
                                    {
                                        articleTop.description.map((text, idx) => (
                                            <StyledColumn
                                                key={idx}
                                                className={`col-12 ${!!articleTop.description[idx + 1] && 'col-sm-6'}`}
                                                isAlone={!articleTop.description[idx + 1]}
                                                locale={locale}
                                            >
                                                {text[locale]}
                                            </StyledColumn>
                                        ))
                                    }

                                </div>
                            )
                        }
                    </article>
                )
            }

            <AboutCounts />
            {
                !!articleBottom && (
                    <article className="mt-3 mt-lg-5 py-0 py-lg-5">
                        {
                            !!articleBottom.image && <ParallaxImage src={articleBottom.image} isMobile={isMobile} />
                        }
                        {
                            !!articleBottom.title && (
                                <Text variant={`${locale === ZH ? 'bold5' : 'bold2'}`} className="mt-4 mt-sm-5">
                                    {articleBottom.title[locale]}
                                </Text>
                            )
                        }
                        {
                            !!articleBottom.description && (
                                <div className="row mt-3">
                                    {
                                        articleBottom.description.map((text, idx) => (
                                            <StyledColumn
                                                key={idx}
                                                className={`col-12 ${!!articleBottom.description[idx + 1] && 'col-sm-6'}`}
                                                isAlone={!articleBottom.description[idx + 1]}
                                                locale={locale}
                                            >
                                                {text[locale]}
                                            </StyledColumn>
                                        ))
                                    }

                                </div>
                            )
                        }
                    </article>
                )
            }
            <AboutVideos />

        </StaticPage>
    );
};

export default About;
