import styled from 'styled-components';
import { Switch as BaseSwitch } from 'antd';
import {
    $basicBlue, $basicGreen, $basicGrey, $basicWhite,
} from '../../styles/styled/colors';

export const StyledControl = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLabel = styled.div`
  font-size: 16px;
  margin-right: 20px;
  margin-top: 1px;
  color: ${$basicWhite};
`;

export const StyledSwitch = styled(BaseSwitch)`
  width: 50px;
  height: 30px;
  background-color: ${$basicBlue};

  .ant-switch-handle {
    width: 24px;
    height: 24px;
    top: 3px;

    &:before {
      background-color: ${$basicGrey};
      border-radius: 100%;
    }
  }

  &.ant-switch-checked {
    background-color: ${$basicWhite};

    .ant-switch-handle {
      left: calc(100% - 24px - 2px);

      &:before {
        background-color: ${$basicGreen};
      }
    }
  }
`;
