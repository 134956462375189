import { createSlice } from '@reduxjs/toolkit';
import { contactsFetch } from '../actions/contactsActions';

const initialState = {
    emails: [],
    address: null,
};

const contactsSlice = createSlice({
    name: 'contacts',
    initialState,
    extraReducers: {
        [contactsFetch.fulfilled]: (state, { payload }) => {
            state.emails = payload.emails;
            state.address = payload.address?.description;
        },
    },
});

export const contactsState = (state) => state.contacts;
export default contactsSlice.reducer;
