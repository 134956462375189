import React from 'react';

export default (
    <>
        <p>Добро пожаловать на азиатскую платформу мясных аукционов!<br/>Азиатская платформа мясных аукционов -
            онлайн-платформа для прямой торговли между китайскими импортерами мяса и предприятиями по производству мяса
            со всего мира. Это первая в мире профессиональная платформа мясных аукционов, которая доставляет мясные
            продукты непосредственно с заводов-производителей китайским потребителям по лучшим ценам.
        </p>
        <p>Азиатская платформа мясных аукционов является закрытым клубом по торговле мясными продуктами. Мы выступаем за
            прозрачные принципы ведения бизнеса, чтобы покупатели напрямую покупали мясную продукцию у лицензированных
            заводов, а заводы продавали свою продукцию напрямую покупателям.
        </p>
        <p>Основным акционером платформы MeatAsia является компания Xiamen International Trade Holding Group Co., Ltd.,
            годовой оборот которой составляет 700 млдр юаней.
        </p>
        <p>Xiamen International Trade Holding Group Co., Ltd. – это китайский государственный многопрофильный
            конгломерат, основные интересы которого сконцентрированы в сфере оптовой торговли, логистики, инвестиций,
            финансовых услуг и недвижимости. Xiamen International Trade Holding Group Co., Ltd. входит в число
            крупнейших компаний мира. С 2012 года компания входит в список пяти крупнейших логистических предприятий
            Китая. В 2017 году Xiamen ITG Group Company вошла в рейтинги Forbes Global 2000, Xiamen ITG Holding Group
            Co. — в рейтинг Fortune Global 500, а также в число 500 крупнейших брендов Азии; в 2018 году компания вошла
            в число 50 крупнейших публичных компаний Китая от Fortune, а стоимость бренда превысила 20 млрд юаней.
        </p>
        <p>Компания Xiamen International Trade Holding Group Co., Ltd. будет выступать надежным гарантом безопасности
            ваших сделок.
        </p>
        <p>Для стабильной и безопасной работы MeatAsia заключил прямые договоры с крупнейшими мясными торговыми
            интернет-площадками Китая. Все члены клуба MeatAsia – добросовестные профессионалы, проверенные временем и
            делом с безукоризненной репутацией на рынке. Присоединяйтесь к MeatAsia Club и станьте частью нового мира, с
            возможностью прибыльной интернет-торговли и прозрачных отношений между крупными надежными производителями и
            добросовестными покупателями. Будьте первыми вместе с MeatAsia!
        </p>
    </>
);
