import {
    ACCOUNT,
    BANK_ADDRESS,
    BANK_NAME,
    COMPANY_ADDRESS,
    COMPANY_NAME, CUSTOMER, INN, KPP, PASSWORD,
    PERSON_EMAIL,
    PERSON_NAME, PERSON_PASSPORT,
    PERSON_PHONE, PHONE, PROFILE_NAME,
    SELLER,
    // SWIFT,
} from '../../utils/consts';
import { EN, RU, ZH } from '../../locales/localesMap';

const validator = {
    [SELLER]: [
        {
            id: PROFILE_NAME,
            errors: [{
                type: 'required',
                errorMessage: {
                    [RU]: 'Это поле обязательно для заполнения',
                    [EN]: 'This field is required',
                    [ZH]: 'This field is required',
                },
            },
            ],
        },
        {
            id: COMPANY_NAME,
            errors: [{
                type: 'required',
                errorMessage: {
                    [RU]: 'Это поле обязательно для заполнения',
                    [EN]: 'This field is required',
                    [ZH]: 'This field is required',
                },
            },
            ],
        },
        {
            id: COMPANY_ADDRESS,
            errors: [{
                type: 'required',
                errorMessage: {
                    [RU]: 'Это поле обязательно для заполнения',
                    [EN]: 'This field is required',
                    [ZH]: 'This field is required',
                },
            },
            ],
        },
        {
            id: `${PERSON_NAME}/1`,
            errors: [{
                type: 'required',
                errorMessage: {
                    [RU]: 'Это поле обязательно для заполнения',
                    [EN]: 'This field is required',
                    [ZH]: 'This field is required',
                },
            },
            ],
        },
        {
            id: `${PERSON_PHONE}/1`,
            errors: [{
                type: 'required',
                errorMessage: {
                    [RU]: 'Это поле обязательно для заполнения',
                    [EN]: 'This field is required',
                    [ZH]: 'This field is required',
                },
            },
                {
                    type: 'number',
                    errorMessage: {
                        [RU]: 'Введите корректный телефон',
                        [EN]: 'Enter the correct number',
                        [ZH]: 'Enter the correct number',
                    },
                },
            ],
        },
        {
            id: `${PERSON_EMAIL}/1`,
            errors: [{
                type: 'required',
                errorMessage: {
                    [RU]: 'Это поле обязательно для заполнения',
                    [EN]: 'This field is required',
                    [ZH]: 'This field is required',
                },
            },
                {
                    type: 'email',
                    errorMessage: {
                        [RU]: 'Введите корректный email',
                        [EN]: 'Введите корректный email',
                        [ZH]: 'Введите корректный email',
                    },
                },
            ],
        },
        {
            id: BANK_NAME,
            errors: [{
                type: 'required',
                errorMessage: {
                    [RU]: 'Это поле обязательно для заполнения',
                    [EN]: 'This field is required',
                    [ZH]: 'This field is required',
                },
            },
            ],
        },
        {
            id: BANK_ADDRESS,
            errors: [{
                type: 'required',
                errorMessage: {
                    [RU]: 'Это поле обязательно для заполнения',
                    [EN]: 'This field is required',
                    [ZH]: 'This field is required',
                },
            },
            ],
        },
        {
            id: INN,
            errors: [
                {
                    type: 'required',
                    errorMessage: {
                        [RU]: 'Это поле обязательно для заполнения',
                        [EN]: 'This field is required',
                        [ZH]: 'This field is required',
                    },
                },
                {
                    type: 'number',
                    errorMessage: {
                        [RU]: 'Введите корректный ИНН',
                        [EN]: 'Enter the correct number',
                        [ZH]: 'Enter the correct number',
                    },
                },
            ],
        },
        {
            id: KPP,
            errors: [
                {
                    type: 'required',
                    errorMessage: {
                        [RU]: 'Это поле обязательно для заполнения',
                        [EN]: 'This field is required',
                        [ZH]: 'This field is required',
                    },
                },
                {
                    type: 'number',
                    errorMessage: {
                        [RU]: 'Введите корректный номер КПП',
                        [EN]: 'Enter the correct number',
                        [ZH]: 'Enter the correct number',
                    },
                },
            ],
        },
        {
            id: ACCOUNT,
            errors: [
                {
                    type: 'required',
                    errorMessage: {
                        [RU]: 'Это поле обязательно для заполнения',
                        [EN]: 'This field is required',
                        [ZH]: 'This field is required',
                    },
                },
            ],
        },
        // {
        //     id: SWIFT,
        //     errors: [
        //         {
        //             type: 'required',
        //             errorMessage: {
        //                 [RU]: 'Это поле обязательно для заполнения',
        //                 [EN]: 'This field is required',
        //                 [ZH]: 'This field is required',
        //             },
        //         },
        //     ],
        // },
        {
            id: PHONE,
            errors: [
                {
                    type: 'required',
                    errorMessage: {
                        [RU]: 'Это поле обязательно для заполнения',
                        [EN]: 'This field is required',
                        [ZH]: 'This field is required',
                    },
                },
                {
                    type: 'number',
                    errorMessage: {
                        [RU]: 'Введите корректный номер телефона',
                        [EN]: 'Enter the correct number',
                        [ZH]: 'Enter the correct number',
                    },
                },
            ],
        },
        {
            id: PASSWORD,
            errors: [
                {
                    type: 'required',
                    errorMessage: {
                        [RU]: 'Это поле обязательно для заполнения',
                        [EN]: 'This field is required',
                        [ZH]: 'This field is required',
                    },
                },
                {
                    type: 'password',
                    errorMessage: {
                        [RU]: 'Пароли должны совпадать',
                        [EN]: 'Passwords must match',
                        [ZH]: 'Passwords must match',
                    },
                },
            ],
        },
    ],
    [CUSTOMER]: [
        {
            id: PROFILE_NAME,
            errors: [{
                type: 'required',
                errorMessage: {
                    [RU]: 'Это поле обязательно для заполнения',
                    [EN]: 'This field is required',
                    [ZH]: 'This field is required',
                },
            },
            ],
        },
        {
            id: COMPANY_NAME,
            errors: [{
                type: 'required',
                errorMessage: {
                    [RU]: 'Это поле обязательно для заполнения',
                    [EN]: 'This field is required',
                    [ZH]: 'This field is required',
                },
            },
            ],
        },
        {
            id: COMPANY_ADDRESS,
            errors: [{
                type: 'required',
                errorMessage: {
                    [RU]: 'Это поле обязательно для заполнения',
                    [EN]: 'This field is required',
                    [ZH]: 'This field is required',
                },
            },
            ],
        },
        {
            id: `${PERSON_NAME}/1`,
            errors: [{
                type: 'required',
                errorMessage: {
                    [RU]: 'Это поле обязательно для заполнения',
                    [EN]: 'This field is required',
                    [ZH]: 'This field is required',
                },
            },
            ],
        },
        {
            id: `${PERSON_PHONE}/1`,
            errors: [{
                type: 'required',
                errorMessage: {
                    [RU]: 'Это поле обязательно для заполнения',
                    [EN]: 'This field is required',
                    [ZH]: 'This field is required',
                },
            },
                {
                    type: 'number',
                    errorMessage: {
                        [RU]: 'Введите корректный телефон',
                        [EN]: 'Enter the correct number',
                        [ZH]: 'Enter the correct number',
                    },
                },
            ],
        },
        {
            id: `${PERSON_PASSPORT}/1`,
            errors: [{
                type: 'required',
                errorMessage: {
                    [RU]: 'Это поле обязательно для заполнения',
                    [EN]: 'This field is required',
                    [ZH]: 'This field is required',
                },
            }],
        },
        {
            id: `${PERSON_EMAIL}/1`,
            errors: [{
                type: 'required',
                errorMessage: {
                    [RU]: 'Это поле обязательно для заполнения',
                    [EN]: 'This field is required',
                    [ZH]: 'This field is required',
                },
            },
                {
                    type: 'email',
                    errorMessage: {
                        [RU]: 'Введите корректный email',
                        [EN]: 'Введите корректный email',
                        [ZH]: 'Введите корректный email',
                    },
                },
            ],
        },
        {
            id: BANK_NAME,
            errors: [{
                type: 'required',
                errorMessage: {
                    [RU]: 'Это поле обязательно для заполнения',
                    [EN]: 'This field is required',
                    [ZH]: 'This field is required',
                },
            },
            ],
        },
        {
            id: INN,
            errors: [
                {
                    type: 'required',
                    errorMessage: {
                        [RU]: 'Это поле обязательно для заполнения',
                        [EN]: 'This field is required',
                        [ZH]: 'This field is required',
                    },
                },
                {
                    type: 'number',
                    errorMessage: {
                        [RU]: 'Введите корректный ИНН',
                        [EN]: 'Enter the correct number',
                        [ZH]: 'Enter the correct number',
                    },
                },
            ],
        },
        {
            id: ACCOUNT,
            errors: [
                {
                    type: 'required',
                    errorMessage: {
                        [RU]: 'Это поле обязательно для заполнения',
                        [EN]: 'This field is required',
                        [ZH]: 'This field is required',
                    },
                },
            ],
        },
        {
            id: PHONE,
            errors: [
                {
                    type: 'required',
                    errorMessage: {
                        [RU]: 'Это поле обязательно для заполнения',
                        [EN]: 'This field is required',
                        [ZH]: 'This field is required',
                    },
                },
                {
                    type: 'number',
                    errorMessage: {
                        [RU]: 'Введите корректный номер телефона',
                        [EN]: 'Enter the correct number',
                        [ZH]: 'Enter the correct number',
                    },
                },
            ],
        },
        {
            id: PASSWORD,
            errors: [
                {
                    type: 'required',
                    errorMessage: {
                        [RU]: 'Это поле обязательно для заполнения',
                        [EN]: 'This field is required',
                        [ZH]: 'This field is required',
                    },
                },
                {
                    type: 'password',
                    errorMessage: {
                        [RU]: 'Пароли должны совпадать',
                        [EN]: 'Passwords must match',
                        [ZH]: 'Passwords must match',
                    },
                },
                {
                    type: 'minLength',
                    value: 8,
                    errorMessage: {
                        [RU]: 'Пароль должен быть не менее 8 символов',
                        [EN]: 'Passwords must match',
                        [ZH]: 'Passwords must match',
                    },
                },
            ],
        },
    ],
};

export default validator;
