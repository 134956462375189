import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { ReactComponent as IconAlert } from '../../../svg/alert.svg';
import { getCategoryIcon } from '../../../utils/getCategoryIcon';
import { StyledModalButton } from '../../../ui/Modal/styles';
import { smQueryDown } from '../../../styles/styled/sizes';
import {
    $basicDarkGreen, $basicGrey, $basicGrey2, $basicYellow,
} from '../../../styles/styled/colors';
import { PRODUCTS_ROUTE } from '../../../utils/consts';

export const StyledModalContent = styled.div`
  .modal {
    &__subtext {
      line-height: 18px;
      color: ${$basicGrey2};
      margin: 20px auto 0;
      width: 100%;
      max-width: 500px;
      text-align: center;
      @media (max-width: 320px) {
        text-align: center;
      }
    }
  }
`;

export const StyledCard = styled.div`
  padding: 15px 15px 15px 20px;
  box-shadow: 0px 10px 30px rgba(10, 9, 75, 0.14);
  border-radius: 4px;
  margin-bottom: 40px;

  @media ${smQueryDown} {
    margin-bottom: 60px;
  }

  .card {
    &__top, &__bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__bottom {
      margin-top: 10px;
      align-items: flex-end;

      @media ${smQueryDown} {
        display: block;
      }
    }

    &__text {
      margin-left: 14px;
      position: relative;
      height: fit-content;

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -8px;
        width: 2px;
        height: 2px;
        background-color: ${$basicGrey};
      }

      &:first-child {
        margin-left: 0;

        &:before {
          display: none;
        }
      }

      &-colored {
        color: ${$basicYellow};
        margin-left: 10px;
        width: 100px;
        line-height: 16px;

        @media ${smQueryDown} {
          width: fit-content;
        }
      }
    }

    &__info {
      display: flex;
      flex-wrap: wrap;
    }

    &__status {
      display: flex;
      align-items: center;

      @media ${smQueryDown} {
        margin-bottom: -15px;
        margin-top: 0;
        width: 100%;
        justify-content: center;
        transform: translateY(40px);
      }

      &-icon {
        width: 16px;
        margin-bottom: 5px;

        @media ${smQueryDown} {
          margin-bottom: 2px;
        }
      }
    }

    &__name {
      display: flex;
      align-items: center;

      @media ${smQueryDown} {
        align-items: flex-start;
      }

      &-icon {
        margin-right: 12px;

        @media ${smQueryDown} {
          svg {
            width: 30px;
            height: 30px;
          }
        }
      }

      &-text {
        @media ${smQueryDown} {
          line-height: 18px;
        }

        &-title {
          font-size: 12px;
          color: ${$basicDarkGreen};

          @media ${smQueryDown} {
            font-size: 14px;
          }
        }

        &-subtitle {
          font-size: 18px;
          font-weight: 500;
          color: ${$basicDarkGreen};

          @media ${smQueryDown} {
            font-size: 16px;
          }
        }
      }
    }

    &__weight {
      font-weight: 500;
      color: ${$basicDarkGreen};

      @media ${smQueryDown} {
        margin-left: 42px;
        margin-top: 12px;
      }
    }
  }
`;

const ProductResult = ({ close, product }) => {
    const optionsToLocalShort = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    };

    const formatDate = (date) => new Date(date).toLocaleString('ru', optionsToLocalShort);
    const { locale, formatMessage } = useIntl();

    const updatedAt = product ? formatDate(product.updatedAt) : null;
    const moderatedFromDt = product ? formatDate(product.moderatedFromDt) : null;
    const icon = product ? getCategoryIcon(product.category?.logo) : null;
    const isMobile = useMediaQuery({ query: smQueryDown });

    return (
        <StyledModalContent>
            {product ? (
                <StyledCard className="modal__card card">
                    <div className="card__top">
                        <div className="card__info">
                            <p className="card__text">
                                {updatedAt}
                            </p>
                            <p className="card__text">
                                Россия
                            </p>
                            <p className="card__text">
                                №
                                {product.factory?.id}
                            </p>
                            <p className="card__text">
                                {product.factory?.name}
                            </p>
                        </div>
                        {!isMobile ? (
                            <div className="card__status">
                                <IconAlert className="card__status-icon" />
                                <p className="card__text-colored">
                                    <FormattedMessage
                                        id="onModerationFrom"
                                        values={{ date: moderatedFromDt }}
                                    />
                                </p>
                            </div>
                        ) : null}
                    </div>
                    <div className="card__bottom">
                        <div className="card__name">
                            <div className="card__name-icon">{icon}</div>
                            <div className="card__name-text">
                                <p className="card__name-text-title">
                                    {product.category?.name[locale]}
                                </p>
                                <p className="card__name-text-subtitle">
                                    {product?.name[locale]}
                                </p>
                            </div>
                        </div>
                        <p className="card__weight">
                            <FormattedMessage id="boxWeight" />:&nbsp;
                            <FormattedMessage
                                id="kgValue"
                                values={{ value: +product.boxWeight }}
                            />
                        </p>
                    </div>
                    {isMobile ? (
                        <div className="card__status">
                            <IconAlert className="card__status-icon" />
                            <p className="card__text-colored">
                                <FormattedMessage
                                    id="onModerationFrom"
                                    values={{ date: moderatedFromDt }}
                                />
                            </p>
                        </div>
                    ) : null}
                </StyledCard>
            ) : null}
            <StyledModalButton
                title={formatMessage({ id: !isMobile ? 'goToProductsPage' : 'goToProductsPageMobile' })}
                width="100%"
                variant="filled"
                onClick={() => {
                    close(PRODUCTS_ROUTE);
                }}
            />
            <p className="modal__subtext">
                <FormattedMessage id="productNote" />
            </p>
        </StyledModalContent>
    );
};

export default ProductResult;
