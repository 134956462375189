import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userState } from '../../store/reducers/userReducer';
import {
    auctionsState, clearAuction, setAddModalVisibility,
} from '../../store/reducers/auctionsReducer';
import AuctionsPagination from './components/AuctionsPagination';
import { productState } from '../../store/reducers/productReducer';
import {
    categoriesFetch, countries, factoriesFetch, subcategoriesFetch,
} from '../../store/actions/productActions';
import { CUSTOMER, HOME_ROUTE, SELLER } from '../../utils/consts';
import useAuctionFilter from './hooks/useAuctionFilter';
import { mdQueryDown } from '../../styles/styled/sizes';
import { Button } from '../../ui';
import { ReactComponent as Plus } from '../../svg/plus.svg';
import Page from '../../Layouts/Page';
import ButtonsGroup from '../../ui/ButtonsGroup';
import { deliveryStatuses } from '../../store/actions/utilsActions';
import AuctionsFilter from './components/AuctionsFilter';
import AuctionsList from './components/AuctionsList';
import AuctionsCustomerFilter from './components/AuctionsCustomerFilter';
import FilterContainer from '../../components/FilterContainer';
import AuctionSort from './components/AuctionSort';

const StyledAuctions = styled.div`
  min-height: calc(100vh - 275px);
`;

const Auctions = () => {
    const isMobile = useMediaQuery({ query: mdQueryDown });
    const { push } = useHistory();
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const { role } = useSelector(userState);
    const { activeCategory } = useSelector(productState);
    const { applyFilter } = useAuctionFilter();
    const {
        status, ordering,
        auctions, addModalOpen,
        currentPage,
    } = useSelector(auctionsState);

    const [filterOpen, setFilterOpen] = useState(false);

    const pageTitle = formatMessage({ id: 'auctionsTitle' });

    const openAddModalHandler = () => {
        dispatch(setAddModalVisibility(true));
    };

    const handleFilterToggle = () => {
        setFilterOpen((prev) => !prev);
    };

    useEffect(() => {
        dispatch(clearAuction());
        applyFilter();
    }, [status, activeCategory, role, ordering, currentPage]);

    useEffect(() => {
        let interval = null;
        if (!addModalOpen) {
            interval = setInterval(() => {
                applyFilter();
            }, 5000);
        }
        return () => {
            clearInterval(interval);
        };
    }, [auctions, addModalOpen]);

    useEffect(() => {
        dispatch(categoriesFetch());
        dispatch(factoriesFetch());
        dispatch(subcategoriesFetch());
        dispatch(deliveryStatuses());
        dispatch(countries());
    }, [dispatch]);

    const isSeller = role === SELLER;
    const isCustomer = role === CUSTOMER;

    useEffect(() => {
        if (!role) {
            push(HOME_ROUTE);
        }
    }, [role]);

    return (
        <>
            <Page title={!isMobile ? pageTitle : null}>
                <StyledAuctions>
                    <div className="d-flex flex-md-row flex-column-reverse">
                        <AuctionsFilter />
                        <ButtonsGroup className="justify-content-end align-items-center mb-md-0 mb-4">
                            {isMobile && <h1 className="flex-grow-1 mb-0">{pageTitle}</h1>}
                            {
                                isSeller && (
                                    <Button
                                        variant="white"
                                        bordered
                                        onClick={openAddModalHandler}
                                    >
                                        <Plus />
                                        <span><FormattedMessage id={isMobile ? 'add' : 'addAuction'} /></span>
                                    </Button>
                                )
                            }
                        </ButtonsGroup>
                    </div>
                    {
                        isCustomer && (
                            <div className="mt-md-0 mt-3">
                                <FilterContainer
                                    open={filterOpen}
                                    onToggle={handleFilterToggle}
                                >
                                    <AuctionsCustomerFilter
                                        onSearch={() => setFilterOpen(false)}
                                    />
                                </FilterContainer>
                            </div>
                        )
                    }
                    <AuctionSort />
                    <AuctionsList />
                </StyledAuctions>
            </Page>
            <AuctionsPagination />
        </>
    );
};

export default Auctions;
