import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Text } from '../../../ui';
import { $basicDarkGreen } from '../../../styles/styled/colors';
import useAuction from '../hooks/useAuction';
import { userState } from '../../../store/reducers/userReducer';
import { CUSTOMER } from '../../../utils/consts';

const AuctionDetailInfoTitle = ({ auction }) => {
    const { role } = useSelector(userState);
    const { formatMessage } = useIntl();
    const {
        id,
        isWinner,
        isCompleted,
        startDateFormat,
        startTime,
        endTime,
    } = useAuction(auction);
    return (
        <>
            <div className="d-flex justify-content-center">
                <Text variant="bold">
                    № {id}
                </Text>
            </div>

            <div className="d-flex justify-content-center align-items-center">
                <Text variant="">
                    {formatMessage({ id: 'startDate' })}:&nbsp;
                </Text>
                <Text variant="bold">
                    {startDateFormat}
                </Text>
            </div>
            <div className="d-flex justify-content-center mb-2">
                <div className="d-flex align-items-center ms-4">
                    <Text variant="">
                        {formatMessage({ id: 'startTime' })}:&nbsp;
                    </Text>
                    <Text variant="bold">
                        {startTime}
                    </Text>
                </div>

                <div className="d-flex align-items-center ms-4">
                    <Text variant="">
                        {formatMessage({ id: 'endTime' })}:&nbsp;
                    </Text>
                    <Text variant="bold">
                        {endTime}
                    </Text>
                </div>
            </div>

            {
                isCompleted && (
                    <Text
                        variant="heading3"
                        className={`text-center pt-3 ${isWinner ? 'pb-5' : 'pb-4'}`}
                        color={$basicDarkGreen}
                    >
                        {
                            isWinner
                                ? <FormattedMessage id="congrats"/>
                                : <FormattedMessage id="auctionCompleted"/>
                        }
                        &nbsp;
                        {!isWinner && role === CUSTOMER && (
                            <FormattedMessage id="thankForAuction"/>
                        )}
                    </Text>
                )
            }
        </>
    );
};

export default AuctionDetailInfoTitle;
