import styled from 'styled-components';
import { Paper } from '../../../../../ui';
import { mdQueryDown, smQueryDown, smQueryUp } from '../../../../../styles/styled/sizes';
import Text from '../../../../../ui/Text';
import { $basicGrey } from '../../../../../styles/styled/colors';

export const StyledPaper = styled(Paper)`
  padding: 18px 27px;

  @media ${smQueryDown} {
    padding: 14px 18px;
    margin-left: -15px;
    margin-right: -15px;
  }
`;

export const StyledHeader = styled.div`
  margin-bottom: 20px;
`;

export const StyledText = styled(Text)`
  display: flex;
  flex-wrap: wrap;

  @media ${smQueryDown} {
    display: block;
  }

  > div {
    display: flex;
    align-items: center;

    &:before {
      content: "";
      display: inline-block;
      border-radius: 100%;
      background-color: ${$basicGrey};
      width: 4px;
      height: 4px;
      margin: 0 8px;
      position: relative;
      top: -2px;

      @media ${smQueryDown} {
        margin-left: 0;
      }
    }

    @media ${smQueryDown} {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media ${smQueryUp} {
      &:first-child {
        &:before {
          display: none;
        }
      }
    }
  }
`;

export const StyledDescription = styled.div`
  @media ${mdQueryDown} {
    order: 9999;
    width: 100%;
    margin-top: 11px;
  }
`;
