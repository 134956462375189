import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { $basicBlack, $basicGrey2 } from '../../../styles/styled/colors';
import { Button, Text } from '../../../ui';
import useAuction from '../hooks/useAuction';
import { setAddModalVisibility, setAuctionsProperty } from '../../../store/reducers/auctionsReducer';
import AuctionsCardSmall from './AuctionsCardSmall';
import { xlQueryDown } from '../../../styles/styled/sizes';

const StyledTitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: ${$basicBlack};
  ${({ isMobile }) => isMobile && css`
    font-size: 22px;
    line-height: 26px;
  `}
`;

const AuctionsCreateResult = ({ handleCancel, auction }) => {
    const isTablet = useMediaQuery({ query: xlQueryDown });
    const dispatch = useDispatch();
    const {
        id,
        cardDetailHandler,
    } = useAuction(auction);
    const detailAuctionHandler = useCallback(
        async () => {
            await dispatch(setAddModalVisibility(false));
            dispatch(setAuctionsProperty({
                property: 'isNewAuction',
                value: false,
            }));
            cardDetailHandler();
        },
        [id],
    );
    console.log('isNew');
    return (
        <Modal
            title={(
                <StyledTitle className="container mt-4" isMobile={isTablet}>
                    <FormattedMessage id="createAuctionResultFormTitle" />
                </StyledTitle>
            )}
            closable={false}
            visible
            centered
            footer={null}
            width={isTablet ? '90%' : '60%'}
            onCancel={handleCancel}
            style={{ maxWidth: '800px' }}
        >
            <AuctionsCardSmall auction={auction} />

            <div className="col-12 col-sm-10 mx-auto d-flex align-items-center mt-4">
                <Button
                    uppercase
                    title={<FormattedMessage id="goToAuction" />}
                    width="100%"
                    onClick={detailAuctionHandler}
                />
            </div>
            <div className="col-12 col-sm-10 mx-auto text-center my-4">
                <Text variant="heading" color={$basicGrey2}>
                    <FormattedMessage id="auctionCreateResultText" />
                </Text>
            </div>
        </Modal>
    );
};

export default AuctionsCreateResult;
