import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import is from 'is_js';
import Modal from '../../../ui/Modal/Modal';
import { clearTempData, productState } from '../../../store/reducers/productReducer';
import ProductResult from './ProductResult';
import ProductForm from './ProductForm/ProductForm';
import ProductSuggestForm from './ProductSuggestForm';
import ProductModalTitle from './ProductModalTitle';
import { userState } from '../../../store/reducers/userReducer';
import { Text } from '../../../ui';
import { APPROVED, CANT_CREATE_OR_CHANGE_ON_MODERATION, SELLER } from '../../../utils/consts';
import { statusColor } from '../../../utils/statusColors';
import localesErrors from '../../../locales/localesErrors';
import { $basicBlack } from '../../../styles/styled/colors';

const StyledTitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 30px;
  text-align: left;
  color: ${$basicBlack};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  ${({ isMobile }) => isMobile && css`
    font-size: 22px;
    line-height: 26px;
  `}
`;

const ProductModal = ({
                          visible = false,
                          toggleModal = () => {
                          },
                          edit = false,
                          values = {},
                      }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { locale } = useIntl();
    const {
        lastCreatedProduct,
        isProductChanging,
    } = useSelector(productState);
    const { mainProductCategory, moderationStatus, role } = useSelector(userState);
    const [isOffer, setIsOffer] = useState(false);
    const isMobile = is.mobile() || is.tablet();

    const offerHandler = () => {
        setIsOffer(true);
    };

    const toggleModalHandler = (url) => {
        setIsOffer(false);
        dispatch(clearTempData());
        toggleModal();

        if (url) {
            history.push(url);
        }
    };

    let mode = '';

    if (edit) mode = 'edit';
    if (isOffer) mode = 'offer';

    return (
        <Modal
            title={(
                <StyledTitle isMobile={isMobile}>

                    <ProductModalTitle
                        withBack={isOffer && !isProductChanging}
                        onBack={() => setIsOffer(false)}
                        isSuccess={isProductChanging}
                        mode={mode}
                    />

                    {
                        (moderationStatus !== APPROVED) && (role === SELLER) && (
                            <Text
                                className="ms-3"
                                variant="heading"
                                color={statusColor(moderationStatus)}
                            >
                                {localesErrors[locale][CANT_CREATE_OR_CHANGE_ON_MODERATION]}
                            </Text>
                        )
                    }
                </StyledTitle>

            )}
            isModalVisible={visible}
            toggleModal={toggleModalHandler}
            size={isProductChanging ? 'short' : null}
        >
            {isProductChanging && (
                <ProductResult
                    product={lastCreatedProduct}
                    close={toggleModalHandler}
                />
            )}
            {!isProductChanging && isOffer && <ProductSuggestForm />}
            {!isProductChanging && !isOffer && (
                <ProductForm
                    onOffer={offerHandler}
                    edit={edit}
                    values={values}
                    allowCategories={mainProductCategory ? [mainProductCategory.id] : null}
                />
            )}
        </Modal>
    );
};

export default ProductModal;
