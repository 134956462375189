import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ModalHeader } from '../../../ui/Modal/Modal';

const ProductModalTitle = ({
                               withBack = false,
                               onBack,
                               mode = 'edit',
                               isSuccess,
                           }) => (
    <ModalHeader
        withBack={withBack}
        onBack={onBack}
    >
        {!mode && !isSuccess && <FormattedMessage id="addProduct" />}
        {!mode && isSuccess && <FormattedMessage id="productAdded" />}
        {mode === 'edit' && !isSuccess && <FormattedMessage id="editProduct" />}
        {mode === 'edit' && isSuccess && <FormattedMessage id="productChanged" />}
        {mode === 'offer' && !isSuccess && <FormattedMessage id="suggestProduct" />}
        {mode === 'offer' && isSuccess && (
            <span style={{ display: 'block', textAlign: 'center' }}>
                <FormattedMessage id="productSuggested" />
            </span>
        )}
    </ModalHeader>
);

export default ProductModalTitle;
