import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled, { css } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { AUCTIONS_ROUTE, HOME_ROUTE, PRODUCTS_ROUTE } from '../../utils/consts';
import { $basicBlack, $basicGrey2, $basicWhite } from '../../styles/styled/colors';
import HeaderUser from './HeaderUser';
import Hum from '../../components/Hum';
import CategoryNav from '../CategoryNav/CategoryNav';
import { mdQueryDown, xlQueryDown, xsQueryDown } from '../../styles/styled/sizes';
import { Logo } from '../../ui';
import HeaderNav from './HeaderNav';

const StyledHeader = styled.header`
  min-width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
  color: ${$basicBlack};
  background: ${$basicWhite};
  position: relative;
  overflow: hidden;

  @media ${xlQueryDown} {
    position: fixed;
    z-index: 9999;
  }

  @media ${mdQueryDown} {
    height: 55px;
  }

  ${({ isHome, isNavOpen, scroll }) => isHome && css`
    transition: background .1s;
    color: ${isNavOpen || scroll > 0 ? $basicBlack : $basicWhite};
    background: ${isNavOpen || scroll > 0 ? 'rgba(255, 255, 255, .95)' : 'transparent'}
  }

  ;

  span.line {
    background: ${isNavOpen || scroll > 0 ? $basicBlack : $basicWhite};
  }
  `}
`;

const StyledHum = styled.div`
  right: 24px;
  top: 20px;
  z-index: 999;
  margin-left: auto;
  padding-left: 30px;

  @media ${mdQueryDown} {
    padding-left: 0;
    margin-left: auto;
  }

  ${({ open }) => open && css`
    color: ${$basicGrey2}
  `};
`;

const Header = () => {
    const location = useLocation();
    const isTablet = useMediaQuery({ query: xlQueryDown });
    const isMobileXs = useMediaQuery({ query: xsQueryDown });
    const [scroll, setScroll] = useState(window.scrollY);
    const [navOpen, setNavOpen] = useState(false);

    const handleHumClick = () => {
        setNavOpen((state) => !state);
    };

    const handleNavClose = () => {
        setNavOpen(false);
    };

    const isHome = location.pathname === HOME_ROUTE;
    const isCategoryNavOpen = location.pathname === PRODUCTS_ROUTE
        || location.pathname === AUCTIONS_ROUTE;

    const scrollHandler = () => setScroll(window.scrollY);

    useEffect(() => {
        setNavOpen(false);
    }, [location]);

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);

        return () => window.removeEventListener('scroll', scrollHandler);
    }, []);

    useEffect(() => {
        document.body.style.overflowY = navOpen ? 'hidden' : 'auto';
    }, [navOpen]);

    return (
        <>
            <StyledHeader
                isHome={isHome}
                isNavOpen={navOpen}
                scroll={scroll}
            >
                <div className="container d-flex align-items-center">
                    <Logo small={isMobileXs} className="me-3 me-md-5" />
                    <HeaderNav
                        open={navOpen}
                        onClose={handleNavClose}
                    />
                    {!navOpen ? (
                        <HeaderUser />
                    ) : null}
                    {isTablet ? (
                        <StyledHum
                            open={navOpen}
                            onClick={handleHumClick}
                        >
                            <Hum isOpen={navOpen} />
                        </StyledHum>
                    ) : null}
                </div>
            </StyledHeader>
            <CategoryNav style={{ display: isCategoryNavOpen ? 'block' : 'none' }} />
        </>
    );
};

export default Header;
