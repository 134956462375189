import en from './en';
import ru from './ru';
import zh from './zh';

export const EN = 'EN';
export const RU = 'RU';
export const ZH = 'ZH-HANS';

export const messages = {
    [EN]: en,
    [RU]: ru,
    [ZH]: zh,
};
