import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import Page from '../../Layouts/Page';
import SupportHeader from './components/SupportHeader/SupportHeader';
import SupportList from './components/SupportList/SupportList';
import Text from '../../ui/Text';
import { $basicWhite } from '../../styles/styled/colors';
import { smQueryDown } from '../../styles/styled/sizes';
import { Button } from '../../ui';
import { supportFetch } from '../../store/actions/supportActions';
import { clearSupport, setCurrentPage, supportState } from '../../store/reducers/supportReducer';
import { localeState } from '../../store/reducers/localesReducer';

const StyledWrapper = styled.div`
  @media ${smQueryDown} {
    margin-left: -15px;
    margin-right: -15px;
  }
`;

const Support = () => {
    const dispatch = useDispatch();
    const { currentLocale } = useSelector(localeState);
    const { items, lastPage, currentPage } = useSelector(supportState);
    const isMobile = useMediaQuery({ query: smQueryDown });
    const { formatMessage } = useIntl();

    const handleShowAll = (e) => {
        e.preventDefault();

        dispatch(setCurrentPage(currentPage + 1));
    };

    useEffect(() => {
        dispatch(supportFetch());
    }, [currentPage]);

    useEffect(() => {
        dispatch(setCurrentPage(1));
    }, []);

    useEffect(() => () => {
        dispatch(clearSupport());
    }, []);

    return (
        <Page title={formatMessage({ id: 'supportTitle' })}>
            <SupportHeader />
            <StyledWrapper className="mt-5">
                <SupportList
                    items={items.map(({ title, description }) => ({
                        title: title[currentLocale],
                        description: description[currentLocale],
                    }))}
                />
                {lastPage > currentPage && (
                    <div className="mt-sm-5 mt-4">
                        {!isMobile ? (
                            <a href="#" onClick={handleShowAll}>
                                <Text color={$basicWhite} underline><FormattedMessage id="showMore" /></Text>
                            </a>
                        ) : (
                            <Button
                                variant="bordered"
                                onClick={handleShowAll}
                                className="w-100"
                            >
                                <FormattedMessage id="showMore" />
                            </Button>
                        )}
                    </div>
                )}
            </StyledWrapper>
        </Page>
    );
};

export default Support;
