import styled from 'styled-components';
import { smQueryDown } from '../../../../styles/styled/sizes';
import {
    $basicBlue, $basicDarkGreen, $basicGreen, $basicGrey,
} from '../../../../styles/styled/colors';

export const StyledCategories = styled.div`
  .categories {
    &__top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media ${smQueryDown} {
        flex-direction: column-reverse;
        align-items: flex-start;
      }

      &-btn {
        text-underline-position: under;
        text-underline-offset: -2px;
        text-decoration: underline;
        font-weight: 500;
        color: ${$basicDarkGreen};
        margin-bottom: 20px;

        @media ${smQueryDown} {
          margin-bottom: 10px;
          padding: 0;
        }
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;

      &-item {
        display: flex;
        align-items: center;
        margin: 0 30px 20px 0;
        padding: 0;

        &-img {
          width: 40px;
          height: 40px;
          padding: 5px;
          background: ${$basicBlue};
          margin-right: 8px;
          border-radius: 50%;

          svg {
            width: 100%;
            height: 100%;
          }
        }

        &-active {
          color: ${$basicGreen};

          svg {
            path {
              stroke: ${$basicGreen};
            }
          }
        }
      }

      @media ${smQueryDown} {
        display: none;
      }
    }

    &__list_mobile {
      display: none;
      margin-bottom: 30px;

      .ant-select-selector {
        border-color: ${$basicGrey} !important;
        border-radius: 4px;
        height: 40px;
      }

      .option {
        height: 40px;

        &__content {
          height: 100%;
          display: flex;
          align-items: center;

          &-icon {
            svg {
              max-width: 32px !important;
              max-height: 32px !important;
            }

            margin-right: 14px;
          }
        }
      }

      @media ${smQueryDown} {
        display: block;
      }
    }
  }
`;

export const StyledNaming = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  .naming {
    &__left {
      flex: 1;
      margin-right: 30px;

      &-input {
        height: 40px;
        border-radius: 4px;
      }
    }

    &__right {
      width: 100px;

      &-input {
        height: 40px;
        border-radius: 4px;
      }
    }
  }

  @media ${smQueryDown} {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .naming {
      &__left {
        width: 100%;
        margin-bottom: 30px;
      }

      &__right {
        width: 100%;
      }
    }
  }
`;
