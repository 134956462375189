import React from 'react';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useIntl } from 'react-intl';
import { xlQueryDown } from '../../styles/styled/sizes';
import { PERSONAL_ROUTE } from '../../utils/consts';
import useNav from '../hooks/useNav';

const PersonalLink = () => {
    const isTablet = useMediaQuery({ query: xlQueryDown });
    const { formatMessage } = useIntl();
    const { setPath } = useNav();
    return (
        <NavLink
            className={`styled-nav-link me-3 ${isTablet ? 'styled-nav-link__mobile' : ''}`}
            to={PERSONAL_ROUTE}
            onClick={setPath(PERSONAL_ROUTE)}
        >
            <span className="pt-1">{formatMessage({ id: 'personalAccount' })}</span>
        </NavLink>
    );
};

export default PersonalLink;
