import { createAsyncThunk } from '@reduxjs/toolkit';
import $api from '../../api/api';

export const contactsFetch = createAsyncThunk(
    'cms/contacts',
    async (_, { rejectWithValue }) => {
        try {
            return (await $api.get('/cms/contacts')).data;
        } catch (e) {
            return rejectWithValue(e.response);
        }
    },
);
