import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userState } from '../../store/reducers/userReducer';

const useAuth = () => {
    const { id } = useSelector(userState);
    const [isAuth, setIsAuth] = useState(false);

    useEffect(() => {
        setIsAuth(!!id);
    }, [id]);
    return {
        isAuth,
    };
};

export default useAuth;
