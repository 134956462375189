import styled from 'styled-components';
import { mdQueryDown } from '../../styles/styled/sizes';

export const StyledSupport = styled.div`
  width: 100%;
  height: 70px;
  background: rgba(239, 247, 255, 0.86);
  margin-bottom: 4px;

  @media ${mdQueryDown} {
    height: auto;
  }

  .support__container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media ${mdQueryDown} {
      display: block;
      padding-top: 15px;
      padding-bottom: 15px;
    }

    &-text {
      font-size: 16px;

      @media ${mdQueryDown} {
        margin-bottom: 10px;
      }
    }

    &-btn {
      text-transform: uppercase;
    }
  }

  @media (max-width: 600px) {
    height: 100%;
    padding: 15px 0;
    .support__container {
      flex-direction: column;

      &-text {
        margin-bottom: 10px;
      }

      &-btn {
        width: 100%;
      }
    }
  }
`;
