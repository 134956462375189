import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { StyledShipments } from './styles';
import { Button, Text } from '../../../../ui';
import { userState } from '../../../../store/reducers/userReducer';
import DetailsCards from '../../../../components/Detail/DetailsCards/DetailsCards';
import DetailContent from '../../../../components/Detail/DetailContent';
import {
    APPROVED,
    CANT_CHANGE_WHILE_AUCTION,
    CANT_CREATE_OR_CHANGE_ON_MODERATION,
    ON_MODERATION, SELLER,
} from '../../../../utils/consts';
import { setAuctionsProperty } from '../../../../store/reducers/auctionsReducer';
import { statusColor } from '../../../../utils/statusColors';
import localesErrors from '../../../../locales/localesErrors';

const ProductDetails = ({
                            productId,
                            specifications,
                            media,
                            factory,
                            hasActiveAuctions,
                            onEdit = () => {
                            },
                            edit = true,
                        }) => {
    const { role, moderationStatus } = useSelector(userState);
    const { formatMessage, locale } = useIntl();
    const dispatch = useDispatch();
    const handleAuction = (e) => {
        e.stopPropagation();

        dispatch(setAuctionsProperty({ property: 'formData', value: { productId } }));
        dispatch(setAuctionsProperty({ property: 'addModalOpen', value: true }));
    };

    let error = '';
    if (role === SELLER) {
        if (moderationStatus !== APPROVED) {
            error = localesErrors[locale][CANT_CREATE_OR_CHANGE_ON_MODERATION];
        } else if (hasActiveAuctions && moderationStatus === APPROVED) {
            error = localesErrors[locale][CANT_CHANGE_WHILE_AUCTION];
        }
    }

    return (
        <DetailContent>
            <DetailsCards
                className="details__mini-cards"
                title={`${formatMessage({ id: 'specifications' })}:`}
                cards={specifications}
            />
            <DetailsCards
                className="details__mini-cards"
                title={formatMessage({ id: 'productImages' })}
                cards={media}
            />

            {
                error && (
                    <Text
                        className="text-center mb-3"
                        variant="heading"
                        color={statusColor(ON_MODERATION)}
                    >
                        {error}
                    </Text>
                )
            }

            <StyledShipments className="shipments">
                <p className="shipments__title">
                    <FormattedMessage id="shipmentFactory" />
                </p>
                <div className="shipments__content">
                    <div className="shipments__content-info">{factory?.name}</div>
                    {role === 'seller' && (
                        <Button
                            title={formatMessage({ id: 'editProduct' })}
                            width="50%"
                            variant="bordered"
                            className="mx-auto shipments__content-btn"
                            onClick={onEdit}
                            disabled={hasActiveAuctions}
                        />
                    )}
                </div>
            </StyledShipments>
            {role === 'seller' && edit && (
                <Button
                    title={formatMessage({ id: 'createAuction' })}
                    width="100%"
                    variant="filled"
                    className="mx-auto create-btn"
                    onClick={handleAuction}
                    disabled={moderationStatus !== APPROVED}
                />
            )}
        </DetailContent>
    );
};

export default ProductDetails;
