import React from "react";
import { useMediaQuery } from "react-responsive";
import TextTruncate from "react-text-truncate";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { AiOutlineClockCircle } from "react-icons/ai";
import { FormattedMessage, useIntl } from "react-intl";
import { useTimer } from "react-timer-hook";
import { ReactComponent as Pdf } from "../../../svg/pdf.svg";
import {
	$basicDarkGreen,
	$basicGreen,
	$basicGrey,
} from "../../../styles/styled/colors";
import { localeState } from "../../../store/reducers/localesReducer";
import { Button, Dot, Text } from "../../../ui";
import useAuction from "../hooks/useAuction";
import { xlQueryDown } from "../../../styles/styled/sizes";
import CardInfoTop from "../../../components/Card/CardInfo/CardInfoTop";
import CardInfoMiddle from "../../../components/Card/CardInfo/CardInfoMiddle";
import CardInfoBottom from "../../../components/Card/CardInfo/CardInfoBottom";
import { CardInfo } from "../../../components/Card/styles";
// import { userState } from "../../../store/reducers/userReducer";
// import { SELLER } from "../../../utils/consts";
import { RU } from "../../../locales/localesMap";

const StyledInfo = styled.div`
	width: 56%;
	min-width: 56%;
	border-right: 1px dashed ${$basicGrey};
`;

const StyledPrices = styled.div`
	//flex: 1 0 auto;
	width: 100%;
`;

const AuctionsCardInfo = ({ auction }) => {
	const { currentLocale } = useSelector(localeState);
	// const { role } = useSelector(userState);
	const { formatMessage, locale } = useIntl();
	const {
		id,
		icon,
		price,
		startPrice,
		currencySymbol,
		etdDate,
		boxWeight,
		storageLife,
		name,
		category,
		totalWeight,
		// deliveryCondition,
		endDate,
		factory,
		country,
		startDateFormat,
		isCompleted,
		logisticsType,
		cardDetailHandler,
		paymentConditions,
	} = useAuction(auction);
	// console.log('endDate', endDate);
	const { minutes, hours, days } = useTimer({
		expiryTimestamp: new Date(endDate),
		autoStart: true,
	});

	const isTablet = useMediaQuery({ query: xlQueryDown });
	// let deliveryConditions = "";
	// if (role === SELLER) {
	// 	deliveryConditions = `${formatMessage({ id: "deliveryConditionSeller" })},${
	// 		deliveryCondition.title[locale]
	// 	}`;
	// } else {
	// 	deliveryConditions = formatMessage({ id: "deliveryConditionCustomer" });
	// }
	return !isTablet ? (
		<div className='d-flex w-75 h-100'>
			<StyledInfo className='py-2 px-3'>
				<div className='d-flex pt-1'>
					<Text>
						<FormattedMessage id='factoryName' /> {factory.name}
					</Text>
					<Dot />
					<Text variant='bold'>{country}</Text>
				</div>

				<div
					className='d-flex flex-column justify-content-center'
					style={{ minHeight: "116px" }}>
					<div className='d-flex mt-3'>
						<div className='d-flex align-items-center col-12 col-sm-7'>
							<div className='col-3 d-flex justify-content-center align-items-center'>
								{icon}
							</div>
							<div className='d-flex flex-column justify-content-between ps-2'>
								<Text variant='heading' color={$basicDarkGreen}>
									{category?.name[currentLocale]}
								</Text>
								<Text
									variant='bold2'
									color={$basicDarkGreen}
									style={{ textOverflow: "ellipsis" }}>
									<TextTruncate
										key={currentLocale}
										line={2}
										text={name[currentLocale]}
									/>
								</Text>
							</div>
						</div>

						<div className='col-12 col-sm-5 ps-2 d-flex flex-column'>
							<Text variant='heading' color={$basicDarkGreen}>
								<FormattedMessage id='boxWeight' />
							</Text>
							<Text variant='bold2' color={$basicDarkGreen} className='mt-2'>
								<FormattedMessage id='kgValue' values={{ value: boxWeight }} />
							</Text>
						</div>
					</div>

					<div className='d-flex align-items-center mt-3'>
						<div className='col-12 col-sm-7 d-flex align-items-center'>
							<div className='col-3 d-flex justify-content-center text-center'>
								<Pdf />
							</div>
							<Text variant='bold' className='ps-2'>
								<FormattedMessage id='specifications' />
							</Text>
						</div>
						<div className='col-12 col-sm-5 d-flex align-items-center ps-2'>
							<Text>
								<FormattedMessage id='productExpirationDate' />: &nbsp;
							</Text>
							<Text variant='bold'>
								<FormattedMessage
									id='monthValue'
									values={{ value: (+storageLife).toFixed() }}
								/>
							</Text>
						</div>
					</div>
				</div>

				<div className='d-flex align-items-center mt-3'>
					{/* <Text variant='heading' className='col-12 col-sm-6'>
						{deliveryConditions}
					</Text> */}
					{/* <Text variant='heading' className='col-12 col-sm-6 ps-2'> */}
					<Text variant='heading' className='col-12'>
						{paymentConditions}
						{/* <FormattedMessage id='insuranceServices' /> */}
					</Text>
				</div>
			</StyledInfo>
			<StyledPrices className='py-2 px-3'>
				<div className='d-flex justify-content-between'>
					<div className='d-flex pt-1'>
						<Text>№ {id}</Text>
						<Dot />
						<Text>{startDateFormat}</Text>
					</div>
					{isCompleted ? (
						<Text className='pt-1'>
							<FormattedMessage id='completed' />
						</Text>
					) : (
						<div className='d-flex'>
							<Text className='me-3 pt-1'>
								<FormattedMessage id='beforeEnd' />:
							</Text>
							<AiOutlineClockCircle color={$basicDarkGreen} size={20} />
							<Text variant='heading' color={$basicDarkGreen} className='ms-1'>
								{days ? (
									<FormattedMessage
										id='timeShort'
										values={{ days, hours, minutes }}
									/>
								) : (
									<FormattedMessage id='time' values={{ hours, minutes }} />
								)}
							</Text>
						</div>
					)}
				</div>
				<div
					className='d-flex flex-column justify-content-center'
					style={{ minHeight: "116px" }}>
					<div className='d-flex align-items-end mt-3'>
						<div className='col-12 col-sm-7 pe-2'>
							<Text variant='heading' color={$basicDarkGreen}>
								<TextTruncate
									key={currentLocale}
									line={2}
									text={formatMessage({ id: "currentPricePerTonn" })}
								/>
							</Text>
							<Text variant='bold2' color={$basicGreen} className='mt-2'>
								<FormattedMessage
									id='moneyPerTon'
									values={{ value: price, currency: currencySymbol }}
								/>
							</Text>
						</div>
						<div className='col-12 col-sm-5'>
							<Text variant='heading' color={$basicDarkGreen}>
								<FormattedMessage id='weightNet' />:
							</Text>
							<Text variant='bold2' color={$basicDarkGreen} className='mt-2'>
								<FormattedMessage
									id='tonFullValue'
									values={{ value: totalWeight }}
								/>
							</Text>
						</div>
					</div>

					<div className='d-flex align-items-center mt-3'>
						<div className={`col-12 col-sm-${locale === RU ? "7" : "5"}`}>
							<div
								className={`${
									locale === RU
										? ""
										: "d-flex align-items-center flex-wrap pe-3"
								}`}>
								<Text className='me-2'>
									<FormattedMessage id='startPrice' />:
								</Text>
								<Text
									variant='bold'
									className='col'
									style={{ whiteSpace: "nowrap" }}>
									<FormattedMessage
										id='moneyPerTon'
										values={{ value: startPrice, currency: currencySymbol }}
									/>
								</Text>
							</div>
						</div>
						<div className={`col-12 col-sm-${locale === RU ? "5" : "7"}`}>
							{!!logisticsType && (
								<div
									className={`${
										locale === RU ? "" : "d-flex align-items-center flex-wrap"
									}`}>
									<Text className='me-2'>
										<FormattedMessage id='logisticsType' />:
									</Text>
									<Text
										variant='bold'
										className='col'
										style={{ whiteSpace: "nowrap" }}>
										{logisticsType[locale]}
									</Text>
								</div>
							)}
						</div>
					</div>

					<div className='d-flex align-items-center mt-2'>
						<Text>
							<FormattedMessage id='shipmentETD' />
							:&nbsp;
						</Text>
						<Text variant='bold' className='text-capitalize'>
							{etdDate}
						</Text>
					</div>
				</div>
				<div className='d-flex flex-column justify-content-end'>
					<Button
						title={formatMessage({ id: "goToAuction" })}
						width='100%'
						onClick={cardDetailHandler}
						uppercase
						className={`${locale === RU ? "mt-2" : "mt-3"}`}
					/>
				</div>
			</StyledPrices>
		</div>
	) : (
		<CardInfo>
			<CardInfoTop
				items={[
					`${formatMessage({ id: "factoryName" })}: ${factory?.name}`,
					country,
					`№ ${id}`,
				]}
			/>
			<CardInfoMiddle
				categoryName={category?.name[currentLocale]}
				categoryIcon={icon}
				name={name[currentLocale]}
				weight={+boxWeight}
				expiration={+storageLife}
			/>
			<CardInfoBottom>
				<div className='row mb-4'>
					<div className='col-6'>
						<div className='mb-3'>
							<Text>{name[currentLocale]}</Text>
							<Text variant='bold3' color={$basicGreen} className='mt-2'>
								<FormattedMessage
									id='moneyPerTon'
									values={{ value: price, currency: currencySymbol }}
								/>
							</Text>
						</div>
					</div>
					<div className='col-6'>
						{isCompleted ? (
							<Text className='pt-1 text-end'>
								<FormattedMessage id='completed' />
							</Text>
						) : (
							<>
								<Text className='pt-1 text-end'>
									<FormattedMessage id='beforeEnd' />:
								</Text>
								<div className='d-flex align-items-center justify-content-end mt-2'>
									<AiOutlineClockCircle color={$basicDarkGreen} size={20} />
									<Text
										variant='heading'
										color={$basicDarkGreen}
										className='ms-1'>
										{days ? (
											<FormattedMessage
												id='timeShort'
												values={{ days, hours, minutes }}
											/>
										) : (
											<FormattedMessage id='time' values={{ hours, minutes }} />
										)}
									</Text>
								</div>
							</>
						)}
					</div>
					<div className='d-flex align-items-center flex-wrap'>
						<Text className='text-center me-2'>
							<FormattedMessage id='startPrice' />:
						</Text>
						<Text
							variant='bold'
							className='col'
							style={{ whiteSpace: "nowrap" }}>
							<FormattedMessage
								id='moneyPerTon'
								values={{ value: startPrice, currency: currencySymbol }}
							/>
						</Text>
					</div>
					{!!logisticsType && (
						<div className='d-flex align-items-center flex-wrap mt-2'>
							<Text className='text-center me-2'>
								<FormattedMessage id='logisticsType' />:
							</Text>
							<Text
								variant='bold'
								className='col'
								style={{ whiteSpace: "nowrap" }}>
								{logisticsType[locale]}
							</Text>
						</div>
					)}
					<div className='d-flex align-items-center mt-2'>
						<Text>
							<FormattedMessage id='shipmentETD' />
							:&nbsp;
						</Text>
						<Text variant='bold' className='text-capitalize'>
							{etdDate}
						</Text>
					</div>
				</div>
				<Button
					title={formatMessage({ id: "goToAuction" })}
					width='100%'
					onClick={cardDetailHandler}
					uppercase
				/>
			</CardInfoBottom>
		</CardInfo>
	);
};

export default AuctionsCardInfo;
