import React from 'react';

export default (
    <>
        <p>Welcome to the Asian Meat Auction Platform!<br/>The Asian Meat Auction Platform is an online platform for
            direct trade between Chinese meat importers and meat production enterprises from around the world. It is the
            world&apos;s first professional meat auction platform that delivers meat products directly from
            manufacturing factories to Chinese buyers at the best prices.
        </p>
        <p>The Asian Meat Auction Platform is an exclusive membership meat products trading club. We stand for
            transparent business principles, so that buyers directly buy meat products from licensed factories, and
            factories sell their products directly to buyers.
        </p>
        <p>The main shareholder of the MeatAsia platform is Xiamen International Trade Holding Group Co., Ltd., whose
            annual turnover is 700 million yuan.
        </p>
        <p>Xiamen International Trade Holding Group Co., Ltd. is a Chinese state-owned diversified conglomerate whose
            main interests are concentrated in wholesale trade, logistics, investment, financial services and real
            estate. Xiamen International Trade Holding Group Co., Ltd. is one of the largest companies in the world.
        </p>
        <p>Since 2012, the company has been included in the list of the five largest logistics companies in China. In
            2017, Xiamen ITG Group Company entered the ratings of Forbes Global 2000, Xiamen ITG Holding Group Co. - in
            the Fortune Global 500 rating, as well as among the 500 largest brands in Asia; in 2018, the company was
            among the 50 largest public companies in China from Fortune, and the brand value exceeded 20 billion
            yuan.
        </p>
        <p>Xiamen International Trade Holding Group Co., Ltd. will act as a reliable guarantor of the security of your
            transactions.
        </p>
        <p>For stable and safe operation, MeatAsia has concluded direct contracts with the largest meat trading Internet
            platforms in China. All members of the MeatAsia club are conscientious professionals, proven by time and
            business with an impeccable reputation in the market.
        </p>
        <p>Join the MeatAsia Club and become part of a new world, with the possibility of profitable online trading and
            transparent relations between large reliable manufacturers and conscientious buyers. Be the first with
            MeatAsia!
        </p>
    </>
);
