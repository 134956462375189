import { createSlice } from '@reduxjs/toolkit';
import { supportFetch } from '../actions/supportActions';

const initialState = {
    currentPage: 1,
    items: [],
    lastPage: 1,
    pageSize: 10,
    total: 0,
};

const supportSlice = createSlice({
    name: 'support',
    initialState,
    reducers: {
        setCurrentPage: (state, { payload }) => {
            state.currentPage = payload;
        },
        clearSupport: (state) => {
            Object.keys(state).forEach((key) => {
                state[key] = initialState[key];
            });
        },
    },
    extraReducers: {
        [supportFetch.fulfilled]: (state, { payload }) => {
            state.currentPage = payload.currentPage;
            state.lastPage = payload.lastPage;
            state.pageSize = payload.pageSize;
            state.total = payload.total;

            if (state.currentPage > 1) {
                state.items = [...state.items, ...payload.items];
            } else {
                state.items = payload.items;
            }
        },
    },
});

export const {
    setCurrentPage,
    clearSupport,
} = supportSlice.actions;
export const supportState = (state) => state.support;
export default supportSlice.reducer;
