import { createSlice } from '@reduxjs/toolkit';
import {
    addToFavourite,
    categoriesFetch, countries,
    factoriesFetch,
    productAdd,
    productEdit,
    productFetchById,
    productsFetch,
    productSuggest,
    removeFromFavourite,
    subcategoriesFetch,
} from '../actions/productActions';

const initialState = {
    total: 0,
    pageSize: 15,
    currentPage: 1,
    lastPage: 0,
    items: [],
    categories: [],
    product: {},
    suggestedProduct: null,
    isProductChanging: false,
    isAddModalVisible: false,
    lastCreatedProduct: null,
    detailedItem: null,
    moderationStatus: 'approved',
    isFavouriteStatus: 'all',
    factories: [],
    subcategories: [],
    countries: [],
    customerFilters: {},
    loading: false,
};

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setProductProperty: (state, { payload }) => {
            state.product[payload.property] = payload.value;
        },
        setProductChangeStatus: (state, { payload }) => {
            state.isProductChanging = payload;
        },
        setModerationStatus: (state, { payload }) => {
            state.moderationStatus = payload;
        },
        setIsFavouriteStatus: (state, { payload }) => {
            state.isFavouriteStatus = payload;
        },
        setCurrentPage: (state, { payload }) => {
            state.currentPage = payload;
        },
        setPageSize: (state, { payload }) => {
            state.pageSize = payload;
        },
        setCustomerFilters: (state, { payload }) => {
            state.customerFilters[payload.property] = payload.value;
        },
        resetCustomerFilters: (state) => {
            state.customerFilters = {};
        },
        setActiveCategory: (state, { payload }) => {
            state.activeCategory = payload;
        },
        setIsAddModalVisible: (state) => {
            state.isAddModalVisible = !state.isAddModalVisible;
        },
        clearTempData: (state) => {
            state.lastCreatedProduct = null;
            state.suggestedProduct = null;
        },
        clearProducts: (state) => {
            Object.keys(state).forEach((key) => {
                state[key] = initialState[key];
            });
        },
    },
    extraReducers: {
        [categoriesFetch.fulfilled]: (state, { payload }) => {
            state.categories = payload.data.items;

            if (state.categories.length && !state.activeCategory) {
                state.activeCategory = state.categories[0].id;
            }
        },
        [factoriesFetch.fulfilled]: (state, { payload }) => {
            state.factories = payload.data.items;
        },
        [subcategoriesFetch.fulfilled]: (state, { payload }) => {
            state.subcategories = payload.data.items;
        },
        [productAdd.pending]: (state) => {
            state.loading = true;
        },
        [productAdd.fulfilled]: (state, { payload }) => {
            if (state.moderationStatus === payload.data?.moderationStatus) {
                state.items.push(payload.data);
            }

            state.lastCreatedProduct = payload.data;
            state.isProductChanging = true;
            state.loading = false;
        },
        [productAdd.rejected]: (state) => {
            state.loading = false;
        },
        [addToFavourite.fulfilled]: (state, { payload }) => {
            state.items = state.items.map((el) => (el.id === payload ? ({ ...el, isFavorite: true }) : el));

            if (state.detailedItem) {
                state.detailedItem.isFavorite = true;
            }
        },
        [removeFromFavourite.fulfilled]: (state, { payload }) => {
            state.items = state.items.map((el) => (el.id === payload ? ({ ...el, isFavorite: false }) : el));

            if (state.detailedItem) {
                state.detailedItem.isFavorite = false;
            }
        },
        [productFetchById.pending]: (state) => {
            state.loading = true;
        },
        [productFetchById.fulfilled]: (state, { payload }) => {
            state.detailedItem = payload.data;
            state.loading = false;
        },
        [productFetchById.rejected]: (state) => {
            state.loading = false;
        },
        [productsFetch.pending]: (state) => {
            state.loading = true;
        },
        [productsFetch.fulfilled]: (state, { payload }) => {
            state.items = payload.data.items;
            state.total = payload.data.total;
            state.pageSize = payload.data.pageSize;
            state.currentPage = payload.data.currentPage;
            state.lastPage = payload.data.lastPage;
            state.loading = false;
        },
        [productsFetch.rejected]: (state) => {
            state.loading = false;
        },
        [productEdit.pending]: (state) => {
            state.loading = true;
        },
        [productEdit.fulfilled]: (state, { payload }) => {
            state.isProductChanging = true;
            state.items = state.items.map((el) => (el.id === payload.data.id ? payload.data : el));
            state.detailedItem = payload.data;
            state.lastCreatedProduct = payload.data;
            state.loading = false;
        },
        [productEdit.rejected]: (state) => {
            state.loading = false;
        },
        [productSuggest.pending]: (state) => {
            state.loading = true;
        },
        [productSuggest.fulfilled]: (state, { payload }) => {
            state.isProductChanging = true;
            state.suggestedProduct = payload.data;
            state.loading = false;
        },
        [productSuggest.rejected]: (state) => {
            state.loading = false;
        },
        [countries.fulfilled]: (state, { payload }) => {
            state.countries = payload.data.items;
        },
    },
});

export const {
    setProductProperty,
    setProductChangeStatus,
    setModerationStatus,
    setCurrentPage,
    setPageSize,
    setCustomerFilters,
    resetCustomerFilters,
    setIsFavouriteStatus,
    setActiveCategory,
    clearTempData,
    setIsAddModalVisible,
    clearProducts,
} = productsSlice.actions;
export const productState = (state) => state.products;
export default productsSlice.reducer;
