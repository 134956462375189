import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { ReactComponent as EyeOpen } from '../../svg/eye-open.svg';
import { ReactComponent as EyeClose } from '../../svg/eye-close.svg';
import { $basicGrey, $basicRed } from '../../styles/styled/colors';
import FormLabel from './FormLabel';
import FormInputError from './FormInputError';

const StyledInput = styled.div`
  padding: 0;
  flex: 1 0 auto;
  color: ${({ error }) => (error ? $basicRed : 'currentColor')} !important;

  input {
    border: 1px solid ${$basicGrey};
    box-sizing: border-box;
    border-radius: 4px;
    height: 42px;
  }

  .ant-input-affix-wrapper, .styled-form-input {
    border: 1px solid ${({ error }) => (error ? $basicRed : $basicGrey)} !important;
  }

  .ant-input-suffix {
    color: ${({ error }) => (error ? $basicRed : $basicGrey)} !important;
  }
`;

const FormInput = ({
                       value, label, placeholder,
                       onChange = undefined, type = 'text', link = null,
                       suffix = null, error = '', onFocus,
                       className = '', required, prefix = null,
                       maxLength = null,
                   }) => {
    const isPhone = type === 'phone';
    const maxPhoneLength = 20;

    return (
        <StyledInput error={!!error} className={className}>
            {
                label && <FormLabel label={label} link={link} required={required} />
            }
            <div className="position-relative">
                {
                    type === 'password'
                        ? (
                            <Input.Password
                                onChange={onChange}
                                placeholder={!error ? placeholder : ''}
                                iconRender={(visible) => (visible ? <EyeOpen /> : <EyeClose />)}
                                height={42}
                                value={value}
                                onFocus={onFocus}
                                autoComplete="false"
                                prefix={prefix}
                            />
                        )
                        : (
                            <Input
                                onChange={(e) => onChange(e)}
                                placeholder={!error ? placeholder : ''}
                                value={value}
                                suffix={suffix}
                                type={type}
                                onFocus={onFocus}
                                autoComplete="false"
                                className="styled-form-input"
                                min="0"
                                maxLength={isPhone ? maxPhoneLength : maxLength}
                                prefix={prefix}
                            />
                        )
                }
                {
                    error && <FormInputError error={error} />
                }
            </div>
        </StyledInput>
    );
};

export default FormInput;
