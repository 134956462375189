import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Button } from '../../ui';
import { StyledSupport } from './styles';
import { setSupportFormOpen } from '../../store/reducers/notificationReducer';

const Support = () => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const supportCallHandler = () => {
        dispatch(setSupportFormOpen(true));
    };
    return (
        <StyledSupport className="support">
            <div className="container support__container">
                <p className="support__container-text">
                    <FormattedMessage id="supportText" />
                </p>
                <Button
                    title={formatMessage({ id: 'writeSupport' })}
                    width="320px"
                    variant="bordered"
                    className="support__container-btn"
                    onClick={supportCallHandler}
                />
            </div>
        </StyledSupport>
    );
};

export default Support;
