import styled from 'styled-components';
import { mdQueryDown, xsQueryDown } from '../../../styles/styled/sizes';
import { $basicGrey3, $basicRed, $basicWhite } from '../../../styles/styled/colors';
import Like from '../../../ui/Like';

export const StyledPreview = styled.div`
  position: relative;
  width: 100%;
  height: 205px;
  border-radius: 4px;
  overflow: hidden;
  background: #eff8ff;

  @media ${mdQueryDown} {
    height: 50vw;
    max-height: 205px;
  }

  .ant-image {
    height: 100%;
    width: 100%;

    &:not(.ant-image-error) {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &.ant-image-error {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 85px;
        height: 85px;
      }
    }
  }

  @media ${mdQueryDown} {
    .slick-slide {
      height: 50vw;
      max-height: 205px;

      > div {
        height: 100%;
      }
    }
  }

  .slider-arrow-prev,
  .slider-arrow-next {
    position: absolute;
    z-index: 10;
    top: 40%;

    @media ${xsQueryDown} {
      top: 32%;
    }
  }

  .slider-arrow-prev {
    left: 20px;
  }

  .slider-arrow-next {
    right: 20px;
  }

  @media (max-width: 320px) {
    max-height: 160px;
  }
`;

export const StyledPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledButtonSpecs = styled.button`
  position: absolute;
  width: 56px;
  height: 52px;
  border: 1px solid ${$basicGrey3};
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: ${$basicWhite};
  font-size: 12px;
  bottom: 23px;
  right: 5px;

  span {
    position: relative;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(1, 10, 32, 0.6);
  }

  @media ${mdQueryDown} {
    width: 40px;
    height: 40px;
    right: 15px;
  }

  @media ${xsQueryDown} {
    width: 34px;
    height: 32px;
    border-radius: 4px;
    bottom: 38px;
    right: 20px;
  }
`;

export const StyledButtonLike = styled(Like)`
  position: absolute;
  top: 10px;
  right: 20px;
  width: 34px;

  @media ${xsQueryDown} {
    top: 17px;
    left: 24px;
  }
`;

export const StyledButtonLens = styled.button`
  position: absolute;
  display: none;

  svg > path {
    fill: ${$basicRed};
  }

  @media (max-width: 320px) {
    display: block;
    top: 17px;
    right: 20px;
  }
`;
